import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import PackageJSON from "../package.json";
import RouterForm from "./router/main";
import CacheBuster from "react-cache-buster";
import { Loader } from "./components";

function App() {
    const isTest = process.env.REACT_APP_ENVIRONMENT === "test";
    const version = PackageJSON.version;
    return (
        <Provider store={store}>
            <CacheBuster
                currentVersion={version}
                isEnabled={isTest} //If false, the library is disabled.
                isVerboseMode={false} //If true, the library writes verbose logs to console.
                loadingComponent={Loader} //If not pass, nothing appears at the time of new version check.
                metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
            >
                <div className="App chmedic-data">
                    <RouterForm />
                </div>
            </CacheBuster>
        </Provider>
    );
}

export default App;
