const sys_fields = [
    {key: "unique_patient_ident",label: "Patient ident",pat_key: 0},
    {key: "unique_treatment_ident",label: "Treatment ident",pat_key: -1 },
    {key: "unique_dataset_ident",label: "Dataset ident",pat_key:-1 },
    {key: "patient_calling_name",label: "Patient name",pat_key: 1 },
    {key: "patient_family_name",label: "Patient surname",pat_key: 1 },
    {key: "patient_birthday",label: "Patient birthday",pat_key: 1},
]


export  {sys_fields};