import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

let loading_timeout;
let delayed_hide

export default function Loader() {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const loader_show = useSelector(state => state.systemReducer.loader_show);
    const loader_timeout = useSelector(state => state.systemReducer.loader_timeout);
    const loader_content = useSelector(state => state.systemReducer.loader_content);
    const loader_fullscreen = useSelector(state => state.systemReducer.loader_fullscreen);
    const loader_silent = useSelector(state => state.systemReducer.loader_silent);
    const getContent = () => {
        if (loader_content) return (
            <div className="loader-content">{loader_content}</div>
        )
        return null;
    }

    useEffect(() => {
        if (loader_show) {
            clearTimeout(delayed_hide);
            setShow(true);
        } else {
            delayed_hide = setTimeout((loader_show) => {
                if (!loader_show) {
                    setShow(false)
                }      
            }, 200)
        }
    }, [loader_show])

    const setAutoHide = () => {
        if (loader_timeout) {
            clearTimeout(loading_timeout);
            loading_timeout = setTimeout(() => {
                dispatch({
                    type: "loader_hide"
                });
            }, loader_timeout);
        }
    }
    clearTimeout(loading_timeout);
    if (!show || loader_silent) return null;
    setAutoHide();
    const loader_class = (loader_fullscreen) ? "loader_base fullscreen" : "loader_base"
    return (
        <div className={loader_class}>
            <div className="loader" />
            {getContent()}
        </div>
    );
}