import React from 'react';
import { Table, Form, TableControl, Button } from '../../components';
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next';
import persons_common from './../../data/z_persons_common';
import { searchInArray } from '../../helpers/search';

class PersonSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = { filter: '' };
    }
    handleFilter(params) {
        this.setState({ filter: params.value });
    }
    render() {
        if (!persons_common.length) return null;
        persons_common.sort((a,b) => (a.submitted < b.submitted) ? 1 : ((b.submitted < a.submitted) ? -1 : 0));
        const filteredList = searchInArray({ list: persons_common, filter: this.state.filter, fields: ['name'] });
        const next_status = (this.props.person_selector) ? 'enabled' : 'hidden';
        return (
            <Form className="event-wrap" title="Select patient">
                <div className="monitor-table-info">
                    <TableControl search={this.state.filter} className="survey-template" handleSearch={this.handleFilter.bind(this)} />
                    <Table name="templates_list" className="log-actions" table_key={'auid'}
                        handleSelect={this.props.selectPerson.bind(this)} selected={this.props.person_selector?.auid}
                        headers={{
                            auid: { label: 'ID', width: '5%' },
                            name: { label: 'Name', width: '20%' },
                            birthdate: { label: 'Birthdate', width: '5%' },
                            phone: { label: 'Phone', width: '10%' },
                            email: { label: 'Email', width: '10%' },
                            address: { label: 'Address', width: '40%' },
                        }} data={filteredList} />
                    <Button status={next_status} label="Next" onClick={()=>{
                        this.props.history.replace('/survey_selector');
                    }} />
                </div>
            </Form>

        )
    }
}

function mapStateToProps(state) {
    return {
        person_selector: state.SurveyReducer.person_selector
    };
}

function mapDispatchToProps(dispatch) {
    return ({
        selectPerson(id,params) {
            dispatch({
                type: 'set_person_selector',
                value: params
            });
            dispatch({
                type: 'set_template_selector',
                value: undefined
            });
        },
    })
}

export default withTranslation()(PersonSelector = connect(mapStateToProps, mapDispatchToProps)(PersonSelector));





