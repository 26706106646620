import React from "react";
import {Button} from "../../components";



export default function ImportCSVSetupTable(props) {

    const renderHead = () => {
        const headers = [];
        props.List.header.forEach((hdr) => {
            if (hdr.visible) {
                headers.push (
                    <th className={"hdr-column "+hdr.field} key={"hdr-" + hdr.field}>
                        {hdr.label}
                    </th>
                );
            }
        })
        return (
            <thead>
                <tr className="hdr-labels">{headers}</tr>
            </thead>

        );
    }

    const renderRows = () => {
        let data = [];
        props.List.data.forEach ((row,idx) => {
            let data_row=[]
            row.forEach((cell) => {
                let class_name=`data-column ${cell.field}`
                if (cell.visible){
                    if (cell.isbtn) {
                        data_row.push (
                            <td className={class_name} key={`cell-${idx}-${cell.field}`}>
                                <Button key={cell.field+"-"+cell.id+"-select"}
                                        name={cell.field+"-"+cell.id}
                                        className={"like-link select"}
                                        label={cell.label}
                                        onClick={() => onSelect(row)}
                                />
                            </td>
                        );
                    } else {
                        let cell_txt=cell.label
                        data_row.push (
                            <td className={class_name} key={`cell-${idx}-${cell.field}`}>
                                {cell_txt}
                            </td>
                        );
                    }

                }

            })
            data.push(
                <tr className={"data-column"} key={"data"+idx}>
                    {data_row}
                </tr>
            )

        })

        return (
            <tbody>
                {data}
            </tbody>

        );
    }

    const onSelect = (row) => {
        if (props.onSelect)
            props.onSelect(row)
    }


    return (
        <div className="imp-setup-table">
            <table>
                {renderHead()}
                {renderRows()}
            </table>
        </div>
    )
}