import get_title from "./gettitle";
import get_choices from "./getchoices";
import get_choices_all from "./getchoicesall";
import { isArray } from "chart.js/helpers";

export default function checkbox(
    question,
    survey_answers,
    lang = "default",
    showValues = false
) {
    const title = get_title(question, lang);
    let survey_answer = survey_answers[question.name];
    if (typeof survey_answer === "object") {
        if (typeof survey_answer?.value==="string") {
            survey_answer = survey_answers[question.name]?.value.split(",")
        } else {
            if (survey_answer?.value) {
                if (isArray(survey_answer?.value))
                     survey_answer = survey_answers[question.name]?.value;
                else
                    survey_answer = [survey_answers[question.name]?.value];
            } else {
                if (!isArray(survey_answer))
                    survey_answer = [survey_answers[question.name]];
            }
         }
     }


    let survey_answer_orig="";
    if (survey_answers[question.name]?.value_orig) {
        survey_answer_orig=survey_answers[question.name]?.value_orig
        if (typeof survey_answer_orig === "object") {
            if (typeof survey_answer_orig?.value==="string") {
                survey_answer_orig = survey_answer_orig.value.split(",")
            } else {
                if (!isArray(survey_answer_orig?.value_orig))
                    survey_answer_orig = [survey_answer_orig?.value];
            }
        }
    }

    const answers = [];
    const answers_orig = [];

    if (survey_answer === "N/A") {
        answers.push("N/A");
    } else {
        if (Array.isArray (survey_answer)) {
            survey_answer.forEach ((survey_answer) => {
                let answer;
                if (survey_answer === "other") {
                    const other_answer_name = question.name + "-Comment";
                    let answer_other = survey_answers[other_answer_name];

                    if (typeof answer_other === "object") {
                        answer_other = answer_other?.value;
                    }

                    answer = answer_other ? answer_other + " (other)" : "(other)";
                } else {
                    answer = get_choices (question, survey_answer, lang, showValues);
                    if (!answer) answer = survey_answer;
                }
                answers.push (answer);
            });
        } else {
            answers.push (survey_answer);
        }
    }

    if (Array.isArray(survey_answer_orig)) {
        survey_answer_orig.forEach((one_answer_orig) => {
            let answer_orig;
            if (survey_answer === "other") {
                const other_answer_name = question.name + "-Comment";
                let answer_other = survey_answers[other_answer_name];

                if (typeof answer_other === "object") {
                    answer_other = answer_other?.value;
                }

                answer_orig = answer_other ? answer_other + " (other)" : "(other)";
            } else {
                answer_orig = get_choices(question, one_answer_orig, lang, showValues);
                if (!answer_orig) answer_orig = one_answer_orig;
            }
            answers_orig.push(answer_orig);

        });
    }

    return {
        title: title,
        answer: answers.join(", "),
        answer_orig: answers_orig.join(", "),
        value: survey_answer,
        all_choices: get_choices_all(question, lang),
        type: "checkbox",
    };
}
