import React from "react";
import { Button, Form } from "../../components";
import "./survey_report.css";

import template from "./../../data/z_template";
import person from "./../../data/z_person";

import {
    BsFillPersonLinesFill,
    BsFillCalendarCheckFill,
    BsFillCursorFill,
    BsTelephoneFill,
} from "react-icons/bs";
import { IoIosMail } from "react-icons/io";

import * as qHelper from "./../../helpers/survey";

export default function SurveyReport(props) {
    var pagesCount = Object.keys(template.pages).length;
    const locale = template.locale;
    const survey = {};

    const surveyHeader = () => {
        return (
            <div className="survey-header">
                <div className="export-pdf">
                    <Button
                        label="Export to PDF"
                        onClick={() => {
                            alert("Export to PDF");
                        }}
                    />
                    <Button
                        label="Send to Email"
                        onClick={() => {
                            alert("Send to email");
                        }}
                    />
                    {/* <DownloadPDF /> */}
                </div>

                <div className="survey-title">
                    {qHelper.gettitle(template, locale)}
                </div>
                <div className="survey-description">
                    {qHelper.getdescription(template, locale)}
                </div>
                <div className="person-data">
                    <div className="name">
                        <BsFillPersonLinesFill />
                        {person.name + " (" + person.auid + ")"}
                    </div>
                    <div className="birthdate">
                        <BsFillCalendarCheckFill />
                        {person.birtdate}
                    </div>
                    <div className="address">
                        <BsFillCursorFill />
                        {person.address}
                    </div>
                    <div className="phone">
                        <BsTelephoneFill />
                        {person.phone}
                    </div>
                    <div className="email">
                        <IoIosMail />
                        {person.email}
                    </div>
                </div>
            </div>
        );
    };

    const surveyFooter = () => {
        return (
            <div className="survey-footer">
                <div className="pages-count">
                    {"Pages count: " + pagesCount}
                </div>
            </div>
        );
    };

    const renderQuestion = ({ question, page_number, qnum }) => {
        const questionNumber =
            (question.questionNumberingOverride ?? page_number + "." + qnum) +
            ". ";

        const renderDate = () => {
            const result = qHelper.inputdate(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">{result.answer}</div>
                </div>
            );
        };
        const renderCustom = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">{result.answer}</div>
                </div>
            );
        };
        const renderRadiogroup = () => {
            const result = qHelper.radiogroup(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">{result.answer}</div>
                </div>
            );
        };
        const renderDropdown = () => {
            const result = qHelper.radiogroup(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">{result.answer}</div>
                </div>
            );
        };
        const renderCheckbox = () => {
            const result = qHelper.checkbox(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">{result.answer}</div>
                </div>
            );
        };
        const renderLeadMatrix = () => {
            const result_lead = qHelper.leadmatrix.matrix(question, survey);
            console.log(qHelper.leadmatrix.plain(question, survey));
            const renderHeader = () => {
                return (
                    <div className="question">
                        <div className="question-title">
                            {questionNumber + result_lead.header}
                        </div>
                    </div>
                );
            };
            const renderSide = (side) => {
                const matrix = [];
                const renderSideHeader = (header) => {
                    matrix.push(
                        <div className="question subquestion matrix3">
                            <div className="question-title">{side + ": "}</div>
                            <div className="question-answer">
                                <div className="question-column1">
                                    {header[0]}
                                </div>
                                <div className="question-column2">
                                    {header[1]}
                                </div>
                                <div className="question-column3">
                                    {header[2]}
                                </div>
                            </div>
                        </div>
                    );
                };
                const renderSideAnswers = (row_name, columns) => {
                    matrix.push(
                        <div className="question subquestion2 matrix3">
                            <div className="question-title">
                                {row_name + ": "}
                            </div>
                            <div className="question-answer">
                                <div className="question-column1">
                                    {columns[0]}
                                </div>
                                <div className="question-column2">
                                    {columns[1]}
                                </div>
                                <div className="question-column3">
                                    {columns[2]}
                                </div>
                            </div>
                        </div>
                    );
                };
                Object.keys(result_lead[side]).forEach((row_name) => {
                    if (row_name === "header")
                        renderSideHeader(result_lead[side][row_name]);
                    else
                        renderSideAnswers(
                            row_name,
                            result_lead[side][row_name]
                        );
                });
                return matrix;
            };
            return (
                <>
                    {renderHeader()}
                    {renderSide(Object.keys(result_lead)[1])}
                    {renderSide(Object.keys(result_lead)[2])}
                </>
            );
        };
        const renderLeadProbes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">Need to implement</div>
                </div>
            );
        };
        const renderOtherTypes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">{result.answer}</div>
                </div>
            );
        };
        switch (question.type) {
            case "inputdate":
                return renderDate();
            case "inputcustom":
                return renderCustom();
            case "radiogroup":
                return renderRadiogroup();
            case "dropdown":
                return renderDropdown();
            case "checkbox":
                return renderCheckbox();
            case "leadmatrix8combined":
                return renderLeadMatrix();
            case "leadmatrix8v2":
                return renderLeadMatrix();
            case "leadprobes8v2":
                return renderLeadProbes();
            default:
                return renderOtherTypes();
        }
    };

    const renderQuestions = ({ questions, page_number }) => {
        const questions_render = [];
        let qnum = 1;
        if (!questions) return questions_render;
        Object.keys(questions).forEach((question_index) => {
            const question = questions[question_index];
            questions_render.push(
                renderQuestion({ question, page_number, qnum })
            );
            qnum++;
        });
        return questions_render;
    };

    const surveyPages = () => {
        const pages = [];
        Object.keys(template.pages).forEach((page_index) => {
            const page = template.pages[page_index];
            const page_number = parseInt(page_index) + 1;
            const title = qHelper.gettitle(page, locale);
            console.log(title);
            pages.push(
                <div className="survey-page" key={page_index}>
                    <div className="page-header">
                        <div className="page-number">
                            {"Page: " + page_number}
                        </div>
                        <div className="page-title">{title}</div>
                    </div>
                    <div className="page-body">
                        {renderQuestions({
                            questions: page.elements,
                            page_number: page_number,
                        })}
                    </div>
                </div>
            );
        });
        return pages;
    };

    return (
        <Form title="Survey report" name="survey_report">
            <div className="survey-body">
                {surveyHeader()}
                {surveyPages()}
                {surveyFooter()}
            </div>
        </Form>
    );
}
