import gettitle from "./gettitle";

const add_label_to_question = ({ question, template }) => {
    //add additional cases
    switch (question.type) {
        default:
            return gettitle(question, template.locale);
    }
};

const getAllQuestionsFromTemplate = (template) => {
    const questions = [];

    if (!template) return questions;

    if (!template.pages) {
        return questions;
    }
    const system_page = "system_page";
    let index = 0;
    let page_number = 1;
    let question_number = 0;

    Object.keys(template.pages).forEach((page_index) => {
        const page = template.pages[page_index];

        question_number = 1;
        if (Array.isArray(page.elements)) {
            page.elements.forEach((question) => {
                const questionNumber =
                    question.questionNumberingOverride ??
                    page_number + "." + question_number;

                question.index = index;
                question.number =
                    page.name !== system_page ? questionNumber : "";
                question.label = add_label_to_question({ question, template });
                question.surveyId = template.id;

                questions.push(question);
                index++;
                question_number++;
            });
        }

        if (page.name !== system_page) page_number++;
    });
    return questions;
};

export default getAllQuestionsFromTemplate;
