const data = {
    "question28": "2021-12-19",
    "2 Disorder being treated": [
        "Fecal Disorder",
        "Urinary Disorder"
    ],
    "question4": "item3",
    "4 if fecal disorder": "Constipation",
    "question6": [
        "Urge incontinence",
        "Pelvic pain"
    ],
    "question10": [
        "item1"
    ],
    "underlying disorder": "non-neurogenic",
    "question26": "yes",
    "question23": [
        "item3",
        "item2"
    ],
    "question27": "item2",
    "question25": "4",
    "question24 Wexner": "yes",
    "question1": "yes",
    "question101": "34",
    "question2": "34",
    "question102": "item1",
    "question3": "yes",
    "question16": [
        "item3",
        "item6"
    ],
    "question15": [
        "item1",
        "item3"
    ],
    "question17": [
        "item2",
        "other"
    ],
    "question20": "3",
    "question18": "yes",
    "question116": "4",
    "question19": "yes",
    "question119": "4",
    "question120": "4",
    "question121": 4,
    "question122": "item1",
    "question123": "2",
    "question124": "2",
    "question125": "3",
    "question126": "3",
    "question21": "item1",
    "question34": "item1",
    "question95": "other",
    "question99": "item2",
    "question127": "22",
    "question128": "yes",
    "question132": "item1",
    "question133": "22",
    "question134": "22",
    "question135": "22",
    "question137": "normal",
    "question139": "no",
    "question141": "yes",
    "question142": "22",
    "question144": "yes",
    "question143": "222",
    "question145": "22",
    "question17-Comment": "custom answer blablabla blablabla",
    "question95-Comment": "100"
}

export default data