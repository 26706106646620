const structure = {
  name: "",
  description: "",
  master_template_id: null,
  show_only_mapped_questions: true,
  mapping: [],
 // column_filter: [],
  title: "",
};

export default structure;
