import { useContext, useEffect } from "react";
import {
    TextField,
    Typography,
    Box,
    IconButton,
    Checkbox,
    Divider,
    FormControlLabel,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import PdfEditorContext from "../pdf_editor_context";

const PdfToolbarElementEdit = () => {
    const pdfEditorContext = useContext(PdfEditorContext);

    useEffect(() => {
        const { width, height, x, y, ...object } =
            pdfEditorContext.data.elements.find(
                (f) => f.id === pdfEditorContext.activeElement
            );

        pdfEditorContext.setEditObject(object);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfEditorContext.activeElement]);

    return (
        <PdfEditorContext.Consumer>
            {({ editObject, setEditObject, onElementDelete }) => (
                <Box sx={{ paddingTop: 1 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6" component="h6">
                            Placeholder edit
                        </Typography>
                        <IconButton onClick={onElementDelete}>
                            <Delete />
                        </IconButton>
                    </Box>

                    <TextField
                        id="layer_name"
                        label="Placeholder Label"
                        variant="standard"
                        fullWidth
                        value={editObject?.placeholder || ""}
                        onChange={(e) => {
                            setEditObject({
                                ...editObject,
                                placeholder: e.target.value,
                            });
                        }}
                        sx={{
                            "& label": {
                                fontWeight: "bold",
                            },
                            "& input": {
                                fontWeight: "bold",
                            },
                        }}
                    />
                    <Divider sx={{ marginTop: 8 }}>Options</Divider>
                    <FormControlLabel
                        label="filled by Representative"
                        control={
                            <Checkbox
                                checked={editObject?.isRepresentitive || false}
                                onChange={(e) => {
                                    setEditObject({
                                        ...editObject,
                                        isRepresentitive: e.target.checked,
                                    });
                                }}
                            />
                        }
                    />
                </Box>
            )}
        </PdfEditorContext.Consumer>
    );
};

export default PdfToolbarElementEdit;
