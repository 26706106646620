import { decode as jsdecode, atob } from "js-base64";

export function decode(base64, isUtf8) {
    let data = jsdecode(base64);

    if (!isUtf8 && !data?.includes('"utf8":true')) {
        data = atob(base64);
    }

    return data;
}
