const AdminBadge = () => {
    if (window.auth.adm_su_pcom !== "100") {
        return <></>;
    }

    return (
        <div
            style={{
                backgroundColor: "#FF00C4",
                borderRadius: "4px",
                fontSize: "10px",
                color: "white",
                height: "fit-content",
                padding: "2px",
            }}
        >
            S-ADM
        </div>
    );
};

export default AdminBadge;
