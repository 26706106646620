import React from 'react'
import Field from './field_class';
import { withTranslation } from 'react-i18next';

class CheckField extends Field{
    constructor(props) {
        super(props);
        this.state = {
            isChecked: true,
        };
    }
    onChange(event) {
        if (this.props.params) {
            this.props.onChange({name: this.props.name, value: event.target.checked, params: this.props.params})
        } else {
            this.props.onChange({name: this.props.name, value: event.target.checked})
        }
    }
    getWrapClassName() {
        return "checkbox field_wrap " + this.getNamePostfix();
    }
    showInput() {
        const {t} = this.props;
        let checked_value = (this.props.value) ? true : false;
        let autofocus = (this.props.autofocus) ? true : '';
        return (
            <input autoFocus={autofocus} id={this.getNamePostfix()} name={this.getNamePostfix()} data-tip={this.props.tip}

                   checked={checked_value}
                   type="checkbox"
                   onChange={this.onChange.bind(this)}
                   autoComplete="off"
                   label = {t(this.props.label)}
                   disabled={this.isDisabled()}/>
        )
    }
}

export default withTranslation()(CheckField);