
export const addNdaAnswers = (answers_array) => {
    answers_array.forEach((answer) => {
        addNdaAnswer(answer);
    });
};



export const addNdaAnswer = (answer) => {
    for (const [key] of Object.entries(answer)) {
        if (answer[key]?.nda) {
            let val = answer[key]?.question_master?.type
            if (val === "expression"
                && !answer[key].value
                && answer[key].value !== 0
            ) {
                answer[key].value = "N/A"
            } else {
                answer[key].value = "N/A"
            }
        }
    }

};


