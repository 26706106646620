export default function SurveyReducer(
    state = {
        template_list: [],
        spreadsheet_surveys: [],
        virtual_templates: [],
        showVirtual: null,
    },
    action
) {
    switch (action.type) {
        case "res_templates_get_template_list":
            state.template_list = action.data.list;
            return { ...state };
        case "res_templates_get_template":
            state.template = action.data;
            return { ...state };
        case "set_template_selector":
            state.template_selector = action.value;
            return { ...state };
        case "set_person_selector":
            state.person_selector = action.value;
            return { ...state };
        case "res_load_spreadsheet_template":
            state.spreadsheet_template = action.data;
            return { ...state };
        case "res_get_surveys_for_template":
            state.spreadsheet_template = action.data.template;
            state.spreadsheet_surveys = action.data.surveys;
            state.template = action.data.template_origin;
            return { ...state };
        case "res_get_virtual_templates":
            state.virtual_templates = action.data.virtual_templates;
            return { ...state };
        case "reset_virtual_templates":
            state.virtual_templates = action.value;
            state.virtual_templates = state.virtual_templates.slice();
            return { ...state };
        case "res_delete_cached_instance":
            state.spreadsheet_surveys = state.spreadsheet_surveys.filter(
                (instance) => {
                    return instance.instance_id !== action.value;
                }
            );
            return { ...state };
        case "set_show_virtual":
            state.showVirtual = action.value;
            return { ...state };
        case "set_template_encrypted":
            state.encrypted = action.value;
            return { ...state };
        default:
            break;
    }
    return state;
}
