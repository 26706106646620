import React, { useState } from "react";
import "../../components/columns_page_selector/page_selector.css"
import { useTranslation } from "react-i18next";
import  "./question_info.css"
import * as qHelper from "../../helpers/survey";
import { Button } from "../../components";
import Modal from "react-modal";
import close_img from "../../static/icons/close.svg";
import InputField from "../../components/controls/inputfield";



const QuestionInfo = (props) => {
    const { t } = useTranslation();
    const [mapedValues, setMapedValues] = useState(props.values_map);
    const [mapedValuesTmp, setMapedValuesTmp] = useState(props.values_map);
    const [showPopupMapValue, setShowPopupMapValue] = useState(false);
    const [showVanishText, setShowVanishText] = useState(false);
    const [popStyle, setPopStyle] = useState({
         content: {
            position: "absolute" ,
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
             maxHeight: "70%",
             overflowY: "auto",
             padding: "5px 5px 10px 10px",
        },
        overlay: {
            background: "rgba(255, 255, 255, 0.1)",
            zIndex: "999999",
        }
    });
    const [mappingValue,setMappingValue]= useState("")
    const [mappingParams,setMappingParams]= useState({ } )

    const headers=["value","text","code"]

    if (!props.master || !props.child) return null;

    const  alloowMapValues = () => {
        return (props.master.choices || props.child.choices)
    }

    const onMapValueStart=(params)=>{
        setMappingValue(params.name);
        setMappingParams(params);
        let style={...popStyle}
        style.content.top=`${params.pointPage.y}px`;
        style.content.left=`${params.pointPage.x+100}px`;
        setPopStyle(style)
        setShowPopupMapValue(true)
    }
    const closeModal = () => {
        setShowPopupMapValue(false)
    }
    const onMapValue = (params) => {
        let map_values=[]
        if (Array.isArray(mapedValues))
            map_values=mapedValues
        let isExists=false;
        map_values.forEach((item,index)=>{
            if (item.child_value===mappingValue){
                isExists=true;
                if (params.name.type==="unmap"){
                    map_values.splice(index,1)
                } else {
                    map_values[index].master_value=params.name.txt
                }
            }
        })
        if (!isExists && params.name.type!=="unmap") {
            map_values.push ({ child_value: mappingValue, master_value: params.name.txt })
        }
        if (props.onMapValue){
            props.onMapValue(mappingValue,params.name,props.master.name,props.child)
            closeModal()
        }

        setMapedValues(map_values)
        setShowVanishText(true);
        setTimeout(() => {
            setShowVanishText(false);
        }, 990);
    }
    const onChangeUnsuitable = (params) => {
        let maped_values=[]
        if (Array.isArray(mapedValuesTmp) )
            maped_values=mapedValuesTmp;
        if (params.isChild) {
            if (maped_values.length===0){
                props.child.choices.forEach((choise)=> {
                    let val
                    let code=""
                    if (typeof choise==="object"){
                        val=choise.value
                        code=choise.code
                    } else {
                        val=choise
                    }
                    maped_values.push(
                        {
                            relation_tupe: "code",
                            child_q_type: props.child.type,
                            child_value: val,
                            child_txt: choise.text,
                            child_code: code,
                            master_q_type: props.master.type,
                            master_value: code,
                            master_txt: "",
                            master_code: code
                        }
                    )
                })
            }
            maped_values.forEach ((map) => {
                if (map.child_value===params.name){
                    map.master_value=params.value
                }
            })
        } else {
            if (maped_values.length===0){
                props.master.choices.forEach((choise)=> {
                    let val
                    let code=""
                    if (typeof choise==="object"){
                        val=choise.value
                        code=choise.code
                    } else {
                        val=choise
                    }
                    maped_values.push(
                        {
                            relation_tupe: "code",
                            child_q_type: props.child.type,
                            child_value: code,
                            child_txt: "",
                            child_code: code,
                            master_q_type: props.master.type,
                            master_value: val,
                            master_txt: choise.text,
                            master_code: code
                        }
                    )
                })
            }
            maped_values.forEach ((map) => {
                if (map.master_value===params.name){
                    map.child_value=params.value
                }
            })
        }
        setMapedValuesTmp(maped_values)
    }
    const onMapValueUnsuitable=(params) => {
        let map_values=[]
        if (Array.isArray(mapedValuesTmp) )
            map_values=mapedValuesTmp;
        if (params.isMap) {
            if (map_values.length===0){
                if (props.child.choices) {
                    props.child.choices.forEach((choise)=> {
                        let val
                        let code=""
                        if (typeof choise==="object"){
                            val=choise.value
                            code=choise.code
                        } else {
                            val=choise
                        }
                        map_values.push(
                            {
                                relation_tupe: "code",
                                child_q_type: props.child.type,
                                child_value: val,
                                child_txt: choise.text,
                                child_code: code,
                                master_q_type: props.master.type,
                                master_value: code,
                                master_txt: "",
                                master_code: code
                            }
                        )
                    })
                } else {
                    props.master.choices.forEach((choise)=> {
                        let val
                        let code=""
                        if (typeof choise==="object"){
                            val=choise.value
                            code=choise.code
                        } else {
                            val=choise
                        }
                        map_values.push(
                            {
                                relation_tupe: "code",
                                child_q_type: props.child.type,
                                child_value: code,
                                child_txt: "",
                                child_code: code,
                                master_q_type: props.master.type,
                                master_value: val,
                                master_txt: choise.text,
                                master_code: code
                            }
                        )
                    })
                }
            }
            setMapedValues(map_values)
        } else {
            setMapedValues([])
            setMapedValuesTmp([])
            map_values=[]
        }
        if (props.onMapValueUnsuitable){
            props.onMapValueUnsuitable(map_values,props.master.name,props.child)
            closeModal()
        }
        setShowVanishText(true);
        setTimeout(() => {
            setShowVanishText(false);
        }, 990);
    }

    const renderQuestionHeader=(question)=>{
        let title=qHelper.gettitle(question,props.locale || 'de')
        return (
            <div className={"quest-header"}>
                { t("Num: ")}
                <span className={"quest-header-num"} >{question.number}</span>
                { t("Name: ")}
                <span className={"quest-header-name"} >{question.name}</span>
                { t("Type: ")}
                <span className={"quest-header-type"} >{question.type}</span>
                { t("Title: ")}
                <span className={"quest-header-title"} >{title}</span>
            </div>
        )
    }

    const renderItemsMaster=()=>{
        const items = [];
        if (props.master.choices) {

            items.push (renderChoiceHeader (props.master.choices[0]))
            props.master.choices.forEach ((choice) => {
                items.push (renderItemChoiceMaster (choice))
            })

        } else {
            items.push (renderText (props.master))
        }
        return items;
    }
    const renderChoiceHeader=()=> {
        let class_name = "quest-item-header"
        let result = []
        headers.forEach((hdr,index) => {
            let header_text=hdr
            if (hdr==="value")
                header_text="key"
            result.push (<div key={index} className={class_name}>{t(header_text)}</div>)
        })
        return result
    }
    const renderItemChoiceMaster = (item) => {
        let class_name="quest-item-value"
        let result = []
        let items_object;

        if (typeof item === "object") {
            items_object=item;
            if (item.value.includes("=")){
                let items_array = item.value.split ("=")
                if (!items_object.text) {
                    items_object.text=items_array[1]
                }
            }
        }  else if (item.includes ("=")) {
            let items_array = item.split ("=")
            items_object={value: item,text: items_array[1]}

        } else {
            items_object={value: item}
        }

        headers.forEach ((hdr, index) => {
            let val = items_object[hdr] ? items_object[hdr] : "";
            if (hdr === "text" && typeof val === "object") {
                let loc = props.locale ?? 'de'
                val = val[loc] ? val[loc] : val.default
            }
            result.push (<div key={index} className={class_name}>{val}</div>)
        })

        return result
    }

    const renderText=(question) => {
        let text="required to input text";
        let decimalplaces = -1
        if (question.decimalPlaces) {
            if (question.decimalPlaces >= 0) {
                decimalplaces = question.decimalPlaces
            }
        }
        if (question.type==="nouislider") {
            let range_min = 0
            if (question.rangeMin)
                range_min = question.rangeMin
            if (question.rangeMax)
                text = `required to input an integer between ${range_min}  and ${question.rangeMax}`
            else
                text = `required to input an integer > ${range_min}`
        } else {
            if (question.maxValue) {
                if (decimalplaces > 0) {
                    text = `required to input a number between ${question.minValue ?? 0} and ${question.maxValue}`
                } else if (decimalplaces === 0) {
                    text = `required to input an integer between ${question.minValue ?? 0} and ${question.maxValue}`
                }
            } else {
                if (decimalplaces > 0) {
                    text = `required to input a number`
                } else if (decimalplaces === 0) {
                    text = `required to input an integer`
                }
            }
        }
        return (
            <div className={"quest-item-text"}>
                {text}
            </div>
        )
    }

    const renderItemsChild=()=>{
        const items = [];
        if (props.child.choices) {
            items.push (renderChoiceHeader (props.child.choices[0]))
            props.child.choices.forEach ((choice) => {
                if (props.master.choices) {
                    items.push (renderItemChoiceChild (choice))
                } else {
                    items.push (renderItemChoiceChildUnsuitable (choice,true))
                }
            })
        } else {
            items.push (renderText (props.child))
            if (props.master.choices) {
                props.master.choices.forEach ((choice) => {
                        items.push (renderItemChoiceChildUnsuitable (choice,false))
                })
            }
        }
        return items;
    }
    const renderItemChoiceChild = (item) => {
        let class_name="like-link quest-item-value"
        let result = []
        let items_object;
        if (typeof item === "object") {
            items_object=item;
            if (item.value.includes("=")){
                let items_array = item.value.split ("=")
                if (!items_object.text) {
                    items_object.text=items_array[1]
                }
            }
        }  else if (item.includes ("=")) {
            let items_array = item.split ("=")
            items_object={value: item,text: items_array[1]}
        } else {
            items_object={value: item}
        }
        headers.forEach ((hdr, index) => {
            let val = items_object[hdr] ? items_object[hdr] : "";
            if (hdr==="text" && typeof val === "object") {
                let loc = props.locale ?? 'de'
                val = val[loc] ? val[loc] : val.default
            }

            if (alloowMapValues () && hdr === "value" && props.mode === "normal") {
                if (mapedValues?.length>0) {
                    mapedValues.forEach ((map) => {
                            if (map.child_value === items_object[hdr]) {
                                val = `${map.master_value} ( Child: ${val} )`
                                class_name += "-maped"
                            }
                    })
                }
                let nm=item
                if (typeof nm === "object") {
                    nm=item[hdr]
                }
                result.push (<Button key={hdr + index}
                                     name={nm}
                                     className={class_name}
                                     label={val}
                                     onClick={onMapValueStart}
                />)
            } else {
                result.push (<div key={index} className={class_name}>{val}</div>)
            }
        })
        return result
    }

    const renderItemChoiceChildUnsuitable = (item, isChild) => {
        let class_name="like-link quest-item-value"
        let result = []
        let items_object;
        if (typeof item === "object") {
            items_object=item;
            if (item.value.includes("=")){
                let items_array = item.value.split ("=")
                if (!items_object.text) {
                    items_object.text=items_array[1]
                }
            }
        }  else if (item.includes ("=")) {
            let items_array = item.split ("=")
            items_object={value: item,text: items_array[1]}

        } else {
            items_object={value: item}
        }
        let val="";
        if (isChild){
            val =items_object.value
        } else {
            val =items_object.code ?? "0"
        }
        if (typeof val === "object") {
            let loc = props.locale ?? 'de'
            val = val[loc] ? val[loc] : val.default
        }
        if (mapedValuesTmp?.length>0) {
            mapedValuesTmp.forEach ((map) => {
                    if (isChild){
                        if (map.child_value === items_object.value) {
                            if (!map.master_value && map.master_value!=="0")
                                val = `${map.child_value}`
                            else
                                val = `${map.master_value} ( Child: ${map.child_value} )`
                            class_name += "-maped"
                        }
                    } else {
                        if (map.master_value === items_object.value) {
                            val = `${map.master_value} ( Child: ${map.child_value} )`
                            class_name += "-maped"
                        }
                    }
            })
        }
        headers.forEach((hdr,index) => {
            if (hdr==="value") {
                if (props.mode === "normal") {
                    result.push (<Button key={val + index}
                                         name={val}
                                         className={class_name}
                                         label={val}
                                         onClick={(params) => {
                                             params.isUnsutable=true
                                             params.child_choise=isChild
                                             onMapValueStart(params)
                                         }}
                        />
                    )
                } else {
                    result.push ( <div key={val+index} className={class_name}>{val}</div>)
                }
            }
            else {
                let lbl=""
                if (isChild) {
                    lbl = item[hdr] ? item[hdr] : ""
                    if (typeof lbl === "object") {
                        let loc = props.locale ?? 'de'
                        lbl = lbl[loc] ? lbl[loc] : lbl.default
                    }
                }
                result.push ( <div key={val+index} className={class_name}>{lbl}</div>)
            }
        })
        return result
    }

    const getMapList = () => {
        let map_list =[]
        let isItemMaped =false;
        if (!props.master.choices) return map_list
        props.master.choices.forEach ((choice) => {
            let val=""
            if (typeof choice ==="object"){
                if (choice.value) {
                    val = choice.value
                }
            } else {
                val=choice
            }
            if (val) {
                if (mapedValues?.length >0 ) {
                    mapedValues.forEach ((v) => {
                        if (v.master_value === val) {
                            val = null
                        }
                        if (v.child_value===mappingValue) {
                            isItemMaped=true
                        }
                    })
                }
                if (val) {
                    map_list.push ({ type: "map", txt: val })
                }
            }
        })
        if (isItemMaped)
            map_list.push({type: "unmap",txt: t("unmap")})
        else {
            if (map_list.length===0)
                map_list.push ({ type: "nomap", txt: t ("No values to map") })
            else
                map_list.push ({ type: "unmap", txt: t ("") })
        }
        return map_list
    }


    const renderValuesMapPopUp=()=>{
        let result = []
        const renderMapList=() => {
            if (mappingParams.isUnsutable)  return null;
            getMapList().forEach((item)=>{
                let class_name="like-link val-map-list"
                if (item.type==="unmap"){
                    class_name+="-unmap"
                }
                if (item.type==="nomap") {
                    class_name="val-map-list-nomap"
                    result.push(<dev key={item.txt}
                                     name={item}
                                     className={class_name}
                                >
                        {item.txt}
                    </dev>)
                } else {
                    result.push(<Button key={item.txt}
                                        name={item}
                                        className={class_name}
                                        label={item.txt}
                                        onClick={onMapValue}
                    />)
                }
            })

            return result
        }
        const renderUnsuitableMap=() => {
            if (!mappingParams.isUnsutable) return null;
            let class_name = "like-link val-map-list"
            let class_name_items = "val-map-list"
            let items_list = []
            let val=""
            let code=""
            if (mappingParams.child_choise) {
                props.child.choices.forEach ((choise) => {

                    if (typeof choise==="object"){
                        val=choise.value
                        code=choise.code
                    } else {
                        val=choise
                    }
                    if (mapedValuesTmp?.length>0){
                        mapedValuesTmp.forEach ((map) => {
                                if (map.child_value === val) {
                                    code=map.master_value
                                }
                        })
                    }
                    items_list.push (
                        <div className={"wrap-" + class_name_items}>
                            <div className={class_name_items+" val-map-list-first"}>{val}</div>
                            <InputField name={val}
                                        className={"bla-bla"}
                                        label=""
                                        onChange={(params) => {
                                            params.isChild=true
                                            onChangeUnsuitable(params)
                                        }}
                                        value={code}
                                        input_control={{ numericOnly: true }} />
                        </div>
                    )
                })
            } else {
                props.master.choices.forEach ((choise) => {
                    if (typeof choise==="object"){
                        val=choise.value
                        code=choise.code
                    } else {
                        val=choise
                    }
                    if (mapedValuesTmp?.length>0){
                        mapedValuesTmp.forEach ((map) => {
                                if (map.master_value === val) {
                                    code=map.child_value
                                }
                        })
                    }
                    items_list.push (
                        <div className={"wrap-" + class_name_items}>
                            <InputField name={val}
                                        label=""
                                        onChange={(params) => {
                                            params.isChild=false
                                            onChangeUnsuitable(params)
                                        }}
                                        value={code}
                                        input_control={{ numericOnly: true }} />
                            <div className={class_name_items}>{val}</div>
                        </div>
                    )
                })
            }
            return (
                <div>
                    {items_list}
                    <div className={"wrap-controls val-map-list"}>
                        <Button key={props.master.name + "-unlink"}
                                name={props.master.name + "-unlink"}
                                className={class_name + "-unlink"}
                                label={"unlink"}
                                onClick={(params) =>{
                                    params.isMap=false
                                    onMapValueUnsuitable(params)
                                }}
                        />
                        <Button key={props.master.name + "-link"}
                                name={props.master.name + "-link"}
                                className={class_name}
                                label={"link"}
                                onClick={(params) =>{
                                    params.isMap=true
                                    onMapValueUnsuitable(params)
                                }}
                        />

                    </div>
                </div>

            )
        }
        return (
            <Modal isOpen={showPopupMapValue}
                   onRequestClose={closeModal}
                   style={popStyle}
            >
                <div className={"map-list-wrap"}>
                    <div key={"close-btn"} className={"question-info-close question-info-close-small"}>
                        <img src={close_img} alt="logo"
                             className={"popup-close"}
                             onClick={()=>
                             {
                                 closeModal()
                             }}
                        />
                    </div>
                    {renderMapList()}
                    {renderUnsuitableMap()}
                </div>
            </Modal>
        )
    }

    const renderVanishText = () => {
        let label_txt=""
        let class_name="vanish-text-off"
         if (showVanishText) {
             label_txt=`Value unlinked`
             if (mapedValues?.length>0){
                 mapedValues.forEach((item)=>{
                     if (item.child_value===mappingValue){
                         label_txt=`Value linked`
                     }
                 })
             }
             if (mapedValuesTmp?.length>0){
                 label_txt=`Value linked`
             }
             class_name="vanish-text"
         }
        return (
            <div className={class_name}>{label_txt}</div>
        )
    }

    return (
        <div className={"question-info"}>
            <div className={`question-info-master ${props.mode}`}>
                <div className={"question-info-master-title"}>{t("Master:")}</div>
                {renderQuestionHeader(props.master)}
                <div className={"quest-items"}>
                    {renderItemsMaster()}
                </div>
            </div>
            <div className={"question-info-child"}>
                <div className={"question-info-child-title"}>{t("Child:")}</div>
                {renderQuestionHeader(props.child)}
                <div className={"quest-items"}>
                    {renderItemsChild()}
                </div>

            </div>
            {renderValuesMapPopUp()}
            {renderVanishText()}
        </div>
    );
}

export default QuestionInfo;
