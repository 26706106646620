const data = {
    "patient_birthdate": "2021-11-23",
    "patient_sex": "male",
    "lead_position": {
        "left": {
            "S1": {
                "manufacturer": "axion",
                "options": "test"
            },
            "S2": {
                "manufacturer": "medtronic",
                "options": "tined_lead"
            }
        },
        "right": {
            "S2": {
                "manufacturer": "medtronic",
                "options": "tined_lead"
            },
            "S1": {
                "manufacturer": "medtronic",
                "options": "tined_lead",
                "comment": "need to check"
            },
            "S3": {
                "manufacturer": "medtronic",
                "options": "test"
            },
            "S4": {
                "manufacturer": "medtronic",
                "options": "test"
            }
        }
    },
    "lead_programming": {
        "L1": {
            "side_enabled": "Yes",
            "amplitude_type": "mA",
            "amplitude_ma": 6,
            "pulse_width": 300,
            "frequency": 53.7,
            "probes": {
                "0": {
                    "electrode_configuration": "0"
                },
                "1": {
                    "electrode_configuration": "0"
                },
                "2": {
                    "electrode_configuration": "0"
                },
                "3": {
                    "electrode_configuration": "-1"
                },
                "C": {
                    "electrode_configuration": "+1"
                }
            }
        },
        "L2": {
            "side_enabled": "Yes",
            "amplitude_type": "V",
            "amplitude_v": 3.5,
            "pulse_width": 300,
            "frequency": 63.2,
            "probes": {
                "0": {
                    "electrode_configuration": "0"
                },
                "1": {
                    "electrode_configuration": "0"
                },
                "2": {
                    "electrode_configuration": "0"
                },
                "3": {
                    "electrode_configuration": "+1"
                },
                "C": {
                    "electrode_configuration": "+1"
                }
            }
        }
    }
}

export default data;