import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
    Navform,
    SurveySelector,
    PersonSelector,
    SurveyReport,
    GroupPersonSelector,
    GroupSurveySelector,
    GroupSurveyReport,
    SurveyReportH,
    SurveyReportPDF,
    TemplateSelector,
    TemplateReport,
    PdfEditorForm,
    MergeTemplateEditor,
    InstancesImporterForm,
} from "../forms";
import Notification from "../components/layout/notification";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import Loader from "../components/layout/loader";
import ProtectedForm from "./protected_form";

export default function RouterForm(props) {
    const main_route = () => {
        //if (!window.uri_params.otp) return null;
        return (
            <Router history={props.history}>
                <Switch>
                    <Route path="/" exact component={Navform} />
                    <Route path="/pdf_editor" component={PdfEditorForm} />
                    <Route path="/pdf_signer" component={PdfEditorForm} />
                    <Route
                        path="/inst_import"
                        component={InstancesImporterForm}
                    />
                    <ProtectedForm>
                        <Route path="/navigation" component={Navform} />
                        <Route
                            path="/survey_selector"
                            component={SurveySelector}
                        />
                        <Route
                            path="/person_selector"
                            component={PersonSelector}
                        />
                        <Route path="/survey_report" component={SurveyReport} />
                        <Route
                            path="/group_person_selector"
                            component={GroupPersonSelector}
                        />
                        <Route
                            path="/group_survey_selector"
                            component={GroupSurveySelector}
                        />
                        <Route
                            path="/group_survey_report"
                            component={GroupSurveyReport}
                        />
                        <Route
                            path="/survey_report_h"
                            component={SurveyReportH}
                        />
                        <Route
                            path="/survey_report_pdf"
                            component={SurveyReportPDF}
                        />
                        <Route
                            path="/template_selector"
                            component={TemplateSelector}
                        />
                        <Route
                            path="/template_report"
                            component={TemplateReport}
                        />
                        <Route
                            path="/virtual_template"
                            component={MergeTemplateEditor}
                        />
                    </ProtectedForm>
                </Switch>
            </Router>
        );
    };
    // const noaccess_route = () => {
    //     if (window.uri_params.otp) return null;
    //     return (
    //         <Router history={props.history}>
    //             <Switch>
    //                 <NoAccessForm />
    //             </Switch>
    //         </Router>
    //     )
    // }
    // const renderGlobalHeader = () => {
    //     return null;
    //     return (
    //         <div className="header">
    //             <div className="logo"><img alt="logo" src={logo} /></div>
    //             <div className="title">SURVEY DATA ANALYZER</div>
    //         </div>
    //     )
    // }
    return (
        <div className={"page"}>
            <Notification />
            <ReactNotification />
            <Loader />
            <div className="desktop_wrap">
                {main_route()}
                {/* {noaccess_route()} */}
            </div>
        </div>
    );
}
