import ApiModel from "../../api/api_model";
import Connector from "../../api/http_connector";
import store from "../../store";
import { decode } from "../../helpers/base64decode";

const connector_template = new Connector({
    link: process.env.REACT_APP_ADMIN_ENDPOINT,
    endpoint: "adapters/adpt_FuA_questionnaire_V3.php",
});
class apiModelTemplate extends ApiModel {
  load_template(callback) {
    const payload = {};
    payload.params = {
      mount_type: "get_new_or_sync_template",
      fid: window.uri_params.fid,
      act: "get",
      act2: "upd_templ",
      otp_param: window.uri_params.otp_param,
      cb: Date.now(),
    };
    this._connectApi(payload, (err, res) => {
      if (!err) {
        window.uri_params.otp_param = res.api_data.otp_param_s64;
        let template = {};
        if (res.api_data?.qarr?.arr_fragen) {
          const json_template = decode(res.api_data.qarr.arr_fragen);
          template = JSON.parse(json_template);
          template.id = res.api_data.qarr.fid;
          template.editor = atob(res.api_data.qarr.ersteller64); //pcom left atob
          template.created = res.api_data.qarr.erstellt;
          store.dispatch({
              type: "res_templates_get_template",
              success: res.success,
              data: template,
          });
        }
      } else {
        console.log(err);
      }
    });
  }




}

const apiTemplate = new apiModelTemplate(connector_template);

export default apiTemplate;
