import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ChartPop = (props) => {
    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    footer: function (tooltipItems) {
                        const submittedValues =
                            tooltipItems[0].dataset.customData[
                                tooltipItems[0].dataIndex
                            ];

                        return (
                            "Answers: [" +
                            submittedValues.slice(0, 5).toString() +
                            (submittedValues.length > 5 ? "..." : "") +
                            "]"
                        );
                    },
                },
            },
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: props.title,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: props.scalesLabels.yTitle,
                },
                suggestedMin: 50,
            },
            x: {
                title: {
                    display: true,
                    text: props.scalesLabels.xTitle,
                },
                ticks: {
                    callback: function (value, index, values) {
                        //We take from All or if it is only one person from him
                        const totalSubmitedAnswers =
                            this.chart.data.datasets[0].customData[value]
                                .length;

                        return (
                            this.getLabelForValue(value) +
                            ` (${totalSubmitedAnswers})`
                        );
                    },
                },
            },
        },
    };

    const dynamicColors = () => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgba(" + r + "," + g + "," + b + ", 0.5)";
    };

    const processData = () => {
        const uniquePersons = [
            ...new Set(
                props.data.map(
                    (question) => question.instance_answering_person_name
                )
            ),
        ];
        const uniqueDates = [
            ...new Set(props.data.map((question) => question.relevant_date)),
        ];

        const processedData = [];

        if (uniquePersons?.length > 1) {
            const allData = [];
            const allCustomData = [];

            uniqueDates.forEach((date) => {
                const chartLineValues = props.data
                    .filter((f) => {
                        return f.relevant_date === date && f.value;
                    })
                    ?.map((answer) => {
                        return answer.value;
                    });

                const chartLineValueAverage =
                    chartLineValues?.reduce(
                        (a, b) => Number(a) + Number(b),
                        0
                    ) / chartLineValues?.length;

                allData.push(chartLineValueAverage);
                allCustomData.push(chartLineValues);
            });

            processedData.push({
                label: "All (" + uniquePersons.length + ")",
                borderColor: "rgba(0,0,255, 0.5)",
                backgroundColor: "rgba(0,0,255, 0.5)",
                data: allData,
                customData: allCustomData,
            });
        }

        if (uniquePersons?.length < 8) {
            uniquePersons.forEach((person) => {
                const color = dynamicColors();

                let chartLine = {
                    label: person,
                    borderColor: color,
                    backgroundColor: color,
                    data: [],
                    customData: [],
                };

                uniqueDates.forEach((date) => {
                    const chartLineValues = props.data
                        .filter((f) => {
                            return (
                                f.relevant_date === date &&
                                f.instance_answering_person_name === person
                            );
                        })
                        ?.map((answer) => {
                            return answer.value;
                        });

                    const chartLineValueAverage =
                        chartLineValues?.reduce(
                            (a, b) => Number(a) + Number(b),
                            0
                        ) / chartLineValues?.length;

                    chartLine.data.push(chartLineValueAverage);
                    chartLine.customData.push(chartLineValues);
                });

                processedData.push(chartLine);
            });
        }

        return {
            labels: uniqueDates,
            datasets: processedData,
        };
    };

    return <Line options={options} data={processData()} />;
};

export default ChartPop;
