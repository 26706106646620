import React, { useState, useEffect } from "react";
import PdfEditor from "../../components/pdf_editor/pdf_editor";
import pdf_source from "../../api/pdf_connector";
import { useHistory, useLocation } from "react-router-dom";

const PdfEditorForm = () => {
    const history = useHistory();
    const location = useLocation();

    const isInstance = window.location.pathname === "/pdf_signer";
    const templateId = window.uri_params.cstid;

    const [instanceId, setInstanceId] = useState(window.uri_params.csiid);
    const [pdfData, setPdfData] = useState(null);

    useEffect(() => {
        if (isInstance && instanceId !== "-1")
            pdf_source.load_instance(instanceId, (err, data) => {
                if (err) {
                    console.log("Failed to load pdf instance");
                    console.log(err);
                    return;
                }

                setPdfData(data.template);
            });

        if (!isInstance || instanceId === "-1")
            pdf_source.load_template(templateId, (err, data) => {
                if (err) {
                    console.log("Failed to load pdf template");
                    console.log(err);
                    return;
                }

                setPdfData(data.template);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function updateUrl(generatedInstanceId) {
        if (generatedInstanceId === instanceId) return;

        const searchParams = new URLSearchParams(location.search);

        searchParams.set("csiid", generatedInstanceId);

        const newSearch = searchParams.toString();

        history.replace({
            pathname: location.pathname,
            search: newSearch,
        });
    }

    function onDocumentSave(pdfData) {
        if (isInstance)
            pdf_source.save_instance(
                templateId,
                instanceId,
                pdfData,
                (err, res) => {
                    if (err) {
                        console.log("Failed to save pdf template");
                        console.log(err);
                        return;
                    }

                    const generatedInstanceId = res.csiid.toString();

                    updateUrl(generatedInstanceId);
                    setInstanceId(generatedInstanceId);
                }
            );

        if (!isInstance)
            pdf_source.save_template(templateId, pdfData, (err, res) => {
                if (err) {
                    console.log("Failed to save pdf template");
                    console.log(err);
                    return;
                }
            });
    }

    if (!pdfData) return <div>loading...</div>;

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <PdfEditor
                isInstance={isInstance}
                data={pdfData}
                onDocumentSave={onDocumentSave}
            />
        </div>
    );
};

export default PdfEditorForm;
