import React from "react";
import { Button, Form } from "../../components";
import './survey_report.css';

import template from './../../data/z_template';
import survey from './../../data/z_survey';
import person from './../../data/z_person';

import { BsFillPersonLinesFill, BsFillCalendarCheckFill, BsFillCursorFill, BsTelephoneFill } from 'react-icons/bs';
import { IoIosMail } from "react-icons/io";

import * as qHelper from './../../helpers/survey';

export default function SurveyReport(props) {

    var pagesCount = Object.keys(template.pages).length;

    const surveyHeader = () => {
        return (
            <div className="survey-header">
                <div className='export-pdf'>
                    <Button label="Export to PDF" onClick={() => { alert('Export to PDF') }} />
                    <Button label="Send to Email" onClick={() => { alert('Send to email') }} />
                </div>

                <div className="survey-title">{template.title}</div>
                <div className="survey-title">{template.description}</div>
                <div className="person-data">
                    <div className="name"><BsFillPersonLinesFill />{person.name + ' (' + person.auid + ')'}</div>
                    <div className="birthdate"><BsFillCalendarCheckFill />{person.birtdate}</div>
                    <div className="address"><BsFillCursorFill />{person.address}</div>
                    <div className="phone"><BsTelephoneFill />{person.phone}</div>
                    <div className="email"><IoIosMail />{person.email}</div>
                </div>
            </div>
        )
    }

    const surveyFooter = () => {
        return (
            <div className="survey-footer">
                <div className="pages-count">{'Pages count: ' + pagesCount}</div>
            </div>
        )
    }


    const renderQuestion = (question) => {
        const renderDate = () => {
            const result = qHelper.inputdate(question, survey[question.name]);
            return (
                <div className="question">
                    <div className="question-title">{'Q. ' + result.title}</div>
                    <div className="question-answer">{result.answer}</div>
                    <div className="question-answer">{result.answer}</div>
                </div>
            )
        }
        const renderRadio = () => {
            const result = qHelper.radiogroup(question, survey[question.name]);
            return (
                <div className="question">
                    <div className="question-title">{'Q. ' + result.title}</div>
                    <div className="question-answer">{result.answer}</div>
                    <div className="question-answer">{result.answer}</div>
                </div>
            )
        }
        const renderLeadMatrix = () => {
            const result_lead = qHelper.leadmatrix.matrix(question,survey);
            console.log(qHelper.leadmatrix.plain(question,survey));
            const renderHeader = () => {
                return (
                    <div className="question">
                        <div className="question-title">{'Q. ' + result_lead.header}</div>
                    </div>
                )
            }
            const renderSide = (side) => {
                const matrix = [];
                const renderSideHeader = (header) => {
                    matrix.push(
                        <div className="question subquestion matrix3">
                            <div className="question-title">{side + ': '}</div>
                            <div className="question-answer">
                                <div className="question-column1">{header[0]}</div>
                                <div className="question-column2">{header[1]}</div>
                                <div className="question-column3">{header[2]}</div>
                            </div>
                            <div className="question-answer">
                                <div className="question-column1">{header[0]}</div>
                                <div className="question-column2">{header[1]}</div>
                                <div className="question-column3">{header[2]}</div>
                            </div>
                        </div>
                    )
                }
                const renderSideAnswers = (row_name, columns) => {
                    matrix.push(
                        <div className="question subquestion2 matrix3">
                            <div className="question-title">{row_name + ': '}</div>
                            <div className="question-answer">
                                <div className="question-column1">{columns[0]}</div>
                                <div className="question-column2">{columns[1]}</div>
                                <div className="question-column3">{columns[2]}</div>
                            </div>
                            <div className="question-answer">
                                <div className="question-column1">{columns[0]}</div>
                                <div className="question-column2">{columns[1]}</div>
                                <div className="question-column3">{columns[2]}</div>
                            </div>
                        </div>
                    )
                }
                Object.keys(result_lead[side]).forEach(row_name => {
                    if (row_name === 'header') renderSideHeader(result_lead[side][row_name]);
                    else renderSideAnswers(row_name,result_lead[side][row_name]);                    
                });
                return matrix;           
            }
            return (
                <>
                    {renderHeader()}
                    {renderSide(Object.keys(result_lead)[1])}
                    {renderSide(Object.keys(result_lead)[2])}
                </>
            )
        }
        switch (question.type) {
            case 'inputdate': return renderDate();
            case 'radiogroup': return renderRadio();
            case 'leadmatrix8combined': return renderLeadMatrix();
            default: return (
                <div className="question">
                    <div className="question-title">{'Q. ' + question.title || question.name}</div>
                    <div className="question-answer">{'A. ' + survey[question.name]}</div>
                </div>
            )
        }

    }

    const renderQuestions = (questions_array) => {
        const questions = [];
        Object.keys(questions_array).forEach((question_index) => {
            const question = questions_array[question_index];
            questions.push(
                renderQuestion(question)
            )

        })
        return questions;
    }

    const surveyPages = () => {
        const pages = [];
        Object.keys(template.pages).forEach((page_index) => {
            const page = template.pages[page_index];
            const page_number = parseInt(page_index) + 1;
            pages.push(
                <div className="survey-page" key={page_index}>
                    <div className="page-header">
                        <div className="page-number">{'Page: ' + page_number}</div>
                        <div className="page-title">{page.title || page.name}</div>
                    </div>
                    <div className="page-body">
                        {renderQuestions(page.elements)}
                    </div>
                </div>
            )
        })
        return pages;
    }

    return (
        <Form title="Survey report" name="group_report survey_report">
            <div className="survey-body">
                {surveyHeader()}
                {surveyPages()}
                {surveyFooter()}
            </div>
        </Form>
    );

}