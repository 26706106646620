import TrashIcon from "../../icons/trashbin";

export default function TableV({
    showQuestions,
    data,
    filter,
    selected,
    setSelected,
    setFilter,
    setShowDebugObject,
    sorter,
    manipulateSorter,
    setShowInstanceSavePDFPopup,
    setShowInstanceDeletePopup,
    setShowDoctorInfo,
    template,
}) {
    const canDelete = (permission) => {
        return permission === 100;
    };

    const renderRow = () => {
        const selectInstance = (event) => {
            const id = event.target.value;
            const new_selected = [...selected];
            const exist = new_selected.indexOf(id);
            if (event.target.checked) {
                if (exist < 0) new_selected.push(id);
            } else {
                if (exist > -1) new_selected.splice(exist, 1);
            }
            setSelected(new_selected);
        };

        const rows = [];

        //slection row
        const row = [];
        row.push(<td key={"numeration"} className="numeration"></td>);
        if (selected.length) {
            row.push(
                <td
                    className={"select has-selection"}
                    key={"select"}
                    onClick={() => {
                        const new_filter = { ...filter };
                        new_filter.selected = [];
                        setFilter(new_filter);
                        setSelected([]);
                    }}
                >
                    Clear
                </td>
            );
        } else {
            row.push(<td className={"select"} key={"select"}></td>);
        }

        data.forEach((column) => {
            row.push(
                <td className={"select"} key={column.instance_id}>
                    <div className="controls">
                        <input
                            type="checkbox"
                            value={column.instance_id}
                            checked={selected.includes(column.instance_id)}
                            onChange={(event) => {
                                selectInstance(event);
                            }}
                        />
                        <button
                            className="btn-pdf-export"
                            onClick={() => {
                                setShowInstanceSavePDFPopup(column.instance_id);
                            }}
                        >
                            PDF
                        </button>
                        {canDelete(column.system_field.permission) && (
                            <button
                                className="btn-delete-instance"
                                onClick={() => {
                                    setShowInstanceDeletePopup(
                                        column.instance_id
                                    );
                                }}
                            >
                                <TrashIcon width="15" height="15" />
                            </button>
                        )}
                    </div>
                </td>
            );
        });
        rows.push(
            <tr key={"selection"} className={"selection"}>
                {row}
            </tr>
        );
        showQuestions.forEach((question) => {
            const row = [];

            if (question.name in sorter) {
                const index_of_sorter = Object.keys(sorter).indexOf(
                    question.name
                );
                const sort_class =
                    " order " + sorter[question.name].order + index_of_sorter;
                row.push(
                    <td
                        className={"numeration" + sort_class}
                        key={"numeration"}
                        onClick={() => {
                            manipulateSorter(question.name);
                        }}
                    >
                        {question.number}
                    </td>
                );
            } else {
                row.push(
                    <td
                        className={"numeration"}
                        key={"numeration"}
                        onClick={() => {
                            manipulateSorter(question.name);
                        }}
                    >
                        {question.number}
                    </td>
                );
            }

            row.push(
                <td key={"label"} className="labels">
                    {question.label}
                </td>
            );

            data.forEach((column) => {
                let td_class = question.name;
                let td = (
                    <td className={td_class} key={column.instance_id}>
                        {column[question.name]}
                    </td>
                );
                if (
                    td_class === "instance_id" &&
                    window.uri_params.debug !== "false"
                ) {
                    const split = column[question.name].split("-");

                    const isMerging = split.length === 2;

                    const isChild = isMerging && split[0] !== template.id;

                    const title = isChild
                        ? template.mapping?.find((f) => f.id === split[0])
                              ?.title
                        : template.masterTitle;

                    const colText = !isMerging ? (
                        <>{split[0]}</>
                    ) : (
                        <>
                            <span className="title">
                                {title.length > 13
                                    ? title?.substring(0, 10) + "... "
                                    : title}
                            </span>
                            <br />
                            <span className="id">
                                {split[0] + " " + split[1]}
                            </span>
                        </>
                    );

                    const formattedDisplay = (
                        <>
                            {colText}
                            {
                                <div className="tooltiptext">
                                    {split[0] +
                                        (isChild ? "C" : "") +
                                        ": " +
                                        title}
                                </div>
                            }
                        </>
                    );

                    td = (
                        <td
                            key={column.instance_id}
                            className={
                                td_class +
                                " tooltip" +
                                (isMerging ? " merging" : "") +
                                (isChild ? " child" : "")
                            }
                            onClick={() => {
                                setShowDebugObject(column);
                            }}
                        >
                            {formattedDisplay}
                        </td>
                    );
                }
                if (
                    td_class === "doctor_id" &&
                    window.uri_params.doctor_info !== "false"
                ) {
                    td = (
                        <td
                            key={column.instance_id + "_" + column.doctor_id}
                            className={td_class + " doctor_info"}
                            onClick={() => {
                                setShowDoctorInfo(column.doctor_id);
                            }}
                        >
                            {column[question.name]}
                        </td>
                    );
                }
                if (
                    column?.system_field?.link_filters &&
                    column?.system_field?.link_filters.includes(question.name)
                ) {
                    td_class += " green";
                    const patient_filter =
                        column[question.name] === filter.patient
                            ? null
                            : column[question.name];
                    td = (
                        <td
                            key={column.instance_id}
                            className={td_class}
                            onClick={() => {
                                setFilter({
                                    ...filter,
                                    patient: patient_filter,
                                });
                            }}
                        >
                            {column[question.name]}
                        </td>
                    );
                }

                row.push(td);
            });
            rows.push(
                <tr key={question.name} className={question.name}>
                    {row}
                </tr>
            );
        });
        return <tbody>{rows}</tbody>;
    };
    return (
        <div className="table-vertical">
            <table>{renderRow()}</table>
        </div>
    );
}
