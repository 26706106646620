import { decode } from "../../helpers/base64decode";

export function extractTemplate(res, id) {
  let survey = {};

  if (res.qarr.arr_fragen) {
    const jsonString = decode(res.qarr.arr_fragen);
    try {
      survey = JSON.parse(jsonString) ?? {};
    } catch (e) {
      console.log("Invalid survey payload format");
    }
  }

  if (!survey.id) survey.id = id || res.qarr.fid;

  if (!survey.title) {
    survey.title = res.qarr.titel;
    if (res.qarr.fbinfo) survey.description = atob(res.qarr.fbinfo); //pcom left atob
  }
  return survey;
}

export function resetSelection(masterQuestions) {
  masterQuestions.forEach((question) => {
    question.mappedQuestion = null;
    question.mappedNumber = null;
    question.mappedLabel = null;
  });
}

export function tableSelection(
  selectedId,
  params,
  childQuestions,
  masterQuestions
) {
  masterQuestions.forEach((question) => {
    if (question.name === selectedId && question.type === params.type) {
      question.mappedQuestion = params;
      question.mappedNumber = params.number;
      question.mappedLabel = params.label;
    }
  });

  return childQuestions.filter((question) => {
    return question.name !== params.name;
  });
}

export function getAllMappedQuestions(masterQuestions) {
  const mapping = [];
  masterQuestions.forEach((question) => {
    if (question.mappedQuestion) {
      mapping.push({
        master: question.name,
        child: question.mappedQuestion.name,
      });
    }
  });
  return mapping;
}
