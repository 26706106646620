import React from "react";


const CsvSelector = (props)  => {

    const onChange = (params) => {
        props.onChange({ row_key: props.row_key,val: params.target.value})
    }

    return (
        <select  onChange={onChange} value={props.value} >
            <option key={props.row_key+"-csv-selector-header"} value={""} defaultValue="" disabled >
                Select csv field
            </option>
            {props.csvList.map((option,idx) => {
                if (option.is_linked){
                    return (
                        <option key={option.key+idx} value={option.key} disabled>
                            {option.label}
                        </option>
                    );
                } else {
                    return (
                        <option key={option.key+idx} value={option.key}>
                            {option.label }
                        </option>
                    );
                }
            })}
        </select>
    );
}

export default CsvSelector;