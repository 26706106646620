import ApiModel from "../../../api/api_model";
import Connector from "../../../api/http_connector";
import store from "../../../store";
import { extract_template, extract_answers } from "./extract_data";
import person_data from "./../person_data";
import { uncompress } from "../../../helpers/pako";
import getAllQuestionsFromTemplate from "../../../helpers/survey/questions";
import { addNdaAnswers } from "../../../helpers/survey";

const connector = new Connector({
    link: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_LINK,
    endpoint: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_ENDPOINT,
});

class apiModelSurvey extends ApiModel {
    load_answers(ids, anonymous_mode, only_scf, callback) {
        store.dispatch({
            type: "set_loader_content",
            content: "loading surveys...",
        });
        store.dispatch({
            type: "loader_show",
        });
        console.log("loading data from server");
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "surveydata";
        payload.mount_type = "load_templates_with_surveys";
        payload.params = {
            do_compress: true,
            fid: ids,
            do_anonymous: anonymous_mode || false,
            only_SCF: only_scf || false,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                callback(false, res);
            } else {
                callback(err, false);
            }
        });
    }
}

const all_mapped_master_names = (mapping) => {
    const question_names_all = [];
    mapping.forEach((child) => {
        child.question_names.forEach((link) => {
            const master_name = link.master;
            if (!question_names_all.includes(master_name))
                question_names_all.push(master_name);
        });
    });
    return question_names_all;
};

const virtual_template = (virtual, anonymous_mode, only_scf, callback) => {
    const ids = virtual.mapping.map((child) => child.id);
    const protected_field = [
        "instance_answering_person_bd",
        "relevant_date",
        "lc",
        "instance_answering_person_name",
        "system_field",
        "instance_id",
        "doctor_id",
    ];
    ids.push(virtual.master_template_id);
    //loading all template and answers
    let template = {};

    const mapping = virtual.mapping?.map((item) => {
        return (({ id, title }) => ({ id, title }))(item);
    });

    let template_origin = {};
    let answers_array = [];
    const filtered_questions = virtual.show_only_mapped_questions
        ? all_mapped_master_names(virtual.mapping)
        : null;

    const apiMaster = new apiModelSurvey(connector);
    apiMaster.load_answers(
        ids.join(","),
        anonymous_mode,
        only_scf,
        async (err, res) => {
            store.dispatch({
                type: "set_loader_content",
                content: "merging surveys...",
            });

            store.dispatch({
                type: "request_started",
            });
            const data = await uncompress(res.api_data);
            if (data) {
                let master_template = data[virtual.master_template_id];
                let allquests = [];

                if (master_template) {
                    template = extract_template(
                        master_template.template,
                        true,
                        filtered_questions
                    );
                    allquests = getAllQuestionsFromTemplate(template);
                    template.title = virtual.title;
                    template.masterTitle = master_template.template.titel;
                    template.description = virtual.description;
                    template.editor = virtual.editor || "";
                    template.created = virtual.created || "";

                    if (template.pages) template.pages.unshift(person_data);
                    template_origin = extract_template(
                        master_template?.template,
                        true,
                        filtered_questions
                    );

                    template_origin.title = virtual.title;
                    template_origin.description = virtual.description;
                    template_origin.editor = virtual.editor || "";
                    template_origin.created = virtual.created || "";
                }
                Object.keys(data).forEach((fid) => {
                    const data_set = data[fid];
                    if (fid === virtual.master_template_id) {
                        const temp_answers = extract_answers(
                            Object.values(data_set?.instances || {}),
                            template_origin.utf8
                        );
                        temp_answers.forEach((answer) => {
                            answer.instance_id = fid + "-" + answer.instance_id;
                            Object.keys(answer).forEach((question_name) => {
                                if (question_name.endsWith("_nda")) {
                                    let f_not_nda =
                                        fid +
                                        "-" +
                                        question_name.replace("_nda", "");
                                    let quest = allquests.find(
                                        (f) => f.name === f_not_nda
                                    );
                                    if (!answer[f_not_nda]?.value) {
                                        answer[f_not_nda] = {
                                            value: "",
                                            nda: answer[question_name],
                                        };

                                        if (quest)
                                            answer[f_not_nda].question_master =
                                                quest;
                                    } else {
                                        answer[f_not_nda].nda =
                                            answer[question_name];
                                        if (quest)
                                            answer[f_not_nda].question_master =
                                                quest;
                                    }

                                    delete answer[question_name];
                                } else {
                                    if (
                                        !protected_field.includes(question_name)
                                    ) {
                                        let quest = allquests.find(
                                            (f) =>
                                                f.name ===
                                                fid + "-" + question_name
                                        );
                                        let full_key =
                                            virtual.master_template_id +
                                            "-" +
                                            question_name;
                                        if (!answer[full_key]?.value)
                                            answer[full_key] = {
                                                value: answer[question_name],
                                            };
                                        else
                                            answer[full_key].value =
                                                answer[question_name];
                                        if (quest)
                                            answer[
                                                virtual.master_template_id +
                                                    "-" +
                                                    question_name
                                            ].question_master = quest;

                                        delete answer[question_name];
                                    }
                                }
                            });
                            answers_array.push(answer);
                        });
                    } else {
                        const temp_answers = extract_answers(
                            Object.values(data_set?.instances || {}),
                            template_origin.utf8
                        );
                        const mapping_set = virtual.mapping.find(
                            (link) => link.id === fid
                        );
                        const mapped_names = mapping_set.question_names;
                        temp_answers.forEach((answer) => {
                            answer.instance_id = fid + "-" + answer.instance_id;
                            Object.keys(answer).forEach((question_name) => {
                                if (question_name.includes("_nda")) {
                                    let child_name_wo_nda =
                                        question_name.replace("_nda", "");
                                    let map_name = mapped_names.find(
                                        (f) => f.child === child_name_wo_nda
                                    );
                                    if (map_name) {
                                        let quest = allquests.find(
                                            (f) =>
                                                f.name ===
                                                virtual.master_template_id +
                                                    "-" +
                                                    map_name.master
                                        );

                                        if (
                                            !answer[
                                                virtual.master_template_id +
                                                    "-" +
                                                    child_name_wo_nda
                                            ]?.value
                                        ) {
                                            answer[
                                                virtual.master_template_id +
                                                    "-" +
                                                    child_name_wo_nda
                                            ] = {
                                                value: "N/A",
                                                nda: answer[question_name],
                                            };
                                        }
                                        if (
                                            !answer[
                                                virtual.master_template_id +
                                                    "-" +
                                                    map_name.master
                                            ]?.value
                                        ) {
                                            answer[
                                                virtual.master_template_id +
                                                    "-" +
                                                    map_name.master
                                            ] = {
                                                value: "N/A",
                                                nda: answer[question_name],
                                            };
                                        }

                                        if (quest) {
                                            answer[
                                                virtual.master_template_id +
                                                    "-" +
                                                    child_name_wo_nda
                                            ].question_master = quest;
                                            answer[
                                                virtual.master_template_id +
                                                    "-" +
                                                    map_name.master
                                            ].question_master = quest;
                                        }
                                    }
                                } else {
                                    let map_name = mapped_names.find(
                                        (f) => f.child === question_name
                                    );
                                    if (map_name) {
                                        let quest = allquests.find(
                                            (f) =>
                                                f.name ===
                                                virtual.master_template_id +
                                                    "-" +
                                                    map_name.master
                                        );
                                        let mapped_txt = {};
                                        if (quest) {
                                            mapped_txt.question_master = quest;
                                        }
                                        if (
                                            typeof answer[question_name] ===
                                            "object"
                                        ) {
                                            mapped_txt.value =
                                                answer[question_name];
                                        } else {
                                            mapped_txt.value =
                                                answer[
                                                    question_name
                                                ].toString();
                                        }
                                        if (Array.isArray(mapped_txt.value)) {
                                            let new_mapped_array = [];
                                            let new_mapped_array_orig = [];
                                            mapped_txt.value.forEach(
                                                (element) => {
                                                    try {
                                                    let el = (element || 'N/A').toString();
                                                    let el_orig;
                                                    map_name.value_mapping.forEach(
                                                        (map) => {
                                                            if (
                                                                map.child_value.toString() ===
                                                                element
                                                            ) {
                                                                el =
                                                                    map.master_value;
                                                                el_orig =
                                                                    map.child_txt
                                                                        ? map.child_txt
                                                                        : map.child_value;
                                                            }
                                                        }
                                                    );
                                                    new_mapped_array.push(el);
                                                    if (el_orig)
                                                        new_mapped_array_orig.push(
                                                            el_orig
                                                        );
                                                    } catch (e) {
                                                        console.log('1error', fid, e);
                                                    }
                                                }
                                            );
                                            mapped_txt.value = new_mapped_array;
                                            mapped_txt.value_orig =
                                                new_mapped_array_orig;
                                        } else {
                                            if (map_name.value_mapping) {
                                                map_name.value_mapping.forEach(
                                                    (map) => {
                                                        if (
                                                            map.child_value.toString() ===
                                                            mapped_txt.value
                                                        ) {
                                                            mapped_txt.value =
                                                                map.master_value;
                                                            mapped_txt.value_orig =
                                                                map.child_txt
                                                                    ? map.child_txt
                                                                    : map.child_value;
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                        answer[
                                            virtual.master_template_id +
                                                "-" +
                                                map_name.master
                                        ] = mapped_txt;
                                    }
                                }

                                if (!protected_field.includes(question_name)) {
                                    delete answer[question_name];
                                }
                            });
                            answers_array.push(answer);
                        });

                        mapping.find((f) => f.id === fid).instances =
                            temp_answers.filter(
                                (f) => !Array.isArray(f)
                            ).length;
                    }
                });
                console.log("222", answers_array);
                template.total_instances = answers_array.filter(
                    (f) => !Array.isArray(f)
                ).length;
                template.mapping = mapping;
                store.dispatch({
                    type: "request_finished",
                });
                addNdaAnswers(answers_array);
                callback(false, { template, template_origin, answers_array });
            }
        }
    );
};

export default virtual_template;
