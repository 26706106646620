import React, { useState } from "react";
import "./page_selector.css";
import { Tooltip } from "react-bootstrap";

const PagesSelector = (props) => {
    const [selected, setSelected] = useState("1");
    const [toggleShowAll, setToggleShowAll] = useState(true);
    const [firstPageNum, setFirstPageNum] = useState(1);
    const count_visiblepages = 1000;
    const onToggleShowAll = (param) => {
        props.onSelect(!toggleShowAll, selected);
        setToggleShowAll(!toggleShowAll);
    };
    const onPageClick = (page) => {
        setSelected(page.id);
        setToggleShowAll(false);
        props.onSelect(false, page.id);
    };
    const onElipsisClicj = (type) => {
        //setToggleShowAll(false)
        changeStatus(type);
        //props.onSelect(false,next_page.toString())
    };

    const changeStatus = (type) => {
        let first_num = firstPageNum;
        if (type === "left") {
            first_num--;
            if (first_num < 1) {
                first_num = 1;
            }
        } else {
            first_num++;
            if (first_num > props.pages.length - (count_visiblepages - 1)) {
                first_num = props.pages.length - (count_visiblepages - 1);
            }
        }

        setFirstPageNum(first_num);
    };

    const renderToggleShowAll = () => {
        if (props.pages.length <= 1) {
            return null;
        }
        return (
            <button
                className="custom_page_selector_v2"
                onClick={onToggleShowAll}
                style={{ minWidth: "fit-content" }}
            >
                All Pages
            </button>
        );
    };

    const renderPage = (page) => {
        let class_name = props.isV2
            ? "custom_page_selector_v2"
            : "page-selector-page";
        let class_name_add = " page-unselected";
        if (page.id === selected && !toggleShowAll) {
            class_name_add = " page-selected";
        }
        class_name += class_name_add;
        if (props.isV2) {
            return (
                <Tooltip key={page.id} id={"page.id"} title={page.title}>
                    <button
                        className={class_name}
                        onClick={() => {
                            onPageClick(page);
                        }}
                    >
                        {page.title}
                    </button>
                </Tooltip>
            );
        }
        return (
            <Tooltip key={page.id} id={"page.id"} title={page.title}>
                <div
                    className={class_name}
                    onClick={() => {
                        onPageClick(page);
                    }}
                >
                    {page.title}
                </div>
            </Tooltip>
        );
    };

    const renderPages = () => {
        let pages = [];
        let blockPages = [];

        for (let i = firstPageNum; i < firstPageNum + count_visiblepages; i++) {
            blockPages.push(i.toString());
        }
        let filteredPages = props.pages.filter((flt) => {
            return blockPages.includes(flt.id);
        });
        filteredPages.forEach((page) => {
            pages.push(renderPage(page));
        });
        return <div className="page-selector-block">{pages}</div>;
    };

    const renderEllipsis = (type) => {
        let text = type === "left" ? "<-..." : "...->";
        if (firstPageNum === 1 && type === "left") text = "";
        if (
            firstPageNum === props.pages.length - (count_visiblepages - 1) &&
            type === "right"
        )
            text = "";
        if (props.pages.length <= count_visiblepages) text = "";
        return (
            <div
                className="page-selector-elipsis"
                onClick={() => {
                    onElipsisClicj(type);
                }}
            >
                {text}
            </div>
        );
    };
    return (
        <div
            className={
                props.isV2 && props.pages[0].id !== "-1"
                    ? "pages-selector_v2"
                    : "pages-selector"
            }
        >
            {Boolean(props.isV2 && props.pages[0].id !== "-1") && (
                <p className="pages-label">Pages:</p>
            )}
            {renderToggleShowAll()}
            <div className="pages-selector-pages">
                {renderEllipsis("left")}
                {renderPages()}
                {renderEllipsis("right")}
            </div>
        </div>
    );
};

export default PagesSelector;
