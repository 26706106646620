import React from "react";
import Cleave from "cleave.js/react";
import { withTranslation } from "react-i18next";

class InputFilter extends React.Component {
    onChange(event) {
        this.props.onChange({
            name: this.props.name,
            filter_field: this.props.filter_field,
            value: event.target.value,
        });
    }
    showLabel(name_postfix) {
        const { t } = this.props;
        if (this.props.label) {
            return <label htmlFor={name_postfix}>{t(this.props.label)}</label>;
        } else {
            return null;
        }
    }
    render() {
        const { t } = this.props;
        let value = this.props.value ? this.props.value : "";
        let autofocus = this.props.autofocus ? true : "";
        let className = this.props.className;
        let name_postfix = this.props.postfix
            ? this.props.name + "_" + this.props.postfix
            : this.props.name + "_filter";
        let wrap_className = className?.length
            ? className
            : "input filter field_wrap " + name_postfix;
        if (this.props.input_control) {
            return (
                <div className={wrap_className}>
                    {this.showLabel(name_postfix)}
                    <Cleave
                        id={name_postfix}
                        name={name_postfix}
                        type={this.props.type}
                        value={value}
                        autoComplete="off"
                        placeholder={t(this.props.placeholder)}
                        options={this.props.input_control}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            );
        } else {
            return (
                <div className={wrap_className}>
                    {this.showLabel(name_postfix)}
                    <div>
                        <input
                            autoFocus={autofocus}
                            id={name_postfix}
                            name={name_postfix}
                            placeholder={t(this.props.placeholder)}
                            type={this.props.type}
                            disabled={this.props.disabled}
                            onChange={this.onChange.bind(this)}
                            value={value}
                            autoComplete="off"
                        />
                    </div>
                </div>
            );
        }
    }
}

export default withTranslation()(InputFilter);
