import Modal from "react-modal";
import { Button } from "../components";

const Warning3BtnPopup = (props) => {
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "100vh",
            overflowY: "auto",
            color: "red",
        },
        overlay: {
            zIndex: "999999",
        }
    };
    const renderBtn1 =() => {
        if (props.btn1Label && props.onbtn1Click)
        {
            return (
                <Button label={props.btn1Label} onClick={props.onbtn1Click} />
            )
        }
    }
    const renderBtn2 =() => {
        if (props.btn2Label && props.onbtn2Click)
        {
            return (
                <Button label={props.btn2Label} onClick={props.onbtn2Click} />
            )
        }
    }
    const renderBtn3 =() => {
        if (props.btn3Label && props.onbtn3Click)
        {
            return (
                <Button label={props.btn3Label} onClick={props.onbtn3Click} />
            )
        }
    }
    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onCancel}
            style={customStyles}
            contentLabel="Survey selector"
        >
            <div>
                <h2 className={"warning-text"}>{props.message}</h2>
                <h2 className={"question-text"}>{props.question}</h2>
                <div className={"buttons-panel"}            >
                    {renderBtn1()}
                    {renderBtn2()}
                    {renderBtn3()}
                </div>
            </div>
        </Modal>
    );
};

export default Warning3BtnPopup;
