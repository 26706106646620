import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    Image,
    BlobProvider,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import "./survey_report.css";
import logo from "./../../static/logo/logo-chmedic.png";

import template from "../../data/z_template_1visit";
import survey from "../../data/z_survey_1visit";
import person from "../../data/z_person";

import * as qHelper from "../../helpers/survey";
import styles from "./survey_report_pdf_styles";

function SurveyReportPDF(props) {
    const { t } = useTranslation();
    const created = dayjs().format("DD.MM.YYYY HH:mm");
    const locale = template.locale;

    const surveyHeader = () => {
        return (
            <View style={styles.header_survey}>
                <View style={styles.survey_info}>
                    <Text className="survey-title">
                        {qHelper.gettitle(template, locale)}
                    </Text>
                    <Text style={styles.survey_description}>
                        {qHelper.getdescription(template, locale)}
                    </Text>
                    <Text style={styles.survey_description}>Revision: 1.0</Text>
                    <Text style={styles.survey_description}>
                        Created by: Silvester Stallone
                    </Text>
                    <Text style={styles.survey_description}>
                        Date: 01.01.2022
                    </Text>
                </View>
                <View style={styles.survey_person}>
                    <Text className="name">
                        {person.name + " (" + person.auid + ")"}
                    </Text>
                    <Text className="birthdate">{person.birtdate}</Text>
                    <Text className="address">{person.address}</Text>
                    <Text className="phone">{person.phone}</Text>
                    <Text className="email">{person.email}</Text>
                </View>
            </View>
        );
    };

    const renderQuestion = ({ question, page_number, qnum }) => {
        const questionNumber =
            (question.questionNumberingOverride ?? page_number + "." + qnum) +
            ". ";

        const renderDate = () => {
            const result = qHelper.inputdate(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        const renderRadiogroup = () => {
            const result = qHelper.radiogroup(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        const renderCheckbox = () => {
            const result = qHelper.checkbox(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        const renderLeadMatrix = () => {
            const result_lead = qHelper.leadmatrix.matrix(question, survey);
            const renderHeader = () => {
                return (
                    <View style={styles.question} wrap={false}>
                        <Text style={styles.question_title}>
                            {questionNumber + result_lead.header}
                        </Text>
                    </View>
                );
            };
            const renderSide = (side) => {
                const matrix = [];
                const renderSideHeader = (header) => {
                    matrix.push(
                        <View className="question subquestion matrix3">
                            <Text className="question-title">
                                {side + ": "}
                            </Text>
                            <View className="question-answer">
                                <Text className="question-column1">
                                    {header[0]}
                                </Text>
                                <Text className="question-column2">
                                    {header[1]}
                                </Text>
                                <Text className="question-column3">
                                    {header[2]}
                                </Text>
                            </View>
                        </View>
                    );
                };
                const renderSideAnswers = (row_name, columns) => {
                    matrix.push(
                        <View className="question subquestion2 matrix3">
                            <Text className="question-title">
                                {row_name + ": "}
                            </Text>
                            <View className="question-answer">
                                <Text className="question-column1">
                                    {columns[0]}
                                </Text>
                                <Text className="question-column2">
                                    {columns[1]}
                                </Text>
                                <Text className="question-column3">
                                    {columns[2]}
                                </Text>
                            </View>
                        </View>
                    );
                };
                Object.keys(result_lead[side]).forEach((row_name) => {
                    if (row_name === "header")
                        renderSideHeader(result_lead[side][row_name]);
                    else
                        renderSideAnswers(
                            row_name,
                            result_lead[side][row_name]
                        );
                });
                return matrix;
            };
            return (
                <>
                    {renderHeader()}
                    {renderSide(Object.keys(result_lead)[1])}
                    {renderSide(Object.keys(result_lead)[2])}
                </>
            );
        };
        const renderOtherTypes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        switch (question.type) {
            case "inputdate":
                return renderDate();
            case "radiogroup":
                return renderRadiogroup();
            case "dropdown":
                return renderRadiogroup();
            case "checkbox":
                return renderCheckbox();
            case "leadmatrix8combined":
                return renderLeadMatrix();
            default:
                return renderOtherTypes();
        }
    };

    const renderQuestions = ({ questions, page_number }) => {
        const questions_render = [];
        let qnum = 1;
        if (!questions) return questions_render;
        Object.keys(questions).forEach((question_index) => {
            const question = questions[question_index];
            questions_render.push(
                renderQuestion({ question, page_number, qnum })
            );
            qnum++;
        });
        return questions_render;
    };

    const surveyPages = () => {
        const pages = [];
        Object.keys(template.pages).forEach((page_index) => {
            const page = template.pages[page_index];
            const page_number = parseInt(page_index) + 1;
            const title = qHelper.gettitle(page, "de");
            pages.push(
                <View className="survey-page" key={page_index}>
                    <View wrap={false} style={styles.chapter_header}>
                        <Text className="page-number">{title}</Text>
                    </View>
                    <View className="page-body">
                        {renderQuestions({
                            questions: page.elements,
                            page_number: page_number,
                        })}
                    </View>
                </View>
            );
        });
        return pages;
    };

    return (
        <Document>
            <Page size="A4" style={styles.body} wrap>
                <View style={styles.header} fixed>
                    <Image style={styles.header_logo} src={logo} />
                    <View style={styles.header_info}>
                        <Text>
                            {t("Survey report") +
                                ": " +
                                qHelper.gettitle(template, locale)}
                        </Text>
                        <Text>{"ID: 4567/" + created}</Text>
                        <Text>{}</Text>
                    </View>
                </View>
                {surveyHeader()}
                {surveyPages()}
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />
            </Page>
        </Document>
    );
}

const DownloadPDF = () => {
    return (
        <BlobProvider document={<SurveyReportPDF />}>
            {({ url }) => (
                <a href={url} target="_blank" rel="noreferrer">
                    Open report
                </a>
            )}
        </BlobProvider>
    );
};

export default DownloadPDF;
