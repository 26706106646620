import { Box, Button, ButtonGroup } from "@mui/material";
import { colors } from "../../pdf/pdf_layer_colors";
import PdfEditorContext from "../pdf_editor_context";
import { Save, PersonAdd } from "@mui/icons-material";
import Layer from "./pdf_layer";

const PdfLayers = () => {
    return (
        <PdfEditorContext.Consumer>
            {({
                data,
                onAddLayer,
                onDeleteLayer,
                onDocumentSave,
                layerModeToggle,
            }) => (
                <Box
                    sx={{
                        padding: "20px",
                        width: "800px",
                        height: "80vh",
                        backgroundColor: "#ededed",
                        overflow: "auto",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 2,
                            alignItems: "center",
                        }}
                    >
                        {data.layers.map((layer, index) => {
                            return (
                                <Layer
                                    key={index}
                                    index={index}
                                    canDelete={data.layers.length > 1}
                                    color={colors[index]}
                                    onDeleteLayer={onDeleteLayer}
                                    {...layer}
                                />
                            );
                        })}
                        <ButtonGroup>
                            <Button
                                disableTouchRipple
                                disableRipple
                                variant="outlined"
                                startIcon={<PersonAdd />}
                                disabled={data.layers.length > 3}
                                onClick={onAddLayer}
                            >
                                Add
                            </Button>
                            <Button
                                disableTouchRipple
                                disableRipple
                                variant="contained"
                                startIcon={<Save />}
                                onClick={onDocumentSave}
                            >
                                Save
                            </Button>
                            <Button
                                disableTouchRipple
                                disableRipple
                                onClick={layerModeToggle}
                            >
                                Edit Instance
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Box>
            )}
        </PdfEditorContext.Consumer>
    );
};

export default PdfLayers;
