const data = {
    auid: 73,
    name: 'Kimio Tremmel',
    birtdate: '1995-02-01',
    sex: 'Male',
    address: 'Greentree street - 45, Berlin, 10234',
    phone: '00334 345345 34535',
    email: 'demo@gmail.com'
}


export default data;