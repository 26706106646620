import axios from "axios";
import store from "../store";

//@TODO: add switch to https
//@TODO: for GET need string params after ?action1=3&action2='test'
class HttpConnector {
    constructor(params) {
        this.link = params.link;
        this.endpoint = params.endpoint + "?cb=" + Date.now();
        this.method = params.method || "post"; //child prop to axios
        this.token = null;
        this.payload = null;
        this.config = {
            method: params.method || "post", //child prop to axios
            baseURL: params.link,
            url: params.endpoint + "?cb=" + Date.now(),
        };
    }
    requestStarted() {
        store.dispatch({
            type: "request_started",
        });
    }
    requestFinished() {
        store.dispatch({
            type: "request_finished",
        });
    }
    request(payload, callback) {
        let self = this;
        this.requestStarted();
        const config = {
            method: this.method,
            baseURL: this.link,
            url: this.endpoint,
            data: payload,
        };

        config.headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        //if JWT exist add it to header
        if (window.token?.token_key) {
            config.headers.Authorization = "Bearer " + window.token?.token_key;
        }

        axios
            .request(config)
            .then(function (response) {
                self.requestFinished();
                callback(false, response.data);
            })
            .catch(function (error) {
                self.requestFinished();
                callback(error, false);
            });
    }
}

export default HttpConnector;
