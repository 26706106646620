import { Skeleton, Stack } from "@mui/material";

const PdfPageSkeleton = () => {
    const defaultPageWidth = 1192;

    return (
        <Stack spacing={2} sx={{ padding: 3 }}>
            <Skeleton
                animation="wave"
                variant="rounded"
                width={defaultPageWidth * 0.6}
                height={240}
            />
            <Skeleton
                animation="wave"
                variant="rounded"
                width={defaultPageWidth}
                height={240}
            />
            <Skeleton
                animation="wave"
                variant="rounded"
                width={defaultPageWidth}
                height={240}
            />
            <Skeleton
                animation="wave"
                variant="rounded"
                width={defaultPageWidth}
                height={952}
            />
        </Stack>
    );
};

export default PdfPageSkeleton;
