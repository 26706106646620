import get_title from "./gettitle";

export default function input(question,survey_answers,lang = 'default') {
    const title = get_title(question,lang);
    let answer = survey_answers[question.name];
    let answer_orig=""
    if (typeof answer==="object"){
        answer=survey_answers[question.name].value
        if (survey_answers[question.name].value_orig)
            answer_orig=survey_answers[question.name].value_orig
    }
    let params = '[';
    params += (question.decimalPlaces) ? 'Precision: ' + question.decimalPlaces : 'Precision: 0';
    params += (question.minValue) ? ', MinValue: ' + question.minValue : '';
    params += (question.maxValue) ? ', MaxValue: ' + question.maxValue : '';
    params += ']';
    
    //minValue: 0, maxValue: 10000, presicion: 2
    return {
        title: title,
        answer: answer,
        answer_orig: answer_orig,
        value: answer,
        type: 'input',
        params: params };
}