import { combineReducers } from 'redux';
import systemReducer from './system_reducer';
import authReducer from './auth_reducer'
import journalReducer from './journal_reducer';
import logActionsReducer from './log_actions_reducer';
import SurveyReducer from './survey_reducer';

import {reducer as notifications} from 'react-notification-system-redux';

const appReducer = combineReducers({
    systemReducer: systemReducer,
    authReducer: authReducer,
    notifications: notifications,
    journalReducer: journalReducer,
    logActionsReducer: logActionsReducer,
    SurveyReducer:SurveyReducer
});

//@TODO: add persistent middleware
const rootReducer = (state, action) => {
    //add methods for clear store data on logout
    return appReducer(state, action);
};

export default rootReducer;
