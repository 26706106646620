/* eslint-disable import/no-anonymous-default-export */
import store  from './../../store'
import Notifications from 'react-notification-system-redux';

function error(message, title) {
    store.dispatch(
        Notifications.error({
            title: title,
            message: message,
            position: 'tc',
            action: {
                label: 'PRESS TO CONTINUE',
                callback: function() {}
            },
            autoDismiss: false})
    );
}

function error_cb(message, title,callback) {
    store.dispatch(
        Notifications.error({
            title: title,
            message: message,
            position: 'tc',
            action: {
                label: 'PRESS TO CONTINUE',
                callback: callback
            },
            autoDismiss: false})
    );
}

function warning(message) {
    store.dispatch(
        Notifications.warning({
            message: message,
            position: 'tc',
            action: {
                label: 'PRESS TO CONTINUE',
                callback: function() {}
            },
            autoDismiss: false})
    );
}

function success(message) {
    store.dispatch(
        Notifications.success({
            title: message,
            position: 'tr',
            autoDismiss: 5})
    );
}
function success_d(message,duration) {
    let dur=500
    if (!isNaN(duration) && !isNaN(parseInt(duration))){
        dur=duration
    }
    store.dispatch(
        Notifications.success({
            title: message,
            position: 'tr',
            autoDismiss: dur })
    );
}

function info(message) {
    store.dispatch(
        Notifications.info({
            dismissible: 'click',
            message: message,
            position: 'tc',
            autoDismiss: 0})
    );
}

export default { success,success_d, error,error_cb, info, warning}