import { Button } from "../../components";
import React from "react";
import show_icon from "../../static/png16/Show_16x16.png"
import hide_icon from "../../static/png16/Hide_16x16.png"
import add_icon from "../../static/png16/Add_16x16.png"
import del_icon from "../../static/png16/Delete_16x16.png"
import info_icon from "../../static/png16/Info_16x16.png"
import info_icon_mapped from "../../static/png16/Warning_16x16.png"
import TemplateSelector from "./template_selector";


export default function TableMerge(props) {
    const firstColumn="firstcolumn"

    const onCellClick=(cell,act)=> {
        props.onTableCellClick(cell,act)
    }
    const onChildAdd=(template)=> {
        props.onChildAdd(template);
    }

    const renderRow=(row,type)=>{
        if (!row["firstcolumn"])  return null;
        const result = [];
        let name="row"+row[firstColumn].name
        let class_name="merge-row merge-row-"+row[firstColumn].name;
        result.push(<tr className={class_name} key={name}>{renderCell(row)}</tr>)
        if (type==="h")
            return <thead key={"body"+name}>{result}</thead>;
        else {
             return <tbody key={"body"+name}>{result}</tbody>;
        }
    }

    const renderCell=(row)=> {
        const result = [];
        let cell = "";
        let class_name = "merge-cell"
        let cell_param={
            id:"",
            question_id: "",
            child_question_id: "",
        }

        Object.keys (row).forEach ((col) => {
            let isVisible=true;
            if (col !== "id" && col !== "title" && col!=="firstcolumn" && props.masterIdRow[col] ) {
                isVisible=props.questionsVisible.includes( props.masterIdRow[col].q_id)
            }
            if (col !== "id" && col !== "title" && isVisible) {
                let name = "cell" + row[col].name
                class_name = "merge-cell-" + (col === firstColumn ? "first" : "data")
                if (row[firstColumn].name==="Template" && col === firstColumn)
                    class_name+="-first"

                let rowtype = row[firstColumn].name;
                if (rowtype.toLowerCase ().includes ("child"))
                    rowtype = "child"
                 if (row[firstColumn].name==="Template" && col === firstColumn)
                      cell = <th className={class_name} key={name}>{row[col].label} </th>
                 else
                    cell = <td className={class_name} key={name}>{row[col].label} </td>
                if (rowtype ==="filter" && col === firstColumn) {
                    cell = <td className={class_name} key={col}>
                        <div>{row[col].label}</div>
                        <div className={"filter-btn-block"}>
                            <Button name={row[firstColumn].name + "-set"}
                                    className="like-link filter-set"
                                    label="Set all"
                                    onClick={() => {
                                        cell_param.id = row.id;
                                        cell_param.question_id = col;
                                        onCellClick (cell_param, "setAll")
                                    }}
                            />
                            <Button name={row[firstColumn].name + "-unset"}
                                    className="like-link filter-unset"
                                    label="Unset all"
                                    onClick={() => {
                                        cell_param.id = row.id;
                                        cell_param.question_id = col;
                                        onCellClick (cell_param, "unSetAll")
                                    }}
                            />
                        </div>
                    </td>
                } else if (
                    rowtype === "child"){
                    if (col === firstColumn) {
                        cell = <td className={class_name} key={col}>
                            <div>{row[col].label}</div>
                            <Button name={row[firstColumn].name}
                                    className="like-link"
                                    label="Remove survey"
                                    onClick={() => {
                                        cell_param.id=row.id;
                                        cell_param.question_id=col;
                                        onCellClick (cell_param, "DelChild")
                                    }}
                            />
                        </td>
                    } else {
                        if (row[col].q_id){
                            let  info_img=info_icon
                            if (row[col].child_values_map){
                                if (row[col].child_values_map.length>0) {
                                    info_img = info_icon_mapped
                                }
                            }
                            cell =
                            <td className={class_name} key={col}>
                               {row[col].label}
                                <div className={"manage-btn"}>
                                    <img className="add-delete-map"
                                         src={del_icon}
                                         alt="logo"
                                         onClick={() => {
                                             cell_param.id=row.id;
                                             cell_param.question_id=col;
                                             cell_param.child_question_id=row[col].q_id
                                             onCellClick (cell_param, "delMap")
                                         }}
                                    />
                                    <img className="child-question-info"
                                         src={info_img}
                                         alt="logo"
                                         onClick={() => {
                                             cell_param.id=row.id;
                                             cell_param.question_id=col;
                                             cell_param.child_question_id=row[col].q_id
                                             onCellClick (cell_param, "qInfo")
                                         }}
                                    />
                                </div>
                            </td>

                        } else {
                            cell = <td className={class_name} key={col}>
                                <div>{row[col].label}</div>
                                <img className="add-delete-map"
                                     src={add_icon}
                                     alt="logo"
                                     onClick={() => {
                                         cell_param.id=row.id;
                                         cell_param.question_id=col;
                                         onCellClick (cell_param, "addMap")
                                     }}
                                />

                            </td>
                        }
                    }
                }
                else if (rowtype === "filter" && col !== firstColumn) {
                    let icon = row[col].label === "+" ? show_icon : hide_icon
                    cell =
                    <td className={class_name+" show_on_off"} key={col}
                        onClick={() => {
                            cell_param.id=row.id;
                            cell_param.question_id=col;
                            onCellClick (cell_param, "ToggleColumnFilter")
                        }}
                    >
                        <img className="show_on_off"
                             src={icon}
                             alt="logo"

                        />
                    </td>
                }

                result.push (cell)
            }
        })
        return result;
    }

    const renderChildren=()=> {
        const result = [];
          props.childRows.forEach ((row) => {
              result.push (renderRow (row))
          })

        return result;
    }

    let childIdList=[]
    if (props.childRows.length>0){
        props.childRows.forEach((child)=>{
            childIdList.push(child.id)
        })

    }

    return (
        <div className="table-horizontal">
            <table>
                {renderRow (props.templateRow,"h")}
                {renderRow (props.masterRow)}
                {renderRow (props.typesRow)}
                {renderRow (props.masterIdRow)}
                {renderRow (props.filterRow)}
                {renderChildren ()}
                <tbody>
                    <tr className={"merge-row"}>
                        <td key="add-child-btn" className={"merge-cell-first add-child-btn"}>
                            <TemplateSelector text="Add child" onAdd={onChildAdd} childList={childIdList}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
