import ApiModel from "./api_model";
import Connector from "./http_connector";
import { encode } from "js-base64";

const connector = new Connector({
    link: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_LINK,
    endpoint: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_ENDPOINT,
});
class apiModelAuth extends ApiModel {
    delete_answer(answerId, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "survey";
        payload.mount_type = "delete_survey_answer";
        payload.params = {
            answerId: answerId,
            au_aid: window.uri_params.au_aid,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }

    load_scf_db(callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "databanken";
        payload.mount_type = "get_scf_dbs";
        payload.params = {
            au_aid: window.uri_params.au_aid,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }

    get_templates_list(fid, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "webtemplate";
        payload.mount_type = "get_templates_list";
        payload.params = {
            fid: fid,
            au_aid: window.uri_params.au_aid,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
    get_templates_list_all(callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "survey";
        payload.mount_type = "list";
        payload.params = {};

        this._connectApi(payload, (err, res) => {
            if (!err) {
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
    load_template(fid, anonymous_mode, only_scf, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "webtemplate";
        payload.mount_type = "load_template";
        payload.params = {
            fid: fid,
            au_aid: window.uri_params.au_aid,
            anonymous_mode: anonymous_mode,
            only_SCF: only_scf,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }

    get_survey(surveyId, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "survey";
        payload.mount_type = "get_survey";
        payload.params = {
            surveyId: surveyId,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }

    save_importcsv_setup(filename, template_id, user_info, data, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "import_csv";
        payload.mount_type = "insert_data";
        payload.params = {
            filename: filename,
            template_id: template_id,
            user_info: user_info,
            data: data,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                callback(false, res.success);
            } else {
                callback(err, res);
            }
        });
    }
    get_saved_importcsv_setup(filename, template_id, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "import_csv";
        payload.mount_type = "get_data";
        payload.params = {
            filename: filename,
            template_id: template_id,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }

    import_save(survey, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "survey";
        payload.mount_type = "import_save";
        payload.params = {
            survey: survey,
            id3d: survey.ident3p,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }

    import_save_bulk(header_info, surveys, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "survey";
        payload.mount_type = "import_save_bulk";
        payload.params = {
            import_json_data: {
                header_info: header_info,
                instances: surveys,
            },
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                if (res?.api_data?.otp_param_s64)
                    window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
    load_combination(fid, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "surveydata";
        payload.mount_type = "load_combination";
        payload.params = {
            fid: fid,
            au_aid: window.uri_params.au_aid,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
    save_combination(template, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "surveydata";
        payload.mount_type = "save_combination";

        const arr_comb = encode(template);

        payload.params = {
            fid: window.uri_params.fids,
            au_aid: window.uri_params.au_aid,
            data: {
                arr_comb_templ_json64: arr_comb,
            },
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }

    load_calc_column(fid, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "surveydata";
        payload.mount_type = "load_calc_column";
        payload.params = {
            fid: fid,
            au_aid: window.uri_params.au_aid,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
    save_calc_column(calc_data, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "surveydata";
        payload.mount_type = "save_calc_column";

        const calc_data_encoded = JSON.stringify(calc_data);

        payload.params = {
            fid: window.uri_params.fids,
            au_aid: window.uri_params.au_aid,
            data: {
                arr_result_col_calc_json64: calc_data_encoded,
            },
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
}

const apiModel = new apiModelAuth(connector);

export default apiModel;
