 import { store } from 'react-notifications-component';

const notifications = {
    success_top_full: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "success",
            insert: "top",
            container: "top-full",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2000
            },
            slidingEnter: {
                duration: 100,
                timingFunction: 'ease-out',
                delay: 0
            },
            slidingExit: {
                duration: 300,
                timingFunction: 'ease-out',
                delay: 0
            }
        });
    },
    error_top_full: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "danger",
            insert: "top",
            container: "top-full",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2000,
                pauseOnHover: true
            },
            slidingEnter: {
                duration: 100,
                timingFunction: 'ease-out',
                delay: 0
            },
            slidingExit: {
                duration: 300,
                timingFunction: 'ease-out',
                delay: 0
            }
        });
    },
    error_center: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "danger",
            insert: "top",
            container: "center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 0,
                showIcon: true
            },
            slidingEnter: {
                duration: 100,
                timingFunction: 'ease-out',
                delay: 0
            },
            slidingExit: {
                duration: 300,
                timingFunction: 'ease-out',
                delay: 0
            }
        });
    }
}

export default notifications;