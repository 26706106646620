import * as qHelper from './../../helpers/survey';

export default function createColumnValue (table_row,question,survey,locale,showValues) {
    switch (question.type) {
        case 'inputdate':
            return table_row[question.name] = qHelper.inputdate(question, survey, locale).answer;
        case 'radiogroup':
            let radiogroup_object=qHelper.radiogroup(question, survey, locale, showValues)
            table_row[question.name]=radiogroup_object.answer
            if (radiogroup_object.answer_orig){
                table_row[question.name+"_orig"]=radiogroup_object.answer_orig
            }
            return table_row[question.name];
        case 'dropdown':
            let dropdown_object=qHelper.radiogroup(question, survey, locale, showValues)
            table_row[question.name]=dropdown_object.answer
            if (dropdown_object.answer_orig)
                table_row[question.name+"_orig"]=dropdown_object.answer_orig
            return table_row[question.name];
        case 'checkbox':
            let checkbox_object=qHelper.checkbox(question, survey, locale, showValues)
            table_row[question.name]=checkbox_object.answer
            if (checkbox_object.answer_orig)
                table_row[question.name+"_orig"]=checkbox_object.answer_orig
            return table_row[question.name];
        case 'leadmatrix8v2':
            return table_row[question.name] = qHelper.matrixcell(question, survey, locale).answer;
        case 'leadprobes8v2':
            return table_row[question.name] = qHelper.programcell(question, survey, locale).answer;
        default:
            let input_object=qHelper.input(question, survey, locale)
            table_row[question.name]=input_object.answer
            if (input_object.answer_orig)
                table_row[question.name+"_orig"]=input_object.answer_orig
            return table_row[question.name];
    }
}

