import React from 'react';
import { Table, Form, TableControl, Button } from '../../components';
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next';
import persons from './../../data/z_persons';
import { searchInArray } from '../../helpers/search';
import './group_selector.css';



class GroupSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = { filter: '', source_group: persons, target_group: [] };
    }
    handleFilter(params) {
        this.setState({ filter: params.value });
    }
    componentDidMount() {
        console.log('did');
        // persons.forEach(person => {
        //     source_group.push(person)
        // })
     
    }
    addPersonToTarget(id,params) {
        if (id)
        this.setState({ 
            target_group: [...this.state.target_group, params],
            source_group: this.state.source_group.filter((person)=>{return person.survey_id !== params.survey_id})
         });
    }
    removePersonFromTarget(id,params) {
        if (id)
        this.setState({ 
            source_group: [...this.state.source_group, params],
            target_group: this.state.target_group.filter((person)=>{return person.survey_id !== params.survey_id})
         });
    }
    render() {
        //if (!this.state.source_group.length) return null;
        this.state.source_group.sort((a, b) => (a.submitted < b.submitted) ? 1 : ((b.submitted < a.submitted) ? -1 : 0));
        const filteredList = searchInArray({ list: this.state.source_group, filter: this.state.filter, fields: ['name'] });
        const next_status = (this.state.target_group.length) ? 'enabled' : 'hidden';
        return (
            <Form className="event-wrap" title="Select patient">
                <div className="side-2-panel">
                    <div className="monitor-table-info">
                        <TableControl search={this.state.filter} className="survey-template" handleSearch={this.handleFilter.bind(this)} />
                        <Table name="templates_list" className="log-actions" table_key={'survey_id'}
                            handleSelect={this.addPersonToTarget.bind(this)}
                            headers={{
                                survey_id: { label: 'ID', width: '10%' },
                                name: { label: 'Name', width: '20%' },
                                submitted: { label: 'Survey submitted', width: '20%' },
                                notes: { label: 'Notes', width: '50%' },
                            }} data={filteredList} />
                       
                    </div>
                    <div className="target-group">
                        <div className="title">Selected persons for survey analyzis</div>
                        <Table name="templates_list" className="log-actions" table_key={'survey_id'}
                            handleSelect={this.removePersonFromTarget.bind(this)}
                            headers={{
                                survey_id: { label: 'ID', width: '10%' },
                                name: { label: 'Name', width: '20%' },
                                submitted: { label: 'Survey submitted', width: '20%' },
                                notes: { label: 'Notes', width: '50%' },
                            }} data={this.state.target_group} />
                             <Button status={next_status} label="Next" onClick={() => {
                            this.props.history.replace('/group_survey_report');
                        }} />
                    </div>
                </div>
            </Form>

        )
    }
}

function mapStateToProps(state) {
    return {
        person_selector: state.SurveyReducer.person_selector
    };
}

function mapDispatchToProps(dispatch) {
    return ({
        selectPerson(id, params) {
            dispatch({
                type: 'set_person_selector',
                value: params
            });
        },
    })
}

export default withTranslation()(GroupSelector = connect(mapStateToProps, mapDispatchToProps)(GroupSelector));





