import dayjs from "dayjs";
import { searchInArray } from "../../helpers/search";
import { isNumber } from "chart.js/helpers";
import createColumnValue from "./cellvalue";
import { createColumnValueAge } from "../../helpers/Template_ext";

const filterBySelection = (data, filter) => {
    return data.filter((row) => {
        return filter.selected.includes(row.instance_id);
    });
};

const filterByString = (
    data,
    filter,
    searchable_question_names,
    non_filterable_fields
) => {
    const filteredListPatient = data.filter((row) => {
        return (
            !filter.patient ||
            row.instance_answering_person_name
                ?.toLowerCase()
                .includes(filter.patient.toLowerCase())
        );
    });

    const filteredListDate = filteredListPatient.filter((row) => {
        return (
            row.relevant_date >=
                dayjs(filter.date_from || new Date(1000, 1, 1)).format(
                    "YYYY-MM-DD"
                ) &&
            row.relevant_date <=
                dayjs(filter.date_till || new Date(3000, 1, 1)).format(
                    "YYYY-MM-DD"
                )
        );
    });

    return searchInArray({
        list: filteredListDate,
        filter: filter.free_search,
        fields: searchable_question_names.filter((question_name) => {
            return !non_filterable_fields.includes(question_name);
        }),
    });
};

const parseField = (field) => {
    if (typeof field !== "string" || !field) return -1;

    let parsed = field;

    if (field.includes("=")) {
        parsed = field.split("=")[0];
    } else if (
        field.toLocaleLowerCase().startsWith("t") &&
        field.toLocaleLowerCase().endsWith(" (other)")
    ) {
        parsed = field.split(" (other)")[0];
    }

    return parseInt(parsed.replace(/[^0-9]/g, "")) || 0;
};

const sortByField = (a, b, field, order_obj, show_codes) => {
    let a_field = a[field] || "";
    let b_field = b[field] || "";

    if (order_obj.type === "dropdown") {
        a_field = parseField(a_field);
        b_field = parseField(b_field);
    }

    if (show_codes) {
        if (
            order_obj.type === "inputcustom" ||
            order_obj.type === "nouislider" ||
            order_obj.type === "radiogroup" ||
            order_obj.type === "dropdown" ||
            order_obj.type === "expression"
        ) {
            a_field = parseFloat(a_field) || 0;
            b_field = parseFloat(b_field) || 0;
        } else if (order_obj.type === "checkbox") {
            if (isNumber(a_field)) a_field = parseFloat(a_field) || 0;
            else a_field = 0;
            if (isNumber(b_field)) b_field = parseFloat(b_field) || 0;
            else b_field = 0;
        }
    } else {
        if (
            order_obj.type === "inputcustom" ||
            order_obj.type === "nouislider"
        ) {
            a_field = parseFloat(a_field) || 0;
            b_field = parseFloat(b_field) || 0;
        }
    }

    if (order_obj.order === "asc")
        return a_field > b_field ? 1 : b_field > a_field ? -1 : 0;

    return a_field < b_field ? 1 : b_field < a_field ? -1 : 0;
};

export function sortRecords(list, sorter, show_codes) {
    const sort_fields = Object.keys(sorter);
    const sort_qty = sort_fields.length;

    if (!sort_qty) return list;

    if (sort_qty === 1)
        list.sort((a, b) =>
            sortByField(
                a,
                b,
                sort_fields[0],
                sorter[sort_fields[0]],
                show_codes
            )
        );
    if (sort_qty === 2)
        list.sort(
            (a, b) =>
                sortByField(
                    a,
                    b,
                    sort_fields[0],
                    sorter[sort_fields[0]],
                    show_codes
                ) ||
                sortByField(
                    a,
                    b,
                    sort_fields[1],
                    sorter[sort_fields[1]],
                    show_codes
                )
        );
    if (sort_qty === 3)
        list.sort(
            (a, b) =>
                sortByField(
                    a,
                    b,
                    sort_fields[0],
                    sorter[sort_fields[0]],
                    show_codes
                ) ||
                sortByField(
                    a,
                    b,
                    sort_fields[1],
                    sorter[sort_fields[1]],
                    show_codes
                ) ||
                sortByField(
                    a,
                    b,
                    sort_fields[2],
                    sorter[sort_fields[2]],
                    show_codes
                )
        );
}

export function processData({ table_rows, showQuestions, showValues, locale ,calcColumn}) {
    table_rows?.forEach((table_row) => {
        showQuestions.forEach((question) => {
            createColumnValue(
                table_row,
                question,
                table_row,
                locale,
                showValues,
            );
        });
        if (calcColumn?.selected?.name)
            createColumnValueAge(table_row,calcColumn,showQuestions[0].surveyId)
    });
}

export function sortAndFilterRecords({
    table_rows,
    filter,
    non_filterable_fields,
    showQuestions,
    sorter,
    showValues,
}) {
    if (!table_rows) return;

    const searchable_question_names = showQuestions.map(
        (question) => question.name
    );

    table_rows =
        filter.selected && filter.selected.length
            ? filterBySelection(table_rows, filter)
            : filterByString(
                  table_rows,
                  filter,
                  searchable_question_names,
                  non_filterable_fields
              );

    sortRecords(table_rows, sorter, showValues);

    return table_rows;
}
