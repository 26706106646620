import React from "react";
import { isDateTime, getInputDate, getLabel } from "../../helpers/import_instances_helper";
import { Tooltip as TooltipBootstrap } from "react-bootstrap";


export default function TableViewMaped(props) {

    if (props.questions.length===0) return null
    if (props.csv_data.length===0) return null


    const renderHead1 = () => {
        const headers = [];
        // headers.push(
        //     <th className={"hdr-column first-header  imp-status"} key={"hdrcsvrowdempty"}>
        //         {""}
        //     </th>
        // );
        headers.push(
            <th className={"hdr-column first-header"} key={"hdrcsvrowsempty1"}>
                {""}
            </th>
        );
        headers.push(
            <th className={"hdr-column first-header"} key={"hdrcsvpatkeyempty1"}>
                {""}
            </th>
        );
        Object.values(props.questions).forEach((row) => {
            if (row.linked_key) {
                if (isDateTime(row) || row.question_choices) {
                    headers.push(
                        <th colSpan={2} className={"hdr-column csv first-header"} key={"hdr-csv1" + row.key}>
                            {row.label}
                        </th>
                    )
                } else {
                    headers.push(
                        <th className={"hdr-column first-header"} key={"hdr1" + row.key}>
                            {row.label}
                        </th>
                    )
                }
            }
        })
        return (
            // <thead>
            <tr className="hdr-labels">{headers}</tr>
            // </thead>

        );
    }
    const renderHead = () => {
        const headers = [];
        // headers.push(
        //     <th className={"hdr-column second-header imp-status"} key={"hdrcsvrows"}>
        //         {"Imported"}
        //     </th>
        // );
        let lbl= `CSV Rows (${props.csv_data.length})`
        headers.push(
            <th className={"hdr-column second-header"} key={"hdrcsvrows1"}>
                {lbl}
            </th>
        );
        headers.push(
            <th className={"hdr-column second-header"} key={"hdrcsvpatkey"}>
                {"Patient key"}
            </th>
        );
        Object.values(props.questions).forEach((row) => {
            if (row.linked_key) {
                if (isDateTime(row) || row.question_choices) {
                    headers.push(
                        <th className={"hdr-column csv second-header"} key={"hdr-csv" + row.key}>
                            {"CSV"}
                        </th>
                    )
                    headers.push(
                        <th className={"hdr-column quest second-header"} key={"hdr-quest" + row.key}>
                            {"Match"}
                        </th>
                    )
                } else {
                    headers.push(
                        <th className={"hdr-column csv second-header"} key={"hdr-csv-empty" + row.key}>
                            {""}
                        </th>
                    )
                }
            }
        })
        return (
            // <thead>
                <tr className="hdr-labels">{headers}</tr>
            // </thead>

        );
    }

    const renderRows = () => {
        if (props.questions.length > 0) {
            let data = [];
            props.csv_data.forEach((csv_row, idx) => {
                let data_row = []
                data_row.push(
                    <td className={"data-column"} key={`cell-${idx}-rowsidx`}>
                        {idx + 1}
                    </td>
                );
                let pat_key=""
                let pat_key_fields=props.questions.find((f) => {return (f.key==="patient_ident_fields" && f.is_system==="srv")})
                if (pat_key_fields) {
                    Object.values(props.questions).forEach((quest) => {
                        if (pat_key_fields.arr_fields.includes(quest.key)) {
                            if (pat_key)
                                pat_key+=pat_key_fields?.pat_ident_key_separator || "#"
                            if (quest.key === "patient_birthday") {
                                pat_key += getInputDate(csv_row[quest.linked_key], quest.options.date_format, quest.options.date_delimiter)
                            } else {
                                pat_key += csv_row[quest.linked_key] ?? ""
                            }
                        }
                    })
                }
                data_row.push(
                    <td className={"data-column"} key={`cell-${idx}-patkey`}>
                        {pat_key.replace(' ','_')}
                    </td>
                );

                Object.values(props.questions).forEach((quest,qidx) => {
                   // let field = csv_row.find((f) => f.field === quest.linked_key)
                    if (typeof csv_row[quest.linked_key]!=="undefined") {
                        let class_name = "data-column"
                        let cell_txt_csv = ""
                        let cell_txt_quest = ""
                        if (isDateTime(quest)) {
                            cell_txt_csv = `${csv_row[quest.linked_key]}`
                            if (cell_txt_csv===props.na_value.text) {
                                class_name +=" na-answer"
                                cell_txt_quest = props.na_value.label
                            } else {
                                let dt = getInputDate(csv_row[quest.linked_key], quest.options.date_format, quest.options.date_delimiter)
                                if (dt === "###") {
                                    cell_txt_quest = " - is not date"
                                    class_name +=" error"
                                } else {
                                    cell_txt_quest = dt
                                    class_name += " success"
                                }
                            }
                        } else if (quest.question_choices) {
                            if ( (csv_row[quest.linked_key] ?? "").toString() === props.na_value.text) {
                                cell_txt_csv=csv_row[quest.linked_key]
                                class_name +=" na-answer"
                                cell_txt_quest = props.na_value.label
                            } else {
                                if (quest.question_type === "checkbox") {
                                    if (csv_row[quest.linked_key]) {
                                        let val_array = (csv_row[quest.linked_key] ?? "").toString().split(",")
                                        let is_na=false;
                                        val_array.forEach((val) => {
                                            let success = true
                                            let val_el
                                            if (quest.options.maped_values)
                                                val_el = quest.options.maped_values.find((f) => f.csv_value === (val ?? "").toString().trim())
                                            if (!val_el?.choice?.value || !val_el)
                                                success = false
                                            else if (val_el?.choice?.value===props.na_value.value) {
                                                is_na=true
                                            }
                                            if (cell_txt_csv) {
                                                cell_txt_csv += ", "
                                            }
                                            if (cell_txt_quest) {
                                                cell_txt_quest += ", "
                                            }
                                            if (is_na) {
                                                cell_txt_csv=csv_row[quest.linked_key]
                                                class_name +=" na-answer"
                                                cell_txt_quest = props.na_value.label
                                            } else {
                                                cell_txt_csv += getLabel(val ?? "", 1, val_el, props.lng)
                                                cell_txt_quest += getLabel(val ?? "", 2, val_el, props.lng)
                                                class_name += success ? " success" : (cell_txt_csv ? " error": "" )
                                            }
                                        })
                                    } else {
                                        cell_txt_csv = ""
                                        cell_txt_quest = ""
                                    }
                                } else {
                                    let el
                                    if (quest.options.maped_values)
                                        el = quest.options.maped_values.find((f) => (f.csv_value ?? "").toString().trim() === (csv_row[quest.linked_key] ?? "").toString().trim())
                                    if (el?.choice?.value===props.na_value.value){
                                        cell_txt_csv=csv_row[quest.linked_key]
                                        class_name +=" na-answer"
                                        cell_txt_quest = props.na_value.label
                                    } else {
                                        cell_txt_csv = getLabel(csv_row[quest.linked_key], 1, el, props.lng)
                                        cell_txt_quest = getLabel(csv_row[quest.linked_key], 2, el, props.lng)
                                        class_name += el?.choice?.value ? " success" : (cell_txt_csv ? " error" : "")
                                    }
                                }
                            }
                        } else {
                            cell_txt_csv = `${csv_row[quest.linked_key]}`
                            cell_txt_quest = `${csv_row[quest.linked_key]}`
                            if ( cell_txt_csv === props.na_value.text ){
                                class_name +=" na-answer"
                            }
                        }
                        if (cell_txt_csv==="null")
                            cell_txt_csv=""
                        if (cell_txt_quest==="null")
                            cell_txt_quest=""
                        if (isDateTime(quest) || quest.question_choices) {
                            data_row.push(
                                <td className={class_name + " csv"} key={`cell-${idx}-${qidx}-${quest.linked_key}-csv`}>
                                    {cell_txt_csv}
                                </td>
                            )
                            data_row.push(
                                <td className={class_name + " quest"} key={`cell-${idx}-${qidx}-${quest.linked_key}-quest`}>
                                    {cell_txt_quest}
                                </td>

                            )
                        } else {
                            data_row.push(
                                <td className={class_name + " csv"} key={`cell-${idx}-${qidx}-${quest.linked_key}-quest`}>
                                    <TooltipBootstrap
                                        key={`cell-${idx}-${qidx}-${quest.linked_key}-tooltip`}
                                        title={cell_txt_quest}>
                                        {cell_txt_quest}
                                    </TooltipBootstrap>
                                </td>

                            )
                        }
                    }
                })
                data.push(
                    <tr className={"data-column"} key={"data" + idx}>
                        {data_row}
                    </tr>
                )
            })
            return (
                <tbody>
                {data}
                </tbody>

            );
        }
    }

    return (
        <div className="table-view-maped">
            <table>
                <thead>
                    {renderHead1()}
                    {renderHead()}
                </thead>
                {renderRows()}
            </table>
        </div>
    )
}