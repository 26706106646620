import Pako from "pako";
import { Buffer } from "buffer";

const chunkify = function* (buffer, chunkSize) {
  var len = buffer.maxByteLength;
  var i = 0;

  while (i < len) {
    const bufferValue = buffer.slice(i, i + chunkSize);

    yield {
      value: bufferValue,
      done: chunkSize > bufferValue.maxByteLength,
    };

    i += chunkSize;
  }
};

export function uncompress(compressed_data) {
  const strData = Buffer.from(compressed_data, "base64").buffer;

  const dataChunks = chunkify(strData, 20000);
  const inflate = new Pako.Inflate();

  for (let chunk of dataChunks) {
      inflate.push(chunk.value, chunk.done);
  }

  if (inflate.err) {
      throw new Error("Decompression error: " + inflate.err);
  }

  const data = Buffer.from(inflate.result).toString();

  return JSON.parse(data);
}
