import get_title from "./gettitle";

export default function inputdate(question,survey_answers,lang = 'default') {

    const title = get_title(question,lang);
    let answer = survey_answers[question.name];
    if (typeof answer==="object"){
        answer=answer?.value
    }
    return { title: title, answer: answer, value: answer, type: 'date' };
}