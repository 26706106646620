import { decode } from "./../helpers/base64decode";
export function getKey (text) {
    return text.toLowerCase().replace(/\W/g, '_')
}
export function isDateTime (row)  {
    let result=false
    if (row.question_type==="inputdate"
        || (row.question_type==="system" && row.key=== "patient_birthday" )
    )
        result=true
    return result
}

export function getText  (text,lng="default") {
    return typeof text === "object"
        ? text[lng] || text.default || text.en || text.de || ""
        : text;
}

export function getLabel  (csv_value,mode,element,lng="default") {
    if (!element?.choice?.value){
        if (mode === 0)
            return `${csv_value} - not found`
        else if (mode === 1)
            return `${csv_value ?? ""}`
        else {
            if (csv_value==="0" || csv_value===0)
                return `not found`
            else
                return csv_value ? `not found` : ""
        }

    }
    let code = ""//  element.choice.code ? `[${ element.choice.code}]`  : ""
    if (mode === 0)
        return `${csv_value} -> ${getText(element.choice.text,lng)} ${code}`
    else if (mode === 1)
        return `${csv_value}`
    else
        return `${getText(element.choice.text,lng)} ${code}`
}

export function getDropdown (val, choices,lng)  {
    let result={}
    let val_str=val ? val.toString().trim() : ""
    if (Array.isArray(choices)) {
        choices.forEach((el) => {
            if (!result.value) {
                let txt = getText (el.text, lng)
                if (txt === val_str) {
                    result = el
                } else {
                    if (el.code) {
                        if (el.code.toString().trim() === val_str)
                            result = el
                    }
                }
            }
        })
    }
    return result
}

export function  getInputDate (date,date_templ,delimiter)  {
    let result;
    let curr_year = new Date().getUTCFullYear();
    if (!date) return ""
    if (typeof date !=="string" || typeof date_templ !=="string" || !delimiter)
        return "###"
    let templ_arr=date_templ.split(delimiter)
    if (templ_arr.length<3) return "###"
    if (!templ_arr[0] || ! templ_arr[1] || !templ_arr[2]) return "###"
    let dt_arr=date.split(delimiter)
    let y=""
    let m=""
    let d=""
    for (let i=0;i< dt_arr.length; i++ ){
        if (templ_arr[i].includes("d"))  d = dt_arr[i].length===1 ? "0"+dt_arr[i] : dt_arr[i]
        else if  (templ_arr[i].includes("m"))  m = dt_arr[i].length===1 ? "0"+dt_arr[i] : dt_arr[i]
        else if  (templ_arr[i].includes("y")) {
            if (dt_arr[i].length===1) {
                y = "200"+dt_arr[i]
            } else if (dt_arr[i].length===2) {
               y = "20"+dt_arr[i]
            }
            else if (dt_arr[i].length===3) y = "2"+dt_arr[i]
            else y = dt_arr[i]
            if (+y>curr_year)
                y = "19"+y.substring(2)

        }
    }
    result=`${y}-${m}-${d}`
    let check_date=Date.parse(result)
    if (isNaN(check_date)) {
        return `###`
    }

    return result
}

export function getSavedSetup (list,db,templates,lng)  {
    let result={header: [],data: []}
    const visible_cols=["selector","id","fid","filename","user_info","last_change"]
    if (Array.isArray(list)) {
        if (list.length>0) {
            result.header=getSavedSetupHeader(list[0],visible_cols,lng)
            list.forEach((row) => {
                result.data.push(getSavedSetupRow(row,visible_cols, db, templates))
            })
        }
    }


    return result
}
function getSavedSetupHeader(row,visible_cols,lng) {
    let hdr = []
    if (visible_cols.includes("selector"))
        hdr.push({ field: "selector", label: "select", visible: visible_cols.includes("selector") })
    Object.keys(row).forEach((key) => {
        let obj = { field: key, label: key, visible: visible_cols.includes(key) }
        if (key === "fid") {
            obj.label = getText("Template", lng)
        } else if (key === "json_data") {
            obj.label = getText("Setup", lng)
        } else if (key === "filename") {
            obj.label = getText("File", lng)
        } else if (key === "user_info") {
            obj.label = getText("User info", lng)
        } else if (key === "datetime") {
            obj.label = getText("Date create", lng)
        } else if (key === "last_change") {
            obj.label = getText("Last change", lng)
        }
        hdr.push(obj)
    })
    return hdr
}
function getSavedSetupRow(row,visible_cols,db, templates) {
    let result=[]
    result.push( {field: "selector",id: row.id,value: "select",label:"select",isbtn: true,visible: visible_cols.includes("selector")})

    Object.keys(row).forEach((key) => {
            let obj= {field: key,id: row.id,value: row[key],label: row[key],isbtn: false,visible:visible_cols.includes(key) }
            if (key ==="fid") {
                let template=templates.find((f) => f.value===row[key])
                if (template)
                    obj.label=template.label
               // obj.isbtn=true
            } else if (key ==="mid") {
                obj.label=db.label
            } else if (key ==="json_data") {
                obj.value = JSON.parse(decode(obj.value,true))
                obj.label="-"
            }
        result.push(obj)
    })
    return result
}

export function getCsvDataError (question,csv_data,na_value)  {
    let result={}
    let values =[]
    csv_data.forEach((csv_row,idx) => {
        if (typeof csv_row[question.linked_key]!=="undefined") {
            if (isDateTime(question)) {
                let dt = getInputDate(csv_row[question.linked_key], question.options.date_format, question.options.date_delimiter)
                if (dt ==="###") {
                    values.push({row_idx: idx+1,value: csv_row[question.linked_key]})
                }
            } else if (question.question_choices && (csv_row[question.linked_key] ?? "").toString() !== na_value.text) {
                if (question.question_type === "checkbox") {
                    if (csv_row[question.linked_key]) {
                        let val_array = (csv_row[question.linked_key] ?? "").toString().split(",")
                        val_array.forEach((val) => {
                            let val_el
                            if (question.options.maped_values)
                                val_el = question.options.maped_values.find((f) => f.csv_value === (val ?? "").toString().trim())
                            if (!val_el?.choice?.value || !val_el) {
                                if (val)
                                    values.push({row_idx: idx+1,value: val})
                            }

                        })
                    }
                } else {
                    let el
                    if (question.options.maped_values)
                        el = question.options.maped_values.find((f) => (f.csv_value ?? "").toString().trim() === (csv_row[question.linked_key] ?? "").toString().trim())
                    if (!el?.choice?.value || !el) {
                        if (csv_row[question.linked_key])
                            values.push({row_idx: idx+1,value: csv_row[question.linked_key]})
                    }
                }
            }
        }
    })
    if (values.length>0) {
        result.question=question.label
        result.values= values
    }


    return result
}

