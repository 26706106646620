import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Button, NavButton, NavButtonDesc } from '../../components';
import config from '../../config';
import './dashboard.css'
import { withRouter } from 'react-router';
import { FcHome, FcNext } from 'react-icons/fc';

class Dashboard extends React.Component {
    need_restart() {
        if (this.props.need_restart) {
            return (
                <span className="system_restart">
                    <Button label='Please restart' name="system_restart_button" onClick={this.restartSystem.bind(this)} />
                </span>
            )
        } else {
            return null
        }
    }
    showTimeout() {
        if (!config.auto_relogin && !config.auto_refresh) {
            return (
                <span>TIMEOUT: {this.props.session_timeout}</span>
            )
        } else {
            return null;
        }
    }
    restartSystem() {
        this.props.setSystemRestart();
    }
    showMenu() {
        return (
            <div className="menu-wrap">
                <NavButton className="home-icon" activeClassName={"active_menu"} to="/navigation" icon_comp={<FcHome />} label="Home" permissions={true} />
            </div>
        )
    }
    showTemplate() {
        if (!this.props.template_selector) return null;
        return (
            <div className="menu-wrap">
                <NavButtonDesc activeClassName={"active_menu"} to="/survey_selector" icon_comp={<FcNext />}
                    label={this.props.template_selector.title}
                    description={this.props.template_selector.description} permissions={true} />
            </div>
        )
    }
    showPerson() {
        if (!this.props.person_selector) return null;
        return (
            <div className="menu-wrap">
                <NavButtonDesc activeClassName={"active_menu"} to="/person_selector" icon_comp={<FcNext />}
                    label={this.props.person_selector.name + ' (' + this.props.person_selector.auid + ')'}
                    description={this.props.person_selector.notes} permissions={true} />
            </div>
        )
    }
    render() {
        const exclude_header = ['/navigation','/survey_report_h','/template_report','/virtual_template'];
        //console.log(this.props.location.pathname)
        if (exclude_header.includes(this.props.location.pathname)) return null;

        const connection_class = (!this.props.connection_status) ? "connection_down" : "connection_up";
        return (
            <div className={"dashboard_wrap " + connection_class}>
                <div className={"dashboard"}>
                    {this.showMenu()}
                    {this.showPerson()}
                    {this.showTemplate()}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let session_timeout = false;
    if (state.authReducer.token.token_expires) {
        session_timeout = dayjs(state.authReducer.token.token_expires).format('YYYY-MM-DD, HH:mm:ss')
    }
    //TODO: add user role
    return {
        device_name: state.systemReducer.device_name,
        user: state.authReducer.user,
        preferences: state.authReducer.user.preferences,
        permissions: state.authReducer.user.permissions,
        session_timeout: session_timeout,
        pulse: state.systemReducer.pulse,
        connection_status: state.systemReducer.connection_status,
        isAuth: state.authReducer.isAuth,
        template_selector: state.SurveyReducer.template_selector,
        person_selector: state.SurveyReducer.person_selector
    };
}

function mapDispatchToProps(dispatch) {
    return ({
    })
}

export default Dashboard = connect(
    mapStateToProps, mapDispatchToProps)(withRouter(Dashboard)
    );
