import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import parseUri from "./helpers/parseuri";
import auth_connector from "./api/auth_connector";
import "./static/css/animation.css";
import "./static/css/common.css";
import "./static/css/forms.css";
import "./static/css/subcomponents.css";
import "./static/css/tables.css";
import "./static/css/menutop.css";
import "./static/css/tablet.css";
import "./static/css/mobile.css";
import "react-tooltip/dist/react-tooltip.css";
import "./i18n";

import Modal from "react-modal";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";

window.uri_params = parseUri (window.location.search);

Modal.setAppElement ("#root");

auth_connector.auth ((err, res) => {
    if (err) {
        console.log (err);
    }
    auth_connector.get_doctor_info ((err, res) => {
        if (err) {
            console.log (err);
        }
        ReactDOM.render (<DevSupport ComponentPreviews={ComponentPreviews}
                                     useInitialHook={useInitial}
        >
            <App />
        </DevSupport>, document.getElementById ("root"));
    });
});
