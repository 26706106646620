export default function AuthReducer(state = { isAuth: false, login: {}, token: {}, user: {}, error: {}, refresh: false, alarm_list: null }, action) {
    switch (action.type) {
        case "login_field_change":
            state.login = { ...state.login };
            state.login[action.field] = action.value;
            return { ...state };
        case 'set_preference':
            if (!state.user.preferences) state.user.preferences = {};
            state.user.preferences[action.field] = action.value;
            state.user.preferences = { ...state.user.preferences }
            return { ...state };
        case "res_auth_login":
            state.isAuth = true;
            //state.login = {};
            state.user = action.data.user_data;
            state.token = action.data.token_data;
            return { ...state };
        case 'res_alarm_list_for_user':
            state.alarm_list = action.data.list;
            return { ...state };
        case "TOKEN_REFRESHED":
            state.isAuth = true;
            state.token = action.data.token_data;
            return { ...state };
        case "TOKEN_REFRESH_FAILED":
            state.isAuth = false;
            state.login = {};
            state.token = {};
            state.user = {};
            return { ...state };
        case "ERROR_LOGIN":
            state.isAuth = false;
            state.login = {};
            state.token = {};
            state.user = {};
            state.error['message'] = 'Authentification failed';
            return { ...state };
        case "LOGOUT":
            state.isAuth = false;
            state.login = {};
            state.token = {};
            state.user = {};
            state.error = {};
            return { ...state };
        default:
            break;
    }
    return state;
}

