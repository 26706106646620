import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function TimestampToDateConverter(timestamp) {
    if (!timestamp || isNaN(timestamp)) return "Invalid Timestamp";

    const date = new Date(parseInt(timestamp, 10));

    return dayjs(date).tz("Europe/Berlin").format("DD/MM/YYYY HH:mmA [GMT]Z");
}

export default TimestampToDateConverter;
