import React from 'react';
import NavList from './nav_list';
import "react-image-gallery/styles/css/image-gallery.css";
import { Form } from '../../components';
import './nav.css'

class Navform extends React.Component {
    render() {
        return (
            <div className="navigationmenu">
            <Form className="navigation-list">
                <NavList />
            </Form>
            <div className="system-data">{process.env.REACT_APP_VERSION}</div>
            </div>

        )
    }
}

export default Navform;