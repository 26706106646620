const TrashIcon = (props) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? "400"}
            height={props.height ?? "400"}
            viewBox={props.viewBox ?? "0, 0, 400,400"}
        >
            <g>
                <path
                    id="path0"
                    d="M140.011 14.168 C 128.348 18.650,125.000 26.038,125.000 47.295 L 125.000 62.500 79.102 62.503 C 34.680 62.506,33.121 62.556,30.657 64.058 C 22.949 68.758,22.949 81.242,30.657 85.942 C 32.958 87.345,34.613 87.494,47.852 87.497 L 62.500 87.500 62.500 218.976 C 62.500 334.514,62.645 351.010,63.696 355.046 C 67.457 369.483,80.517 382.543,94.954 386.304 C 101.893 388.111,298.107 388.111,305.046 386.304 C 319.483 382.543,332.543 369.483,336.304 355.046 C 337.355 351.010,337.500 334.514,337.500 218.976 L 337.500 87.500 352.148 87.497 C 365.387 87.494,367.042 87.345,369.343 85.942 C 377.051 81.242,377.051 68.758,369.343 64.058 C 366.879 62.556,365.320 62.506,320.898 62.503 L 275.000 62.500 275.000 47.295 C 275.000 25.881,271.644 18.574,259.767 14.130 C 253.058 11.620,146.554 11.654,140.011 14.168 M250.000 50.000 L 250.000 62.500 200.000 62.500 L 150.000 62.500 150.000 50.000 L 150.000 37.500 200.000 37.500 L 250.000 37.500 250.000 50.000 M312.320 217.383 C 312.081 364.650,312.738 350.790,305.696 357.132 C 299.833 362.413,306.275 362.109,200.000 362.109 C 93.725 362.109,100.167 362.413,94.304 357.132 C 87.262 350.790,87.919 364.650,87.680 217.383 L 87.469 87.500 200.000 87.500 L 312.531 87.500 312.320 217.383 M151.525 139.260 C 145.239 143.497,145.703 136.658,145.703 225.000 C 145.703 302.797,145.718 303.541,147.308 306.247 C 152.163 314.511,164.656 314.846,169.535 306.843 C 171.972 302.846,171.972 147.154,169.535 143.157 C 166.065 137.465,157.075 135.520,151.525 139.260 M234.563 139.058 C 228.467 142.775,228.912 136.005,228.912 225.000 C 228.912 302.751,228.942 304.347,230.465 306.843 C 235.344 314.846,247.837 314.511,252.692 306.247 C 255.219 301.946,255.219 148.054,252.692 143.753 C 249.125 137.680,240.490 135.444,234.563 139.058 "
                    stroke="none"
                    fill="#000000"
                    fillRule="evenodd"
                ></path>
            </g>
        </svg>
    );
};

export default TrashIcon;
