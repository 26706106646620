import { memo, useRef } from "react";
import "./custom-scrollbar.css";
import { FiTrash } from "react-icons/fi";
import { Tooltip as TooltipBootstrap } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { showCalcColumn } from "../../helpers/Template_ext";
import { useEffect } from "react";
import { LoaderHideInfitite } from "../../helpers/loader";

const TableH2 = memo(function TableH2({
    showQuestions,
    locale,
    data,
    filter,
    selected,
    setSelected,
    setFilter,
    sorter,
    setShowDebugObject,
    manipulateSorter,
    setShowInstanceSavePDFPopup,
    setShowInstanceDeletePopup,
    setShowDoctorInfo,
    showOrigValues,
    template,
    chartColumn,
    setChartColumn,
    calcColumn,
    hideOrangeBar,
}) {
    useEffect(() => {
        LoaderHideInfitite();
    });
    
    // custom scrollbar start
    const topScrollRef = useRef(null);
    const contentScrollRef = useRef(null);
   
    useEffect(() => {
        const interval = setInterval(() => {
            if (contentScrollRef.current) {
                topScrollRef.current.style.width = contentScrollRef.current.scrollWidth + 'px';
                console.log(contentScrollRef.current.scrollWidth)
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      const handleTopScroll = () => {
        if (contentScrollRef.current && topScrollRef.current) {
          contentScrollRef.current.scrollLeft = topScrollRef.current.parentElement.scrollLeft;
        }
      };
  
      const handleContentScroll = () => {
        if (contentScrollRef.current && topScrollRef.current) {
          topScrollRef.current.parentElement.scrollLeft = contentScrollRef.current.scrollLeft;
        }
      };
  
      const topScrollEl = topScrollRef.current;
      const contentScrollEl = contentScrollRef.current;
  
      if (topScrollEl && contentScrollEl) {
        topScrollEl.parentElement.addEventListener('scroll', handleTopScroll);
        contentScrollEl.addEventListener('scroll', handleContentScroll);
      }
  
      return () => {
        if (topScrollEl && contentScrollEl) {
          topScrollEl.parentElement.removeEventListener('scroll', handleTopScroll);
          contentScrollEl.removeEventListener('scroll', handleContentScroll);
        }
      };
    }, []);

     // custom scrollbar end

    let frozen_columns = [0, 1, 2, 3, 4, 5, 6];
    const createHeader = () => {
        const table_header = {};
        showQuestions.forEach((question) => {
            if (question.type === calcColumn?.selected?.type) {
                if (showCalcColumn(calcColumn))
                    table_header[question.name] = question;
            } else {
                table_header[question.name] = question;
            }
        });
        return table_header;
    };

    const table_header = createHeader();
    const canDelete = (permission) => {
        return permission === 100;
    };

    const renderHead = () => {
        const result_label = [];
        const result_numeration = [];
        const result_charts = [];
        let class_frozen = "";

        if (frozen_columns.includes(0)) class_frozen = " frozen fr0";
        //selection column
        result_label.push(
            <th className={"select" + class_frozen} key={"select"}></th>
        );

        if (selected.length) {
            result_numeration.push(
                <th
                    className={"select has-selection" + class_frozen}
                    key={"select"}
                    onClick={() => {
                        const new_filter = { ...filter };
                        new_filter.selected = [];
                        setFilter(new_filter);
                        setSelected([]);
                    }}
                >
                    Clear
                </th>
            );
        } else {
            result_numeration.push(
                <th
                    className={"select" + class_frozen}
                    key={"select"}
                    style={{
                        opacity: hideOrangeBar ? 0.5 : 1,
                    }}
                >
                    {!hideOrangeBar ? "Sorting" : "Clear"}
                </th>
            );
        }
        result_charts.push(
            <th key={"select"} className={"select" + class_frozen}>
                Chart
            </th>
        );

        const getAnswerValue = (
            headerQuestion,
            instanceQuestion,
            questionId
        ) => {
            const choiceOption = headerQuestion?.choices?.find((f) => {
                return (
                    f.value ===
                    (instanceQuestion[questionId]?.value ??
                        instanceQuestion[questionId])
                );
            });

            const finalValue =
                choiceOption?.calcValue ??
                choiceOption?.code ??
                instanceQuestion[questionId]?.value ??
                instanceQuestion[questionId];

            return finalValue;
        };

        // This code section is for table header of survey data i.e. sorting
        Object.keys(table_header).forEach((key) => {
            const header = table_header[key];
            const header_label = header.label;
            const isSystemColumn = !header.number;
            const isFrozen = frozen_columns.includes(result_label.length)
            const header_numeration = isSystemColumn ? isFrozen ? "" : "RC" : header.number;
            class_frozen = "";
            if (
                frozen_columns.includes(result_label.length) &&
                !hideOrangeBar
            ) {
                class_frozen = " frozen fr" + result_label.length;
            } else if (
                frozen_columns.includes(result_label.length) &&
                hideOrangeBar &&
                result_label.length > 1
            ) {
                class_frozen = " frozen fr" + result_label.length + " hidden";
            }

            const th_class_name = key + " onerowwrap tworowwrap" + class_frozen;
            result_label.push(
                <th
                    className={th_class_name}
                    key={key}
                    style={{
                        display:
                            isSystemColumn &&
                            hideOrangeBar &&
                            header_label !== "ID" &&
                            "none",
                    }}
                >
                    <TooltipBootstrap key={key} id={"key"} title={header_label}>
                        {header_label}
                    </TooltipBootstrap>
                </th>
            );
            const templateQuestion = showQuestions?.find((f) => {
                return f.name === key;
            });

            const nonNumeric = data?.find((f) => {
                const realValue = getAnswerValue(
                    templateQuestion,
                    f,
                    header.name
                );

                return (
                    !header.number ||
                    (realValue !== "N/A" && !isFinite(realValue) && realValue)
                );
            });

            if (!isSystemColumn) {
                result_charts.push(
                    <th key={key} className={"chart-check" + class_frozen}>
                        {nonNumeric === undefined && (
                            <div className="checkbox_v2 accent-orange">
                                <input
                                    type="checkbox"
                                    id={`table_numeric_checkbox ${key}`}
                                    checked={header.name === chartColumn}
                                    onChange={() => {
                                        setChartColumn(
                                            header.name === chartColumn
                                                ? null
                                                : header.name
                                        );
                                    }}
                                />
                                <label
                                    for={`table_numeric_checkbox ${key}`}
                                ></label>
                            </div>
                        )}
                    </th>
                );
            } else {
                let lbl = result_charts.length === 1 ? "visualization" : "";
                result_charts.push(
                    <th
                        key={"select" + key}
                        className={"select" + class_frozen}
                    >
                        {lbl}
                    </th>
                );
            }

            if (key in sorter) {
                const index_of_sorter = Object.keys(sorter).indexOf(key);
                const sort_class =
                    " order " + sorter[key].order + index_of_sorter;
                result_numeration.push(
                    <th
                        key={key}
                        className={
                            (isSystemColumn ? "system" : "") + class_frozen
                        }
                    >
                        <div
                            className={key + sort_class}
                            onClick={() => {
                                manipulateSorter(key);
                            }}
                        >
                        {/* Chapter table sort */}
                        {header_numeration ? header_numeration : ""}
                        </div>
                    </th>
                );
            } else {
                result_numeration.push(
                    <th
                        key={key}
                        className={
                            (isSystemColumn ? "system" : "") + class_frozen + "RC"
                        }
                    >
                        <div
                            className={key}
                            onClick={() => {
                                if (hideOrangeBar && !isSystemColumn)
                                  {  manipulateSorter(key);
                                    // console.log("hideOrangeBar key",key);
                                    }
                                else if (!hideOrangeBar){ manipulateSorter(key); 
                                // console.log("not hideOrangeBar key",key);
                                };
                            }}
                        >
                           {/* {console.log("sorting",header_numeration)} */}
                           {header_numeration ? header_numeration : ""}
                        </div>
                    </th>
                );
            }
        });

        if (!result_label.length) return null;
        return (
            <thead>
                <tr className="labels">{result_label}</tr>
                <tr className="numeration">{result_numeration}</tr>
                <tr className="charts">{result_charts}</tr>
            </thead>
        );
    };

    const renderRow = () => {
        const selectInstance = (event) => {
            const id = event.target.value;
            const new_selected = [...selected];
            const exist = new_selected.indexOf(id);
            if (event.target.checked) {
                if (exist < 0) new_selected.push(id);
            } else {
                if (exist > -1) new_selected.splice(exist, 1);
            }
            setSelected(new_selected);
        };
        const renderCell = (row, index) => {
            const result = [];
            let class_frozen = "";
            if (frozen_columns.includes(0)) class_frozen = " frozen fr0";
            result.push(
                <td
                    className={"select" + class_frozen}
                    key={`${row.Instance_id}-${index}`}
                >
                    <div className="controls">
                        <div className="checkbox_v2 rowselect">
                            <input
                                type="checkbox"
                                id={`${row.Instance_id}-${index}`}
                                value={row.instance_id}
                                checked={selected.includes(row.instance_id)}
                                onChange={(event) => {
                                    selectInstance(event);
                                }}
                            />
                            <label
                                htmlFor={`${row.Instance_id}-${index}`}
                            ></label>
                        </div>
                        <button
                            className="btn-pdf-export"
                            onClick={() => {
                                setShowInstanceSavePDFPopup(row.instance_id);
                            }}
                        >
                            PDF
                        </button>

                        {canDelete(row.system_field.permission) && (
                            <button
                                className="btn-delete-instance"
                                onClick={() => {
                                    setShowInstanceDeletePopup(row.instance_id);
                                }}
                            >
                                <FiTrash size={15} />
                            </button>
                        )}
                    </div>
                </td>
            );
            let column_nr = 0;
            Object.keys(table_header).forEach((key) => {
                let td_class = key;
                column_nr++;
                class_frozen = "";

                if (
                    frozen_columns.includes(column_nr) &&
                    hideOrangeBar &&
                    column_nr > 1
                ) {
                    class_frozen = " frozen fr" + column_nr + " hidden";
                } else if (
                    frozen_columns.includes(column_nr) &&
                    !hideOrangeBar
                ) {
                    class_frozen = " frozen fr" + column_nr;
                }
                let td = (
                    <td
                        className={td_class + class_frozen}
                        key={key + index}
                    ></td>
                );
                if (typeof row[key] !== "object") {
                    td = (
                        <td
                            className={td_class + class_frozen}
                            key={key + index}
                        >
                            {row[key] || "-"}
                        </td>
                    );
                } else {
                    td = (
                        <td
                            className={
                                td_class +
                                " tooltip merging instance_id " +
                                class_frozen
                            }
                            key={key + index}
                            onClick={() => {
                                setShowDebugObject(row);
                            }}
                        >
                            <span className="value-error">
                                {"Wrong value type (object)"}
                            </span>
                        </td>
                    );
                }
                if (row[key + "_orig"] && showOrigValues) {
                    let original_text = "";
                    if (typeof row[key + "_orig"] === "object") {
                        if (row[key + "_orig"][locale])
                            original_text =
                                "(" + row[key + "_orig"][locale] + ")";
                        else
                            original_text =
                                "(" + row[key + "_orig"]["default"] + ")";
                    } else {
                        original_text = "(" + row[key + "_orig"] + ")";
                    }

                    td = (
                        <td
                            className={td_class + class_frozen}
                            key={key + index + "_orig"}
                        >
                            {row[key]}
                            <br />
                            <span className="value-orig">{original_text}</span>
                        </td>
                    );
                }

                if (
                    td_class === "instance_id" &&
                    window.uri_params.debug !== "false"
                ) {
                    const split = row[key].split("-");
                    const isChild = split[0] !== template.id;
                    let title =
                        template.mapping?.find((f) => f.id === split[0])
                            ?.title ??
                        template.masterTitle ??
                        template.title;
                    if (typeof title === "object") {
                        if (template.locale)
                            title = title?.[template.locale] || title?.default;
                        else title = title?.default;
                    }

                    const colText = (
                        <>
                            <span className="title">
                                {title?.length > 13
                                    ? title?.substring(0, 10) + "... "
                                    : title ?? ""}
                            </span>
                            <br />
                            <span className="id">
                                {split[0] + " " + split[1]}
                            </span>
                        </>
                    );

                    td = (
                        <td
                            key={key}
                            data-tooltip-content={
                                split[0] + (isChild ? "C" : "") + ": " + title
                            }
                            className={
                                td_class +
                                " tooltip merging" +
                                (isChild ? " child" : "") +
                                class_frozen
                            }
                            onClick={() => {
                                setShowDebugObject(row);
                            }}
                        >
                            {colText}
                        </td>
                    );
                }

                if (
                    td_class === "doctor_id" &&
                    window.uri_params.doctor_info !== "false"
                ) {
                    td = (
                        <td
                            key={key}
                            className={td_class + " doctor_info" + class_frozen}
                            onClick={() => {
                                setShowDoctorInfo(row.doctor_id);
                            }}
                        >
                            {row[key]}
                        </td>
                    );
                }
                if (
                    row?.system_field?.link_filters &&
                    row?.system_field?.link_filters.includes(key)
                ) {
                    td_class += " green";
                    const patient_filter =
                        row[key] === filter.patient ? null : row[key];
                    td = (
                        <td
                            key={key}
                            className={td_class + class_frozen}
                            onClick={() => {
                                setFilter({
                                    ...filter,
                                    patient: patient_filter,
                                });
                            }}
                        >
                            {row[key]}
                        </td>
                    );
                }
                result.push(td);
            });
            return result;
        };
        const result = [];
        data.forEach((row, index) => {
            result.push(
                <tr key={row.instance_id}>{renderCell(row, index)}</tr>
            );
        });
        console.timeEnd("renderRows");
        return <tbody>{result}</tbody>;
    };

    return (
        <div className="table-horizontal">
            <div className="wmd-view-topscroll">
                <div className="scroll-div1" ref={topScrollRef}></div>
            </div>
            <div className="wmd-view" ref={contentScrollRef}>
                {/* <div className="scroll-div2"> */}
                    <table>
                        {renderHead()}
                        {renderRow()}
                    </table>
                {/* </div> */}
            </div>
            <ReactTooltip className="reacttooltip" anchorSelect=".tooltip" />
        </div>
    );
});

export default TableH2;
