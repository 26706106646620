import React from "react";

import {
    Button,
    InputFilter,
    DatePickerField,
    SelectField,
} from "../../components";
import { withTranslation } from "react-i18next";
import store from "../../store";

class TableControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date_period: null,
        };
    }
    handleSearch(result) {
        const filter = { ...this.props.search };
        filter[result.name] = result.value;
        this.props.setFilter(filter);
    }
    handleExport(params) {
        switch (params.value) {
            case "data_csv":
                this.props.exportToCSV();
                break;
            case "template_pdf":
                this.props.setShowSavePDFPopup(true);
                break;
            default:
                break;
        }
    }
    handleSearchDates(params) {
        const filter = { ...this.props.search };
        const date = new Date();
        let date_from = date;
        let date_till = date;
        this.setState({ date_period: params.value });
        switch (params.value) {
            case "prev_month":
                date_from = new Date(
                    date.getFullYear(),
                    date.getMonth() - 1,
                    1
                );
                date_till = new Date(date.getFullYear(), date.getMonth(), 0);
                break;
            case "current_month":
                date_from = new Date(date.getFullYear(), date.getMonth(), 1);
                date_till = new Date(
                    date.getFullYear(),
                    date.getMonth() + 1,
                    0
                );
                break;
            case "prev_week":
                date_from = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate() - date.getDay() - 6
                );
                date_till = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate() - date.getDay()
                );
                break;
            case "current_week":
                date_from = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate() - date.getDay() + 1
                );
                date_till = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate() - date.getDay() + 7
                );
                break;
            case "today":
                date_from = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate()
                );
                date_till = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate()
                );
                break;
            default:
                date_from = null;
                date_till = null;
                break;
        }
        filter["date_from"] = date_from;
        filter["date_till"] = date_till;
        this.props.setFilter(filter);
    }
    clearFilters() {
        this.props.setFilter({});
    }
    clearSort() {
        //const [sorter, setSorter] = useState({ lc: { order: 'desc' } });
        //this.props.setSorter({ lc: { order: "desc" } });
        console.log("clear sort called");
        this.props.setSorter({});
    }
    onMerging() {
        this.props.onMerging();
    }
    showChart() {
        this.props.setShowChartPopUp(true);
    }
    render() {
        const {
            selected,
            filter,
            setFilter,
            virtualSelector,
            // setShowVirtual,
            showVirtual,
            showValues,
            setShowValues,
            showOrigValues,
            setShowOrigValues,
            anonymousMode,
            setAnonymousMode,
            onlySCF,
            setOnlySCF,
            setHideOrangeBar,
        } = this.props;
        const clear_selected_status = selected.length ? "enabled" : "disabled";
        const filter_selected_status =
            Array.isArray(filter.selected) && filter.selected.length
                ? "Unfilter selected"
                : "Filter selected";
        return (
            <div className="table-control-wrap">
                <div className="table_search">
                    <div className="search-row">
                        <div className="input_fields">
                            <InputFilter
                                placeholder="Search Patient"
                                disabled={false}
                                name="patient"
                                label=""
                                onChange={this.handleSearch.bind(this)}
                                value={this.props.search.patient}
                                className="field_wrap_v2 searchPatient"
                            />
                            <InputFilter
                                placeholder="Free Search"
                                name="free_search"
                                label=""
                                onChange={this.handleSearch.bind(this)}
                                value={this.props.search.free_search}
                                className="field_wrap_v2 freeSearch"
                            />
                            <DatePickerField
                                placeholder="Date From"
                                name="date_from"
                                className="date_field_v2"
                                dateFormat="dd.MM.yyyy"
                                value={this.props.search.date_from}
                                onChange={this.handleSearch.bind(this)}
                                isV2
                            />
                            <DatePickerField
                                placeholder="Date Till"
                                name="date_till"
                                className="date_field_v2"
                                dateFormat="dd.MM.yyyy"
                                value={this.props.search.date_till}
                                onChange={this.handleSearch.bind(this)}
                                isV2
                            />
                            <Button
                                label="Clear filters"
                                onClick={this.clearFilters.bind(this)}
                                customClass="filter_button_v2"
                            />
                        </div>

                        {/*right side*/}

                        <div className="select_fields">
                            <SelectField
                                name="date_periods"
                                placeholder="Export CSV/PDF"
                                allowEmpty={true}
                                options={[
                                    {
                                        label: "Export data to CSV",
                                        value: "data_csv",
                                    },
                                    {
                                        label: "Export template to PDF",
                                        value: "template_pdf",
                                    },
                                ]}
                                onChange={(params) => {
                                    this.handleExport(params);
                                }}
                                className="custom_selectfield_v2 "
                                isV2
                            />
                            <SelectField
                                name="virtual_selector"
                                placeholder="Not merged"
                                allowEmpty={true}
                                value={showVirtual}
                                options={virtualSelector}
                                onChange={(params) => {
                                    // setShowVirtual(params.value);
                                    store.dispatch({
                                        type: "set_show_virtual",
                                        value: params.value,
                                    });
                                }}
                                className="custom_selectfield_v2"
                                isV2
                            />
                            <Button
                                className="like-link"
                                label="Dataset Merging Setup"
                                onClick={() => {
                                    //history.push("/virtual_template");
                                    this.onMerging();
                                }}
                                customClass="filter_button_v2 data_merging_v2"
                            />
                        </div>
                        {/* row break */}
                    </div>
                    <div className="filter-row_v2">
                        <div className="show_hide_orangebar">
                            <p className="label">Hide orange columns :</p>
                            <div className="toggle">
                                OFF{" "}
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            setHideOrangeBar(e.target.checked);
                                            if (e.target.checked)
                                                this.clearSort();
                                        }}
                                    ></input>
                                    <span class="slider round"></span>
                                </label>{" "}
                                ON
                            </div>
                        </div>
                        <p className="filters-text">Filters: </p>
                        <div
                            className="checkbox_v2"
                            style={{ alignSelf: "center" }}
                        >
                            <input
                                type="checkbox"
                                name="onlyscf"
                                id="onlyscf"
                                value={onlySCF}
                                onChange={() => {
                                    setOnlySCF(!onlySCF);
                                }}
                            />
                            <label htmlFor="onlyscf">Only SCF</label>
                        </div>
                        <div
                            className="checkbox_v2"
                            style={{ alignSelf: "center" }}
                        >
                            <input
                                type="checkbox"
                                id="anonymousmode"
                                name="anonymousmode"
                                value={anonymousMode}
                                onChange={() => {
                                    setAnonymousMode(!anonymousMode);
                                }}
                            />
                            <label htmlFor="anonymousmode">Anonymous</label>
                        </div>

                        <div
                            className="checkbox_v2"
                            style={{ alignSelf: "center" }}
                        >
                            <input
                                type="checkbox"
                                name="OrigValShow"
                                id="OrigValShow"
                                value={showOrigValues}
                                onChange={() => {
                                    setShowOrigValues(!showOrigValues);
                                }}
                            />
                            <label htmlFor="OrigValShow">
                                Show Original Values
                            </label>
                        </div>
                        {/* <div className="layout-wrap"> */}
                        {/* <div className="filter-fields-wrap filter-row layout"> */}
                        <div
                            className="checkbox_v2"
                            style={{ alignSelf: "center" }}
                        >
                            <input
                                type="checkbox"
                                name="layout"
                                id="layout"
                                value={showValues}
                                onChange={() => {
                                    setShowValues(!showValues);
                                }}
                            />
                            <label htmlFor="layout">Answers / Values</label>
                            {/* </div> */}
                            {/* <div className="filter-row layout"></div> */}
                        </div>
                        <Button
                            label="Clear sorts"
                            onClick={this.clearSort.bind(this)}
                            customClass="filter_button_v2"
                        />

                        <div className="filter-second-part">
                            {" "}
                            {/* second part */}
                            <SelectField
                                name="date_periods"
                                placeholder="Select period"
                                allowEmpty={true}
                                value={this.state.date_period}
                                options={[
                                    {
                                        label: "Prev month",
                                        value: "prev_month",
                                    },
                                    {
                                        label: "Current month",
                                        value: "current_month",
                                    },
                                    { label: "Prev week", value: "prev_week" },
                                    {
                                        label: "Current week",
                                        value: "current_week",
                                    },
                                    { label: "Today", value: "today" },
                                ]}
                                onChange={(params) => {
                                    this.handleSearchDates(params);
                                }}
                                className="custom_selectfield_v2"
                                isV2
                            />
                            <Button
                                status={clear_selected_status}
                                label={filter_selected_status}
                                className="filter-fields"
                                onClick={() => {
                                    const new_filter = { ...filter };
                                    new_filter.selected = new_filter.selected
                                        ?.length
                                        ? []
                                        : selected;
                                    //new_filter.selected = selected;
                                    setFilter(new_filter);
                                }}
                                customClass={
                                    selected.length
                                        ? "custom_button_v2 active"
                                        : "custom_button_v2"
                                }
                            />
                            <Button
                                label="Chart visualization"
                                className="chart"
                                status={
                                    this.props.chartColumn ? null : "disabled"
                                }
                                onClick={this.showChart.bind(this)}
                                customClass={
                                    !this.props.chartColumn
                                        ? "custom_button_v2"
                                        : "custom_button_v2 active"
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TableControl);
