import { Button, DivT } from '../../components';
import DownloadPDF from './../../helpers/survey_pdf/template_report_pdf';

export default function SaveTemplatePDF({ template, setShowSavePDFPopup }) {
    return (
        <div className="popup save-pdf">
            <div className="popup-content-wrap">
                <div className="popup-content">
                    <DivT className="title" label="Export template to PDF" />
                    <div className='controls'>
                        <div className="button button-inline" onClick={() => { setShowSavePDFPopup(false) }}>
                            <DownloadPDF template={template} />
                        </div>
                        <Button label="Cancel" onClick={() => { setShowSavePDFPopup(false) }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

