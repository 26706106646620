import { decode } from "../../../helpers/base64decode";
import { NotificationTemplates as NT } from '../../../components';

export const extract_template = (
    template_record,
    uniq = false,
    filtered_questions = null,
    instances=[]
) => {

    let template = {};
    if (template_record?.arr_fragen) {
        const json_template = decode(template_record?.arr_fragen);
        template = JSON.parse(json_template);
    }
    template.editor = atob(template_record.ersteller64); //pcom left atob
    template.created = template_record.erstellt;
    template.id = template_record.fid;
    //description from record if template description is missing
    template.description = template.description || atob(template_record.fbinfo); //pcom left atob
    let instances_fields=Object.keys(instances);
    if (instances_fields.length>0){
        if (instances[instances_fields[0]].mkid!=="0") {
            template.encrypted = true
        }
    }

    if (uniq)
        Object.keys(template.pages).forEach((page_index) => {
            const page = template.pages[page_index];
            if (filtered_questions) {
                page.elements = page.elements.filter((question) => {
                    return filtered_questions.includes(question.name);
                });
            }
            if (Array.isArray(page.elements)) {
                page.elements.forEach((question) => {
                    question.name = template.id + "-" + question.name;
                });
            }
        });

    return template;
};

export const extract_answers = (firstQuestionaireData, isUtf8) => {
    const answers_array = [];
    //getting data
    firstQuestionaireData.forEach((_, index) => {
        //system_field contains necessary data for controlling cell
        let answers_encoded=firstQuestionaireData[index].arr_antw_aid
                                        ? firstQuestionaireData[index].arr_antw_aid
                                        : firstQuestionaireData[index].arr_antw_aid_b64
        let answers_json=decode(answers_encoded, isUtf8)

        let answers_parsed;
        try {
            answers_parsed= JSON.parse(answers_json)
        } catch (e) {
            console.error ('extact_answer exception',e.message);
            NT.error(e.message,'Extract answers')
            return answers_array;
        }

        let answers=answers_parsed?.arr_antworten;

        answers.instance_id = firstQuestionaireData[index]?.id;
        answers.doctor_id = firstQuestionaireData[index]?.dest_entid;
        if (
            firstQuestionaireData[index]?.instance_answering_person_is_pseudonym
        ) {
            const hash =
                firstQuestionaireData[index]
                    ?.instance_answering_person_pseudonym ?? "";

            answers.instance_answering_person_name =
                hash.slice(0, 4) + "..." + hash.slice(-4);
            answers.system_field = {
                encrypted: true,
                permission: firstQuestionaireData[index]?.permission,
                link_filters: ["instance_answering_person_name"],
            };
        } else {
            answers.instance_answering_person_name =
                firstQuestionaireData[index]?.instance_answering_person_name;
            answers.system_field = {
                encrypted: false,
                permission: firstQuestionaireData[index]?.permission,
                link_filters: ["instance_answering_person_name"],
            };
        }

        answers.instance_answering_person_bd =
            firstQuestionaireData[index]?.instance_answering_person_bd;
        answers.relevant_date =
            firstQuestionaireData[index]?.relevant_date.split(" ")[0];
        answers.lc = firstQuestionaireData[index]?.lc;
        answers_array.push(answers);
    });

    return answers_array;
};


