export default function isArraysEquals (arr1,arr2,fields) {
    let isEquals=false
    let diff=false
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        diff=true
    } else {
        if (arr1.length!==arr2.length) {
            diff=true
        } else {
            if (!fields) {
                arr1.forEach((el1) => {
                    if (!diff) {
                        let found_el=arr2.find((el2) => { return el1===el2})
                        if (!found_el) {
                            diff=true
                        }
                    }
                })
            } else {
                let field_sarray=fields.split(",");
                arr1.forEach((el1) => {
                    if (!diff) {
                        let found_el=arr2.find((el2) => {
                            let  result=true
                            field_sarray.forEach((fld) => {
                                if (result){
                                    if (el1[fld]!==el2[fld]){
                                        result=false
                                    }
                                }
                            })
                            return result
                        })
                        if (!found_el) {
                            diff=true
                        }
                    }
                })
            }
        }
    }



    isEquals=(diff===false)
    return isEquals
}