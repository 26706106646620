import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { Layers } from "@mui/icons-material";

const ToolbarLayer = (props) => {
    return (
        <ListItem disablePadding>
            <ListItemButton
                disableRipple
                selected={props.isActive}
                sx={{ padding: "5px" }}
                onClick={() => props.onSelectLayer(props.index)}
            >
                <ListItemIcon sx={{ minWidth: 30 }}>
                    <Layers sx={{ color: props.color }} />
                </ListItemIcon>
                <ListItemText
                    sx={{
                        maxWidth: "100px",
                        whiteSpace: "wrap",
                    }}
                    primary={props.label}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default ToolbarLayer;
