import React from 'react'

import Button from '../controls/button'
import InputField from '../controls/inputfield'
import InputFilter from '../controls/inputfilter'
import { withTranslation } from 'react-i18next';

class TableControl extends React.Component{
    handleSearch(result) {
        this.props.handleSearch(result);
    }
    handleRPP(result) {
        this.props.handleNavigation(result);
        this.props.handleNavigation({name:'page',value:1});
    }
    handlePP() {
        if (this.props.navigation.page > 1) this.props.handleNavigation({name:'page',value: this.props.navigation.page-1});
    }
    handleNP() {
        if (this.props.navigation.page < this.props.navigation.max_pages) this.props.handleNavigation({name:'page',value: this.props.navigation.page+1});
    }
    handleFP() {
        this.props.handleNavigation({name:'page',value:1});
    }
    handleLP() {
        this.props.handleNavigation({name:'page',value:this.props.navigation.max_pages});
    }
    showSearch() {
        const { t } = this.props;
        return (
            <div className="table_search">
                <InputFilter placeholder={t("search")} name="search" label="" onChange={this.handleSearch.bind(this)} value={this.props.search}/>
            </div>
        )
    }
    showNavigation() {
        if (this.props.navigation) {
            let disabled_back = (this.props.navigation.page === 1) ? 'disabled' : 'enabled';
            let disabled_next = (this.props.navigation.page === this.props.navigation.max_pages) ? 'disabled' : 'enabled';
            let records_info = 'Total records: ' + this.props.navigation.total_rows + ' Page: ' + this.props.navigation.page + ' from ' + this.props.navigation.max_pages;
            return (
                <div className="table_navigation">
                    <div className="info">
                        <div className='info_labels'>
                            <p className="records_info">Show records on page</p>
                            <p className="records_nav">{records_info}</p>
                        </div>
                        <InputField name="rpp" label="" onChange={this.handleRPP.bind(this)} value={this.props.navigation.rpp} input_control={
                            {numericOnly: true,blocks: [2]}}/>
                    </div>
                    <div className="button_block">
                        <Button name="first_page" onClick={this.handleFP.bind(this)} status={disabled_back} label="First"/>
                        <Button name="prev_page" onClick={this.handlePP.bind(this)} status={disabled_back} label="Previous"/>
                        <Button name="next_page" onClick={this.handleNP.bind(this)} status={disabled_next} label="Next"/>
                        <Button name="last_page" onClick={this.handleLP.bind(this)} status={disabled_next} label="Last"/>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
    render() {

        return (
            <div className="table_control">
                {this.showSearch()}
                {this.showNavigation()}
            </div>
        )

    }
}

export default  withTranslation()(TableControl);