import React, { useEffect } from "react";
import { Form } from "../../components";
import { useSelector } from "react-redux";
import data_source from "./template_connect";
import "./template_report.css";

import DownloadPDF from "../../helpers/survey_pdf/template_report_pdf";

import * as qHelper from "./../../helpers/survey";

export default function TemplateReport(props) {
    const template = useSelector((state) => state.SurveyReducer?.template);
    const survey = {};
    //const survey = {};
    useEffect(() => {
        data_source.load_template();
    }, []);

    if (!template) return null;
    var pagesCount = Object.keys(template.pages).length;
    const locale = template.locale;

    const surveyHeader = () => {
        return (
            <div className="survey-header">
                <div className="export-pdf">
                    <DownloadPDF template={template} />
                </div>

                <div className="survey-title">
                    {qHelper.gettitle(template, locale)}
                </div>
                <div className="survey-description">
                    <div className="description">
                        {qHelper.getdescription(template, locale)}
                    </div>
                    <div className="creator">{template.editor}</div>
                    <div className="created">{template.created}</div>
                </div>
            </div>
        );
    };

    const surveyFooter = () => {
        return (
            <div className="survey-footer">
                <div className="pages-count">
                    {"Pages count: " + pagesCount}
                </div>
            </div>
        );
    };

    const renderQuestion = ({ question, page_number, qnum }) => {
        const questionNumber =
            (question.questionNumberingOverride ?? page_number + "." + qnum) +
            ". ";

        const renderDate = () => {
            const result = qHelper.inputdate(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">Date</div>
                </div>
            );
        };
        const renderCustom = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">
                        Numeric {result.params}
                    </div>
                </div>
            );
        };
        const renderRadiogroup = () => {
            const result = qHelper.radiogroup(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">
                        Single: {result.all_choices}
                    </div>
                </div>
            );
        };
        const renderCheckbox = () => {
            const result = qHelper.checkbox(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">
                        Multi: {result.all_choices}
                    </div>
                </div>
            );
        };
        const renderDropdown = () => {
            const result = qHelper.checkbox(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">
                        Single: {result.all_choices}
                    </div>
                </div>
            );
        };
        const renderLeadMatrix = () => {
            const result_lead = qHelper.leadmatrix.matrix(
                question,
                survey,
                locale
            );
            const renderHeader = () => {
                return (
                    <div className="question">
                        <div className="question-title">
                            {questionNumber + result_lead.header}
                        </div>
                    </div>
                );
            };
            const renderSide = (side) => {
                const matrix = [];
                const renderSideHeader = (header) => {
                    matrix.push(
                        <div className="question subquestion matrix3">
                            <div className="question-title">{side + ": "}</div>
                            <div className="question-answer">
                                <div className="question-column1">
                                    {header[0]}
                                </div>
                                <div className="question-column2">
                                    {header[1]}
                                </div>
                                <div className="question-column3">
                                    {header[2]}
                                </div>
                            </div>
                        </div>
                    );
                };
                const renderSideAnswers = (row_name, columns) => {
                    matrix.push(
                        <div className="question subquestion2 matrix3">
                            <div className="question-title">
                                {row_name + ": "}
                            </div>
                            <div className="question-answer">
                                <div className="question-column1">
                                    {columns[0]}
                                </div>
                                <div className="question-column2">
                                    {columns[1]}
                                </div>
                                <div className="question-column3">
                                    {columns[2]}
                                </div>
                            </div>
                        </div>
                    );
                };
                Object.keys(result_lead[side]).forEach((row_name) => {
                    if (row_name === "header")
                        renderSideHeader(result_lead[side][row_name]);
                    else
                        renderSideAnswers(
                            row_name,
                            result_lead[side][row_name]
                        );
                });
                return matrix;
            };
            return (
                <>
                    {renderHeader()}
                    {renderSide(Object.keys(result_lead)[1])}
                    {renderSide(Object.keys(result_lead)[2])}
                </>
            );
        };
        const renderLeadProbes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">Need to implement</div>
                </div>
            );
        };
        const renderOtherTypes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <div className="question">
                    <div className="question-title">
                        {questionNumber + result.title}
                    </div>
                    <div className="question-answer">Text</div>
                </div>
            );
        };
        // "type": "leadmatrix8v2",
        // "type": "leadprobes8v2",
        switch (question.type) {
            case "inputdate":
                return renderDate();
            case "inputcustom":
                return renderCustom();
            case "inputinteger":
                return renderCustom();
            case "radiogroup":
                return renderRadiogroup();
            case "dropdown":
                return renderDropdown();
            case "checkbox":
                return renderCheckbox();
            case "leadmatrix8combined":
                return renderLeadMatrix();
            case "leadmatrix8v2":
                return renderLeadMatrix();
            case "leadprobes8v2":
                return renderLeadProbes();
            default:
                return renderOtherTypes();
        }
    };

    const renderQuestions = ({ questions, page_number }) => {
        const questions_render = [];
        let qnum = 1;
        if (!questions) return questions_render;
        Object.keys(questions).forEach((question_index) => {
            const question = questions[question_index];
            questions_render.push(
                renderQuestion({ question, page_number, qnum })
            );
            qnum++;
        });
        return questions_render;
    };

    const surveyPages = () => {
        const pages = [];
        Object.keys(template.pages).forEach((page_index) => {
            const page = template.pages[page_index];
            const page_number = parseInt(page_index) + 1;
            const title = qHelper.gettitle(page, locale);
            pages.push(
                <div className="survey-page" key={page_index}>
                    <div className="page-header">
                        <div className="page-number">
                            {"Page: " + page_number}
                        </div>
                        <div className="page-title">{title}</div>
                    </div>
                    <div className="page-body">
                        {renderQuestions({
                            questions: page.elements,
                            page_number: page_number,
                        })}
                    </div>
                </div>
            );
        });
        return pages;
    };

    return (
        <Form title="Template view" name="survey_report">
            <div className="template-body">
                {surveyHeader()}
                {surveyPages()}
                {surveyFooter()}
            </div>
        </Form>
    );
}
