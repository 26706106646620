import React, { useState } from "react";
import { DivT, SelectField } from "../index";
import "./paginator.css";
import prev_icon from "../../static/png16/Prev_16x16.png";
import next_icon from "../../static/png16/Next_16x16.png";
import first_icon from "../../static/png16/First_16x16.png";
import last_icon from "../../static/png16/Last_16x16.png";

const RowsPaginator = (props) => {
    const { isV2 = false } = props;
    const [page, setPage] = useState(props.page);
    const [itemsOnPage, setItemsOnPage] = useState(props.itemsOnpage);

    const pagesCount = () => {
        let res = 1;
        if (props.itemsOnpage > 0) {
            res = Math.ceil(props.count / props.itemsOnpage);
        }
        return res;
    };

    const onClickNext = () => {
        if (page <= pagesCount() - 1) {
            let new_page = page + 1;
            setPage(new_page);
            if (props.onChange) props.onChange(new_page, itemsOnPage);
        }
    };
    const onClickPrev = () => {
        if (page > 1) {
            let new_page = page - 1;
            setPage(new_page);
            if (props.onChange) props.onChange(new_page, itemsOnPage);
        }
    };

    const renderCurrentPage = () => {
        let pages = [];
        let pages_count = pagesCount();
        for (let i = 1; i <= pages_count; i++) {
            pages.push({ label: `${i}`, value: i });
        }
        if (page > pages_count) setPage(pages_count);
        return (
            <div className={"current-page"}>
                <SelectField
                    name="current-pages"
                    allowEmpty={false}
                    value={page}
                    options={pages}
                    onChange={(params) => {
                        setPage(params.value);
                        if (props.onChange)
                            props.onChange(params.value, itemsOnPage);
                    }}
                    className="custom_selectfield_v2 pagination-select_v2"
                    isV2
                />
            </div>
        );
    };
    const renderItemsOnPage = () => {
        return (
            <div className={isV2 ? "items-on-page_v2" : "items-on-page"}>
                <DivT
                    className={isV2 ? "paginator-title_v2" : "paginator-title"}
                    title={"Items on page"}
                />
                <SelectField
                    name="items-count"
                    allowEmpty={false}
                    value={itemsOnPage}
                    options={[
                        { label: "5", value: 5 },
                        { label: "10", value: 10 },
                        { label: "20", value: 20 },
                        { label: "50", value: 50 },
                        { label: "100", value: 100 },
                        { label: "200", value: 200 },
                        { label: "300", value: 300 },
                    ]}
                    onChange={(params) => {
                        setItemsOnPage(params.value);
                        let cnt = 1;
                        if (params.value > 0) {
                            cnt = Math.ceil(props.count / params.value);
                        }
                        let curr_page = page;
                        if (curr_page > cnt) {
                            curr_page = cnt;
                            setPage(curr_page);
                        }
                        if (props.onChange)
                            props.onChange(curr_page, params.value);
                    }}
                    className="custom_selectfield_v2 itemsOnPage-select_v2"
                    isV2
                />
            </div>
        );
    };

    const renderFirstPage = () => {
        let class_name = "page-first";
        if (page === 1 || itemsOnPage < 1) {
            class_name += " inactive";
        }

        return (
            <img
                className={class_name}
                src={first_icon}
                alt="logo"
                onClick={() => {
                    setPage(1);
                    if (props.onChange) props.onChange(1, itemsOnPage);
                }}
            />
        );
    };
    const renderLastPage = () => {
        let class_name = "page-last";
        if (page === pagesCount() || itemsOnPage < 1) {
            class_name += " inactive";
        }
        return (
            <img
                className={class_name}
                src={last_icon}
                alt="logo"
                onClick={() => {
                    let last_page = pagesCount();
                    setPage(last_page);
                    if (props.onChange) props.onChange(last_page, itemsOnPage);
                }}
            />
        );
    };
    const renderPagePrev = () => {
        let class_name = isV2 ? "page-prev_v2" : "page-prev";
        if (page === 1 || itemsOnPage < 1) {
            class_name += " inactive";
        }
        if (isV2) {
            return (
                <p className={class_name} onClick={onClickPrev}>
                    Prev
                </p>
            );
        }
        return (
            <img
                className={class_name}
                src={prev_icon}
                alt="logo"
                onClick={onClickPrev}
            />
        );
    };
    const renderPageNext = () => {
        let class_name = isV2 ? "page-next_v2" : "page-next";
        if (page === pagesCount() || itemsOnPage < 1) {
            class_name += " inactive";
        }
        if (isV2) {
            return (
                <p className={class_name} onClick={onClickNext}>
                    Next
                </p>
            );
        }
        return (
            <img
                className={class_name}
                src={next_icon}
                alt="logo"
                onClick={onClickNext}
            />
        );
    };

    const title = () => {
        if (itemsOnPage < 1) {
            return `Page 1 of ${pagesCount()} (all items)`;
        }

        let start = itemsOnPage * page - itemsOnPage + 1;
        let end = start + itemsOnPage - 1;
        if (end > props.count) end = props.count;
        return `Page ${page} of ${pagesCount()} (items ${start} - ${end} )`;
    };

    let class_name = isV2 ? "paginator_v2" : "paginator";
    if (props.className) class_name += " " + props.className;
    return (
        <div className={class_name}>
            <DivT
                className={isV2 ? "paginator-title_v2" : "paginator-title"}
                title={title()}
            />
            {!isV2 && renderFirstPage()}
            {!isV2 && renderPagePrev()}
            {!isV2 && renderCurrentPage()}
            {!isV2 && renderPageNext()}
            {!isV2 && renderLastPage()}
            {isV2 && (
                <div className="paginator_v2_button">
                    {renderFirstPage()}
                    {renderPagePrev()}
                    {renderCurrentPage()}
                    {renderPageNext()}
                    {renderLastPage()}
                </div>
            )}
            {renderItemsOnPage()}
        </div>
    );
};

export default RowsPaginator;
