import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    Image,
    PDFDownloadLink,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import logo from "./../../static/logo/logo-chmedic.png";

import * as qHelper from "../../helpers/survey";
import styles from "./template_report_pdf_styles";

function TemplateReportPDF({ template }) {
    const { t } = useTranslation();
    const locale = template.locale || "de";

    const survey = {};

    const surveyHeader = () => {
        return (
            <View style={styles.header_survey}>
                <View style={styles.survey_info}>
                    <Text className="survey-title">
                        {qHelper.gettitle(template, locale)}
                    </Text>
                    <Text style={styles.survey_description}>
                        {qHelper.getdescription(template, locale)}
                    </Text>
                    <Text style={styles.survey_description}>
                        Created by: {template.editor}
                    </Text>
                    <Text style={styles.survey_description}>
                        Date: {template.created}
                    </Text>
                </View>
            </View>
        );
    };

    const renderQuestion = ({ question, page_number, qnum }) => {
        const questionNumber =
            (question.questionNumberingOverride ?? page_number + "." + qnum) +
            ". ";

        const renderDate = () => {
            const result = qHelper.inputdate(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>Date</Text>
                </View>
            );
        };
        const renderCustom = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Numeric {result.params}
                    </Text>
                </View>
            );
        };
        const renderRadiogroup = () => {
            const result = qHelper.radiogroup(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Single: {result.all_choices}
                    </Text>
                </View>
            );
        };
        const renderCheckbox = () => {
            const result = qHelper.checkbox(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Multi: {result.all_choices}
                    </Text>
                </View>
            );
        };
        const renderDropdown = () => {
            const result = qHelper.checkbox(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Single: {result.all_choices}
                    </Text>
                </View>
            );
        };
        const renderLeadMatrix = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Lead/IPG Programming (Complex params)
                    </Text>
                </View>
            );
        };
        const renderLeadProbes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Lead Position (Complex params)
                    </Text>
                </View>
            );
        };
        const renderNoUiSlider = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Numeric [{question.rangeMin ?? 0}...
                        {question.rangeMax ?? 10}]
                    </Text>
                </View>
            );
        };
        const renderExpression = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Expression (Formula)
                    </Text>
                </View>
            );
        };
        const renderOtherTypes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>Text</Text>
                </View>
            );
        };

        switch (question.type) {
            case "inputdate":
                return renderDate();
            case "inputcustom":
                return renderCustom();
            case "inputinteger":
                return renderCustom();
            case "radiogroup":
                return renderRadiogroup();
            case "dropdown":
                return renderDropdown();
            case "checkbox":
                return renderCheckbox();
            case "leadmatrix8combined":
                return renderLeadMatrix();
            case "leadmatrix8v2":
                return renderLeadMatrix();
            case "leadprobes8v2":
                return renderLeadProbes();
            case "nouislider":
                return renderNoUiSlider();
            case "expression":
                return renderExpression();
            default:
                return renderOtherTypes();
        }
    };

    const renderQuestions = ({ questions, page_number }) => {
        const questions_render = [];
        let qnum = 1;
        if (!questions) return questions_render;
        Object.keys(questions).forEach((question_index) => {
            const question = questions[question_index];
            questions_render.push(
                renderQuestion({ question, page_number, qnum })
            );
            qnum++;
        });
        return questions_render;
    };

    const surveyPages = () => {
        const pages = [];
        Object.keys(template.pages).forEach((page_index) => {
            const page = template.pages[page_index];
            const page_number = parseInt(page_index) + 1;
            const title = qHelper.gettitle(page, locale);
            const description = qHelper.getdescription(page, locale);

            pages.push(
                <View className="survey-page" key={page_index}>
                    <View wrap={false} style={styles.chapter_header}>
                        <Text className="page-number">{`${page_number}. ${title}`}</Text>
                    </View>
                    <View style={styles.page_info}>
                        <Text style={styles.survey_description}>
                            {description}
                        </Text>
                    </View>
                    <View className="page-body">
                        {renderQuestions({
                            questions: page.elements,
                            page_number: page_number,
                        })}
                    </View>
                </View>
            );
        });
        return pages;
    };

    return (
        <Document>
            <Page size="A4" style={styles.body} wrap>
                <View style={styles.header} fixed>
                    <Image style={styles.header_logo} src={logo} />
                    <View style={styles.header_info}>
                        <Text>
                            {t("Template report") +
                                ": " +
                                qHelper.gettitle(template, locale)}
                        </Text>
                        <Text>{"ID: " + template.id}</Text>
                        <Text>{}</Text>
                    </View>
                </View>
                {surveyHeader()}
                {surveyPages()}
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />
            </Page>
        </Document>
    );
}

const DownloadPDF = ({ template }) => {
    const date = dayjs(new Date()).format("YYYY-MM-DD_HHmmss");
    const filename = `${qHelper.gettitle(template, template.locale || "de")}_${
        template.id
    }_${date}.pdf`;
    return (
        <PDFDownloadLink
            className="button"
            document={TemplateReportPDF({ template })}
            fileName={filename}
        >
            {({ blob, url, loading, error }) =>
                loading ? "Wait, preparing PDF..." : "Download PDF"
            }
        </PDFDownloadLink>
    );
};

export default DownloadPDF;
