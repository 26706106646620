const data = [
    {
        survey_id:1,
        auid: 73,
        name: 'Kimio Tremmel',
        submitted: '2021-01-08',
        notes: 'Data filled in another hospital (JSRSD)'
    },
    {
        survey_id:2,
        auid: 77,
        name: 'Alex Jasinsky',
        submitted: '2021-11-12',
        notes: 'Data filled at home'
    },
    {
        survey_id:3,
        auid: 123,
        name: 'Arnold Swarchneger',
        submitted: '2020-10-12',
        notes: 'Data filled in tropics'
    },
    {
        survey_id:4,
        auid: 666,
        name: 'Silvester Stallone',
        submitted: '2019-03-23',
        notes: 'Before something'
    },
    {
        survey_id:5,
        auid: 73,
        name: 'Kimio Tremmel',
        submitted: '2021-10-12',
        notes: 'Data filled in another hospital (JSRSD)'
    },
    {
        survey_id:6,
        auid: 77,
        name: 'Alex Jasinsky',
        submitted: '2021-11-12',
        notes: 'Data filled at home'
    },
    {
        survey_id:7,
        auid: 123,
        name: 'Arnold Swarchneger',
        submitted: '2020-12-12',
        notes: 'Data filled in tropics'
    },
    {
        survey_id:8,
        auid: 666,
        name: 'Silvester Stallone',
        submitted: '2020-04-12',
        notes: 'Test again'
    },
    {
        survey_id:9,
        auid: 73,
        name: 'Kimio Tremmel',
        submitted: '2020-11-02',
        notes: 'Data filled in another hospital (JSRSD)'
    },
    {
        survey_id:10,
        auid: 77,
        name: 'Alex Jasinsky',
        submitted: '2021-04-22',
        notes: 'Data filled at SSNM'
    },
    {
        survey_id:11,
        auid: 123,
        name: 'Arnold Swarchneger',
        submitted: '2021-11-01',
        notes: 'Submitted in LA'
    },
    {
        survey_id:12,
        auid: 666,
        name: 'Silvester Stallone',
        submitted: '2021-03-23',
        notes: 'Before something 2'
    },
]

export default data;