import get_choices from "./getchoices";
import get_title from "./gettitle";
import question_leadmatrix from "./../../data/z_leadmatrix";

export default function matrix(question, survey_answers, lang = "default") {
    const result = [];

    let survey_answer = survey_answers[question.name];
    if (!survey_answer) return result;
    if (survey_answer === "N/A" || survey_answer.value === "N/A" || survey_answer.nda) {
        result["answer"] = "N/A";
        return result;
    }

    result["header"] = get_title(question, lang);

    const text = [];
    let res = side(0, question, survey_answer, lang);
    if (res) text.push("Left: " + res);
    res = side(1, question, survey_answer, lang);
    if (res) text.push("Right: " + res);
    result["answer"] = text.join(". ");
    return result;
}

function side(side_index, question, survey_answer_all, lang = "default") {
    const question_side = question_leadmatrix.elementsJSON[side_index];
    let survey_answer = survey_answer_all[question_side.name];
    let str = [];
    question_side.rows.forEach((row) => {
        const result = [];
        if (!survey_answer) return;
        const row_answer = survey_answer[row];
        if (!row_answer) return;
        question_side.columns.forEach((column) => {
            if (
                !row_answer[column.name] &&
                typeof row_answer[column.name] !== "object"
            )
                return;
            if (column.name === "comment") result.push(row_answer[column.name]);
            else
                result.push(get_choices(column, row_answer[column.name], lang));
        });
        str.push(`${row} ${result.join(",")}`);
    });
    const test = str.join(". ");
    return test;
}
