import React from 'react';
import { NavButtonDesc } from './../../components'
import { connect } from 'react-redux';
import { FcList, FcReading, FcShare } from 'react-icons/fc';

class NavList extends React.Component {
    render() {
        return (
            <div className="menu main-menu">
                <ul className="navmenu">
                    <NavButtonDesc to="/template_selector" label="Template list" description="List of deployed templates" permissions={true} icon_comp={<FcReading />} />
                    <NavButtonDesc to="/person_selector" label="Personal survey" description="Analyze data for selected person" permissions={true} icon_comp={<FcReading />} />
                    <NavButtonDesc to="/group_survey_selector" label="Survey dynamic" description="Survey statistics for person or group" permissions={true} icon_comp={<FcList />} />
                    <NavButtonDesc to="/log_actions" label="Report" description="Send report to email" permissions={true} icon_comp={<FcShare />} />
                    <NavButtonDesc to="/survey_report_h" label="Survey horizontal" description="Survey spreadsheet" permissions={true} icon_comp={<FcList />} />
                    <NavButtonDesc to="/virtual_template_editor" label="Virtual template" description="Virtual template editor/creator" permissions={true} icon_comp={<FcReading />} />
                </ul>
            </div>
        )
    }

}
function mapStateToProps(state) {
    //TODO: add user role
    return {
        user: state.authReducer.user

    };
}

export default NavList = connect(
    mapStateToProps)(
        NavList
    );
