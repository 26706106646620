import React, { useRef, useEffect } from "react";
import { Label, Text, Tag, Transformer, Path } from "react-konva";

const PdfElement = (props) => {
    const containerRef = useRef(null);
    const transRef = useRef(null);
    const elementRef = useRef(null);

    const transformable = props.type === "text" && props.selected;

    const minElementWidth = 300;
    const minElementHeight = 40;

    useEffect(() => {
        if (!transformable) return;

        transRef.current.nodes([containerRef.current]);
        transRef.current.getLayer().batchDraw();
    }, [transformable]);

    function onDragEnd() {
        if (!elementRef.current) return;

        const newCoords = elementRef.current.parent.position();

        props.onElementUpdate({
            id: props.id,
            x: Math.round(newCoords.x),
            y: Math.round(newCoords.y),
        });
    }

    function onClick() {
        if (props.disabled) return;

        props.onEditElement(props.id);
    }

    function handleDragBound(pos) {
        const stage = elementRef.current.getStage();
        const stageWidth = stage.width();
        const stageHeight = stage.height();

        const draggableNode = elementRef.current;
        const draggableWidth = draggableNode.width();
        const draggableHeight = draggableNode.height();

        const minX = 0;
        const minY = 0;
        const maxX = stageWidth - draggableWidth;
        const maxY = stageHeight - draggableHeight;

        const restrictedX = Math.max(minX, Math.min(maxX, pos.x));
        const restrictedY = Math.max(minY, Math.min(maxY, pos.y));

        return {
            x: restrictedX,
            y: restrictedY,
        };
    }

    function onTransform(e) {
        const shape = e.target;

        props.onElementUpdate({
            id: props.id,
            width: Math.round(shape.width() * shape.scaleX()),
            height: Math.round(shape.height() * shape.scaleY()),
        });

        shape.scaleX(1);
        shape.scaleY(1);
    }

    let style = {
        fill: props.fill,
        cornerRadius: 5,
        opacity: props.disabled ? 0.5 : 0.7,
    };

    if (props.selected) {
        style = {
            ...style,
            opacity: 1,
            stroke: "black",
            strokeWidth: 2,
            dash: [5, 5],
        };
    }

    const elHeight = props.height ?? minElementHeight;
    const elWidth = props.width ?? minElementWidth;

    return (
        <>
            <Label
                ref={containerRef}
                draggable={!props.disabled && !props.locked}
                x={props.x}
                y={props.y}
                dragBoundFunc={handleDragBound}
                onDragEnd={(event) => {
                    onDragEnd(event);
                }}
                onTransform={onTransform}
            >
                <>
                    <Tag {...style} />
                    <Text
                        ref={elementRef}
                        onClick={(event) => {
                            onClick(event);
                        }}
                        align="center"
                        height={elHeight}
                        width={elWidth}
                        text={
                            (props.isRepresentitive ? "*" : "") +
                            props.placeholder
                        }
                        padding={8}
                        fontSize={26}
                    />
                    {props.type === "signature" && (
                        <>
                            <Text
                                text="CHMedicSigned by"
                                offsetX={-83}
                                offsetY={17}
                            />
                            <Path
                                stroke={props.fill}
                                strokeWidth={0.3}
                                offsetX={11}
                                offsetY={60}
                                scaleX={6}
                                scaleY={6}
                                data="m 10.990027,57.851766 c -0.755034,0 -1.4036421,0.622505 -1.8593182,1.574064 -0.455676,0.951559 -0.732255,2.2503 -0.732255,3.689181 0,1.438881 0.276579,2.740205 0.732255,3.691764 0.4556761,0.951558 1.1042842,1.574064 1.8593182,1.574064 h 12.000818 0.578259 v -0.26355 H 10.990027 c -0.606768,0 -1.1881535,-0.521679 -1.6210898,-1.425753 -0.4329363,-0.904073 -0.7048666,-2.171644 -0.7048666,-3.576525 0,-1.404881 0.2719303,-2.66987 0.7048666,-3.573942 0.4329363,-0.904074 1.0143218,-1.425753 1.6210898,-1.425753 h 12.579077 v -0.204639 c 0,-0.01975 4.11e-4,-0.03922 5.17e-4,-0.05891 h -0.72347 z"
                            />
                            <Text
                                text={
                                    props.id
                                        .substring(0, 16)
                                        .toUpperCase()
                                        .replace(/-/g, "") + "..."
                                }
                                offsetX={-83}
                                offsetY={-44}
                            />
                        </>
                    )}
                </>
            </Label>
            {transformable && (
                <Transformer
                    ref={transRef}
                    rotateEnabled={false}
                    keepRatio={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (
                            newBox.width < minElementWidth ||
                            newBox.height < minElementHeight
                        ) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    );
};

export default PdfElement;
