import React from "react";
import Select from "react-select";
import Field from "./field_class";
import { withTranslation } from "react-i18next";

class SelectField extends Field {
    getWrapClassName() {
        let wrap_class_name = "select field_wrap " + this.getNamePostfix();
        if (this.props.className && this.props.isV2)
            return this.props.className;
        if (this.props.className & !this.props.isV2)
            wrap_class_name += " " + this.props.className;
        return wrap_class_name;
    }
    onChange(val) {
        this.props.onChange({
            name: this.props.name,
            value: val?.value,
            label: val?.label,
        });
    }
    showInput() {
        let value = this.props.value !== "" ? this.props.value : "";

        let options = !Array.isArray(this.props.options)
            ? []
            : this.props.options;
        if (
            this.props.option_objects &&
            Array.isArray(this.props.option_objects.objects_array)
        ) {
            const field_name_for_value =
                this.props.option_objects.field_name_for_value;
            const field_name_for_label =
                this.props.option_objects.field_name_for_label;
            this.props.option_objects.objects_array.forEach((option_object) => {
                const option = {
                    label: option_object[field_name_for_label],
                    value: option_object[field_name_for_value],
                };
                options.push(option);
            });
        }
        let clearable = this.props.allowEmpty ? true : false;
        let select_id = { id: this.props.name };
        let name_postfix = this.props.postfix
            ? this.props.name + "_" + this.props.postfix
            : this.props.name + "_edit";
        const { t } = this.props;
        return (
            <Select
                name={name_postfix}
                menuPlacement="auto"
                isClearable={clearable}
                className={this.props.className}
                classNamePrefix="select-field"
                label={t(this.props.label)}
                inputProps={select_id}
                value={options.filter(function (option) {
                    return option.value === value;
                })}
                isSearchable={false}
                width="200px"
                placeholder={t(this.props.placeholder)}
                options={options}
                onChange={this.onChange.bind(this)}
                menuPortalTarget={document.querySelector("body")}
                isDisabled={this.isDisabled()}
                styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
            />
        );
    }
}

export default withTranslation()(SelectField);
