import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Form(props) {
    const { t } = useTranslation();
    const showTitle = () => {
        if (props.title) {
            return (
                <div className="form-header-wrap">
                    <div className="form-header">{t(props.title)}</div>
                </div>
            )
        } else {
            return null;
        }
    }
    let className = "form";
    if (props.className !== undefined) {
        className = className + ' ' + props.className
    }
    if (props.name) {
        className = className + ' ' + props.name
    }
    if (props.is_hidden) return null;
    let context_class_name='form-content-wrap'
    if (props.classNameContent) {
        context_class_name+=` ${props.classNameContent}`
    }
    return (
        <div className={className}>
            {showTitle()}
            <div className={context_class_name}>{props.children}</div>
        </div>
    )
}
