import config from '../config'
let _default_hostname = process.env.REACT_APP_API_HOSTNAME || window.location.hostname;
let _default_port = process.env.REACT_APP_API_PORT || '3030';
let _default_host = process.env.REACT_APP_API_HOST || window.location.hostname + ':3030';
let _default_socket = process.env.REACT_APP_SOCKET_HOST || window.location.hostname + ':3030';

export default function SystemReducer(state = {
    connection: {
        hostname: _default_hostname, host: _default_host, port: _default_port, socket_host: _default_socket
    },
    loader_show: false, loader_timeout: config.loader_timeout || 10000, loader_content: null, loader_fullscreen: false, loader_silent: false,
    config_data: { need_restart: false, system_restarting: false },
    alarm_data: { system_armed: false, intrusion_module_id: false, arm_countdown: false },
    alarm_log: [],
    config_db: {}, config_validation: {}, alarm_select: {},
    device_name: '',
    event_action: {},
    controllers_state: {},
    modules_state: {}, loading: false,
    remote_command: { type: {}, params: {} },
    component_info: {}, refresh: false,
    pulse: false,
    connection_status: true,
    error: ''
}, action) {
    //window.location.hostname
    switch (action.type) {
        case 'request_started':
        case 'loader_show':
            if (action.content) state.loader_content = action.content;
            if (action.loader_fullscreen) state.loader_fullscreen = action.loader_fullscreen;
            state.loader_show = true;
            return { ...state };
        case 'loader_show_infinite':
            state.loader_timeout = 0
            state.loader_show = true;
            return { ...state };
        case 'request_finished':
        case 'loader_hide':
            state.loader_show = false;
            state.loader_content = null;
            state.loader_fullscreen = false;
            state.loader_silent = false;
            state.loader_timeout = config.loader_timeout || 10000;
            return { ...state };
        case 'set_loader_content':
            state.loader_content = action.content;
            return { ...state };
        case 'LOADING_START':
            state.loading = true;
            return { ...state };
        case 'LOADING_STOP':
            state.loading = false;
            return { ...state };
        case 'DEVICE_PULSE':
            state.pulse = !state.pulse;
        // eslint-disable-next-line no-fallthrough
        case 'DEVICE_EVENT':
            state.event_action = action.event_action;
            state.modules_state = action.data.modules_state;
            state.server_time = action.server_time;
            state.config_data = action.data.config_data;
            state.alarm_data = action.data.alarm_data;
            state.device_name = action.data.device_name;
            state.controllers_online = action.data.controllers_online;
            //mini log
            if (state.event_action.type === 'ALARM_ARM' || state.event_action.type === 'ALARM_DISARM') {
                state.refresh = !state.refresh
            }
            return { ...state };
        case 'ALARM_EVENT':
            if (action.event_action === 'ALARM_EVENT') {
                console.log('ALARM_EVENT');
                console.log(action.data);
                let log_state = action.data;
                let log = {
                    time: action.server_time,
                    type: state.event_action.type,
                    area_name: log_state.area_name,
                    module_name: log_state.module_name,
                    component_id: log_state.component_id,
                    class_prop: log_state.class_prop,
                    state: log_state.state.msg
                };
                state.alarm_log.unshift(log);
                let log_length = state.alarm_log.length;
                if (log_length > 100) {
                    state.alarm_log.splice(100, (log_length - 100))
                }
            }
            state.refresh = !state.refresh
            return { ...state };
        case 'CONFIG_UPDATE_FORM':
            state.config_db[action.field] = action.value;
            if (state.config_validation[action.field]) {
                state.config_validation[action.field] = {};
            }
            state.config_db = { ...state.config_db }
            return { ...state };
        case 'TEST_UPDATE_AREA':
            state.test_area = action.value;
            return { ...state };
        case 'res_config_get_config':
            state.config_db = action.data;
            return { ...state };
        case 'res_config_update_validation_error':
            state.config_validation = action.data.fields;
            return { ...state };
        case 'ALARM_SELECT':
            state.alarm_select = action.data;
            return { ...state };


        case 'LOGGED_IN':
            state.config_data = action.data.config_data;
            return { ...state };


        case 'HOSTNAME_UPDATE':
            state.connection.hostname = action.hostname;
            state.connection.host = action.hostname + ':3030';
            return { ...state };

        case 'MODULES_STATE':
            state.modules_state = action.data;
            return { ...state };
        case 'ALARM_LOG':
            state.alarm_log = action.data;
            return { ...state };
        case 'COMPONENT_EVENT':
            state.modules_state = action.data;
            return { ...state };
        case 'MODULE_COMMAND':
            state.remote_command = action.remote_command;
            return { ...state };
        case 'REMOTE_COMMAND':
            if (action.data.remote_command === 'component_info') {
                state.component_info = action.data.data;
            }
            return { ...state };
        case 'CLEAR_COMPONENT_INFO':
            state.component_info = {};
            return { ...state };
        case 'SYSTEM_COMMAND':
            state.remote_command = action.remote_command;
            return { ...state };
        case 'SYSTEM_RESTART':
            state.remote_command = action.remote_command;
            state.config_data.system_restarting = true;
            return { ...state };
        case 'connection_up':
            state.connection_status = true;
            return { ...state };
        case 'connection_down':
            state.connection_status = false;
            return { ...state };
        default:
            break;
    }
    return state;
}

