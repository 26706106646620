/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Form,
    Table,
    Button,
    AdminBadge,
    ChartPop,
    DivT,
    SelectField,
    Toggle,
} from "../../components";
import TableControl from "./table_control_v2";
import { sortByPropName } from "../../helpers/sort";
import * as qHelper from "./../../helpers/survey";
import notifications from "./../../helpers/notifications";
import "./survey_report_h.css";
import data_source from "./survey_connect";
import data_source_quest from "./../../api/questionnaire_connector";
import TableH2 from "./table_h2";
import TableV from "./table_v";
import tableCSV from "./table_csv_h";
import DebugTree from "./debug_tree";
import DoctorInfo from "./doctor_info";
import SaveTemplatePDF from "./save_template_pdf";
import SaveInstancePDF from "./save_instance_pdf";
import DeleteInstance from "./delete_instance";
import getAllQuestionsFromTemplate from "../../helpers/survey/questions";
import { useHistory } from "react-router-dom";
import { FcRefresh } from "react-icons/fc";
import Modal from "react-modal";
import close_img from "../../static/icons/close.svg";
import MergeTemplateEditor from "../merge_editor/merge_template";
import store from "../../store";
import auth_connector from "../../api/questionnaire_connector";
import { decode } from "../../helpers/base64decode";
import PagesSelector from "../../components/columns_page_selector/page_selector";
import RowsPaginator from "../../components/rows_paginator/paginator";
import { sortAndFilterRecords, processData } from "./process_records";
import {
    Calc_column_structure,
    getColumnsList,
} from "../../helpers/Template_ext";
import { searchInArray } from "../../helpers/search";
import _ from "lodash";
import { getText } from "../../helpers/import_instances_helper";
import { LoaderShowInfitite } from "../../helpers/loader";
import TimestampToDateConverter from "../../helpers/TimestampToDateConverter";

const non_filterable_fields = [
    "instance_answering_person_bd",
    "relevant_date",
    "lc",
    "system_field",
    "instance_answering_person_name",
];
const system_fields = [
    "instance_answering_person_bd",
    "relevant_date",
    "lc",
    "instance_answering_person_name",
    "instance_id",
    "doctor_id",
    "permission",
];

export default function SurveyReport(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const template = useSelector(
        (state) => state.SurveyReducer?.spreadsheet_template
    );
    const template_origin = useSelector(
        (state) => state.SurveyReducer?.template
    );
    const data_full = useSelector(
        (state) => state.SurveyReducer?.spreadsheet_surveys
    );
    const encrypted = useSelector(
        (state) => state.SurveyReducer?.encrypted || false
    );
    //const [processedData, setProcessedData] = useState([]);
    const [data, setData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [showFieldSelector, setShowFieldSelector] = useState(false);
    const [v_layout, setLayout] = useState(false);
    const [showValues, setShowValues] = useState(false);
    const [showOrigValues, setShowOrigValues] = useState(false);
    const [filter, setFilter] = useState({});
    const [chartColumn, setChartColumn] = useState(null);
    const [selected, setSelected] = useState([]);
    const [sorter, setSorter] = useState({ lc: { order: "desc" } });
    const [allQuestions, setAllQuestions] = useState([]);
    const [includedQuestions, setIncludedQuestions] = useState([]);
    const [excludedQuestions, setExcludedQuestions] = useState([]);
    const [showDebugObject, setShowDebugObject] = useState(null);
    const [showDoctorInfo, setShowDoctorInfo] = useState(null);
    const [showSavePDFPopup, setShowSavePDFPopup] = useState(false);
    const [showInstanceSavePDFPopup, setShowInstanceSavePDFPopup] =
        useState(false);
    const [showInstanceDeletePopup, setShowInstanceDeletePopup] =
        useState(false);
    const showVirtual = useSelector(
        (state) => state.SurveyReducer?.showVirtual
    );
    const virtual_templates = useSelector(
        (state) => state.SurveyReducer?.virtual_templates
    );
    const [virtualSelector, setVirtualSelector] = useState(null);
    const [virtualTemplate, setVirtualTemplate] = useState(null);
    const [locale, setLocale] = useState("de");
    const [refresh, setRefresh] = useState(false);
    const [showChartPopUp, setShowChartPopUp] = useState(false);
    const [showMergingPopUp, setShowMergingPopUp] = useState(false);
    const [isMergingChanged, setIsMergingChanged] = useState(false);
    const [columnsPages, setColumnsPages] = useState([
        { id: "-1", name: "-1", title: "All pages" },
    ]);
    const [questionsVisible, setQuestionsVisible] = useState([]);
    const [showAllColumnsPages, setShowAllColumnsPages] = useState(true);
    const [page, setPage] = useState(1);
    const [itemsOnPage, setItemsOnPage] = useState(10);
    const [anonymousMode, setAnonymousMode] = useState(false);
    const [onlySCF, setOnlySCF] = useState(false);
    const [calcColumn, setCalcColumn] = useState({});
    const [calcColumnTmp, setCalcColumnTmp] = useState({});
    const [showPatAgeSetPopup, setShowPatAgeSetPopup] = useState(false);
    const [table_rows, setTable_rows] = useState([]);
    const [hideOrangeBar, setHideOrangeBar] = useState(false);

    useEffect(() => {
        console.log("clone");
        console.time("clone");
        let table_rows = _.cloneDeep(data_full).filter(
            (f) => !Array.isArray(f)
        );
        setTable_rows(table_rows);
        console.timeEnd("clone");
    }, [data_full, showValues]);

    useEffect(() => {
        LoaderShowInfitite("prepare data...");
        setTimeout(() => {
            const showQuestions =
                includedQuestions.length > 6 ? includedQuestions : allQuestions;
            processData({
                table_rows,
                showQuestions,
                showValues,
                locale,
                calcColumn,
            });
            //setProcessedData(table_rows);
            setData(
                sortAndFilterRecords({
                    table_rows,
                    filter,
                    non_filterable_fields,
                    showQuestions,
                    sorter,
                    showValues,
                })
            );
        }, 10);
    }, [table_rows, includedQuestions, allQuestions, locale, sorter, filter]);

    // useEffect(() => {
    //     LoaderShowInfitite("processing sorting...");
    //     console.log(" rerender sotret")
    //     const showQuestions =
    //         includedQuestions.length > 6 ? includedQuestions : allQuestions;

    //     if (!processedData) return;

    //     const table_rows = [...processedData];
    //     setData(
    //         sortAndFilterRecords({
    //             table_rows,
    //             filter,
    //             non_filterable_fields,
    //             showQuestions,
    //             sorter,
    //             showValues,
    //         })
    //     );
    // }, [sorter, filter, processedData]);

    useEffect(() => {
        LoaderShowInfitite("prepare page...");
        console.log("rerender page");
        setTimeout(() => {
            if (itemsOnPage > 0) {
                let start = itemsOnPage * (page - 1);
                setPageData(data.slice(start, start + itemsOnPage));
                return;
            }

            setPageData(data);
        }, 100);
    }, [page, itemsOnPage, data]);

    useEffect(() => {
        const getChildQuestText = (survey, question, value) => {
            let result = "";
            if (Array.isArray(survey?.pages)) {
                survey.pages.forEach((page) => {
                    if (Array.isArray(page.elements)) {
                        page.elements.forEach((el) => {
                            if (el.name === question) {
                                if (Array.isArray(el.choices)) {
                                    el.choices.forEach((choice) => {
                                        if (typeof choice === "object") {
                                            if (choice.value === value) {
                                                if (
                                                    typeof choice.text ===
                                                    "object"
                                                ) {
                                                    result = choice.text[locale]
                                                        ? choice.text[locale]
                                                        : choice.text.default;
                                                } else {
                                                    if (!choice.text) {
                                                        if (
                                                            choice.value.includes(
                                                                "="
                                                            )
                                                        ) {
                                                            let items_array =
                                                                choice.value.split(
                                                                    "="
                                                                );
                                                            result =
                                                                items_array[1];
                                                        } else {
                                                            result = value;
                                                        }
                                                    } else {
                                                        result = choice.text;
                                                    }
                                                }
                                            }
                                        } else if (choice === value) {
                                            if (choice.includes("=")) {
                                                let items_array =
                                                    choice.split("=");
                                                result = items_array[1];
                                            } else {
                                                result = value;
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            }

            return result;
        };
        if (!showVirtual) {
            setVirtualTemplate(null);
        } else {
            let template = virtual_templates.find((template) => {
                return template.title === showVirtual;
            });

            if (Array.isArray(template?.mapping)) {
                let survey = {};
                template.mapping.forEach((child) => {
                    auth_connector.load_template(
                        child.id,
                        anonymousMode,
                        onlySCF,
                        (err, res) => {
                            if (err) {
                                console.log(err);
                            } else {
                                LoaderShowInfitite("loading...");
                                if (res.qarr.arr_fragen) {
                                    const jsonString = decode(
                                        res.qarr.arr_fragen
                                    );
                                    try {
                                        survey = JSON.parse(jsonString) ?? {};
                                        if (
                                            Array.isArray(child.question_names)
                                        ) {
                                            child.question_names.forEach(
                                                (quest) => {
                                                    if (
                                                        Array.isArray(
                                                            quest.value_mapping
                                                        )
                                                    ) {
                                                        quest.value_mapping.forEach(
                                                            (map) => {
                                                                if (
                                                                    !map.child_txt
                                                                ) {
                                                                    map.child_txt =
                                                                        getChildQuestText(
                                                                            survey,
                                                                            quest.child,
                                                                            map.child_value
                                                                        );
                                                                }
                                                            }
                                                        );
                                                    }
                                                }
                                            );
                                        }
                                    } catch (e) {
                                        console.log(
                                            "Invalid survey payload format"
                                        );
                                    }
                                }
                            }
                        }
                    );
                });
                setVirtualTemplate(template);
            } else {
                setVirtualTemplate(template);
            }
        }
    }, [showVirtual, virtual_templates, locale, anonymousMode, onlySCF]);

    //dependency link
    useEffect(() => {
        if (isMergingChanged) {
            if (isMergingChanged) {
                setIsMergingChanged(false);
            }
        } else {
            setTable_rows([]);
            setTimeout(() => {
                if (!virtualTemplate) {
                    data_source.load_answers(
                        anonymousMode,
                        onlySCF,
                        (err, data) => {
                            if (err) {
                                notifications.error_center(
                                    "Permission error",
                                    err?.api_error?.message
                                );
                            }
                        }
                    );
                } else {
                    data_source.load_virtual(
                        virtualTemplate,
                        anonymousMode,
                        onlySCF,
                        (err, data) => {
                            if (err) {
                                notifications.error_center(
                                    "Permission error",
                                    err.api_error.message
                                );
                            }
                        }
                    );
                }
            }, 1000);
        }
    }, [virtualTemplate, refresh, isMergingChanged, anonymousMode, onlySCF]);

    useEffect(() => {
        const virtual_selector = [];
        virtual_templates.forEach((template) => {
            virtual_selector.push({
                label: template.title || "unknown",
                value: template.title || "unknown",
            });
        });
        setVirtualSelector(virtual_selector);
    }, [virtual_templates]);

    useEffect(() => {
        if (template) {
            data_source_quest.load_calc_column(template.id, (err, res) => {
                let current = Calc_column_structure.calculateColumns;
                if (err) {
                    console.log("Failed to load calc column settings");
                } else {
                    if (res && res !== "0") {
                        try {
                            current = JSON.parse(res);
                        } catch (e) {
                            console.log("Decode calc column settings error", e);
                        }
                    }
                    if (current?.selected) {
                        setCalcColumn(current);
                        setCalcColumnTmp(current);
                    } else {
                        console.log("Failed to decode calc column settings");
                    }
                    let all_quest = getAllQuestionsFromTemplate(template);
                    if (current?.selected?.column_place_after) {
                        let calc_quest = {
                            index: current.selected.column_place_after.idx + 1,
                            label: current.selected.label,
                            name: current.selected.name,
                            number: "",
                            surveyId: template.id,
                            type: current.selected.type,
                        };
                        all_quest.splice(
                            current.selected.column_place_after.idx + 1,
                            0,
                            calc_quest
                        );
                    }
                    setAllQuestions(all_quest);
                    let pages_tmp = [];
                    setLocale(template.locale || locale);
                    template.pages.forEach((page, index) => {
                        if (index > 0) {
                            pages_tmp.push({
                                id: index.toString(),
                                num: index,
                                name: page.name,
                                title: `${index}. ${
                                    qHelper.gettitle(page, locale) || page.name
                                }`,
                            });
                        }
                    });
                    setColumnsPages(pages_tmp);
                }
            });
        }
    }, [template, locale]);

    useEffect(() => {
        LoaderShowInfitite("prepare columns...");
        setTimeout(() => {
            setExcludedQuestions(
                allQuestions.filter((question) => {
                    return virtualTemplate?.column_filter?.includes(
                        question.name.replace(question.surveyId + "-", "")
                    );
                })
            );
            let includeQuestions = allQuestions.filter((question) => {
                if (showAllColumnsPages) {
                    return (
                        system_fields.includes(question.name) ||
                        !virtualTemplate?.column_filter?.includes(
                            question.name.replace(question.surveyId + "-", "")
                        )
                    );
                } else {
                    return (
                        system_fields.includes(question.name) ||
                        (questionsVisible.includes(question.number) &&
                            !virtualTemplate?.column_filter?.includes(
                                question.name.replace(
                                    question.surveyId + "-",
                                    ""
                                )
                            ))
                    );
                }
            });
            setIncludedQuestions(includeQuestions);
        }, 10);
    }, [allQuestions, virtualTemplate, showAllColumnsPages, questionsVisible]);

    const exportToCSV = () => {
        const showQuestions =
            includedQuestions.length > 6 ? includedQuestions : allQuestions;
        const title = qHelper.gettitle(template, locale);
        tableCSV({
            title,
            showFieldSelector,
            showQuestions,
            locale,
            template,
            data,
            filter,
            selected,
            non_filterable_fields,
            sorter,
            showValues,
            showOrigValues,
        });
    };

    const manipulateSorter = (field_name) => {
        const new_sorter = { ...sorter };
        const fields = Object.keys(new_sorter);
        const fields_qty = fields.length;
        const last_field = fields[fields_qty - 1];
        const in_sorter = field_name in new_sorter;
        if (
            field_name === last_field &&
            new_sorter[last_field].order === "desc"
        ) {
            delete new_sorter[last_field];
        } else {
            if (fields_qty > 2 && !in_sorter) delete new_sorter[last_field];
            if (!new_sorter[field_name]) {
                new_sorter[field_name] = {};
                new_sorter[field_name].order = "asc";
                const question = allQuestions.find((question) => {
                    return question.name === field_name;
                });
                new_sorter[field_name].type = question.type;
            } else {
                new_sorter[field_name].order =
                    new_sorter[field_name].order === "asc" ? "desc" : "asc";
            }
        }
        setSorter(new_sorter);
    };

    const checkSorter = () => {
        const showQuestions =
            includedQuestions.length > 6 ? includedQuestions : allQuestions;
        const showFields = showQuestions.map((question) => question.name);
        const new_sorter = { ...sorter };

        Object.keys(new_sorter).forEach((index) => {
            if (!showFields.includes(index)) delete new_sorter[index];
        });
        setSorter(new_sorter);
    };

    const onMerging = () => {
        setShowMergingPopUp(true);
    };

    const renderSurveyHeader = () => {
        if (!template) return null;
        const description = qHelper.getdescription(template, locale);
        let title = qHelper.gettitle(template, locale);
        title += template.id ? " (" + template.id + ")" : "";
        title += showVirtual ? " (combined data)" : "";
        const renderTitle = () => {
            if (encrypted) {
                return (
                    <div>
                        {title}
                        <span className={"encrypted"}>Enc</span>
                    </div>
                );
            } else {
                return <div>{title}</div>;
            }
        };

        const props = {
            filter,
            v_layout,
            setLayout,
            showValues,
            setShowValues,
            showOrigValues,
            setShowOrigValues,
            showFieldSelector,
            search: filter,
            setFilter,
            setSelected,
            selected,
            checkSorter,
            setSorter,
            setShowFieldSelector,
            exportToCSV,
            locale,
            setShowSavePDFPopup,
            virtualSelector,
            history,
            // setShowVirtual,
            showVirtual,
            refresh,
            setRefresh,
            setShowChartPopUp,
            chartColumn,
            onMerging,
            anonymousMode: anonymousMode,
            setAnonymousMode: setAnonymousMode,
            onlySCF: onlySCF,
            setOnlySCF: setOnlySCF,
            setHideOrangeBar,
            hideOrangeBar,
        };
        return (
            <div className="survey-header" style={{ marginBottom: "0px" }}>
                <div className="template-info-wrap">
                    <div className="survey-title">
                        {renderTitle()}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Button
                                name="refresh"
                                className="btn-icon"
                                onClick={() => {
                                    setVirtualTemplate(null);
                                    setRefresh(!refresh);
                                }}
                            >
                                <FcRefresh size={"20px"} />
                            </Button>
                            <AdminBadge />
                        </div>
                    </div>
                    <div className="survey-description">
                        <p className="description">{description}</p>
                        <div className="editor-created">
                            {template.editor && (
                                <p>{`Editor: ${template.editor}`}</p>
                            )}
                            <p>{`Created: ${
                                template.created.split(" ")[0]
                            }`}</p>
                        </div>
                    </div>
                </div>
                <TableControl className="survey-horizontal" {...props} />
            </div>
        );
    };

    const renderSurvey = () => {
        if (showFieldSelector) return null;
        const showQuestions =
            includedQuestions.length > 6 ? includedQuestions : allQuestions;
        if (!showQuestions.length) return null;

        const props = {
            showFieldSelector,
            showQuestions,
            locale,
            template,
            data: pageData,
            filter,
            selected,
            sorter,
            setSelected,
            setFilter,
            non_filterable_fields,
            setShowDebugObject,
            manipulateSorter,
            setShowInstanceSavePDFPopup,
            setShowInstanceDeletePopup,
            setShowDoctorInfo,
            showOrigValues,
            chartColumn,
            setChartColumn,
            calcColumn,
            hideOrangeBar,
        };
        return v_layout ? <TableV {...props} /> : <TableH2 {...props} />;
    };

    const renderFieldSelector = () => {
        if (!showFieldSelector) return null;
        const addQuestionToTarget = (id, params) => {
            if (!id) return;
            const new_included_questions = [...includedQuestions, params];
            sortByPropName({
                params: new_included_questions,
                field_name: "index",
            });
            setIncludedQuestions(new_included_questions);
            setExcludedQuestions(
                excludedQuestions.filter((question) => {
                    return question.name !== params.name;
                })
            );
            includedQuestions.sort((a, b) =>
                a.index > b.index ? 1 : b.index > a.index ? -1 : 0
            );
        };
        const removeQuestionFromTarget = (id, params) => {
            if (!id) return;
            const new_excluded_questions = [...excludedQuestions, params];
            sortByPropName({
                params: new_excluded_questions,
                field_name: "index",
            });
            setExcludedQuestions(new_excluded_questions);
            setIncludedQuestions(
                includedQuestions.filter((question) => {
                    return question.name !== params.name;
                })
            );
        };
        const removeAllFromTarget = () => {
            setAllQuestions(getAllQuestionsFromTemplate(template));
        };
        const excludedQuestionsWithoutSystemFields = excludedQuestions.filter(
            (question) => {
                return !system_fields.includes(question.name);
            }
        );
        const includedQuestionsWithoutSystemFields = includedQuestions.filter(
            (question) => {
                return !system_fields.includes(question.name);
            }
        );
        return (
            <div className="side-2-panel">
                <div className="source-group">
                    <div className="title">Available columns</div>
                    <Table
                        name="available"
                        className="log-actions"
                        table_key={"name"}
                        handleSelect={addQuestionToTarget}
                        headers={{
                            number: { label: "Number", width: "10%" },
                            label: { label: "Label", width: "90%" },
                        }}
                        data={excludedQuestionsWithoutSystemFields}
                    />
                </div>
                <div className="target-group">
                    <div className={"wrap"}>
                        <Button
                            label={"Clear"}
                            onClick={removeAllFromTarget}
                        ></Button>
                        <div className="title">Selected columns</div>
                    </div>
                    <Table
                        name="excluded_questions"
                        className="log-actions"
                        table_key={"name"}
                        handleSelect={removeQuestionFromTarget}
                        headers={{
                            number: { label: "Number", width: "10%" },
                            label: { label: "Label", width: "90%" },
                        }}
                        data={includedQuestionsWithoutSystemFields}
                    />
                </div>
            </div>
        );
    };

    const renderDebugObject = () => {
        if (!showDebugObject) return null;
        return (
            <div className="popup-wrap">
                <DebugTree
                    template={template}
                    instance={showDebugObject}
                    setShowDebugObject={setShowDebugObject}
                />
            </div>
        );
    };

    const renderDoctorInfo = () => {
        if (!showDoctorInfo) return null;
        const props = { showDoctorInfo, setShowDoctorInfo };
        return (
            <div className="popup-wrap">
                <DoctorInfo {...props} />
            </div>
        );
    };

    const renderTemplateSavePDFPopup = () => {
        if (!showSavePDFPopup) return null;
        return (
            <div className="popup-wrap">
                <SaveTemplatePDF
                    template={template_origin}
                    setShowSavePDFPopup={setShowSavePDFPopup}
                />
            </div>
        );
    };

    const renderInstanceSavePDFPopup = () => {
        if (!showInstanceSavePDFPopup) return null;
        const survey = data.find((instance) => {
            return instance.instance_id === showInstanceSavePDFPopup;
        });
        return (
            <div className="popup-wrap">
                <SaveInstancePDF
                    template={template_origin}
                    setShowInstanceSavePDFPopup={setShowInstanceSavePDFPopup}
                    survey={survey}
                    calcColumn={calcColumn}
                />
            </div>
        );
    };

    const onDelete = (instance_id) => {
        dispatch({
            type: "res_delete_cached_instance",
            value: instance_id,
        });
    };

    const renderFooter = () => {
        return (
            <div className="table-footer_v2">
                {Boolean(template?.total_instances) && (
                <>
                    <p>
                        Total number of records:
                        <span className="counter">
                            {template.total_instances}
                        </span>
                    </p>
                    <p>Build: <span className="">{TimestampToDateConverter(process.env.BUILD_ID)}</span></p>
                </>
                )}
                {Boolean(template?.master_instances) && (
                    <p>
                        {"Master " +
                            qHelper.gettitle(
                                { title: template.masterTitle },
                                locale
                            ) +
                            ": "}
                        <span className="counter">
                            {template.master_instances}
                        </span>
                    </p>
                )}
                {Boolean(template?.mapping?.length) &&
                    template.mapping.map((item, index) => {
                        return (
                            <p key={index}>
                                {"Child " +
                                    (index + 1) +
                                    " " +
                                    qHelper.gettitle(
                                        { title: item.title },
                                        locale
                                    ) +
                                    ": "}
                                <span className="counter">
                                    {item.instances}
                                </span>
                            </p>
                        );
                    })}
            </div>
        );
    };

    const renderInstanceDeletePopup = () => {
        if (!showInstanceDeletePopup) return null;
        const survey = data.find((instance) => {
            return instance.instance_id === showInstanceDeletePopup;
        });

        survey.instance_id_db = survey?.instance_id?.split("-").pop();

        return (
            <div className="popup-wrap">
                <DeleteInstance
                    template={template_origin}
                    setShowInstanceDeletePopup={setShowInstanceDeletePopup}
                    survey={survey}
                    onDelete={onDelete}
                />
            </div>
        );
    };
    const renderChartPopup = () => {
        if (!showChartPopUp) return null;

        const customStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "26vw",
                maxWidth: "50vw",
                overflowY: "hidden",
                background: "rgb(255,255,255,1)",
                border: "2px solid black",
            },
            overlay: {
                zIndex: "999",
            },
        };

        const getAnswerValue = (headerQuestion, instanceQuestion) => {
            const choiceOption = headerQuestion?.choices?.find((f) => {
                return (
                    f.value ===
                    (instanceQuestion[chartColumn]?.value ??
                        instanceQuestion[chartColumn])
                );
            });

            const finalValue =
                choiceOption?.calcValue ??
                choiceOption?.code ??
                instanceQuestion[chartColumn]?.value ??
                instanceQuestion[chartColumn];

            return finalValue;
        };

        const questionHeader = allQuestions.find((question) => {
            return question.name === chartColumn;
        });

        const chartTitle = qHelper.gettitle(questionHeader, locale);

        const rawData = pageData
            ?.map((question) => {
                return {
                    instance_answering_person_name:
                        question.instance_answering_person_name,
                    relevant_date: question.relevant_date,
                    value: getAnswerValue(questionHeader, question),
                };
            })
            .filter((f) => f.value !== "N/A" && f.value);

        const chartData = {
            title: chartTitle,
            scalesLabels: { xTitle: "Relevant date", yTitle: "Answers" },
            data: rawData,
        };

        return (
            <div className="popup-wrap">
                <Modal
                    className="popup chart"
                    isOpen={showChartPopUp}
                    onRequestClose={() => {
                        setShowChartPopUp(false);
                    }}
                    style={customStyles}
                    contentLabel="Template chart"
                >
                    <ChartPop {...chartData} />
                    <div
                        className="btn close"
                        onClick={() => {
                            setShowChartPopUp(false);
                        }}
                    >
                        <img src={close_img} alt="close" />
                    </div>
                </Modal>
            </div>
        );
    };

    const onColumnPageSelect = (showAllColumns, columns_pages_selected) => {
        let quuestions = [];
        allQuestions.forEach((quest) => {
            if (quest.number) {
                let page = quest.number.split(".")[0];
                if (showAllColumns || page === columns_pages_selected) {
                    quuestions.push(quest.number);
                }
            }
        });
        setShowAllColumnsPages(showAllColumns);
        setQuestionsVisible(quuestions);
    };

    const renderColumnPagesSelector = () => {
        let pages = columnsPages;
        let showAll = showAllColumnsPages;
        const props = {
            pages,
            showAll,
        };
        return <PagesSelector {...props} onSelect={onColumnPageSelect} isV2 />;
    };

    const renderRowsPaginator = () => {
        if (!data?.length) return null;

        let items_count = data?.length ?? 0;

        let displayed_pages_count = 3;
        let curr_page = page;
        if (items_count === 0 || (itemsOnPage < 0 && page > 1)) {
            curr_page = 1;
            setPage(1);
        } else {
            let pages_count = Math.ceil(items_count / itemsOnPage);
            if (pages_count < page) {
                curr_page = pages_count;
                setPage(pages_count);
            }
        }
        return (
            <RowsPaginator
                page={curr_page}
                itemsOnpage={itemsOnPage}
                count={items_count}
                displayedPagesCount={displayed_pages_count}
                onChange={(active_page, items_on_page) => {
                    if (page !== active_page) setPage(active_page);
                    if (itemsOnPage !== items_on_page) {
                        setItemsOnPage(items_on_page);
                    }
                }}
                isV2
            />
        );
    };

    const renderColumnsCalcResultButton = () => {
        if (!data?.length) return null;
        return (
            <Button
                label="Calculated Result Columns"
                name="calc_result_columns_button"
                onClick={() => {
                    setShowPatAgeSetPopup(true);
                }}
                customClass="calculated-result_v2 custom_button_v2"
            />
        );
    };
    const renderMerging = () => {
        if (!showMergingPopUp) return null;
        const customStyles = {
            content: {
                top: 0,
                left: 0,
                right: "auto",
                bottom: "auto",
                maxHeight: "100vw",
                maxWidth: "100vw",
                overflowY: "hidden",
                background: "rgb(255,255,255,1)",
                border: "2px solid black",
            },
            overlay: {
                zIndex: "999",
            },
        };
        return (
            <div className="popup-wrap">
                <Modal
                    className="popup merging"
                    isOpen={showMergingPopUp}
                    style={customStyles}
                >
                    <MergeTemplateEditor
                        anonymousMode={anonymousMode}
                        onlySCF={onlySCF}
                        onClose={(
                            need_refresh,
                            changed_child_rows,
                            new_virtual_template
                        ) => {
                            setShowMergingPopUp(false);
                            if (changed_child_rows || need_refresh) {
                                setVirtualTemplate(new_virtual_template);
                                let virtual_templates_tmp =
                                    virtual_templates.slice();
                                if (Array.isArray(virtual_templates_tmp)) {
                                    virtual_templates_tmp.forEach(
                                        (template, index) => {
                                            if (
                                                template.title ===
                                                new_virtual_template.title
                                            ) {
                                                virtual_templates_tmp[index] =
                                                    new_virtual_template;
                                            }
                                        }
                                    );
                                    store.dispatch({
                                        type: "reset_virtual_templates",
                                        value: virtual_templates_tmp,
                                    });
                                }
                                store.dispatch({
                                    type: "set_show_virtual",
                                    value: new_virtual_template.title,
                                });
                                setIsMergingChanged(true);
                            }
                        }}
                    ></MergeTemplateEditor>
                </Modal>
            </div>
        );
    };

    const renderColumnsCalcSettings = () => {
        if (!showPatAgeSetPopup) return null;
        const customStyles = {
            content: {
                // top: "20vh",
                // left: "20vw",
                // right: "auto",
                // bottom: "auto",
                maxHeight: "400px",
                maxWidth: "500px",
                overflowY: "hidden",
                background: "rgb(255,255,255,1)",
                border: "1px solid gray",
                borderRadius: "15px",
            },
            overlay: {
                zIndex: "999",
            },
        };
        return (
            <div className="popup-wrap">
                <Modal
                    className="popup age-settings"
                    isOpen={showPatAgeSetPopup}
                    style={customStyles}
                >
                    <div className={"popup-content age-settings-content"}>
                        <DivT
                            className="title"
                            label="Calculated result columns options"
                        />
                        <div className={"age-settings-columns"}>
                            <div>
                                {renderCalcColumns()}
                                {renderCalcType()}
                                {renderCalcColumnAfter()}
                                <Toggle
                                    name="hide_calc_column"
                                    label="Hide"
                                    value={calcColumnTmp?.selected?.hide}
                                    onChange={() => {
                                        let tmp = { ...calcColumnTmp };
                                        tmp.selected.hide =
                                            !tmp?.selected?.hide;
                                        setCalcColumnTmp(tmp);
                                    }}
                                />
                            </div>
                            {renderOperCalcColumns()}
                        </div>

                        <div className={"tooltip"}>
                            <Button
                                label="Cancel"
                                onClick={() => {
                                    setCalcColumnTmp(calcColumn);
                                    setShowPatAgeSetPopup(false);
                                }}
                            />
                            <Button
                                label="Save"
                                status={"active"}
                                onClick={save_calc_column}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };
    const renderCalcColumns = () => {
        let options = [];
        getColumnsList().forEach((col, idx) => {
            options.push({
                value: col.name,
                label: col.label,
            });
            if (!calcColumnTmp.selected.name && idx === 0)
                calcColumnTmp.selected = col;
        });
        return (
            <SelectField
                name="column_selector"
                placeholder="Select column"
                label="Result Column Name"
                allowEmpty={false}
                value={calcColumnTmp.selected.name}
                options={options}
                onChange={(params) => {
                    let tmp = { ...calcColumnTmp };
                    let col = tmp.columns.find((f) => f.name === params.value);
                    if (col) tmp.selected = col;
                    setCalcColumnTmp(tmp);
                }}
            />
        );
    };
    const renderCalcType = () => {
        let options = [];
        calcColumnTmp.selected.calc_types.forEach((calc_tp, idx) => {
            let tp = options.find((f) => f.value === calc_tp.name);
            if (!tp)
                options.push({
                    value: calc_tp.name,
                    label: calc_tp.label,
                    tp: calc_tp,
                });
            if (idx === 0 && !calcColumnTmp.selected.selected_calc_type.id)
                calcColumnTmp.selected.selected_calc_type = calc_tp;
        });
        return (
            <SelectField
                name="column_calc_type"
                placeholder="Select calculation"
                label="Calculation type"
                allowEmpty={false}
                value={calcColumnTmp.selected.selected_calc_type.name}
                options={options}
                onChange={(params) => {
                    let tmp = { ...calcColumnTmp };
                    let tp = options.find((f) => f.value === params.value);
                    if (tp) tmp.selected.selected_calc_type = tp.tp;
                    setCalcColumnTmp(tmp);
                }}
            />
        );
    };
    const renderOperCalcColumns = () => {
        let result = [];
        calcColumnTmp.selected.oper_columns.forEach((col) => {
            result.push(renderOperCalcColumnOne(col));
        });
        return <div>{result}</div>;
    };
    const renderOperCalcColumnOne = (col) => {
        let options = [];
        let input_date_questions = searchInArray({
            list: allQuestions,
            filter: col.type,
            fields: ["type"],
        });
        options.push({
            value: "instance_answering_person_bd",
            label: `Birthdate`,
        });
        options.push({
            value: "relevant_date",
            label: `Relevant Date`,
        });
        input_date_questions.forEach((quest) => {
            let nm = quest.name.replace(template.id + "-", "");
            options.push({
                value: nm,
                label: `${quest.number} ${quest.title || nm}`,
            });
        });
        return (
            <SelectField
                key={col.el}
                name="column_calc_type"
                placeholder="Select calculation"
                label={`${col.label}`}
                allowEmpty={false}
                value={col.name}
                options={options}
                onChange={(params) => {
                    let tmp = { ...calcColumnTmp };
                    col.name = params.value;
                    setCalcColumnTmp(tmp);
                }}
            />
        );
    };
    const renderCalcColumnAfter = () => {
        let options = [];
        // options.push ({
        //     value: "-10",
        //     label: `Not show`,
        //     idx: 5,
        // })
        options.push({
            value: "-1",
            label: `After system fields`,
            idx: 5,
        });
        allQuestions.forEach((quest) => {
            if (
                quest.index &&
                quest.type !== calcColumnTmp.selected.type &&
                quest.number
            ) {
                let nm = quest.name.replace(template.id + "-", "");
                options.push({
                    value: nm,
                    label: `${quest.number} ${
                        getText(quest?.title, "") || quest?.label || nm
                    }`,
                    idx: quest.index,
                });
            }
        });
        return (
            <SelectField
                name="column_calc_after"
                placeholder="Select question"
                label="Result Column Position"
                allowEmpty={false}
                value={calcColumnTmp.selected.column_place_after.name}
                options={options}
                onChange={(params) => {
                    let tmp = { ...calcColumnTmp };
                    let option = options.find((f) => f.value === params.value);
                    if (option) {
                        tmp.selected.column_place_after.name = params.value;
                        tmp.selected.column_place_after.idx = option.idx;
                    }
                    setCalcColumnTmp(tmp);
                }}
            />
        );
    };

    const save_calc_column = () => {
        // let templ_orig_tmp = { ...template_origin };
        setCalcColumn(calcColumnTmp);
        setShowPatAgeSetPopup(false);
        let allquest_tmp = [...allQuestions];
        allquest_tmp = allquest_tmp.filter(
            (quest) => quest.type !== calcColumnTmp.selected.type
        );
        let calc_quest_new = {
            index: calcColumnTmp.selected.column_place_after.idx + 1,
            label: calcColumnTmp.selected.label,
            name: calcColumnTmp.selected.name,
            number: "",
            surveyId: template.id,
            type: calcColumnTmp.selected.type,
        };
        allquest_tmp.splice(
            calcColumnTmp.selected.column_place_after.idx + 1,
            0,
            calc_quest_new
        );
        setAllQuestions(allquest_tmp);
        data_source_quest.save_calc_column(calcColumnTmp, (err, res) => {
            if (err) {
                console.log("Failed to save template");
                console.log(err);
            }
        });
    };

    return (
        <Form name="group_report survey_report_h">
            <div className="survey-body sheet">
                {renderSurveyHeader()}
                {renderColumnPagesSelector()}
                {renderSurvey()}
                {renderFieldSelector()}
                {renderDebugObject()}
                {renderDoctorInfo()}
                {renderTemplateSavePDFPopup()}
                {renderInstanceSavePDFPopup()}
                {renderInstanceDeletePopup()}
                {Boolean(template?.pages?.length) && (
                    <div className="paginator-wrap">
                        {renderRowsPaginator()}
                        {renderColumnsCalcResultButton()}
                    </div>
                )}
                {renderFooter()}
            </div>
            {renderChartPopup()}
            {renderMerging()}
            {renderColumnsCalcSettings()}
        </Form>
    );
}
