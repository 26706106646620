import React from "react";
import {
    Box,
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import {
    Draw,
    Title,
    Person,
    PersonAdd,
    PersonAddAlt1,
    Event,
    MapsHomeWork,
    Edit,
} from "@mui/icons-material";
import { colors } from "../../pdf/pdf_layer_colors";
import PdfEditorContext from "../pdf_editor_context";
import PdfToolboxItem from "./pdf_toolbox_item";
import ToolbarLayer from "./pdf_toolbar_layer";

const PdfToolbarMenu = () => {
    const tabPanelStyles = {
        padding: 0,
        height: "260px",
        overflow: "auto",
        borderBottom: 1,
        borderColor: "divider",
    };

    return (
        <PdfEditorContext.Consumer>
            {({
                data,
                activeLayer,
                onActiveLayerIndexChange,
                onDeleteLayer,
                onEditLayer,
                isLocked,
                layerModeToggle,
            }) => (
                <Box>
                    <List>
                        {data.layers.map((layer, index) => {
                            return (
                                <ToolbarLayer
                                    key={index}
                                    index={index}
                                    isLocked={isLocked}
                                    isActive={index === activeLayer}
                                    canDelete={data.layers?.length > 1}
                                    label={layer.placeholder}
                                    color={colors[index]}
                                    onSelectLayer={onActiveLayerIndexChange}
                                    onDeleteLayer={onDeleteLayer}
                                    onEditLayer={onEditLayer}
                                />
                            );
                        })}
                        <ListItem sx={{ padding: 0 }}>
                            <ListItemButton
                                disabled={isLocked}
                                sx={{ padding: "9px 5px" }}
                                disableRipple
                                onClick={layerModeToggle}
                            >
                                <ListItemIcon>
                                    <Edit />
                                </ListItemIcon>
                                <ListItemText primary={"Edit layers"} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                    {!isLocked && (
                        <List style={tabPanelStyles}>
                            <PdfToolboxItem
                                icon={
                                    <Person
                                        sx={{ color: colors[activeLayer] }}
                                    />
                                }
                                label="Full name"
                                type="fullname"
                            />
                            <PdfToolboxItem
                                icon={
                                    <PersonAddAlt1
                                        sx={{ color: colors[activeLayer] }}
                                    />
                                }
                                label="Name"
                                type="name"
                            />
                            <PdfToolboxItem
                                icon={
                                    <PersonAdd
                                        sx={{ color: colors[activeLayer] }}
                                    />
                                }
                                label="Surname"
                                type="surname"
                            />
                            <PdfToolboxItem
                                icon={
                                    <MapsHomeWork
                                        sx={{ color: colors[activeLayer] }}
                                    />
                                }
                                label="Address"
                                type="address"
                            />
                            <PdfToolboxItem
                                icon={
                                    <Event
                                        sx={{ color: colors[activeLayer] }}
                                    />
                                }
                                label="Date"
                                type="date"
                            />
                            <PdfToolboxItem
                                icon={
                                    <Draw sx={{ color: colors[activeLayer] }} />
                                }
                                label="Signature"
                                type="signature"
                            />
                            <PdfToolboxItem
                                icon={
                                    <Title
                                        sx={{ color: colors[activeLayer] }}
                                    />
                                }
                                label="Text"
                                type="text"
                            />
                        </List>
                    )}
                    <Divider />
                </Box>
            )}
        </PdfEditorContext.Consumer>
    );
};

export default PdfToolbarMenu;
