import React, { useEffect, useState, useContext, forwardRef } from "react";
import { Box } from "@mui/material";
import { Stage, Layer, Image } from "react-konva";
import PdfEditorContext from "../pdf_editor_context";
import { colors } from "../../pdf/pdf_layer_colors";
import PdfPageSkeleton from "../../pdf/pdf_page_skeleton";
import PdfElement from "./pdf_element";

const PdfPage = forwardRef((_, ref) => {
    const pdfEditorContext = useContext(PdfEditorContext);

    const [image, setImage] = useState(null);

    useEffect(() => {
        const img = new window.Image();
        img.src =
            process.env.REACT_APP_CONNECT_QUESTIONNAIRE_LINK +
            "/cache/" +
            pdfEditorContext.selectedPageImageUrl;

        img.onload = () => {
            setImage(img);
        };
    }, [pdfEditorContext.selectedPageImageUrl]);

    return (
        <PdfEditorContext.Consumer>
            {({
                onDrop,
                onEditCancel,
                onEditSave,
                onElementUpdate,
                onEditElement,
                activePage,
                activeLayer,
                activeElement,
                data,
                isLocked,
            }) => (
                <Box
                    ref={ref}
                    onDrop={onDrop}
                    onDragOver={(e) => e.preventDefault()}
                >
                    {image ? (
                        <Stage width={image.width} height={image.height}>
                            <Layer>
                                <Image
                                    image={image}
                                    width={image.width}
                                    height={image.height}
                                />
                            </Layer>
                            {data.layers.map((layer, layerIndex) => {
                                return (
                                    <Layer key={layerIndex}>
                                        {data.elements
                                            .filter(
                                                (f) =>
                                                    f.layerid === layer.id &&
                                                    f.page === activePage
                                            )
                                            .map((element, elementIndex) => {
                                                return (
                                                    <PdfElement
                                                        key={elementIndex}
                                                        selected={
                                                            activeElement ===
                                                            element.id
                                                        }
                                                        disabled={
                                                            activeLayer !==
                                                            layerIndex
                                                        }
                                                        locked={isLocked}
                                                        fill={
                                                            colors[layerIndex]
                                                        }
                                                        {...element}
                                                        onEditCancel={
                                                            onEditCancel
                                                        }
                                                        onEditSave={onEditSave}
                                                        onEditElement={
                                                            onEditElement
                                                        }
                                                        onElementUpdate={
                                                            onElementUpdate
                                                        }
                                                    />
                                                );
                                            })}
                                    </Layer>
                                );
                            })}
                        </Stage>
                    ) : (
                        <PdfPageSkeleton />
                    )}
                </Box>
            )}
        </PdfEditorContext.Consumer>
    );
});

export default PdfPage;
