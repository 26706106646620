import React from 'react'
import { withTranslation } from 'react-i18next';

class TextareaField extends React.Component{
    componentDidMount() {
        if (this.props.autoFocus) {
            this.nameInput.focus();
        }
    }
    onKeyDown(event) {
        if (this.props['onEnter'] && event.key==='Enter') {
            this.props.onEnter({name: this.props.name, value: event.target.value}) 
        }
    }
    getNamePostfix() {
        return (this.props.postfix) ? this.props.name + '_' + this.props.postfix : this.props.name + '_edit';
    }
    getWrapClassName() {
        let wrap_class_name = "input field_wrap " + this.getNamePostfix();
        if (this.props.className) wrap_class_name += ' ' + this.props.className;
        return wrap_class_name;
    }
    isDisabled() {
        let disabled = false;
        if (this.props.status && this.props.status === 'disabled') {
            disabled = true;
        }
        return disabled;
    }
    isHidden() {
        let hidden = false;
        if (this.props.status && this.props.status === 'hidden') {
            hidden = true;
        }
        return hidden;
    }
    showError() {
        if(this.props.error && this.props.error['message']) {
            return (<span className="error_tag">{this.props.error.message}</span>)
        } else return null;
    }
    showLabel() {
        const { t } = this.props;
        if (this.props.label) {
            return (
                <label htmlFor={this.getNamePostfix()}>{t(this.props.label)}</label>
            )
        } else {
            return null;
        }
    }
    onChange(event) {
        if (this.props['onChange'])
        this.props.onChange({name: this.props.name, value: event.target.value})
    }
    showInput() {
        const {t} = this.props;
        let value = (this.props.value) ? this.props.value : '';
        let maxlength = (this.props.maxlength) ? this.props.maxlength : 1000;
        return (
            <textarea id={this.getNamePostfix()} name={this.getNamePostfix()} data-tip={this.props.tip}
                    placeholder={this.props.placeholder}
                    label = {t(this.props.label)}
                    autoCorrect="off" 
                    autoCapitalize="none" 
                    autoComplete={this.props.autoComplete || "off"}
                    type={this.props.type}
                    onChange={this.onChange.bind(this)}
                    maxLength={maxlength}
                    value={value}
                    tabIndex={this.props.tabIndex}
                    disabled={this.isDisabled()}
                    />
        )
    }
    render() {
        if (this.isHidden()) {
            return null
        } else {
            return (
                <div className={this.getWrapClassName()}>
                    {this.showLabel()}
                    {this.showInput()}
                    {this.showError()}
                </div>
            )
        }
    }
}

export default withTranslation()(TextareaField);