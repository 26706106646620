import React from 'react';

import Dashboard from './../components/layout/dashboard';

class ProtectedForm extends React.Component {
    showProtected() {
        const main_wrap_class_name = `main-wrap ${this.props.location.pathname.substring(1)}`;
        return (
            <div className={main_wrap_class_name}>
                <Dashboard/>
                <div className="page-body-wrap">
                    <div className="page-body">
                        {this.props.children}
                    </div>
                </div>
                {/* <BusyScreen/> */}
            </div>
        )
    }

    render() {
        //if (auth.isAuth()) 
        return (
            this.showProtected()
        )
       // } else {
        //    this.props.history.push('/logout');
        //}
    }
}

export default ProtectedForm;