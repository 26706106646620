import { useTranslation } from "react-i18next";
import SelectField from './selectfield';

function LanguageSelector() {
    const { i18n } = useTranslation();
    const changeLanguage = (params) => {
        i18n.changeLanguage(params.value);
        localStorage.setItem('vcs_lng', params.value);
    };
    const language_array = []
    Object.keys(i18n.store.data).forEach((lng) => {
        language_array.push({value:lng,label:i18n.store.data[lng].label})
    });
    return (
        <SelectField className="language-selector" name="language" value={i18n.language} options={language_array} onChange={changeLanguage} />
    )

}

export default LanguageSelector;
