 import React, { useState, useEffect } from "react";
import {
    Button, CheckList, DivT, Form, InputField, SelectField,
    Toggle,
} from "../../components";
import CSVReader from 'react-csv-reader'
import auth_connector from "../../api/questionnaire_connector";
import "./instances_importer.css"
import {sys_fields} from "./import_structures";
import { decode } from "../../helpers/base64decode";
import gettitle from "../../helpers/survey/gettitle";
import TableViewMaped from "./table_view_maped";
import { indexToAbc } from "../../helpers/index_to_abc";
import { isNull } from "url/util";
 import CsvSelector from "./csv_selector";
 import OptionsMapedValueSelector from "./options_maped_value_selector";
 import {
    getDropdown,
    isDateTime,
    getText,
    getInputDate,
    getSavedSetup,
     getKey,
     getCsvDataError,
} from "../../helpers/import_instances_helper";
 import { NotificationTemplates as NT } from "./../../components";
 import Modal from "react-modal";
 import ImportCSVSetupTable from "./setup_list";
 import { useTranslation } from "react-i18next";
 import { Tooltip as TooltipBootstrap } from "react-bootstrap";
 import {useHistory} from "react-router-dom";

const InstancesImporterForm = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const curr_mid = parseInt(window?.auth?.mid) || -1;
    const [language, setLanguage] = useState ("default");
    const [selectedScfDb, setSelectedScfDb] = useState ({});
    const [temlatesList, setTemlatesList] = useState ([]);
    const [selectedTemplate, setSelectedTemplate] = useState ({});
    const [csvData, setCsvData] = useState ([]);
    const [csvFile, setCsvFile] = useState ({});
    const [savedOptions, setSavedOptions] = useState({header: [],data: []});
    const [questions, setQuestions] = useState([]);
    const [currQuestion, setCurrQuestion] = useState("");
    const [csvList, setCsvList] = useState([]);
    const [csvFirstIsHeader,setCsvFirstIsHeader]=useState( true);
    const [eachDatasetNewPatient,setEachDatasetNewPatient]=useState( false);
    const [eachDatasetNewTreatment,setEachDatasetNewTreatment]=useState( true);
    const [userInfo,setUserInfo] =useState("")
    const [showSaveOptionsPopup,setShowSaveOptionsPopup]=useState(false)
    const [showSelectOptionsPopup,setShowSelectOptionsPopup]=useState(false)
    const [NAValue,setNAValue] =useState({value:"-10",text: "k.a.",label: "N/A",code:-10})
    const [showErrorsImportPopup,setShowErrorsImportPopup]=useState(false)
    const [showPatientsKeyPopup,setShowPatientsKeyPopup]=useState(false)
    const [patKeyFields, setPatKeyFields] = useState([]);
    const [tablePreviewHeight,setTablePreviewHeight]=useState(false)
    const [beErrorShowed,setBeErrorShowed]=useState(false)


    useEffect (() => {
        let list=[]
        sys_fields.forEach((fld,idx) =>{
            if (fld.pat_key>=0){
                list.push(
                    {
                        key: fld.key,
                        label: fld.label,
                        checked: fld.pat_key===0,
                    }
                )
            }
        })
        setPatKeyFields(list)
    },[])

    useEffect (() => {
        if (!window?.auth) return
        const getTemplatesList = () => {
            auth_connector.get_templates_list_all ((err, res) => {
                if (err) {
                    console.log (err);
                    return;
                }
                const result = [];
                Object.values(res.list).forEach( (el) => {
                    if (parseInt(el.mid)===curr_mid) {
                        result.push ({
                            value: el.id,
                            label: `${el.id} ${el.titel}`,
                        });
                    }
                })
                setTemlatesList (result);
            });
        }

        auth_connector.load_scf_db ((err, data) => {
            if (err) {
                console.log ("Failed to load scf db");
                console.log (err);
                return;
            }
            let current_scf_db = {}
            Object.keys (data.arr_access_scf)
                .forEach (el => {
                    let db = {
                        id: el,
                        label: data.arr_access_scf[el].title,
                        value: data.arr_access_scf[el].mid,
                    }

                    if (curr_mid === data.arr_access_scf[el].mid) {
                        current_scf_db = db
                    }
                })
            setSelectedScfDb (current_scf_db)
            getTemplatesList ();
        });
    }, [curr_mid]);

    const updateSavedSetup = (saved_options) => {
        let new_questions=saved_options.find((f) => f.field==="json_data").value
        let csv_list=[...csvList]
        if (questions.length > 0 ) {
            let questions_tmp=[...questions]
            questions_tmp.forEach((quest) => {
                let new_quest = new_questions.find((f) => f.key === quest.key)
                if (quest.key==="patient_ident_fields") {
                    quest.arr_fields=new_quest.arr_fields
                } else {
                    if (new_quest) {
                        if (new_quest.linked_key) {
                            quest.linked_key = new_quest.linked_key
                            quest.linked_label = new_quest.linked_label
                            quest.show_mode = new_quest.show_mode
                            quest.options = new_quest.options
                            if (new_quest.options?.selecting && new_quest.question_choices) {
                                new_quest.options.selecting = []
                                new_quest.question_choices.forEach((item) => {
                                    new_quest.options?.selecting.push(
                                        {
                                            // label: getText(item.text, language) + (item.code ? ` ( ${item.code} )` : ""),
                                            label: getText(item.text, language),
                                            value: item.value,
                                        }
                                    )
                                })
                            }
                            let csv_row = csv_list.find((f) => f.key === quest.question_title)
                            if (csv_row) {
                                csv_row.is_linked = true
                                if (quest.key === "unique_patient_ident") {
                                    setEachDatasetNewPatient(false)
                                }
                            }
                        } else {
                            quest.show_mode = "short"
                            quest.options = {}
                        }
                    } else {
                        quest.linked_key = null
                        quest.linked_label = ""
                        quest.show_mode = "short"
                        quest.options = {}
                    }
                }
            })
            setQuestions(questions_tmp)
            setCsvList(csv_list)
        }
    }

    const onChangeTemplate = (params) => {
        if (params.value===selectedTemplate.value && !params.force) {
            return
        }
        setSelectedTemplate (params)
        if (params.value) {
            auth_connector.load_template(params.value, false,false,(err, res) => {
                if (err) {
                    console.log(err);
                    return;
                }
                if (res.qarr.arr_fragen) {
                    let survey = {};
                    const jsonString = decode(res.qarr.arr_fragen);
                    try {
                        survey = JSON.parse(jsonString) ?? {};
                    } catch (e) {
                        console.log("Invalid survey payload format",e);
                        NT.error("Invalid survey payload format")
                        return
                    }
                    survey.id = params.value;
                    survey.title = getText(res.qarr.titel,survey.locale);
                    setLanguage(survey.locale)
                    iniQuestions(survey);
                }
            });
        }
    }
    const iniQuestions = (survey) => {
        let quest_array=[];
        sys_fields.forEach((fld,idx) =>{
            quest_array.push(
                {
                    key: fld.key,
                    is_system: "sys",
                    page_num: `0`,
                    page_name: "System",
                    question_id: "",
                    question_num: `0.${idx+1}.`,
                    question_type: "system",
                    question_choices: null,
                    question_choices_find_by: "",
                    question_title: fld.label,
                    question_description: "",
                    linked_key: null,
                    linked_label: "",
                    label: fld.label,
                    start_label: fld.label,
                    show_mode: "short",
                    options: {}
                }
            )
        })
        let srv_quest={
            key: "patient_ident_fields",
            is_system: 'srv',
            arr_fields: [],
            pat_ident_key:"",
            pat_ident_key_separator: "#",
        }
        patKeyFields.forEach((el) => {
            if (el.checked)
                srv_quest.arr_fields.push(el.key)
        })
        quest_array.push(srv_quest)
        survey.pages.forEach( (pg,pgidx) => {
                if (pg.elements) {
                    pg.elements.forEach((el,idx) => {
                        let title=gettitle(el,survey.locale)
                            ??  el.name
                        let dsc=getText(el.description, language) || "no description"
                        quest_array.push (
                            {
                                key: el.name,
                                is_system: "data",
                                page_num: `${pgidx+1}.`,
                                page_name: pg.name,
                                question_id: el.name,
                                question_num: `${pgidx+1}.${idx+1}.`,
                                question_type: el.type,
                                question_choices: el.choices,
                                question_choices_find_by: el.choices ?  "text" : "",
                                question_title: title,
                                question_description: dsc,
                                linked_key: null,
                                linked_label: "",
                                label: `${pgidx+1}.${idx+1}. ${title} (${el.type} : ${el.name})`,
                                start_label:`${pgidx+1}.${idx+1}. ${title} (${el.type} : ${el.name})`,
                                show_mode: "short",
                                options: {}
                            }
                        )
                    })
                }
            }
        )
        quest_array.forEach((q_row) => {
            q_row.options=getOptions(q_row)
        })
        setQuestions(quest_array)
    }

    const uploadFile = (data, fileInfo, originalFile,firstisheader) => {
        setCsvData(data);
        setCsvFile(fileInfo)
        let csv_array=[]
        Object.keys(data[0]).forEach((key,index) => {
            let lbl = (firstisheader ?? csvFirstIsHeader)
                ? `${index} - ${indexToAbc(index+1)} - ${key}`
                : `${index} - ${indexToAbc(index+1)}`
            let values_distinct = []
            data.forEach ((row) => {
                if (typeof row[key]!=="undefined") {
                    if (typeof row[key]==="string" && row[key].includes(",")) {
                        let values_list = row[key].toString().split (",");
                        values_list.forEach ((val) => {
                            if (!values_distinct.includes (val.toString().trim()))
                                values_distinct.push (val.toString().trim())
                        })
                    } else {
                        if (!values_distinct.includes ((row[key] ?? "").toString().trim()))
                            values_distinct.push ((row[key] ?? "").toString ().trim())
                    }
                }
            })
            csv_array.push (
                {
                    key: key,
                    key_transform: `${getKey(key)}`,
                    label: lbl,
                    is_linked: false,
                    values_distinct: values_distinct,
                }
            )
        })
        onClearLinked("short",true)
        setCsvList(csv_array);
    }
    const loadSavedOptions = (needopenpopup) => {
        auth_connector.get_saved_importcsv_setup(csvFile.name,selectedTemplate.value,(err, res) => {
            if (err) {
                setShowSaveOptionsPopup(false);
                NT.error("Something went wrong")
                return
            }
            let saved_options={header: [],data: []}
            if (res) {
                if (res.length>0) {
                    saved_options = getSavedSetup([res[0]], selectedScfDb, temlatesList, language).data[0]
                    let data=saved_options.find((f) => f.field==="json_data")
                    if (data) {
                        let pat_ident=data.value.find((f) => f.key==="patient_ident_fields")
                        if (pat_ident) {
                            let patKeyFields_tmp=[...patKeyFields]
                            patKeyFields_tmp.forEach((el) => {
                                    el.checked=pat_ident.arr_fields.includes(el.key)
                            })
                            setPatKeyFields(patKeyFields_tmp)
                        }
                    }
                }
                else {
                    NT.error("There aren't matching schemas")
                    return
                }

            }
            setSavedOptions(saved_options)

            if (needopenpopup) {
                setShowSelectOptionsPopup(true)
            } else {
                updateSavedSetup(saved_options)
            }
        })

    }

    const onUnMapValue = (key) => {
        let csvList_tmp = [...csvList]
        let questions_tmp = [...questions]
        let question=questions_tmp.find ((f) => f.key === key)
        if (question) {
            question.linked_key = null
            question.linked_label = ""
            csvList_tmp.forEach((el) => {
                let linked = questions_tmp.find((f) => f.linked_key===el.key)
                el.is_linked=!!linked
            })
            // if (key==="unique_patient_ident") {
            //     setEachDatasetNewPatient(true)
            // }
        }
        setCsvList(csvList_tmp)
        setQuestions(questions_tmp)
    }

    const loadOptionsStatus = () => {
        let res = "disabled"
        if (csvData.length>0) {
            res = "active"
        }
        return res
    }
    const saveOptionsStatus = () => {
        let res = "disabled"
        if (csvData.length>0 && questions.length>0 && questions.find((f) => f.linked_key)) {
            res = "active"
        }

        return res
    }

    const saveStatus = () => {
        if (!window.auth) return "disabled"

        return "active"
        //let errors=getErrorsForImport()
       // return errors.length===0 ? "active" : "disabled"
    }
    const getErrorsForImport = () => {
        let errors=[]
        if (questions.length===0) {
            errors.push({text: t("Template is not selected"),details: []})
        }
        if (!csvFile?.name) {
            errors.push(t("File is not selected"))
        }
        let is_data_linked=false
        let pat_ident_fields=questions.find((f) => f.key==="patient_ident_fields").arr_fields
        let pat_ident_fld_linked=    pat_ident_fields.length>0
        let csv_data_errors=[]
        questions.forEach((f) => {
            let err=getCsvDataError(f, csvData, NAValue)
            if (err.question) {
                csv_data_errors.push(err)
            }
            if (f.is_system==='sys') {
                if (pat_ident_fld_linked) {
                    if (pat_ident_fields.includes(f.key) && !f.linked_key)
                        pat_ident_fld_linked=false
                }
            } else {
                if (f.linked_key)
                    is_data_linked=true
            }
        })
        if (!is_data_linked) {
            errors.push({text: t("There are no linked data"),details: []})
        }
        if (!pat_ident_fld_linked) {
                errors.push({text: t("Some fields assigned to define a patient identifier are not linked"),details: []})
        }
        if (csv_data_errors.length>0) {
            errors.push({text: t("There is errors in csv data"),details: csv_data_errors})
        }
        return errors
    }
    const linkMatchedStatus = () => {
        let res = "disabled"
        if (csvFirstIsHeader && csvData.length>0 && questions.length>0) {
            res = "active"
        }

        return res
    }
    const ExpandCollapsetatus = () => {
        let res = "hidden"
        if (questions.length>0) {
            if (questions.length > 0) {
                let is_options = questions.find((f) => showedOptions(f))
                if (is_options)
                    res = "active"
            }
        }
        return res
    }
    const clearLinkedStatus = () => {
        let res = "disabled"
        if (questions.length>0) {
            let data = questions.find((f) => {
                return (
                    !isNull(f.linked_key)
                )
            })
            if (csvData.length > 0 && questions.length > 0 && data) {
                res = "active"
            }
        }
        return res
    }


    const getAnswers = (data) => {
        let result={}
        Object.keys(data).forEach((key)=>{
            let quest=questions.find((f) => {return (f.linked_key===key && f.is_system==='data')})
            if (quest) {
                if (data[key]===NAValue.text){
                    result[quest.key+"_nda"]=true
                } else {
                    if (isDateTime(quest)) {
                        let dt = getInputDate(data[key], quest.options.date_format, quest.options.date_delimiter)
                        if (result[quest.key] !== "###" && dt)
                            result[quest.key] = dt
                    } else if (quest.question_choices) {
                        if (quest.question_type === "checkbox") {
                            if (data[key]) {
                                let val_array = data[key].toString().split(",")
                                let is_na=false
                                let items = []
                                val_array.forEach((val) => {
                                    let val_el = quest.options.maped_values.find((f) => f.csv_value === val.toString().trim())
                                    if (val_el.choice.value) {
                                        items.push(val_el.choice.value)
                                        if (val_el.choice.value===NAValue.value)
                                            is_na=true
                                    }
                                })
                                if (is_na===true)
                                    result[quest.key+"_nda"]=true
                                else if (items.length > 0)
                                    result[quest.key] = items
                            }
                        } else {

                            let data_key=""
                            if (data[key])
                                data_key=data[key].toString().trim()
                            else if (data[key]===0 || data[key]==="0")
                                data_key="0"
                            let el = quest.options.maped_values.find((f) => f.csv_value?.toString().trim() === data_key)
                            if (el?.choice?.value) {
                                if (el?.choice?.value===NAValue.value)
                                    result[quest.key+"_nda"]=true
                                else
                                    result[quest.key] = el.choice?.value
                            }
                        }
                    } else {
                        if (data[key] || data[key]===0 || data[key]==="0")
                            result[quest.key] = data[key]
                    }
                }
            }
        })
        return result
    }


    const getNewInstance = (survey,row) => {
        let result={...survey}
        result.astatus=100
        result.au_aid=-1
        result.struct_type="instance"
        result.dest_entid=window.auth.pro_zo_entid//getPatients(row)
        result.arr_antworten =getAnswers(row)
        result.import_info={}
        let pat_key_fields=questions.find((f) => {return (f.key==="patient_ident_fields" && f.is_system==="srv")})
        let quest_pat_ident=questions.find((f) => {return (f.key==="unique_patient_ident" && f.is_system==="sys")})
        result.import_info[quest_pat_ident.key]=""
        sys_fields.forEach((fld) => {
            let quest=questions.find((f) => {return (f.key===fld.key && f.is_system==='sys')})
            if (pat_key_fields?.arr_fields.includes(fld.key)) {
                if (result.import_info[quest_pat_ident.key])
                    result.import_info[quest_pat_ident.key]+=pat_key_fields?.pat_ident_key_separator || "#"
                if (fld.key === "patient_birthday") {
                    result.import_info[quest_pat_ident.key] += getInputDate(row[quest.linked_key], quest.options.date_format, quest.options.date_delimiter)
                } else {
                    let str=row[quest.linked_key]
                    if (typeof str==="string")
                        str = str.replace(' ','_')
                    result.import_info[quest_pat_ident.key] += str ?? ""
                }
            }
            if (quest && quest.key!=="unique_patient_ident") {
                Object.keys(row).forEach((row_key) => {
                    if (row_key === quest.linked_key) {
                        if (fld.key === "patient_birthday") {
                            result.import_info[fld.key] = getInputDate(row[row_key], quest.options.date_format, quest.options.date_delimiter)
                        } else {
                            result.import_info[fld.key] = row[row_key] ?? ""
                        }
                    }

                })
            }
            if (!result.import_info[fld.key] || result.import_info[fld.key]==="###")
                result.import_info[fld.key]=""
        })
        return result
    }

    const onLoadOptions = () => {
        loadSavedOptions(false)
        //setShowSelectOptionsPopup(true)
    }
    const onSaveOptions = () => {
        if (csvFile && questions.length>0) {
            setShowSaveOptionsPopup(true);
        }
    }
    const onConfirmSaveOptions = () =>{
        let data=JSON.stringify(questions)
        auth_connector.save_importcsv_setup(csvFile.name,selectedTemplate.value,userInfo,data,(err, res) => {
            if (!err) {
                setShowSaveOptionsPopup(false);
                NT.success_d("Saved", 1);
            } else {
                setShowSaveOptionsPopup(false);
                NT.error("Something went wrong")
            }
        })
    }
    const onImport = () => {
        let errors =getErrorsForImport()
        if (errors.length>0){
            setShowErrorsImportPopup(true)
            return
        }
        let csv_data=[...csvData]
        auth_connector.get_survey(selectedTemplate.value,(err, res) => {
            if (!err) {
                let surveys=[]
                csv_data.forEach((row,idx) => {
                    if (row.saved && !row.saved.includes("Error")) {
                        return;
                    }
                    let new_answer=getNewInstance(res.survey,row)
                    new_answer.import_info.idx=idx;
                    surveys.push(new_answer)
                })
                /* eslint-disable */
               let header_info= {
                   each_row_new_patient:eachDatasetNewPatient,
                   each_row_new_treatment:eachDatasetNewTreatment,
               }
                /* eslint-enable */
               auth_connector.import_save_bulk(header_info,surveys,(err, res) => {
                   if (!err) {
                       // csv_data.forEach((row,idx) => {
                       //     if (res) {
                       //         let find_row=res.find((f) => { return f.qarr?.import_info?.idx===idx});
                       //         if (find_row?.success) {
                       //             row.saved = `Ok. au_aid: ${find_row.qarr?.au_aid}`
                       //         } else {
                       //             row.saved = `Error: `
                       //         }
                       //     }
                       // })
                       // setCsvData(csv_data)
                       NT.success_d("The import was successful",2)
                   } else {
                       NT.error("Import error")
                   }
               })
           }
       })

   }

   const onLinkMatched = () => {
       if (Array.isArray(csvList) && Array.isArray(questions)) {
           let csv_list=[...csvList]
           let questions_tmp=[...questions]
           questions_tmp.forEach((q_row) => {
               if (!q_row.linked_key) {
                   let csv_row=csv_list.find((f) => f.key===q_row.question_title)
                    if (csv_row) {
                        csv_row.is_linked=true
                        q_row.linked_key=csv_row.key
                        q_row.linked_label=csv_row.label
                        if (q_row.key === "unique_patient_ident") {
                            setEachDatasetNewPatient(false)
                        }
                        q_row.options = getOptions(q_row,csv_row)
                    }
               }
           })
           setCsvList(csv_list)
           setQuestions(questions_tmp)
       }
   }
   const onClearLinked = (show_mode,clear_map) => {
       if (Array.isArray(csvList) && Array.isArray(questions))  {
           if (questions.length > 0 && csvList.length>0) {
               let questions_tmp = [...questions]
               let csv_list=[...csvList]
               questions_tmp.forEach((row) => {
                   row.linked_key = ""
                   row.linked_label = ""
                   if (show_mode)
                       row.show_mode = show_mode
                   if (row.options) {
                       if (clear_map)
                           row.options.maped_values = []
                   }
               })
               csv_list.forEach((row) => {
                   row.is_linked=false
               })
               setCsvList(csv_list)
               setQuestions(questions_tmp);
               setCurrQuestion("")
           }
       }
   }
   const onExpandCollapse = (params) => {
       let questions_tmp=[...questions]
       questions_tmp.forEach((quest) => {
           if (params.name==="expand") {
               quest.show_mode="ext"
           } else {
               quest.show_mode="short"
           }
       })
       setCurrQuestion(questions_tmp)
   }


   // const getTogleNewPatientStatus = () => {
   //     if (questions.length>0) {
   //         let quest = questions.find((f) => f.key === "unique_patient_ident");
   //         return quest?.linked_key ? "disabled" : "enabled"
   //     }
   //     return "disabled"
   // }

   const renderHeader =() => {
       return (
           <div className={"header-wrap"}>
               {/*<div className={"title"}>{selectedScfDb?.label}</div>*/}
                <div className={"title"}>{"Select template"}</div>
                <SelectField
                    name="templates_selector"
                    // placeholder="Select template"
                    allowEmpty={false}
                    value={selectedTemplate.value}
                    options={temlatesList}
                    onChange={onChangeTemplate}
                />
                {renderCsvReader()}
                <Button
                    label="Load last col matchings"
                    status={loadOptionsStatus()}
                    onClick={onLoadOptions}
                />
                <Button
                    label="Save import setup"
                    status={saveOptionsStatus()}
                    onClick={onSaveOptions}
                />


                <Button
                    label="Import"
                    status={saveStatus()}
                    onClick={onImport}
                />

            </div>
        )
    }


    const renderCsvReader = () => {
        const parseCSVReaderOptions = {
            header: csvFirstIsHeader,
            dynamicTyping: true,
            skipEmptyLines: true,
            //transformHeader: header => header//</header>getKey(header)
        }
        const parseCSVReaderErrors = (params) => {
            console.log('Error parse CSV file', params);
        }
        return (
            <CSVReader
                cssClass="csv-reader-input"
                onFileLoaded={uploadFile}
                onError={parseCSVReaderErrors}
                parserOptions={parseCSVReaderOptions}
                inputId="csvreader"
                inputName="csvreader"
                inputStyle={{ color: 'navy' }}
                disabled={!selectedTemplate.value}
            />
        )
    }

    const renderHeaderToolBar = () => {
        return (
            <div className={"header-wrap row2"}>
                <Toggle
                    label="First row is header"
                    name="firstisheader"
                    value={csvFirstIsHeader}
                    onChange={() => {
                        setCsvFirstIsHeader(!csvFirstIsHeader);
                        uploadFile(csvData,csvFile,csvFile,!csvFirstIsHeader)
                    }}
                />
                <Button
                    label="Patient key"
                    status={"active"}
                    onClick={()=> {setShowPatientsKeyPopup(true) }}
                />
                <Button
                    label="Auto match cols"
                    status={linkMatchedStatus()}
                    onClick={onLinkMatched}
                />
                <Button
                    label="Clear matches"
                    status={clearLinkedStatus()}
                    onClick={() => {onClearLinked("",false)}}
                />
                {/*<Toggle*/}
                {/*    label="Each dataset - new Patient"*/}
                {/*    name="eachdatasetnewpatient"*/}
                {/*    value={eachDatasetNewPatient}*/}
                {/*    onChange={() => {*/}
                {/*        setEachDatasetNewPatient(!eachDatasetNewPatient);*/}
                {/*    }}*/}
                {/*    status = {getTogleNewPatientStatus()}*/}
                {/*/>*/}
                <div></div>
                <Toggle
                    label="Each dataset - new Treatment"
                    name="eachdatasetnewtreatment"
                    value={eachDatasetNewTreatment}
                    onChange={() => {
                        setEachDatasetNewTreatment(!eachDatasetNewTreatment);
                    }}
                />

                <Button
                    name={"expand"}
                    label="Expand all"
                    status={ExpandCollapsetatus()}
                    onClick={onExpandCollapse}
                />
                <Button
                    name={"collapse"}
                    label="Collapse all"
                    status={ExpandCollapsetatus()}
                    onClick={onExpandCollapse}
                />
                <InputField
                    name={"na-value"}
                    className={"na-value"}
                    label="N/A value"
                    onChange={(params) => {
                        let obj={...NAValue}
                        obj.text=params.value;
                        setNAValue(obj);
                    }}
                    value={NAValue.text}
                />
                <Toggle
                    label="Table preview height - auto"
                    name="tableprewiewheight"
                    value={tablePreviewHeight}
                    onChange={() => {
                        setTablePreviewHeight(!tablePreviewHeight);
                    }}
                />
            </div>
        )
    }

    const renderQuestionsListHeader = () => {
        let questArray=[]
        if (questions.length>0) {
            questArray.push (renderQuestionHeaderRow ())
        }

        return (<div className={"quest-context-hdr"}>
                {questArray}
            </div>
        )
    }

    const renderQuestionsList = () => {
        let questArray=[]
        if (questions.length>0) {
            questions.forEach ((row) => {
                questArray.push (renderQuestionRow (row))
            })
        }

        return (<div className={"quest-list"}>
                    <div className={"quest-context"}>
                    {questArray}
                    </div>
                </div>
        )
    }

    const renderQuestionHeaderRow = () => {
        return (
            <div key={"questions-header"} className={"quest-row hdr"}>
                <DivT title={"Template"} className={"quest-col-hdr"} />
                <DivT title={"CSV"} className={"quest-col-hdr"} />
                <DivT title={"Options"} className={"quest-col-hdr"} />
                <div/>
            </div>
        )
    }
    const renderQuestionRow = (row) => {
        let link_class = "like-link" + (row.linked_key ? " maped" : " unmaped")
        let unlink_status = row.linked_key ? "" : "hidden"
        if (row.key === currQuestion && !row.linked_key)
            link_class += " ready"
        if (row.is_system !== 'data')
            link_class += " sys"
        return (
            <div key={row.key} className={"quest-row"}>
                <TooltipBootstrap
                    key={row.key}
                    title={row.label + "\n" + row.question_description}>
                    <Button name={row.key}
                            className={link_class}
                            label={row.label}
                            onClick={() => {
                                setCurrQuestion(currQuestion === row.key ? "" : row.key)
                            }}
                    />
                </TooltipBootstrap>
                {renderCsvFieldsSelector(row)}

                <Button key={row.key + "-unlink"}
                        name={row.key + "-unlink"}
                        className={"like-link unlink"}
                        label={"unlink"}
                        status={unlink_status}
                        onClick={() => onUnMapValue(row.key)}
                />
                <div>
                    {renderOptions(row)}
                </div>

            </div>
        )
    }

    const renderCsvFieldsSelector = (row) => {
        if (row.key===currQuestion || row.linked_key) {
            return (
                <CsvSelector
                    row_key={row.key}
                    onChange={onChangeCsvField}
                    value={row.linked_key ?? ""}
                    csvList={csvList}
                />
            )
        } else {
            return (
                <div></div>
            )
        }
    }
    const onChangeCsvField  = (params) => {
        if (questions.length>0) {
            let questions_tmp = [...questions]
            let csvlist_tmp = [...csvList]
            let quest = questions_tmp.find((f) => f.key === params.row_key)
            if (quest) {
                let csv = csvList.find((f) => f.key === params.val)
                if (csv) {
                    quest.linked_key = csv.key
                    quest.linked_label = csv.label
                }
                if (params.row_key === "unique_patient_ident") {
                    setEachDatasetNewPatient(false)
                }
                quest.options = getOptions(quest,csv)
            }
            csvlist_tmp.forEach((el) => {
                let linked = questions_tmp.find((f) => f.linked_key === el.key)
                el.is_linked = !!linked
            })
            setCsvList(csvlist_tmp)
            setQuestions(questions_tmp)
        }
    }

    const getOptions = (quest,csv) => {
        let result={maped_values: [],selecting: []}
        let csv_row={...csv}
        if (!csv?.key)
            csv_row=csvList.find((f) => f.key===quest.key)
        if (csv_row?.key) {
            if (quest.question_choices) {
                csv_row.values_distinct.forEach((el) => {
                    if (el === NAValue.text ) {
                        result.maped_values.push(
                            {
                                csv_value: el,
                                choice: NAValue,
                            }
                        )
                    } else if (el || el === 0)  {
                        let find_el = getDropdown(el, quest.question_choices, language)
                        result.maped_values.push(
                            {
                                csv_value: el,
                                choice: find_el,
                            }
                        )
                    }
                })
                quest.question_choices.forEach((item) => {
                    result.selecting.push(
                        {
                            // label: getText(item.text, language) + (item.code ? ` ( ${item.code} )` : ""),
                            label: getText(item.text, language) ,
                            value: item.value,
                        }
                    )
                })
            } else if (isDateTime(quest)) {
                result = quest.options
                let defaults_format = "yyyy-mm-dd"
                let delmiter = "-"
                if (!result.date_format) {
                    result.date_format = defaults_format
                    result.date_delimiter = delmiter
                }
                result.is_data_error = ""
                csv_row.values_distinct.forEach((val) => {
                    if (!result.is_data_error &&  val!==NAValue.text) {
                        let dt = getInputDate(val, result.date_format, result.date_delimiter)
                        if (dt === "###") {
                            result.is_data_error = "There are values other than the date"
                        }
                    }
                })
            }
        }

        return result;
    }


    const onOptions = (key) => {
        let questions_tmp=[...questions]
        let quest = questions_tmp.find((f) => f.key===key)
        if (quest) {
            if (quest.show_mode==="short")
                quest.show_mode="ext"
            else
                quest.show_mode="short"

            setQuestions(questions_tmp)
        }
    }

    const showedOptions = (row) => {
        let result = false
        if (row.linked_key) {
            if (row.question_choices)
                result = true
            if (isDateTime(row))
                result = true
        }
        return result
    }

    const renderOptions = (row) => {
        let err_class = getCsvDataError(row, csvData,NAValue).question ? " error" : ""
        if (showedOptions(row)) {
            return (
                <div className={"options"+err_class}>
                    <Button key={row.key + "-options"}
                            name={row.key + "-options"}
                            className={"like-link options"}
                            label={row.show_mode === "short" ? "Show options" : "Hide options"}
                            onClick={() => onOptions (row.key)}
                    />
                    {renderOptionsContext (row)}
                </div>
            )
        }
    }

    const onChangeMaped = (params) => {
        let questions_tmp = [...questions]
        let quest = questions_tmp.find((f) => f.key === params.row_key)
        if (quest) {
            let val = quest?.options?.maped_values.find((f) => f.csv_value === params.map_val)
            if (val) {
                if (!params.val) {
                    val.choice = {}
                } else if (params.val===NAValue.value) {
                    val.choice=NAValue
                } else {
                    let choice = quest.question_choices.find((f) => f.value === params.val)
                    if (choice) {
                        val.choice = choice
                    }
                }
            }
            setQuestions(questions_tmp)
        }
    }
    const renderOptionsContext = (row) => {
        if (row.show_mode === "ext") {
            if (isDateTime(row)) {
                return (
                    <div className={"options-context date"}>
                        <InputField
                            name={row.key}
                            className={"date-formet"}
                            label="Date format"
                            onChange={(params) => {
                                let quest_tmp = [...questions]
                                let question = quest_tmp.find ((f) => f.key === row.key)
                                if (question) {
                                    question.options.date_format = params.value
                                }
                                let csv = csvList.find ((f) => f.key === question.linked_key)
                                question.options = getOptions (question,csv)
                                setQuestions (quest_tmp)
                            }}
                            value={row.options.date_format}
                        />
                        <InputField
                            name={row.key}
                            className={"delimiter"}
                            label="Delimiter"
                            onChange={(params) => {
                                let quest_tmp = [...questions]
                                let question = quest_tmp.find ((f) => f.key === row.key)
                                if (question) {
                                    question.options.date_delimiter = params.value
                                }
                                let csv = csvList.find ((f) => f.key === question.linked_key)
                                question.options = getOptions (question,csv)
                                setQuestions (quest_tmp)
                            }}
                            value={row.options.date_delimiter}
                        />
                        <DivT className={"date-error"} title={row.options.is_data_error ?? ""} />
                    </div>
                )
            } else if (row.question_choices) {
                let map_values_rows = []
                let map_values_hdr = []
                map_values_hdr.push (<div key={row.key+"0"} className={"map-col1"}>{"CSV value"}</div>)
                map_values_hdr.push (<div key={row.key+"1"} className={"map-col2"}></div>)
                map_values_hdr.push(<div key={row.key+"2"} className={"map-col3"}>{"Mapped to"}</div>)
                if (row.options.maped_values) {
                    row.options.maped_values.forEach((val) => {
                        let map_values = []
                        map_values.push(<div key={row.key + val.csv_value + "1"}
                                             className={"map-col col1"}>{val.csv_value}</div>)
                        map_values.push(<div key={row.key + val.csv_value + "2"}
                                             className={"map-col col2"}>{" ->"}</div>)

                        map_values.push(
                            <OptionsMapedValueSelector key={row.key + "-" + val.csv_value}
                                                       row_key={row.key}
                                                       map_value={val.csv_value}
                                                       onChange={onChangeMaped}
                                                       value={val.choice.value ?? ""}
                                                       List={row.options.selecting}
                                                       na_value={NAValue}
                            />
                        )
                        let class_name= "map-row"
                        if (!val.choice.value)
                            class_name+=" not-found"
                        if (val.choice.value===NAValue.value)
                            class_name+=" na-answer"
                        map_values_rows.push(
                            <div key={row.key + val.csv_value + "-map"}
                                 className={class_name}>
                                {map_values}
                            </div>
                        )
                    })
                }
                return (
                    <div className={"options-context dropdown"}>
                        <div key={row.key+"header-map"} className={"map-row hdr"}>
                            {map_values_hdr}
                        </div>
                        {map_values_rows}
                    </div>
                )
            }
        }
    }

    const saveOptionsPopup = () => {
        if (!showSaveOptionsPopup) return null;
        const customStyles = {
            content: {
                top: "40%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "100vh",
                overflowY: "auto",
                borderRadius: "15px",
            },
            overlay: {
                background: "rgba(72, 72, 72, 0.7)",
                zIndex: "999999",
            }
        };
        return (
            <Modal
                isOpen={showSaveOptionsPopup}
                onRequestClose={() => { setShowSaveOptionsPopup(false) }}
                style={customStyles}
                contentLabel="Save current setup"
            >
                <div className="popup-content imp setup save">
                    <DivT className="title" label="Save current setup" />
                    <DivT className="label" label="Enter user info" />
                    <InputField
                        //label="Enter user info"
                        name="user_info"
                        onChange={(input) => {
                            setUserInfo(input.value)
                        }}
                        value={userInfo}
                    />
                    <div
                       // style={{ justifyContent: "space-evenly", display: "flex" }}
                    >
                        <Button label="Cancel" onClick={() => { setShowSaveOptionsPopup(false) }} />
                        <Button label="Save" status={"active"} onClick={onConfirmSaveOptions} />

                    </div>
                </div>
            </Modal>
        )

    }
    const selectOptionsPopup = () => {
        if (!showSelectOptionsPopup) return null;
        const customStyles = {
            content: {
                top: "40%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                maxWidth: "90vw",
                overflowY: "auto",
                borderRadius: "15px",
            },
            overlay: {
                background: "rgba(72, 72, 72, 0.7)",
                zIndex: "999999",
            }
        };
        return (
            <Modal
                isOpen={showSelectOptionsPopup}
                onRequestClose={() => { setShowSelectOptionsPopup(false) }}
                style={customStyles}
                contentLabel="Import setup selector"
            >
                <div className="popup-content imp setup select">
                    <DivT className="title" label="Select import setup" />
                    <ImportCSVSetupTable
                        List={savedOptions}
                        onSelect={onSelectSetupRow }
                    />
                    <div
                        style={{ justifyContent: "space-evenly", display: "flex" }}
                    >
                        <Button label="Cancel" onClick={() => { setShowSelectOptionsPopup(false) }} />
                    </div>
                </div>
            </Modal>
        )

    }

    const onSelectSetupRow = (row) => {
        setShowSelectOptionsPopup(false)
    }

    const errorsImportPopup = () => {
        if (!showErrorsImportPopup) return null;
        const customStyles = {
            content: {
                top: "40%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "100vh",
                overflowY: "auto",
                borderRadius: "15px",
            },
            overlay: {
                background: "rgba(72, 72, 72, 0.7)",
                zIndex: "999999",
            }
        };
        const renderImportErrors = () => {
            let err_list=[]
            let errors=getErrorsForImport()
            errors.forEach((err,index) => {
                if (err.details.length>0) {
                    let err_dtl=[];
                    err.details.forEach((el,elidx) => {
                        err_dtl.push(<div key={"el"+elidx} className={"quest-error"}>{`question: ${el.question}`}</div>)
                        if (el.values.length>0) {
                            el.values.forEach((dtl,idx) => {
                                err_dtl.push(<ul key={"dtl"+elidx+"-"+idx}>{`row index: ${dtl.row_idx}, value: ${dtl.value}`}</ul>)
                            })
                        }
                    })
                    err_list.push(
                        <div key={"wrap-Err"+index} className={"wrap-csv-error"} >
                            <div key={"hdr"+index} className={"quest-error-header"}>{t("CSV data errors")}</div>
                            {err_dtl}
                        </div>
                    )
                } else {
                    err_list.push(
                        <div key={"wrap-Err"+index} className={"wrap-common-error"} >
                            <ul key={"txt"+index}>{err.text}</ul>
                        </div>
                    )
                }


            })
            return (
                <div  className={"errors-list"}>
                    {err_list}
                </div>
            )
        }
        return (
            <Modal
                isOpen={showErrorsImportPopup}
                onRequestClose={() => { setShowErrorsImportPopup(false) }}
                style={customStyles}
                contentLabel="You can't Import now"
            >
                <div className="popup-content imp-err-list">
                    <DivT className="title" label="Import errors" />
                    {renderImportErrors()}
                    <div
                        style={{ justifyContent: "space-evenly", display: "flex" }}
                    >
                        <Button label="Cancel"
                               // status = "active"
                                onClick={() => { setShowErrorsImportPopup(false) }}
                        />
                    </div>
                </div>
            </Modal>
        )

    }

    const patientsKeyPopup = () => {
        if (!showPatientsKeyPopup) return null;
        const customStyles = {
            content: {
                top: "30%",
                left: "20%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "100vh",
                overflowY: "auto",
                borderRadius: "15px",
            },
            overlay: {
                background: "rgba(72, 72, 72, 0.7)",
                zIndex: "999999",
            }
        };

        return (
            <Modal
                isOpen={showPatientsKeyPopup}
                onRequestClose={() => { setShowPatientsKeyPopup(false) }}
                style={customStyles}
                contentLabel="Select fields to generate patient key"
            >
                <div className="popup-content patients-key">
                    <DivT className="title" label="Fields to generate a patient key" />
                    <CheckList
                        list={patKeyFields}
                        onChangeChecked={onChangeCheckedFields}
                    />
                    <div
                        style={{ justifyContent: "space-evenly", display: "flex" }}
                    >
                        {/*<Button label="Save" onClick={onSavePatientsKey} />*/}
                        <Button label="Close"
                            // status = "active"
                                onClick={() => { setShowPatientsKeyPopup(false) }}
                        />
                    </div>
                </div>
            </Modal>
        )
    }

    const onChangeCheckedFields = (params) => {
        let patKeyFields_tmp=[...patKeyFields]


        let item= patKeyFields_tmp.find((f) => f.key===params.key)
        if (item) {
            item.checked=params.checked
        }
        let question_tmp=[...questions]
        let quest=question_tmp.find((f)  => f.key==="patient_ident_fields" && f.is_system==='srv')
        if (quest){
            quest.arr_fields=[]
            patKeyFields_tmp.forEach((el) => {
                if (el.checked)
                    quest.arr_fields.push(el.key)
            })
            setQuestions(question_tmp)
        }
        setPatKeyFields(patKeyFields_tmp)
    }

    // const onSavePatientsKey = (row) => {
    //     setShowPatientsKeyPopup(false)
    // }



    // const stub=1
    // if (stub===1)
    // {
    //     return (
    //             <div className={"stub"}>Under constraction...</div>
    //         )
    // }
    if (!window?.auth?.mid) {
        if  (!beErrorShowed) {
            setBeErrorShowed(true)
            NT.error_cb("Import session timeout. Please reenter to the Import page.","",()=> history.goBack())
        }

        return (
            <Form className="instance-import-wrap"
                  classNameContent={tablePreviewHeight ? "height-auto" : ""}
                  title="Instances import"
            >
                {/*<Button*/}
                {/*    label="Back"*/}
                {/*    status={"active"}*/}
                {/*    onClick={() => {*/}
                {/*        history.goBack();*/}
                {/*    }}*/}
                {/*/>*/}

            </Form>
        )
    }
    return (
            <Form className="instance-import-wrap"
                  classNameContent={tablePreviewHeight ? "height-auto" : ""}
                  title="Instances import"
            >
                {renderHeader()}
                {renderHeaderToolBar()}
                {renderQuestionsListHeader()}
                {renderQuestionsList()}
                <TableViewMaped
                    questions={questions}
                    csv_data={csvData}
                    na_value={NAValue}
                    lng={language}
                />
                {saveOptionsPopup()}
                {selectOptionsPopup()}
                {errorsImportPopup()}
                {patientsKeyPopup()}
            </Form>
        )

};

export default InstancesImporterForm;