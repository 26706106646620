import { Button, DivT } from "../../components";
import data_source from "../../api/questionnaire_connector";

export default function DeleteInstance({
  survey,
  setShowInstanceDeletePopup,
  onDelete,
}) {
  return (
    <div className="popup save-pdf">
      <div className="popup-content-wrap">
        <div className="popup-content">
          <DivT
            className="title"
            label="Are you sure you want to delete instance?"
          />
          <DivT
            className="description"
            label={"Instance ID: " + survey.instance_id}
          />
          <DivT
            className="description"
            label={"Patient: " + survey.instance_answering_person_name}
          />
          <div className="controls">
            <Button
              label="Yes"
              onClick={() => {
                data_source.delete_answer(survey.instance_id_db, (err) => {
                  setShowInstanceDeletePopup(false);

                  if (err) {
                    console.log(err);
                  } else {
                    onDelete(survey.instance_id);
                  }
                });
              }}
            />
            <Button
              label="No"
              onClick={() => {
                setShowInstanceDeletePopup(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
