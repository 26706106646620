import { Button, DivT } from "../../components";
import DownloadPDFIinstance from "./../../helpers/survey_pdf/survey_report_pdf";

export default function SaveInstancePDF({
                                            template,
                                            survey,
                                            setShowInstanceSavePDFPopup,
                                            calcColumn,
                                        }) {
    return (
        <div className="popup save-pdf">
            <div className="popup-content-wrap">
                <div className="popup-content">
                    <DivT className="title" label="Export survey to PDF" />
                    <DivT
                        className="description"
                        label={"Survey ID: " + survey.instance_id}
                    />
                    <DivT
                        className="description"
                        label={
                            "Patient: " + survey.instance_answering_person_name
                        }
                    />
                    <div className="controls">
                        <div
                            className="button button-inline"
                            onClick={() => {
                                setShowInstanceSavePDFPopup(false);
                            }}
                        >
                            <DownloadPDFIinstance
                                template={template}
                                survey={survey}
                                calcColumn={calcColumn}
                            />
                        </div>
                        <Button
                            label="Cancel"
                            onClick={() => {
                                setShowInstanceSavePDFPopup(false);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
