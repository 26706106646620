import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
class Table extends React.Component{
    componentDidUpdate() {
        //this.refs.table.cleanSelected();
    }
    handleSelect(row,isSelected) {
        if (typeof this.props.handleSelect === 'function') {
            if (isSelected) {
                this.props.handleSelect(row[this.props.table_key],row);
            } else {
                this.props.handleSelect();
            }
        }
    }
    render() {
        let wrap_className = "table_wrap " + this.props.name + ' ' + this.props.className;
        const selectRow = {
            mode: 'radio',  // multi select
            clickToSelect: true,
            onSelect: this.handleSelect.bind(this),
            hideSelectColumn: true,
            selected: [ this.props.selected ],
            className: 'selected'
        };
        //TODO: col_width obsolete, need to refactor actual forms
        let col_width = (this.props.col_width) ? this.props.col_width : {};
        let headers = this.props.headers;
        let data = (this.props.data && this.props.data.length > 0) ? this.props.data : [];
        const {t} = this.props;
        if (!this.props.dynamic_width)
        return (
        <div className={wrap_className}>
            <BootstrapTable selectRow={ (typeof this.props.handleSelect === 'function') ? selectRow : {} } hover={true} keyField={this.props.table_key} data={data} striped options={{ noDataText: 'No data' }}>
                {Object.keys(this.props.headers).map(function (key) {
                    return (<TableHeaderColumn className={key} columnClassName={key} key={key} width={col_width[key] || headers[key].width} dataField={key}>{t(headers[key].label)}</TableHeaderColumn>);
                })}
            </BootstrapTable>
        </div>
        )
        else return (
            <div className={wrap_className}>
            <BootstrapTable selectRow={ (typeof this.props.handleSelect === 'function') ? selectRow : {} } hover={true} keyField={this.props.table_key} data={data} striped options={{ noDataText: 'No data' }}>
                {Object.keys(this.props.headers).map(function (key) {
                    return (<TableHeaderColumn className={key} columnClassName={key} key={key} dataField={key}>{t(headers[key].label)}</TableHeaderColumn>);
                })}
            </BootstrapTable>
        </div>
        )
    }
}

export default withTranslation()(Table);
