import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../src/locales/translationEN.json";
import translationDE from "../src/locales/translationDE.json";

// the translations
const resources = {
    en: {
        translation: translationEN,
        label: 'English',
        value: 'en'
    },
    lv: {
        translation: translationDE,
        label: 'German',
        value: 'de'
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        languages: ["en","de"],
        lng: localStorage.getItem('vcs_lng') || 'en',
        debug: false,
        nsSeparator: '::',

        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            useSuspense: false,
        },
    });


i18n.on('languageChanged', function(lng) {
    console.log('language changed to ' + lng);
});

export default i18n;
