import React from 'react';
import { withTranslation } from 'react-i18next';

class SubFormDetails extends React.Component {
    showTitle() {
        const {t} = this.props;
        if (this.props.title) {
            return (
                <h2>{t(this.props.title)}</h2>
            )
        } else {
            return null;
        }
    }
    render() {
        let className = "subform";
        if(this.props.className !== undefined){
            className = className + ' ' + this.props.className
        }
        if (this.props.is_hidden) {
            return null
        } else {
            return (
                <div className={className}>
                    {this.showTitle()}
                    {this.props.children}
                </div>
            )
        }
    }
}

export default withTranslation()(SubFormDetails);