import { Button } from '../../components';
import React, { useEffect, useState } from "react";
import data_source from './survey_connect'

export default function DebugTree({ showDoctorInfo, setShowDoctorInfo }) {
    const [doctorInfo, setDoctorInfo] = useState(null);

    useEffect(()=>{
        data_source.get_doctor_info(showDoctorInfo, (err,res)=>{
            if (err) return
            setDoctorInfo(res.api_data)
        })
    },[showDoctorInfo])
    if (!doctorInfo?.id) return null;
    return (
        <div className="popup doctor-info-wrap">
            <div className="popup-content flex-column">
                <div className='doctor-info'>
                   <div className='title'>Doctor info</div>
                   <div className='description'>ID: {doctorInfo.id}</div>
                   <div className='description'>Name: {doctorInfo.zaname}</div>
                   <div className='description'>Email: {doctorInfo.email}</div>
                </div>
                <div className='toolbar'>
                    <Button label="Close" onClick={() => { setShowDoctorInfo(null) }} />
                </div>
            </div>
        </div>
    )
}

