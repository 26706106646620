import { useEffect, useRef, useContext, useState } from "react";
import { Box, Fade, IconButton } from "@mui/material";
import { DragIndicator, Lock, LockOpen } from "@mui/icons-material";
import PdfEditorContext from "../pdf_editor_context";
import PdfToolbarMenu from "./pdf_toolbar_menu";
import PdfToolbarLayerEdit from "./pdf_toolbar_layer_edit";
import PdfToolbarElementEdit from "./pdf_toolbar_element_edit";
import PdfToolbarPaginator from "./pdf_toolbar_paginator";
import PdfEditorFooter from "./pdf_toolbar_footer";

const PdfToolbar = () => {
    const pdfEditorContext = useContext(PdfEditorContext);

    const [isVisible, setVisible] = useState(false);

    const margin = 10;

    const selfRef = useRef(null);
    const positionRef = useRef({
        x: margin,
        y: margin,
        prevScrollY: margin,
        offsetScrollY: margin,
    });
    const draggingRef = useRef(false);
    const offsetRef = useRef({ x: 0, y: 0 });

    useEffect(() => {
        if (!selfRef.current) return;

        const positionSelf = selfRef.current.getBoundingClientRect();

        positionRef.current.x = -positionSelf.width - margin;

        selfRef.current.style.transform = `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`;

        setVisible(true);
    }, []);

    useEffect(() => {
        if (!pdfEditorContext.pageRef.current || !selfRef.current) return;

        const handleMouseUp = (event) => {
            if (!draggingRef.current) return;

            draggingRef.current = false;
        };

        document.addEventListener("mouseup", handleMouseUp);

        const handleMouseMove = (event) => {
            if (!pdfEditorContext.pageRef.current || !selfRef.current) return;
            if (!draggingRef.current) return;

            const positionPage =
                pdfEditorContext.pageRef.current.getBoundingClientRect();
            const positionSelf = selfRef.current.getBoundingClientRect();

            positionRef.current.x = Math.max(
                Math.min(
                    event.clientX - offsetRef.current.x,
                    positionPage.right + margin - positionPage.left
                ),
                -positionSelf.width - margin
            );

            positionRef.current.y = Math.max(
                Math.min(
                    event.clientY - offsetRef.current.y,
                    positionPage.height - positionSelf.height - margin
                ),
                margin
            );

            positionRef.current.offsetScrollY = positionRef.current.y;

            selfRef.current.style.transform = `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`;
        };

        document.addEventListener("mousemove", handleMouseMove);

        const handleScroll = () => {
            if (!positionRef.current || !selfRef.current) return;

            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;

            const positionPage =
                pdfEditorContext.pageRef.current.getBoundingClientRect();
            const positionSelf = selfRef.current.getBoundingClientRect();

            const scollUp = scrollTop < positionRef.current.prevScrollY;

            if (scollUp)
                positionRef.current.offsetScrollY = positionRef.current.y;

            positionRef.current.y = Math.max(
                Math.min(
                    scollUp
                        ? positionRef.current.y -
                              (positionRef.current.prevScrollY - scrollTop)
                        : scrollTop + positionRef.current.offsetScrollY,
                    positionPage.height - positionSelf.height - margin
                ),
                margin
            );

            positionRef.current.prevScrollY = scrollTop;

            selfRef.current.style.transform = `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`;
        };

        document.addEventListener("scroll", handleScroll);

        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("scroll", handleScroll);
        };
    }, [pdfEditorContext.pageRef, selfRef]);

    const startDragging = (event) => {
        if (draggingRef.current) return;

        offsetRef.current.x = event.clientX - positionRef.current.x;
        offsetRef.current.y = event.clientY - positionRef.current.y;

        draggingRef.current = true;
    };

    return (
        <PdfEditorContext.Consumer>
            {({ editMode, isLocked, isLockable, onLock }) => (
                <Fade in={isVisible} timeout={2000}>
                    <Box
                        ref={selfRef}
                        sx={{
                            position: "absolute",
                            width: "200px",
                            minHeight: "500px",
                            zIndex: 1,
                            backgroundColor: "whitesmoke",
                            borderRadius: "15px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "5px",
                            filter: "drop-shadow(0.4rem 0rem 0.4rem gray)",
                        }}
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                left: 10,
                                top: 10,
                                cursor: "move",
                            }}
                            onMouseDown={startDragging}
                        >
                            <DragIndicator />
                        </Box>
                        <PdfToolbarPaginator />
                        <Box
                            sx={{
                                position: "absolute",
                                right: 10,
                                top: 10,
                            }}
                        >
                            <IconButton
                                sx={{
                                    padding: 0,
                                    boxShadow: "none !important",
                                    border: "0 !important",
                                }}
                                disableRipple
                                disabled={!isLockable}
                                onClick={onLock}
                            >
                                {isLocked && <Lock />}
                                {!isLocked && <LockOpen />}
                            </IconButton>
                        </Box>
                        <Box sx={{ width: "100%", flexGrow: 1 }}>
                            {editMode === "layer" && <PdfToolbarLayerEdit />}
                            {editMode === "element" && (
                                <PdfToolbarElementEdit />
                            )}
                            {!editMode && <PdfToolbarMenu />}
                        </Box>
                        <PdfEditorFooter />
                    </Box>
                </Fade>
            )}
        </PdfEditorContext.Consumer>
    );
};

export default PdfToolbar;
