export const Calc_column_structure = {
    calculateColumns:
        {
            selected: {},
            columns: [
                {
                    name: "calc_patient_age",
                    label: "Patient age",
                    type: "calccolumnresult",
                    oper_columns: [
                        { el: 1, name: "", type: "inputdate",label:"Column 1" },
                        { el: 2, name: "", type: "inputdate" ,label: "Column 2"},
                    ],
                    calc_types: [
                        { id: 1, name: "years_calculation", label: "Years difference" },
                        { id: 2, name: "days_calculation", label: "Days difference" },
                    ],
                    selected_calc_type: {},
                    column_place_after: { idx: 5, name: "-1" },
                    hide : false
                }
            ],
        }
}

export function getColumnsList() {
    return Calc_column_structure.calculateColumns.columns
}

export function  showCalcColumn (calc_column) {
    let res= false
    if (calc_column?.selected?.column_place_after?.name) {
        if (calc_column?.selected?.hide)
            return false;
    }
    let columns_names = false
    if (calc_column?.selected?.oper_columns) {
        if (Array.isArray(calc_column.selected.oper_columns)) {
            let find_col_wo_name=calc_column.selected.oper_columns.find((f) => !f.name)
            if (!find_col_wo_name)
                columns_names=true
        }
    }
    if (calc_column?.selected?.column_place_after?.idx
        && columns_names
        && calc_column?.selected?.selected_calc_type?.name
    ) {
        res = true
    }
        return res
}

export  function createColumnValueAge (table_row,calc_column,fid) {
    if (!calc_column?.selected?.oper_columns){
        table_row[calc_column.selected.name]=""
        return null
    }

    let opds="";
    let bds="";

    calc_column?.selected?.oper_columns.forEach((col) => {
        if (col.el===1) {
            bds=table_row[col.name] || table_row[fid+'-'+col.name];
        } else if (col.el===2) {
            opds=table_row[col.name] || table_row[fid+'-'+col.name];
        }
    })

    if (!opds  || opds==="-" || !bds  || bds==="-") {
        table_row[calc_column.selected.name]="";
        return null;
    }

    if (bds && typeof bds==="string")  bds=bds.replace('xx','15')
    if (opds && typeof opds==="string")  opds=opds.replace('xx','15')

    let bd=Date.parse(bds)
    let opd=Date.parse(opds)


    if (isNaN(opd)  || isNaN(bd)) {
        table_row[calc_column.selected.name]="";
        return null;
    }
    let age=0
    if (calc_column.selected.selected_calc_type.id===1)
        age = Math.floor((opd-bd) / 31536000000)
    else if (calc_column.selected.selected_calc_type.id===2)
        age = Math.floor((opd-bd) / 86400000)

     table_row[calc_column.selected.name]=age;
     return table_row[calc_column.selected.name];
}

export function  syncLabelsCalcColumn (calc_column) {
    if (!calc_column?.selected?.name)
        return;
    let col=Calc_column_structure.calculateColumns.columns.find((f) => f.name===calc_column.selected.name)
    if (!col)
        return;
    calc_column.selected.label=col.label
    calc_column.selected.oper_columns.forEach((oper) => {
        let fnd= col.oper_columns.find((f) => f.el===oper.el)
        if (fnd)
            oper.label=fnd.label
    })
    calc_column.selected.calc_types.forEach((tp) => {
        let fnd= col.calc_types.find((f) => f.id===tp.id)
        if (fnd) {
            tp.label=fnd.label
            if (calc_column.selected.selected_calc_type?.id) {
                if (calc_column.selected.selected_calc_type.id===tp.id)
                    calc_column.selected.selected_calc_type.label=tp.label
            }
        }
    })

}



