import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@mui/material";
import PdfEditorContext from "./pdf_editor_context";
import PdfPage from "./pdf_editor_page/pdf_page";
import PdfLayers from "./pdf_editor_page/pdf_layers";
import PdfToolbar from "./pdf_editor_toolbar/pdf_toolbar";
import generateGuid from "../../helpers/generateGuid";

const PdfEditor = (props) => {
    const pageRef = useRef(null);

    const isInstance = props.isInstance;

    const [data, setData] = useState(props.data);
    const [activePage, setPage] = useState(0);
    const [activeLayer, setActiveLayer] = useState(0);
    const [selectedTab, setTab] = useState("1");
    const [activeElement, setActiveElement] = useState(0);
    const [editMode, setEditMode] = useState(null);
    const [editObject, setEditObject] = useState(null);
    const [layerMode, setLayerMode] = useState(isInstance);

    const isLocked =
        (isInstance && data.lock === "instance") || data.lock === "template";

    const isLockable =
        !data.lock ||
        (isInstance && data.lock === "instance") ||
        (!isInstance && data.lock === "template");

    function onPageChange(pageNumber) {
        if (editMode) onEditCancel();

        setPage(pageNumber);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function onActiveLayerIndexChange(layerIndex) {
        setActiveLayer(layerIndex);
    }

    function onPageClear() {
        data.elements = data.elements.filter((f) => f.page !== activePage);

        setData({ ...data });
    }

    function onAddLayer() {
        data.layers.push({
            id: generateGuid(),
            placeholder: "New layer",
        });

        setData({ ...data });
    }

    function onDocumentSave() {
        props.onDocumentSave(data);
    }

    function onLock() {
        setData({
            ...data,
            lock: !data.lock ? (isInstance ? "instance" : "template") : null,
        });
    }

    function onDrop(e) {
        const elementProps = JSON.parse(e.dataTransfer.getData("text"));

        const offset = e.target.getBoundingClientRect();

        const position = {
            x: e.clientX - Number(offset.left),
            y: e.clientY - Number(offset.top),
        };

        const newElementId = generateGuid();

        data.elements.push({
            id: newElementId,
            page: activePage,
            layerid: data.layers[activeLayer].id,
            type: elementProps.type,
            placeholder: elementProps.label,
            x: position.x,
            y: position.y,
        });

        setData({ ...data });

        onEditElement(newElementId);
    }

    function onDeleteLayer(index) {
        data.elements = data.elements.filter(
            (f) => f.layerid !== data.layers[index].id
        );
        data.layers.splice(index, 1);
        setData({ ...data });

        if (activeLayer === index) setActiveLayer(0);
        else if (activeLayer > index) setActiveLayer(activeLayer - 1);
    }

    function layerModeToggle() {
        setLayerMode(!layerMode);
    }

    function onEditLayer(layerIndex) {
        setActiveLayer(layerIndex);
        setEditMode("layer");
    }

    function onEditElement(elementId) {
        setActiveElement(elementId);
        setEditMode("element");
    }

    function onElementDelete() {
        data.elements = data.elements.filter((f) => f.id !== activeElement);

        setActiveElement(null);
        setEditMode(null);
        setData({ ...data });
    }

    function onEditSave() {
        if (editMode === "layer") {
            updateLayer(editObject);
        } else if (editMode === "element") {
            updateElement(editObject);
            setActiveElement(null);
        }

        setEditMode(null);
        onDocumentSave();
    }

    function onEditCancel() {
        setEditMode(null);
        setActiveElement(null);
    }

    function onElementUpdate(element) {
        updateElement(element);

        setData({ ...data });
    }

    function updateLayer(updatedLayer) {
        for (let l = 0; l < data.layers.length; l++) {
            const layer = data.layers[l];
            if (layer.id === updatedLayer.id) {
                data.layers[l] = {
                    ...layer,
                    ...updatedLayer,
                };
                return;
            }
        }
    }

    function updateElement(updatedElement) {
        for (let e = 0; e < data.elements.length; e++) {
            const element = data.elements[e];
            if (element.id === updatedElement.id) {
                data.elements[e] = {
                    ...element,
                    ...updatedElement,
                };
                return;
            }
        }
    }

    useEffect(() => {
        setActiveLayer(0);

        if (data.layers && data.layers.length === 0) {
            onAddLayer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const context = {
        pageRef,
        editObject,
        setEditObject,
        selectedTab,
        setTab,
        activeLayer,
        activePage,
        activeElement,
        editMode,
        onAddLayer,
        onPageChange,
        onActiveLayerIndexChange,
        onPageClear,
        onDocumentSave,
        onDeleteLayer,
        onDrop,
        onElementUpdate,
        onElementDelete,
        onEditElement,
        onEditLayer,
        onEditCancel,
        onEditSave,
        selectedPageImageUrl: data.pages[activePage],
        data,
        isLocked,
        isLockable,
        onLock,
        isInstance,
        layerMode,
        layerModeToggle,
        setData,
    };

    return (
        <PdfEditorContext.Provider value={{ ...context }}>
            <Paper elevation={3} sx={{ position: "relative", margin: "20px" }}>
                {!layerMode && (
                    <>
                        <PdfToolbar />
                        <PdfPage ref={pageRef} />
                    </>
                )}
                {layerMode && <PdfLayers />}
            </Paper>
        </PdfEditorContext.Provider>
    );
};

export default PdfEditor;
