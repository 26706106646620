import React from "react";
import { withTranslation } from "react-i18next";
/**
 * Button.
 * @param {Object} params - table key, reducer name, mount name (could be only single per model)
 * @param {Object} template - creator of initial payload ({app:web, version:2.0 etc})
 * @param {Object} connector - connector object providing transport and endpoint (in case for http_connector)
 */

class Button extends React.Component {
    onClick(event) {
        if (this.props.type === "submit") {
            event.preventDefault();
        }
        this.props.onClick({
            name: this.props.name,
            pointClient: { x: event.clientX, y: event.clientY },
            pointPage: { x: event.pageX, y: event.pageY },
            params: this.props.params || {},
        });
    }

    render() {
        let button_className = this?.props?.customClass
            ? this.props.customClass
            : "button btn btn-primary " + (this.props.className || "");
        let type = this.props.type || "button";
        const { t } = this.props;
        switch (this.props.status) {
            case "disabled":
                return (
                    <button
                        style={this.props.style}
                        id={this.props.name}
                        tabIndex={this.props.tabIndex}
                        className={button_className}
                        name={this.props.name}
                        disabled
                        type={type}
                        onClick={this.onClick.bind(this)}
                    >
                        {this.props.children}
                        {t(this.props.label)}
                    </button>
                );
            case "hidden":
                return null;
            default:
                return (
                    <button
                        style={this.props.style}
                        tabIndex={this.props.tabIndex}
                        id={this.props.name}
                        className={
                            button_className +
                            (this.props.status === "active" ? " active" : "")
                        }
                        name={this.props.name}
                        type={type}
                        onClick={this.onClick.bind(this)}
                    >
                        {this.props.children} {t(this.props.label)}
                    </button>
                );
        }
    }
}

export default withTranslation()(Button);
