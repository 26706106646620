import Modal from "react-modal";
import { Button } from "../components";

const YesNoPopup = (props) => {
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "100vh",
            overflowY: "auto",
        },
        overlay: {
            zIndex: "999999",
        }
    };

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onCancel}
            style={customStyles}
            contentLabel="Survey selector"
        >
            <div>
                <h2 className={"warning-text"}>{props.message}</h2>
                <div
                    style={{ justifyContent: "space-evenly", display: "flex" }}
                >
                    <Button label="Yes" onClick={props.onConfirm} />
                    <Button label="No" onClick={props.onCancel} />
                </div>
            </div>
        </Modal>
    );
};

export default YesNoPopup;
