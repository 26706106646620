import React, { useEffect, useState } from "react";
import { Button, Form, InputField } from "../../components";
import "./merge_template.css";
import "./merge_table.css";
import dayjs from "dayjs";
import * as qHelper from "./../../helpers/survey";
import data_source from "../../api/questionnaire_connector";
import Select from "react-select";
import notifications from "./../../helpers/notifications";
import { extractTemplate } from "./merge_template_helper";
import { decode } from "js-base64";
import YesNoPopup from "../../helpers/yesNoPopup";
import { useTranslation } from "react-i18next";
import TableMerge from "./table_merge";
import getAllQuestionsFromTemplate from "../../helpers/survey/questions";
import Modal from "react-modal";
import virtual_template_structure from "./virtual_template_structure";
import PagesSelector from "../../components/columns_page_selector/page_selector";
import WarningPopup from "../../helpers/warning_popup";
import QuestionInfo from "./question_info";
import close_img from "../../static/icons/close.svg";
import isArraysEquals from "../../helpers/is_array_equals";
import Warning3BtnPopup from "../../helpers/warning_3btn__popup";
import { NotificationTemplates as NT } from "./../../components";

export default function MergeTemplateEditor(props) {
    const { t } = useTranslation();
    const [template_master, setTemplateMaster] = useState(null);
    const [virtual_template, setVirtualTemplate] = useState(
        virtual_template_structure
    );
    const [locale, setLocale] = useState("en");
    const [templateName, setTemplateName] = useState({
        name: "",
        descript: "",
    });
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selected, setSelected] = useState("-1");
    const [confirmationModalState, setConfirmationModalState] = useState(false);
    const [warningModal, setWarningModal] = useState({
        is_open: false,
        message: "",
    });
    const [warning3btnModal, setWarning3btnModal] = useState({
        is_open: false,
        message: "",
        question: "",
    });
    const [questionsInfoVisible, setQuestionsInfoVisible] = useState({
        is_open: false,
        master: {},
        child: {},
        values_map: [],
    });

    const [children, setChildren] = useState([]);
    const [childQuestions, setChildQuestions] = useState([]);

    const [templateRow, setTemplateRow] = useState({});
    const [typesRow, setTypesRow] = useState({});
    const [masterRow, setMasterRow] = useState({});
    const [masterIdRow, setMasterIdRow] = useState({});
    const [filterRow, setFilterRow] = useState({});
    const [childRows, setChildRows] = useState([]);
    const [tableCellClickParams, setTableCellClickParams] = useState({});

    const [pages, setPages] = useState([
        { id: "-1", name: "-1", title: "All pages" },
    ]);
    const [questionsVisible, setQuestionsVisible] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedCombination, setIsLoadedCombination] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isChangedChildRows, setIsChangedChildRows] = useState(false);
    const [isEmptyTemplate, setIsEmptyTemplate] = useState(false);

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "70%",
            overflowY: "auto",
        },
        overlay: {
            background: "rgba(255, 255, 255, 0.5)",
            zIndex: "999990",
        },
    };

    useEffect(() => {
        data_source.load_template(window.uri_params.fids, props.anonymousMode,props.onlySCF,(err, data) => {
            if (err) {
                console.log("Failed to load template");
                console.log(err);
                notifications.error_center(
                    "Permission error",
                    err.api_error.message
                );
                return;
            }

            let survey = extractTemplate(data, data.fid);
            setTemplateMaster(survey);
            data_source.load_combination(
                window.uri_params.fids,
                (err, data) => {
                    if (err) {
                        console.log("Failed to load combination");
                        console.log(err);
                        return;
                    }
                    setIsLoadedCombination(true);
                    if (data && data !== "") {
                        const virtualTemp = JSON.parse(decode(data));
                        setVirtualTemplate(virtualTemp);
                    } else {
                        setIsEmptyTemplate(true);
                    }
                }
            );
        });
    }, [props.anonymousMode,props.onlySCF]);

    useEffect(() => {
        let pages_tmp = [];
        if (template_master) {
            let loc = "en"; //template_master.locale || "de"
            setLocale(loc);
            template_master.pages.forEach((page, index) =>
                pages_tmp.push({
                    id: (index + 1).toString(),
                    num: index + 1,
                    name: page.name,
                    title: `${index + 1}. ${
                        qHelper.gettitle(page, locale) || page.name
                    }`,
                })
            );

            setPages(pages_tmp);
        }
    }, [template_master, locale,]);

    useEffect(() => {
        const InitMaster = () => {
            if (!template_master) return;

            let questions = getAllQuestionsFromTemplate(template_master);

            let master_title = qHelper.gettitle(template_master, locale);
            if (questions.length > 0) {
                let template_row = {
                    id: template_master.id,
                    title: master_title,
                    firstcolumn: {
                        index: 0,
                        label: "Question Nr.",
                        name: "Template",
                        q_id: "",
                        question: {},
                        child_values_map: [],
                    },
                };
                let types_row = {
                    id: template_master.id,
                    title: master_title,
                    firstcolumn: {
                        index: 0,
                        label: "Type",
                        name: "Type",
                        q_id: "",
                        question: {},
                        child_values_map: [],
                    },
                };
                let master_row = {
                    id: template_master.id,
                    title: master_title,
                    firstcolumn: {
                        index: 0,
                        label: template_master?.id + " " + master_title,
                        name: "Master",
                        q_id: "",
                        question: {},
                        child_values_map: [],
                    },
                };
                let master_id_row = {
                    id: template_master.id,
                    title: master_title,
                    firstcolumn: {
                        index: 0,
                        label: "Question ID",
                        name: "MasterId",
                        q_id: "",
                        question: {},
                        child_values_map: [],
                    },
                };

                let columnidx = 0;
                let id = "";

                questions.forEach((value, index) => {
                    id = value.name;
                    columnidx = index + 1;

                    template_row[id] = {
                        index: columnidx,
                        label: value.number,
                        name: id + columnidx,
                        q_id: value.name,
                        question: value,
                        child_values_map: [],
                    };
                    types_row[id] = {
                        index: columnidx,
                        label: value.type,
                        name: id + columnidx,
                        q_id: value.name,
                        question: value,
                        hild_map: {},
                    };
                    master_row[id] = {
                        index: columnidx,
                        label: value.label,
                        name: id + columnidx,
                        q_id: value.name,
                        question: value,
                        child_values_map: [],
                    };
                    master_id_row[id] = {
                        index: columnidx,
                        label: value.name,
                        name: id + columnidx,
                        q_id: value.name,
                        question: value,
                        child_values_map: [],
                    };
                });
                setTemplateRow(template_row);
                setTypesRow(types_row);
                setMasterRow(master_row);
                setMasterIdRow(master_id_row);
            }
        };
        InitMaster();
        setTemplateName({
            name: virtual_template.title,
            descript: virtual_template.description,
        });
    }, [virtual_template, locale, template_master]);

    useEffect(() => {
        if (!masterIdRow.id) return;
        if (!isEmptyTemplate && !virtual_template.master_template_id) return;

        if (!filterRow?.id) {
            let filter_row = {
                id: masterIdRow.id,
                title: masterIdRow.title,
                firstcolumn: {
                    index: 0,
                    label: "Column filter",
                    name: "filter",
                    q_id: "",
                    question: {},
                    child_values_map: [],
                },
            };
            Object.keys(masterIdRow).forEach((master_q) => {
                let isVisible = false;
                if (
                    master_q !== "id" &&
                    master_q !== "title" &&
                    master_q !== "firstcolumn"
                ) {
                    if (virtual_template.column_filter)
                        isVisible =
                            !virtual_template.column_filter.includes(master_q);
                    filter_row[master_q] = {
                        index: masterIdRow[master_q].index,
                        label: isVisible ? "+" : "-",
                        name: master_q + masterIdRow[master_q].index,
                        q_id: master_q,
                        question: masterIdRow[master_q].question,
                        child_values_map: [],
                    };
                }
            });
            setFilterRow(filter_row);
        }
    }, [masterIdRow, virtual_template, filterRow, isEmptyTemplate]);

    useEffect(() => {
        const AddChild = (combine, childArray) => {
            let child_rows = [];
            let num = 0;
            let maped = {};
            let child_col = {};
            childArray.forEach((child) => {
                let survey_questions = getAllQuestionsFromTemplate(child);
                num++;
                maped = {};
                combine.mapping.forEach((map) => {
                    if (map.id === child.id) {
                        maped = map;
                    }
                });
                child_col = {
                    id: child.id,
                    title: qHelper.gettitle(child, locale),
                    firstcolumn: {
                        index: 0,
                        label: child.id + " " + qHelper.gettitle(child, locale),
                        name: "child-" + num + "-first",
                        q_id: "",
                        question: {},
                        child_values_map: [],
                    },
                };
                Object.keys(masterIdRow).forEach((master_q) => {
                    if (
                        master_q !== "id" &&
                        master_q !== "title" &&
                        master_q !== "firstcolumn"
                    ) {
                        child_col[master_q] = {
                            index: masterIdRow[master_q].index,
                            label: "",
                            name: "child-" + num + "-" + master_q,
                            q_id: "",
                            question: {},
                            child_values_map: [],
                        };
                        let child_question = {};
                        if (maped.question_names) {
                            maped.question_names.forEach((comb_q) => {
                                if (master_q === comb_q.master) {
                                    let map_title = "";
                                    child.pages.forEach((pg) => {
                                        if (pg.elements) {
                                            pg.elements.forEach((el) => {
                                                if (el.name === comb_q.child) {
                                                    child_question = el;
                                                    map_title =
                                                        qHelper.gettitle(
                                                            el,
                                                            locale
                                                        );
                                                    if (!map_title)
                                                        map_title = el.label;
                                                }
                                            });
                                        }
                                    });
                                    let chmap = [];
                                    if (Array.isArray(comb_q?.value_mapping)) {
                                        comb_q.value_mapping.forEach((val) => {
                                            chmap.push(val);
                                        });
                                    }
                                    let q_num = "";
                                    if (Array.isArray(survey_questions)) {
                                        q_num = survey_questions.find(
                                            (quest) => {
                                                return (
                                                    quest.name === comb_q.child
                                                );
                                            }
                                        )?.number;
                                    }
                                    child_col[master_q] = {
                                        index: masterIdRow[master_q].index,
                                        label: q_num + " " + map_title,
                                        name:
                                            "child-" + num + "-" + comb_q.child,
                                        q_id: comb_q.child,
                                        question: child_question,
                                        child_values_map: chmap,
                                    };
                                }
                            });
                        }
                    }
                });
                child_rows.push(child_col);
            });
            setChildRows(child_rows);
        };
        let children_tmp = [];
        virtual_template.mapping.forEach((child, index) => {
            children_tmp.push({ idx: index, child_id: child.id, survey: {} });
        });
        virtual_template.mapping.forEach((child) => {
            data_source.load_template(child.id, props.anonymousMode,props.onlySCF,(err, res) => {
                if (err) {
                    console.log("Failed to load child");
                    console.log(err);
                    return;
                }

                if (res.qarr.arr_fragen) {
                    const survey = extractTemplate(res, child.id);
                    let is_all = true;
                    children_tmp.forEach((tmp) => {
                        if (tmp.child_id === survey.id) {
                            tmp.survey = survey;
                        }
                        if (!tmp.survey.id) is_all = false;
                    });

                    if (is_all) {
                        let child_surveys = [];
                        children_tmp.forEach((tmp) => {
                            child_surveys.push(tmp.survey);
                        });
                        AddChild(virtual_template, child_surveys);
                        setChildren(child_surveys);
                    }
                }
            });
        });
    }, [masterIdRow, virtual_template, locale,props.anonymousMode,props.onlySCF]);

    useEffect(() => {
        if (!masterIdRow?.id || !isLoadedCombination) return;
        //let maped_array = [];
        //
        // childRows.forEach ((child) => {
        //     Object.keys (masterIdRow).forEach ((master_q) => {
        //         if (master_q !== "id" && master_q !== "title" && master_q !== "firstcolumn") {
        //             if (child[master_q].q_id) {
        //                 if (!maped_array.includes (master_q)) {
        //                     maped_array.push (master_q)
        //                 }
        //             }
        //         }
        //     })
        // })

        if (!isLoaded && isLoadedCombination) {
            setIsLoaded(true);
        }
    }, [masterIdRow, childRows, isLoadedCombination, isLoaded]);

    useEffect(() => {
        if (!masterIdRow?.id) return;
        let quuestions = [];
        Object.keys(masterIdRow).forEach((key) => {
            if (key !== "id" && key !== "title" && key !== "firstcolumn") {
                quuestions.push(masterIdRow[key].q_id);
            }
        });
        setQuestionsVisible(quuestions);
    }, [masterIdRow]);

    const mapQuestion = (cell) => {
        const allowMapUsed = true;
        let child_questions = [];
        let used_questions = [];
        let master_question_type = typesRow[cell.question_id].label;
        const exclude_types_to_map = [
            "inputdate",
            "comment",
            "leadmatrix8v2",
            "leadprobes8v2",
            "expression",
        ];

        let question_reason = (child_question) => {
            let reason = { ext_label: "", disabled: true, class_name: "" };
            let used = used_questions.find((item) => {
                return item === child_question.name;
            });
            if (child_question.type === master_question_type) {
                reason.disabled = false;
                if (used) {
                    reason.ext_label = " - used";
                    reason.disabled = !allowMapUsed;
                    reason.class_name = "-used";
                }
            } else {
                if (
                    exclude_types_to_map.find((item) => {
                        return (
                            item === child_question.type ||
                            item === master_question_type
                        );
                    })
                ) {
                    reason.ext_label = " - unsuitable type";
                    reason.disabled = true;
                    reason.class_name = "-unsuitable";
                } else {
                    if (used) {
                        reason.ext_label = " - used";
                        reason.class_name = "-used";
                    }
                    reason.ext_label += " - specific mapping";
                    reason.disabled = used ? !allowMapUsed : false;
                    reason.class_name += "-specific";
                }
            }
            return reason;
        };
        childRows.forEach((child) => {
            if (child.id === cell.id) {
                if (child[cell.question_id]?.child_values_map) {
                    child[cell.question_id].child_values_map = [];
                }
                Object.keys(child).forEach((col) => {
                    if (child[col].q_id) {
                        used_questions.push(child[col].q_id);
                    }
                });
            }
        });
        children.forEach((child) => {
            if (child.id === cell.id) {
                let survey_questions = getAllQuestionsFromTemplate(child);
                survey_questions.forEach((quest) => {
                    let reason = question_reason(quest);
                    let child_question = {
                        question: quest,
                        disabled: reason.disabled,
                        reason: reason,
                    };
                    child_questions.push(child_question);
                });
                setChildQuestions(child_questions);
                setIsOpen(true);
            }
        });
    };

    const onTableCellClick = (cell, act) => {
        setTableCellClickParams(cell);
        let filter_row = { ...filterRow };
        if (act === "DelChild") {
            setConfirmationModalState(true);
        } else if (act === "addMap") {
            mapQuestion(cell);
        } else if (act === "delMap") {
            let children_tmp = childRows.slice();
            let row = children_tmp.filter((child) => {
                return child.id === cell.id;
            });
            if (row.length > 0) {
                row[0][cell.question_id].label = "";
                row[0][cell.question_id].q_id = "";
                setChildRows(children_tmp);
            }
        } else if (act === "ToggleColumnFilter") {
            let was_open = filter_row[cell.question_id].label === "+";
            filter_row[cell.question_id].label = was_open ? "-" : "+";
            setFilterRow(filter_row);
        } else if (act === "setAll" || act === "unSetAll") {
            Object.keys(filter_row).forEach((key) => {
                if (key !== "id" && key !== "title" && key !== "firstcolumn") {
                    filter_row[key].label = act === "setAll" ? "+" : "-";
                }
            });
            setFilterRow(filter_row);
        } else if (act === "qInfo") {
            let child_question = {};
            let child__q_id = {};
            let values_map = [];

            childRows.forEach((child) => {
                if (child.id === cell.id) {
                    child__q_id = child[cell.question_id].q_id;
                    values_map = child[cell.question_id].child_values_map;
                }
            });
            children.forEach((child) => {
                if (child.id === cell.id) {
                    let survey_questions = getAllQuestionsFromTemplate(child);
                    survey_questions.forEach((quest) => {
                        if (quest.name === child__q_id) child_question = quest;
                    });
                }
            });

            setQuestionsInfoVisible({
                is_open: true,
                master: masterRow[cell.question_id].question,
                child: child_question,
                values_map: values_map,
            });
        }
    };

    const onChildDelete = () => {
        let child_tmp = childRows.filter(
            (child) => child.id !== tableCellClickParams.id
        );
        setIsChangedChildRows(true);
        setChildRows(child_tmp);
        setChildren((child) =>
            child.filter((flt) => {
                return flt.id !== tableCellClickParams.id;
            })
        );
    };

    const onChildAdd = (template) => {
        if (template.id) {
            let is_exist = false;
            childRows.forEach((child) => {
                if (child.id === template.id) {
                    is_exist = true;
                }
            });
            if (is_exist) return;
            let num = 1;
            if (childRows.length > 0) num = childRows.length + 1;
            let txt = `${template.id} ${qHelper.gettitle(template, locale)}`
            let child_row = {
                id: template.id,
                title: txt,
                firstcolumn: {
                    index: 0,
                    label: txt,
                    name: "child" + num + "first",
                    q_id: "",
                    question: {},
                    child_values_map: [],
                },
            };

            Object.keys(templateRow).forEach((col) => {
                if (col !== "id" && col !== "title" && col !== "firstcolumn") {
                    child_row[col] = {
                        index: templateRow[col].index,
                        label: "",
                        name: "child" + num + col,
                        q_id: "",
                        question: {},
                        child_values_map: [],
                    };
                }
            });
            setChildren((children) => [...children, template]);
            setChildRows((childRows) => [...childRows, child_row]);
            setIsChangedChildRows(true);
        }
    };

    const onPageSelect = (showAll, pages_selected) => {
        if (!masterIdRow) return;
        let quuestions = [];
        if (showAll) {
            Object.keys(masterIdRow).forEach((key) => {
                if (key !== "id" && key !== "firstcolumn") {
                    quuestions.push(masterIdRow[key].q_id);
                }
            });
        } else {
            Object.keys(masterIdRow).forEach((key) => {
                if (key !== "id" && key !== "firstcolumn" && key !== "title") {
                    let question_num_array = templateRow[key].label.split(".");
                    if (question_num_array[0] === pages_selected)
                        quuestions.push(masterIdRow[key].q_id);
                }
            });
        }
        setQuestionsVisible(quuestions);
    };

    const checkTemplate = () => {
        let isOk = true;
        let warning_txt = "";
        if (!templateName.name) {
            isOk = false;
            warning_txt = "Template name is required!";
        }
        setWarningModal({ is_open: !isOk, message: warning_txt });
        return isOk;
    };

    const createVirtualTemplateToSave = () => {
        let virtualTemp = { ...virtual_template };
        virtualTemp.title = templateName.name;
        virtualTemp.description = templateName.descript;
        virtualTemp.created = dayjs().format("DD.MM.YYYY HH:mm");
        virtualTemp.utf8 = true;
        virtualTemp.show_only_mapped_questions = false;
        virtualTemp.type = "virtual";
        if (window.doctorname) {
            virtualTemp.editor = window.doctorname;
        }
        if (!virtualTemp.name) virtualTemp.name = virtualTemp.title;
        virtualTemp.mapping = [];
        virtualTemp.column_filter = [];
        childRows.forEach((child) => {
            let maped_objects = [];
            Object.keys(masterIdRow).forEach((key) => {
                if (key !== "id" && key !== "firstcolumn" && key !== "title") {
                    if (child[key]?.q_id) {
                        if (child[key]?.child_values_map?.length > 0) {
                            maped_objects.push({
                                master: key,
                                child: child[key].q_id,
                                value_mapping: child[key].child_values_map,
                            });
                        } else {
                            maped_objects.push({
                                master: key,
                                child: child[key].q_id,
                                value_mapping: [],
                            });
                        }
                    }
                }
            });

            if (maped_objects.length > 0) {
                virtualTemp.mapping.push({
                    id: child.id,
                    title: child.title,
                    question_names: maped_objects,
                });
            }
        });
        Object.keys(masterIdRow).forEach((key) => {
            if (key !== "id" && key !== "firstcolumn" && key !== "title") {
                if (filterRow[key]?.label === "-") {
                    virtualTemp.column_filter.push(key);
                }
            }
        });
        if (!virtualTemp.master_template_id) {
            virtualTemp.master_template_id = template_master.id;
        }
        return virtualTemp;
    };

    const saveTemplate = (need_back) => {
        if (!checkTemplate()) {
            return;
        }
        let newVirtualTemplate = createVirtualTemplateToSave();
        data_source.save_combination(
            JSON.stringify(newVirtualTemplate),
            (err) => {
                if (err) {
                    console.log(err);
                    setWarningModal({ is_open: true, message: err });
                    return;
                }
                setVirtualTemplate(newVirtualTemplate);
                setIsChanged(true);
                if (need_back) {
                    props.onClose(true, isChangedChildRows);
                } else {
                    NT.success_d("Saved", 1);
                }
            }
        );
    };

    const back = () => {
        if (!props.onClose) {
            setWarningModal({
                is_open: true,
                message: "You have no 'onClose' props!",
                question: "",
            });
            return;
        }
        let isChanges = wasChanges();
        if (isChanges) {
            setWarning3btnModal({
                is_open: true,
                message: "You have unsaved changes in your merging setup!",
                question: "",
            });
        } else {
            props.onClose(isChanged, isChangedChildRows, virtual_template);
        }
    };

    const wasChanges = () => {
        let result = false;
        let newVirtTempl = createVirtualTemplateToSave();
        if (
            virtual_template.title !== newVirtTempl.title ||
            virtual_template.description !== newVirtTempl.description
        ) {
            result = true;
        }
        if (!result) {
            if (virtual_template.column_filter) {
                if (!newVirtTempl?.column_filter) {
                    result = true;
                } else {
                    if (
                        !isArraysEquals(
                            virtual_template.column_filter,
                            newVirtTempl.column_filter
                        )
                    ) {
                        result = true;
                    }
                }
            }
        }
        if (!result) {
            if (virtual_template.mapping) {
                if (!newVirtTempl?.mapping) {
                    result = true;
                } else {
                    if (
                        !isArraysEquals(
                            virtual_template.mapping,
                            newVirtTempl.mapping,
                            "id"
                        )
                    ) {
                        result = true;
                    }
                }
                if (!result) {
                    virtual_template.mapping.forEach((map) => {
                        let map2 = newVirtTempl.mapping.find((fnd) => {
                            return map.id === fnd.id;
                        });
                        if (!map2) {
                            result = true;
                        } else {
                            if (map.question_names) {
                                if (!map2.question_names) {
                                    result = true;
                                } else {
                                    if (
                                        !isArraysEquals(
                                            map.question_names,
                                            map2.question_names,
                                            "child,master"
                                        )
                                    ) {
                                        result = true;
                                    }
                                }
                                if (!result) {
                                    map.question_names.forEach((quest) => {
                                        let quest2 = map2.question_names.find(
                                            (fnd) => {
                                                return (
                                                    quest.child === fnd.child &&
                                                    quest.master === fnd.master
                                                );
                                            }
                                        );
                                        if (!quest2) {
                                            result = true;
                                        } else {
                                            if (
                                                !isArraysEquals(
                                                    quest.value_mapping,
                                                    quest2.value_mapping,
                                                    "child_value,master_value"
                                                )
                                            ) {
                                                result = true;
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            }
        }

        return result;
    };

    const renderHeader = () => {
        return (
            <div className="survey-header">
                <div className="template-info-wrap">
                    <div className="template-info">
                        <div className="title">
                            <InputField
                                label="Merged Dataset Name:"
                                name="title"
                                onChange={(input) => {
                                    let template_name = { ...templateName };
                                    template_name.name = input.value;
                                    setTemplateName(template_name);
                                }}
                                value={templateName.name}
                            />
                            <InputField
                                label="Info (optional):"
                                name="description"
                                onChange={(input) => {
                                    let template_name = { ...templateName };
                                    template_name.descript = input.value;
                                    setTemplateName(template_name);
                                }}
                                value={templateName.descript}
                                maxlength={150}
                            />
                        </div>
                    </div>

                    {renderControls()}
                </div>
            </div>
        );
    };
    const renderControls = () => {
        let save_status = isLoaded ? "enabled" : "disabled";
        return (
            <div className="filter-row">
                <Button
                    label="Save virtual template"
                    status={save_status}
                    onClick={() => {
                        saveTemplate(false);
                    }}
                />
                <Button label="Back" status={save_status} onClick={back} />
            </div>
        );
    };

    const renderTemplate = () => {
        const props = {
            templateRow,
            typesRow,
            filterRow,
            masterRow,
            masterIdRow,
            childRows,
            onTableCellClick,
            questionsVisible,
            onChildAdd,
        };
        return <TableMerge {...props} />;
    };

    const renderQuestionsSelector = () => {
        let questions = [
            {
                id: "-1",
                label: "Select question",
                disabled: true,
                class_name: "",
            },
        ];
        if (Object.keys(childQuestions).length === 0 || !modalIsOpen)
            return null;
        childQuestions.forEach((value) => {
            let txt = qHelper.gettitle(value.question, locale);
            if (!txt) txt = value.question.label;
            questions.push({
                id: value.question.number,
                label:
                    value.question.number +
                    " " +
                    txt +
                    " ( type:" +
                    value.question.type +
                    " )" +
                    value.reason.ext_label,
                disabled: value.reason.disabled,
                class_name: value.reason.class_name,
            });
        });
        let master_label_header =
            "master question:" +
            masterRow[tableCellClickParams.question_id].label;
        let master_label =
            "num: " +
            templateRow[tableCellClickParams.question_id].label +
            ", name: " +
            masterIdRow[tableCellClickParams.question_id].label +
            ", type: " +
            typesRow[tableCellClickParams.question_id].label;

        const ConfirmSelector = () => {
            setIsOpen(false);
            if (selected === "-1") return;
            let cildren_tmp = childRows.slice();
            cildren_tmp.forEach((row) => {
                if (row.id === tableCellClickParams.id) {
                    let question = childQuestions.filter((num) => {
                        return num.question.number === selected;
                    });

                    if (question.length > 0) {
                        let txt = qHelper.gettitle(
                            question[0].question,
                            locale
                        );
                        if (!txt) txt = question[0].question.label;
                        row[tableCellClickParams.question_id].label =
                            selected + " " + txt;
                        row[tableCellClickParams.question_id].q_id =
                            question[0].question.name;
                        row[tableCellClickParams.question_id].question =
                            question[0].question;
                    }
                }
            });
            let filter_row = { ...filterRow };
            if (filter_row[tableCellClickParams.question_id].label === "-") {
                filter_row[tableCellClickParams.question_id].label = "+";
                setFilterRow(filter_row);
            }
            setSelected("-1");
        };
        const CloseModal = () => {
            setIsOpen(false);
            setSelected("-1");
        };
        const confirmButtonStatus = selected === "-1" ? "disabled" : "";

        const renderSelectedInfo = () => {
            if (selected === "-1") return null;
            let cell = tableCellClickParams;
            let child_question = {};

            children.forEach((child) => {
                if (child.id === cell.id) {
                    let survey_questions = getAllQuestionsFromTemplate(child);
                    survey_questions.forEach((quest) => {
                        if (quest.number === selected) {
                            child_question = quest;
                        }
                    });
                }
            });
            let map_val = [];
            childRows.forEach((child) => {
                if (child.id === cell.id) {
                    if (child[cell.question_id].child_values_map)
                        map_val = child[cell.question_id].child_values_map;
                }
            });
            return (
                <QuestionInfo
                    child={child_question}
                    master={masterRow[cell.question_id].question}
                    mode={"show"}
                    locale={locale}
                    values_map={map_val}
                    onMapValue={onMapValue}
                />
            );
        };

        const renderMergedControl = () => {
            let selected_question = questions.find((quest) => {
                return quest.id === selected;
            });
            if (!selected_question) return null;
            if (
                selected_question.disabled &&
                selected_question.label.includes("unsuitable type")
            ) {
                return (
                    <div className={"question-selector-wrap"}>
                        <div className={"question-info-warning"}>
                            {t("The question types doesn't match")}
                        </div>
                        <Button
                            label="Merge column"
                            onClick={ConfirmSelector}
                            status={confirmButtonStatus}
                        />
                    </div>
                );
            }
            return (
                <div className={"question-selector-wrap"}>
                    <Button
                        label="Merge column"
                        onClick={ConfirmSelector}
                        status={confirmButtonStatus}
                    />
                </div>
            );
        };

        return (
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Questions selector"
            >
                <div className={"question-selector-container"}>
                    <div className={"question-selector"}>
                        <div className={"question-selector-top"}>
                            <div className={"master-label-header"}>
                                {master_label_header}
                            </div>
                            <img
                                src={close_img}
                                alt="logo"
                                onClick={CloseModal}
                            />
                            <div className={"master-label"}>{master_label}</div>
                        </div>
                        <Select
                            styles={{
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: ["-specific", "-used-specific"].find(
                                        (f) => state.data.class_name === f
                                    )
                                        ? "#fb4a01"
                                        : state.data.disabled
                                        ? "grey"
                                        : !state.data.class_name
                                        ? "#00ae04"
                                        : "black",
                                }),
                            }}
                            maxMenuHeight={300}
                            className="question-selector-items"
                            placeholder="Select question"
                            isOptionDisabled={(option) => option.disabled}
                            options={questions.map((question) => {
                                return {
                                    ...question,
                                    value: question.id,
                                };
                            })}
                            onChange={(event) => {
                                setSelected(event.value);
                            }}
                        />
                    </div>
                    {renderSelectedInfo()}
                    {renderMergedControl()}
                </div>
            </Modal>
        );
    };

    const renderPagesSelector = () => {
        const props = {
            pages,
        };
        return <PagesSelector {...props} onSelect={onPageSelect} />;
    };

    const onMapValue = (
        child_value,
        master_obj_value,
        master_question_name,
        child
    ) => {
        let childRows_tmp = [...childRows];
        let questionsInfoVisible_tmp = { ...questionsInfoVisible };
        childRows_tmp.forEach((child_row, row_idx) => {
            if (child_row.id === child.surveyId) {
                if (child_row[master_question_name].child_values_map) {
                    let isExists = false;
                    child_row[master_question_name].child_values_map.forEach(
                        (map_val, index) => {
                            if (map_val.child_value === child_value) {
                                isExists = true;
                                if (master_obj_value.type === "unmap") {
                                    childRows_tmp[row_idx][
                                        master_question_name
                                    ].child_values_map.splice(index, 1);
                                } else {
                                    childRows_tmp[row_idx][
                                        master_question_name
                                    ].child_values_map[index].master_value =
                                        master_obj_value.txt;
                                }
                            }
                        }
                    );
                    if (!isExists && master_obj_value.type !== "unmap") {
                        childRows_tmp[row_idx][
                            master_question_name
                        ].child_values_map.push({
                            child_value: child_value,
                            master_value: master_obj_value.txt,
                        });
                    }

                    questionsInfoVisible_tmp.values_map =
                        childRows_tmp[row_idx][
                            master_question_name
                        ].child_values_map;
                }
            }
        });
        setChildRows(childRows_tmp);
        setQuestionsInfoVisible(questionsInfoVisible_tmp);
    };
    const onMapValueUnsuitable = (
        maped_values,
        master_question_name,
        child
    ) => {
        let childRows_tmp = [...childRows];
        let questionsInfoVisible_tmp = { ...questionsInfoVisible };
        childRows_tmp.forEach((child_row) => {
            if (child_row.id === child.surveyId) {
                child_row[master_question_name].child_values_map = maped_values;
                questionsInfoVisible_tmp.values_map = maped_values;
            }
        });

        setChildRows(childRows_tmp);
        setQuestionsInfoVisible(questionsInfoVisible_tmp);
    };

    const renderQuestionInfo = () => {
        if (!questionsInfoVisible.is_open) return null;
        return (
            <Modal
                isOpen={questionsInfoVisible.is_open}
                style={customStyles}
                contentLabel="Questions info"
            >
                <div className={"question-info-close"}>
                    <img
                        src={close_img}
                        alt="logo"
                        onClick={() => {
                            let setQuestionsInfoVisible_tmp = {
                                ...setQuestionsInfoVisible,
                            };
                            setQuestionsInfoVisible_tmp.is_open = false;
                            setQuestionsInfoVisible(
                                setQuestionsInfoVisible_tmp
                            );
                        }}
                    />
                </div>
                <QuestionInfo
                    child={questionsInfoVisible.child}
                    master={questionsInfoVisible.master}
                    mode={"normal"}
                    locale={locale}
                    values_map={questionsInfoVisible.values_map}
                    onMapValue={onMapValue}
                    onMapValueUnsuitable={onMapValueUnsuitable}
                />
            </Modal>
        );
    };

    return (
        <Form name="group_report virtual-editor">
            <div className="virtual-editor-body">{renderHeader()}</div>
            <div className="virtual-editor-table">
                {renderPagesSelector()}
                {renderTemplate()}
            </div>
            {renderQuestionsSelector()}
            {renderQuestionInfo()}
            <YesNoPopup
                message={t("Are you sure you want to remove the survey?")}
                isOpen={confirmationModalState}
                onCancel={() => {
                    setConfirmationModalState(false);
                }}
                onConfirm={() => {
                    onChildDelete();
                    setConfirmationModalState(false);
                }}
            />
            <WarningPopup
                message={t(warningModal.message)}
                isOpen={warningModal.is_open}
                onConfirm={() => {
                    setWarningModal({ is_open: false, message: "" });
                }}
            />
            <Warning3BtnPopup
                message={t(warning3btnModal.message)}
                question={t(warning3btnModal.question)}
                isOpen={warning3btnModal.is_open}
                btn2Label={"Go back and loose changes"}
                btn3Label={"Cancel"}
                onbtn2Click={() => {
                    setWarning3btnModal({
                        is_open: false,
                        message: "",
                        question: "",
                    });
                    if (props.onClose) {
                        props.onClose(isChanged);
                    }
                }}
                onbtn3Click={() => {
                    setWarning3btnModal({
                        is_open: false,
                        message: "",
                        question: "",
                    });
                }}
            />
        </Form>
    );
}
