import get_title from "./gettitle";
import get_choices from "./getchoices";
import get_choices_all from "./getchoicesall";

export default function radiogroup(question, survey_answers, lang = 'default', showValues = false) {
    const title = get_title(question, lang);
    let survey_answer=survey_answers[question.name];
    let survey_answer_orig;
    if (typeof survey_answer==="object"){
        survey_answer=survey_answers[question.name]?.value
        if (survey_answers[question.name].value_orig)
            survey_answer_orig=survey_answers[question.name].value_orig
    }
    let answer;
    let answer_orig;
    if (survey_answer === 'other') {
        //"question17-Comment": "21. question another option"
        const other_answer_name = question.name + '-Comment';
        let answer_other=survey_answers[other_answer_name]
        if (typeof answer_other==="object") {
            answer_other=answer_other?.value
        }
        //answer = (survey_answers[other_answer_name]) ? survey_answers[other_answer_name] + ' (other)' : "(other)";
        answer=answer_other ? answer_other + ' (other)'  : '(other)'
    } else {
        answer = get_choices(question, survey_answer, lang, showValues) ;
        if (!answer)
            answer=survey_answer

    }
    if (survey_answer_orig) {
        if (survey_answer_orig !== 'other') {
            answer_orig = survey_answer_orig;
        } else {
            answer_orig ='(other)'
        }
    }
    return {
        title: title,
        answer: answer,
        answer_orig: answer_orig,
        value: survey_answer,
        all_choices: get_choices_all(question, lang),
        type: 'radiogroup'
    };
}