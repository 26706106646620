const data = {
    "locale": "de",
    "title": {
     "default": "SSSNM Basisdaten",
     "de": "Baseline visit"
    },
    "pages": [
     {
      "name": "Diagnosis",
      "elements": [
       {
        "type": "inputdate",
        "name": "question28",
        "title": {
         "de": "Date of baseline visit"
        }
       },
       {
        "type": "checkbox",
        "name": "2 Disorder being treated",
        "title": {
         "default": "welche süßigkeiten schmecken dir?",
         "de": "Disorder being treated (tick all that apply)"
        },
        "choices": [
         "Fecal Disorder",
         "Urinary Disorder"
        ],
        "noneText": {
         "de": "beides trifft zu"
        }
       },
       {
        "type": "radiogroup",
        "name": "question4",
        "visibleIf": "{2 Disorder being treated} allof ['Fecal Disorder', 'Urinary Disorder']",
        "title": {
         "de": "Wich one is predominant"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "Dominant Fecal"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "Dominant Urinary"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "Equal"
          }
         }
        ]
       },
       {
        "type": "radiogroup",
        "name": "4 if fecal disorder",
        "visibleIf": "{2 Disorder being treated} contains 'Fecal Disorder'",
        "title": {
         "de": "Fecal Disorder"
        },
        "choices": [
         {
          "value": "Fecal incontinence",
          "text": {
           "de": "Fecal incontinence"
          }
         },
         {
          "value": "Constipation",
          "text": {
           "de": "Constipation"
          }
         },
         {
          "value": "Pelvic pain",
          "text": {
           "de": "Pelvic pain"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "checkbox",
        "name": "question6",
        "visibleIf": "{2 Disorder being treated} contains 'Urinary Disorder'",
        "title": {
         "de": "Urinary Disorder"
        },
        "choices": [
         {
          "value": "Urgency frequency syndrome",
          "text": {
           "de": "Urgency frequency syndrome"
          }
         },
         {
          "value": "Urge incontinence",
          "text": {
           "de": "Urge incontinence"
          }
         },
         {
          "value": "Chronic non-obstructive urinary retention",
          "text": {
           "de": "Chronic non-obstructive urinary retention"
          }
         },
         {
          "value": "Pelvic pain",
          "text": {
           "de": "Pelvic pain"
          }
         },
         {
          "value": "Combined urinary retention and urgency",
          "text": {
           "de": "Combined urinary retention and urgency"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "checkbox",
        "name": "question8",
        "visibleIf": "{4 if fecal disorder} = 'Fecal incontinence'",
        "title": {
         "de": "Reason for fecal incontinence"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "Anal sphincter defect"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "Idiopathic"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "Pelvic surgery"
          }
         },
         {
          "value": "item4",
          "text": {
           "de": "Neurogenic"
          }
         },
         {
          "value": "item5",
          "text": {
           "de": "Multifactorial"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "radiogroup",
        "name": "question9",
        "visibleIf": "{question6} contains 'Chronic non-obstructive urinary retention'",
        "title": {
         "de": "if chronic non-obstructive urinary retention"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "Fowler`s syndrome"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "others than Fowler`s syndrome"
          }
         }
        ]
       },
       {
        "type": "checkbox",
        "name": "question10",
        "visibleIf": "{4 if fecal disorder} = 'Constipation'",
        "title": {
         "de": "type of constipation"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "slow transit"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "outlet obstruction"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "both"
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "question12",
        "visibleIf": "{2 Disorder being treated} = ['Urinary Disorder', 'both']",
        "title": {
         "de": "urinary incontince Other reasons"
        }
       },
       {
        "type": "radiogroup",
        "name": "underlying disorder",
        "choices": [
         {
          "value": "non-neurogenic",
          "text": {
           "de": "non-neurogenic"
          }
         },
         {
          "value": "neurogenic",
          "text": {
           "de": "neurogenic"
          }
         }
        ]
       },
       {
        "type": "checkbox",
        "name": "question14",
        "visibleIf": "{underlying disorder} = 'neurogenic'",
        "title": {
         "de": "if neurogenic than"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "Multiple Sclerosis"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "Parkinson`s disease"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "Mylomeningocele"
          }
         },
         {
          "value": "item4",
          "text": {
           "de": "Cerebrovascular event"
          }
         },
         {
          "value": "item5",
          "text": {
           "de": "cerebral palsy"
          }
         },
         {
          "value": "item6",
          "text": {
           "de": "complete spinal cord injury"
          }
         },
         {
          "value": "item7",
          "text": {
           "de": "incomplete spinal cord injury"
          }
         },
         {
          "value": "item8",
          "text": {
           "de": "herniated disc"
          }
         },
         {
          "value": "item9",
          "text": {
           "de": "pelvic surgery"
          }
         },
         {
          "value": "item10",
          "text": {
           "de": "diabetic neuropathy"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "text",
        "name": "question22",
        "visibleIf": "{underlying disorder} = 'neurogenic'",
        "title": {
         "de": "Duration of neurogenic disorder in years"
        },
        "description": {
         "de": "  "
        }
       }
      ],
      "title": {
       "de": "Diagnosis"
      }
     },
     {
      "name": "Fecal incontinence Pathology",
      "elements": [
       {
        "type": "radiogroup",
        "name": "question26",
        "title": {
         "de": "Has the patient had any previous therapies"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "checkbox",
        "name": "question23",
        "visibleIf": "{question26} = 'yes'",
        "title": {
         "de": "if yes, specify"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "Medication"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "Bio feedback"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "Spincteroplasty"
          }
         },
         {
          "value": "item4",
          "text": {
           "de": "pTNS"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "text",
        "name": "question100",
        "title": {
         "de": "Number of involuntary evacuations per week"
        }
       },
       {
        "type": "radiogroup",
        "name": "question27",
        "title": {
         "de": "Ability to defer defecation"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "< 1minute"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "1-5 minutes"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "5-15 minutes"
          }
         },
         {
          "value": "item4",
          "text": {
           "de": ">15 minutes"
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "question25",
        "title": {
         "de": "Urinary incontinence index (0-8) mit Dres. Engeler/Marti weglassen besprechen "
        }
       },
       {
        "type": "radiogroup",
        "name": "question24 Wexner",
        "title": {
         "de": "Was the Wexner Cleveland clinic incontinence score recorded (0-20)"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "radiogroup",
        "name": "question1",
        "title": {
         "de": "Was the Vaizey Wexner score recorded (0-24)"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "question101",
        "visibleIf": "{question24 Wexner} = 'yes'",
        "title": {
         "de": "Wexner Cleveland clinic incontinence score (0-20)"
        }
       },
       {
        "type": "text",
        "name": "question2",
        "visibleIf": "{question1} = 'yes'",
        "title": {
         "de": "Vaizey Wexner Cleveland score (0-24)"
        }
       },
       {
        "type": "radiogroup",
        "name": "question102",
        "title": {
         "de": "Was the ASCRS (American Society of Colon and Rectal surgeons) score recorded?"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "no"
          }
         }
        ]
       }
      ],
      "visibleIf": "{2 Disorder being treated} contains 'Fecal Disorder'",
      "title": {
       "de": "Fecal Incontinence Pathology"
      }
     },
     {
      "name": "Urinary Incontinence Pathology",
      "elements": [
       {
        "type": "radiogroup",
        "name": "question3",
        "title": {
         "de": "Has the patient had any previous therapies"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "checkbox",
        "name": "question16",
        "visibleIf": "{question3} = 'yes'",
        "title": {
         "de": "if yes, specify (tick all that apply)"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "Antimuscarinic drugs"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "Alpha blockers"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "Parasympathomimetics"
          }
         },
         {
          "value": "item4",
          "text": {
           "de": "Botolinum toxin intradetrusor injections"
          }
         },
         {
          "value": "item5",
          "text": {
           "de": "Botulinum toxin sphincter injections"
          }
         },
         {
          "value": "item6",
          "text": {
           "de": "Estrogen Therapy"
          }
         },
         {
          "value": "item7",
          "text": {
           "de": "Electrotherapy (TENS, SANS intravesical electrostimulation)"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "checkbox",
        "name": "question15",
        "title": {
         "de": "Anti-incontinence surgery (tick all that apply)"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "sling procedures"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "colposuspension"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "bladder augmentation"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "checkbox",
        "name": "question17",
        "title": {
         "de": "bladder outlet surgery (tick all that apply)"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "TURP"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "bladder neck incision "
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "internal urethrotomy/urethroplasty"
          }
         },
         {
          "value": "item4",
          "text": {
           "de": "meatotomy/meatoplasty"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "inputinteger",
        "name": "question20",
        "title": {
         "de": "Mean voids per day e.g. 7"
        },
        "correctAnswer": "-0"
       },
       {
        "type": "inputinteger",
        "name": "question111",
        "title": {
         "de": "Mean spontaneous voids per 24 h"
        },
        "correctAnswer": "-0"
       },
       {
        "type": "inputinteger",
        "name": "question113",
        "title": {
         "de": "Mean voids per night (awaken during sleep) "
        },
        "correctAnswer": "-0"
       },
       {
        "type": "inputinteger",
        "name": "question112",
        "title": {
         "de": "Mean voided volume per void in ml "
        },
        "correctAnswer": "-0"
       },
       {
        "type": "radiogroup",
        "name": "question18",
        "title": {
         "de": "intermittent self-catheterisation used?"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "question114",
        "visibleIf": "{question18} = 'item1'",
        "title": {
         "de": "Mean use of intermittent self-catheterisation during daytime?"
        }
       },
       {
        "type": "text",
        "name": "question115",
        "visibleIf": "{question18} = 'yes'",
        "title": {
         "de": "Mean use of intermittent self-catheterisation per 24 h?"
        }
       },
       {
        "type": "text",
        "name": "question116",
        "visibleIf": "{question18} = 'yes'",
        "title": {
         "de": "Mean use of intermittent self-catheterisation during the night?"
        }
       },
       {
        "type": "text",
        "name": "question117",
        "visibleIf": "{question18} = 'yes'",
        "title": {
         "de": "Catheterised volume in ml per catheterisation/residual urine via catheter"
        }
       },
       {
        "type": "radiogroup",
        "name": "question19",
        "title": {
         "de": "Suprapubic catheter /indwelling catheter:"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "question118",
        "visibleIf": "{question19} = 'item1'",
        "title": {
         "de": "Mean use of suprapubic/ transurethral catheter per day (or in daytime?):"
        }
       },
       {
        "type": "text",
        "name": "question119",
        "visibleIf": "{question19} = 'yes'",
        "title": {
         "de": "Mean use of suprapubic/ transurethral catheter per 24 hours:"
        }
       },
       {
        "type": "text",
        "name": "question120",
        "visibleIf": "{question19} = 'yes'",
        "title": {
         "de": "Mean use of suprapubic/ transurethral catheter per night:"
        }
       },
       {
        "type": "text",
        "name": "question121",
        "title": {
         "de": "Suprapubic volume in mL:"
        },
        "inputType": "number",
        "min": "0",
        "max": "5000",
        "step": 1
       },
       {
        "type": "radiogroup",
        "name": "question122",
        "title": {
         "de": "Condom catheter"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "question123",
        "title": {
         "de": "Leaks per 24 hours:"
        }
       },
       {
        "type": "text",
        "name": "question124",
        "title": {
         "de": "Pad use per 24 hours:"
        }
       },
       {
        "type": "text",
        "name": "question125",
        "title": {
         "de": "Number of incontinence episodes over the last week:"
        }
       },
       {
        "type": "text",
        "name": "question126",
        "title": {
         "de": "Pain visual analogue scale (VAS) (0-10) (if Pelvic pain):"
        }
       },
       {
        "type": "radiogroup",
        "name": "question21",
        "title": {
         "de": "Urodynamic investigations:"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "radiogroup",
        "name": "question34",
        "title": {
         "de": "First desire to void in ml:"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "no desire"
          }
         }
        ],
        "hasOther": true,
        "otherText": {
         "de": "first desire to void in ml:"
        }
       },
       {
        "type": "radiogroup",
        "name": "question95",
        "title": {
         "de": "Strong desire to void in ml:"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "no desire"
          }
         }
        ],
        "hasOther": true,
        "otherText": {
         "de": "strong desire to void in ml:"
        }
       },
       {
        "type": "inputinteger",
        "name": "question96",
        "title": {
         "de": "Compliance in ml/cmH2O"
        }
       },
       {
        "type": "radiogroup",
        "name": "question97",
        "title": {
         "de": "Detrusor overactivity"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "checkbox",
        "name": "question98",
        "visibleIf": "{question97} = 'yes'",
        "title": {
         "de": "if yes, specify (tick all that apply):"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "Phasic"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "Terminal"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "Detrusor overactivity incontinence"
          }
         }
        ]
       },
       {
        "type": "radiogroup",
        "name": "question99",
        "title": {
         "de": "Stress Urinary incontincontinence"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "inputinteger",
        "name": "question127",
        "title": {
         "de": "Storage phase Pdet max in cm H2O:"
        }
       },
       {
        "type": "radiogroup",
        "name": "question128",
        "title": {
         "de": "Other types of urinary incontinence:"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "question129",
        "visibleIf": "{question128} = 'yes'",
        "title": {
         "de": "if yes, specify:"
        }
       },
       {
        "type": "radiogroup",
        "name": "question130",
        "title": {
         "de": "Voiding possible?"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "radiogroup",
        "name": "question132",
        "title": {
         "de": "Voiding phase Pdet max:"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "inputinteger",
        "name": "question131",
        "title": {
         "de": "Pdet Qmax in cm H2O:"
        }
       },
       {
        "type": "inputinteger",
        "name": "question133",
        "title": {
         "de": "Qmax in ml/s:"
        }
       },
       {
        "type": "inputinteger",
        "name": "question134",
        "title": {
         "de": "Voided volume in ml:"
        }
       },
       {
        "type": "inputinteger",
        "name": "question135",
        "title": {
         "de": "Post void residual in ml:"
        }
       },
       {
        "type": "inputinteger",
        "name": "question136",
        "title": {
         "de": "Maximum cystometric capacity in ml (void volume+post void residual):"
        }
       },
       {
        "type": "radiogroup",
        "name": "question137",
        "title": {
         "de": "Pelvic floor electromyography:"
        },
        "choices": [
         {
          "value": "normal",
          "text": {
           "de": "normal"
          }
         },
         {
          "value": "Pathological",
          "text": {
           "de": "Pathological"
          }
         }
        ]
       },
       {
        "type": "checkbox",
        "name": "question138",
        "visibleIf": "{question137} = 'Pathological'",
        "title": {
         "de": "if pathological, specify (tick all that apply):"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "Detrusor sphincter dyssynergia"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "Dysfunctional voiding (neurologically normal patients)"
          }
         },
         {
          "value": "item3",
          "text": {
           "de": "Non-relaxing urethral sphincter obstruction"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "radiogroup",
        "name": "question139",
        "title": {
         "de": "Spincter EMG:"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "radiogroup",
        "name": "question140",
        "visibleIf": "{question139} = 'yes'",
        "title": {
         "de": "if yes:"
        },
        "choices": [
         {
          "value": "item1",
          "text": {
           "de": "normal"
          }
         },
         {
          "value": "item2",
          "text": {
           "de": "Consistent with Fowler`s syndrome"
          }
         }
        ],
        "hasOther": true
       },
       {
        "type": "radiogroup",
        "name": "question141",
        "title": {
         "de": "Ultrasound measurement of the urethral sphincter volume?"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "inputinteger",
        "name": "question142",
        "visibleIf": "{question141} = 'yes'",
        "title": {
         "de": "if yes, specify in mm3 (or ml)"
        }
       },
       {
        "type": "radiogroup",
        "name": "question144",
        "title": {
         "de": "Measurement of maximum urethral closure pressure at rest?"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "de": "yes"
          }
         },
         {
          "value": "no",
          "text": {
           "de": "no"
          }
         }
        ]
       },
       {
        "type": "inputinteger",
        "name": "question143",
        "visibleIf": "{question144} = 'yes'",
        "title": {
         "de": "if yes, specify in cmH2O"
        }
       },
       {
        "type": "text",
        "name": "question145",
        "title": {
         "de": "Comments in free text"
        }
       }
      ],
      "visibleIf": "{2 Disorder being treated} contains 'Urinary Disorder'",
      "title": {
       "de": "Urinary Incontinence Pathology"
      }
     }
    ]
   }

   export default data;