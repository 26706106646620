import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class NavButton extends React.Component{
    returnImg() {
        if (this.props.icon) {
            return (<img src={this.props.icon} alt="logo" className="navigation-icon" />)
        }
        return null;
    }
    returnIcon() {
        if (this.props.icon_comp) {
            return (this.props.icon_comp)
        }
        return null;
    }
    returnLabel() {
        if (this.props.label) {
            const { t } = this.props;
            return (<label>{t(this.props.label)}</label>)
        }
        return null;
    }
    returnDummy() {
        if (!this.props.icon && !this.props.label) {
            return (<div class="content"></div>)
        }
        return null;
    }
    render() {
        let class_name = 'navigation icon-wrap';
        if (this.props.className) class_name += ' ' + this.props.className;
        if (this.props.name) class_name += ' ' + this.props.name;
        if (this.props.permissions)
        return (
            <div className={class_name}>
                <NavLink className = {this.props.cname} activeClassName={"active_menu"} to={this.props.path || this.props.to || '/'}>
                        {this.returnImg()}
                        {this.returnIcon()}
                        {this.returnLabel()}
                        {this.returnDummy()}
                        {this.props.children}
                </NavLink>                      
            </div>   
        );
        return null;
    }
}

export default withTranslation()(NavButton);