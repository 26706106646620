export function searchInArray(params) {
    const list = params.list;
    const filter = (params.filter) ? params.filter : '';
    const fields = params.fields;
    return list.filter(element=>find_in_fields(element,fields,filter));
}

function find_in_fields(obj,fields,filter) {
    let str = '';
    fields.forEach(field=>{
        if (obj[field]) str = str + obj[field] + ' ';
    })
    return str.toLowerCase().includes(filter.toLowerCase());
}

export function searchInArrayExcludive(params) {
    const list = params.list;
    const filter = (params.filter) ? params.filter : '';
    const fields = params.fields;
    return list.filter(element=>find_in_fields(element,fields,filter) || true);
}