const data = [
    {
        fid:1,
        title: 'Initial Screening Type 1',
        description: 'Use for getting data on first patient visit',
        editor: 'Kimio Tremmel',
        created: '2021-10-01',
        mid: 'SSNM',
    },
    {
        fid:2,
        title: 'Implantation',
        description: 'Implantation data',
        editor: 'Alex Jasinskis',
        created: '2021-10-01',
        mid: 'SSNM',
    },
    {
        fid:3,
        title: 'IIEF Questionaire',
        description: 'IIEF standart questionaire',
        editor: 'Kimio Tremmel',
        created: '2021-10-01',
        mid: 'SSNM',
    },
    {
        fid:4,
        title: 'ASCRS Questionaire',
        description: 'ASCRS standart questionaire',
        editor: 'Alex Jasinskis',
        created: '2021-10-01',
        mid: 'SSNM',
    },
    {
        fid:5,
        title: 'Initial Screening Type 2',
        description: 'Use for getting data on before implantation',
        editor: 'Kimio Tremmel',
        created: '2021-10-01',
        mid: 'SSNM',
    }
]

export default data;