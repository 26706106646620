export function indexToAbc(index) {
    let result=""
    const alfa = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const range = alfa.length;

    const firstCharPos = Math.floor((index-1)/range);
    const secondCharPos = index - (firstCharPos * range);

    if (firstCharPos > 0) result += alfa[firstCharPos - 1];
    if (secondCharPos > 0) result += alfa[secondCharPos - 1];

    return result
}