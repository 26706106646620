import auth_connector from "../../api/questionnaire_connector";
import React, { useState, useEffect } from "react";
import { Button } from "../../components";
import { decode } from "../../helpers/base64decode";
import Modal from "react-modal";
import "./template_selector.css";
import gettitle from "../../helpers/survey/gettitle";



const TemplateSelector = (props) => {
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "100vh",
            overflowY: "auto",
        },
        overlay: {
            zIndex: "999999",
        }
    };
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState("-1");
    const [templateData, setTemplateData] = useState([]);
    const [tocShowed, setTocShowed] = React.useState(false);

    const OpenModal = () => {
        setIsOpen(true);
        document.body.style.overflow = "hidden";
    };

    const CloseModal = () => {
        setIsOpen(false);
        // setSelected("-1")
        // setTemplateData([])
        document.body.style.overflow = "unset";
    };

    useEffect(() => {
        auth_connector.get_templates_list(window.uri_params.fid, (err, res) => {
            if (err) {
                console.log(err);
                return;
            }

            const result = [];

            for (const property in res) {
                if (res[property].id === window.uri_params.fids) {
                    continue;
                }

                result.push({
                    id: res[property].id,
                    title: res[property].titel,
                });
            }

            setOptions(result);
        });
    }, []);

    const getTemplateData = (event) => {
        const fid = event.target.value;

        setSelected(fid);
        setTocShowed(false)
        auth_connector.load_template(fid, false,false,(err, res) => {
            if (err) {
                console.log(err);
                return;
            }

            if (res.qarr.arr_fragen) {
                let survey = {};
                const jsonString = decode(res.qarr.arr_fragen);
                try {
                    survey = JSON.parse(jsonString) ?? {};
                } catch (e) {
                    console.log("Invalid survey payload format");
                }
                survey.id = fid;
                survey.title = res.qarr.titel;
                setTemplateData(survey);
            }
        });
    };

    const Selector = (selectorProps) => {
        return (
            <select onChange={getTemplateData} value={selectorProps.value}>
                <option value={"-1"} disabled>
                    Select template
                </option>
                {selectorProps.options.map((option) => {
                    if (props.childList.includes(option.id)){
                        return (
                            <option key={option.id} value={option.id} disabled>
                                {"fid: " + option.id + " Title: " + option.title + " - Added"}
                            </option>
                        );
                    } else {
                        return (
                            <option key={option.id} value={option.id}>
                                {"fid: " + option.id + " Title: " + option.title}
                            </option>
                        );
                    }
                })}
            </select>
        );
    };

    const OnConfirm = () => {
        setIsOpen(false);
        document.body.style.overflow = "unset";
        props.onAdd(templateData);
        setSelected("-1")
        setTemplateData([])
        setTocShowed(false)
    };
    const ToggleShowQuestions=()=> {
        setTocShowed(!tocShowed)
    }

    const showQuestionsToggler=()=>{
        if (selected==="-1"){
            return null
        }
        const tocShowedText=tocShowed ? "hide questions list" :  "show questions list"
        return (
            <div className={"show-toggler"}>
                <Button className="like-link" label={tocShowedText} onClick={ToggleShowQuestions} />
            </div>
        )
    }

    const Toc = (tocProps) => {
        if (!tocShowed) return null;
        if (!tocProps.templateData.pages){
            return (
                <div id="toc_container">
                    <p className="toc_title">Questions list is empty</p>
                </div>
            )}
        return (
            <div id="toc_container">
                <ul className="toc_list">
                    {tocProps.templateData.pages?.map((page, indexPage) => {
                        return (
                            <li key={page.name}>
                                <h1>
                                    {indexPage +
                                        1 +
                                        ". " +
                                        (gettitle(page,tocProps.templateData.locale) ?? page.name)}
                                </h1>
                                <ul>
                                    {page.elements?.map(
                                        (question, indexQuestion) => {
                                            return (
                                                <li key={question.name}>
                                                    <h2>
                                                        {indexPage +
                                                            1 +
                                                            "." +
                                                            (indexQuestion +
                                                                1) +
                                                            ". " +
                                                            (gettitle(question,tocProps.templateData.locale)
                                                             ??  question.name
                                                            )
                                                        }
                                                    </h2>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };
    const confirmButtonStatus=selected==="-1" || !templateData.pages ? "disabled" : ""


    return (
        <div>
            <Button className="btn btn-primary like-link add-child" onClick={OpenModal}>
                {props.text}
            </Button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={CloseModal}
                style={customStyles}
                contentLabel="Survey selector"
            >
                <div>
                    <div className={"selector-header"}>Add Child: add Data Collection to merged Datasets</div>
                    <Selector options={options} value={selected} />
                    <Button label="Select" onClick={OnConfirm} status={confirmButtonStatus} />
                    <Button label="Cancel" onClick={CloseModal} />
                    {showQuestionsToggler()}
                    <Toc templateData={templateData} />
                </div>
            </Modal>
        </div>
    );
};

export default TemplateSelector;
