import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
//import Field from './fieldclass';

class DatePickerField extends React.Component {
    // extend from FieldClass?
    componentDidMount() {
        if (this.props.autoFocus) {
            // this.nameInput.focus();
        }
    }
    onChange(value) {
        this.props.onChange({ name: this.props.name, value: value });
    }

    getNamePostfix() {
        return this.props.postfix
            ? this.props.name + "_" + this.props.postfix
            : this.props.name + "_edit";
    }

    getWrapClassName() {
        let wrap_class_name = "datepicker field_wrap " + this.getNamePostfix();
        if (this.props.className && this.props.isV2)
            return this.props.className;
        if (this.props.className & !this.props.isV2)
            wrap_class_name += " " + this.props.className;
        return wrap_class_name;
    }
    isHidden() {
        let hidden = false;
        if (this.props.status && this.props.status === "hidden") {
            hidden = true;
        }
        return hidden;
    }

    showError() {
        if (this.props.error && this.props.error["message"]) {
            return (
                <span className="error_tag">{this.props.error.message}</span>
            );
        } else return null;
    }

    showLabel() {
        if (this.props.label) {
            const { t } = this.props;
            return (
                <label htmlFor={this.getNamePostfix()}>
                    {t(this.props.label)}
                </label>
            );
        } else {
            return null;
        }
    }

    showDatePicker() {
        const { t } = this.props;
        let dateFormat = this.props.dateFormat
            ? this.props.dateFormat
            : "dd.MM.yyyy";
        const placeholder = t(this.props.placeholder);
        return (
            <DatePicker
                wrapperClassName={this.getWrapClassName()}
                dateFormat={dateFormat}
                locale={this.props.locale}
                placeholderText={placeholder}
                selected={this.props.selected || this.props.value}
                onChange={this.onChange.bind(this)}
                id={this.getNamePostfix()}
                name={this.getNamePostfix()}
            />
        );
    }

    render() {
        if (this.isHidden()) {
            return null;
        } else {
            return (
                <div
                    className={
                        this?.props?.className?.length
                            ? this?.props?.className
                            : this.getWrapClassName()
                    }
                >
                    {this.showLabel()}
                    {this.showDatePicker()}
                    {this.showError()}
                </div>
            );
        }
    }
}

export default withTranslation()(DatePickerField);
