import ReactJson from 'react-json-view'
import { Button } from '../../components';
import React, { useState } from "react";

export default function DebugTree({ template, instance, setShowDebugObject }) {
    const [collapsed, setCollapsed] = useState(false);
    if (!template) return null;
    const template_with_answer = { ...template }
    template_with_answer.system_field = instance.system_field;
    Object.keys(template_with_answer.pages).forEach((page_index) => {
        const page = template_with_answer.pages[page_index];
        page.elements.forEach(question => {
            const nda = question.name+'_nda';
            question.value = instance[question.name] || null;
            if (nda in instance) question.nda = true;
        })
    });
    return (
        <div className="popup object-view">
            <div className="popup-content flex-column">
                <div className='object-header'>
                    <Button label="Collapse" onClick={() => { setCollapsed(3) }} />
                    <Button label="Expand" onClick={() => { setCollapsed(false) }} />
                    <Button label="Close" onClick={() => { setShowDebugObject(null) }} />
                </div>

                <div className='object-wrap'>
                    <ReactJson name={'ID' + window.uri_params.fids} src={template_with_answer} displayArrayKey={false}
                    collapsed={collapsed} theme="rjv-default" iconStyle="circle" displayObjectSize={false} enableClipboard={false} />
                </div>


            </div>
        </div>
    )
}

