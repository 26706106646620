import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    Image,
    PDFDownloadLink,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import logo from "./../../static/logo/logo-chmedic.png";

import * as qHelper from "../../helpers/survey";
import styles from "./template_report_pdf_styles";

//const system_fields = ['instance_answering_person_bd', 'relevant_date', 'instance_answering_person_name', 'instance_id'];

function SurveyReportPDF({ template, survey, calcColumn }) {
    const { t } = useTranslation();
    const locale = template.locale || "de";
    const surveyHeader = () => {
        return (
            <View style={styles.header_survey}>
                <View style={styles.survey_person}>
                    <Text className="name">
                        Patient: {survey.instance_answering_person_name}
                    </Text>
                    <Text className="birthdate">
                        Birthdate: {survey.instance_answering_person_bd}
                    </Text>
                    <Text className="date">
                        Survey date: {survey.relevant_date}
                    </Text>
                </View>
                <View style={styles.survey_info}>
                    <Text className="survey-title">
                        {qHelper.gettitle(template, locale)}
                    </Text>
                    <Text style={styles.survey_description}>
                        {qHelper.getdescription(template, locale)}
                    </Text>
                </View>
            </View>
        );
    };

    const renderQuestion = ({ question, page_number, qnum }) => {
        const questionNumber =
            (question.questionNumberingOverride ?? page_number + "." + qnum) +
            ". ";

        const renderDate = () => {
            const result = qHelper.inputdate(question, survey, locale);
            return (
                <View key={questionNumber} style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        const renderCustom = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View key={questionNumber} style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        const renderRadiogroup = () => {
            const result = qHelper.radiogroup(question, survey, locale);
            return (
                <View key={questionNumber} style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        const renderCheckbox = () => {
            const result = qHelper.checkbox(question, survey, locale);
            return (
                <View key={questionNumber} style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        const renderDropdown = () => {
            const result = qHelper.radiogroup(question, survey, locale);
            return (
                <View key={questionNumber} style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };
        const renderLeadProbes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View key={questionNumber} style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>
                        Lead Position (Complex params)
                    </Text>
                </View>
            );
        };
        const renderLeadMatrix = () => {
            const result_lead = qHelper.leadmatrix.matrix(question, survey);
            const renderHeader = () => {
                return (
                    <View
                        key={questionNumber}
                        style={styles.question}
                        wrap={false}
                    >
                        <Text style={styles.question_title}>
                            {questionNumber + result_lead.header}
                        </Text>
                        <Text style={styles.question_answer}>
                            Lead/IPG Programming (Complex params)
                        </Text>
                    </View>
                );
            };

            return renderHeader();
            /*
            const renderSide = (side) => {
                const matrix = [];
                const renderSideHeader = (header) => {
                    matrix.push(
                        <View className="question subquestion matrix3">
                            <Text className="question-title">
                                {side + ": "}
                            </Text>
                            <View className="question-answer">
                                <Text className="question-column1">
                                    {header[0]}
                                </Text>
                                <Text className="question-column2">
                                    {header[1]}
                                </Text>
                                <Text className="question-column3">
                                    {header[2]}
                                </Text>
                            </View>
                        </View>
                    );
                };
                const renderSideAnswers = (row_name, columns) => {
                    matrix.push(
                        <View className="question subquestion2 matrix3">
                            <Text className="question-title">
                                {row_name + ": "}
                            </Text>
                            <View className="question-answer">
                                <Text className="question-column1">
                                    {columns[0]}
                                </Text>
                                <Text className="question-column2">
                                    {columns[1]}
                                </Text>
                                <Text className="question-column3">
                                    {columns[2]}
                                </Text>
                            </View>
                        </View>
                    );
                };
                Object.keys(result_lead[side]).forEach((row_name) => {
                    if (row_name === "header")
                        renderSideHeader(result_lead[side][row_name]);
                    else
                        renderSideAnswers(
                            row_name,
                            result_lead[side][row_name]
                        );
                });
                return matrix;
            };

            return (
                <>
                    {renderHeader()}
                    {renderSide(Object.keys(result_lead)[1])}
                    {renderSide(Object.keys(result_lead)[2])}
                </>
            );
            */
        };
        const renderOtherTypes = () => {
            const result = qHelper.input(question, survey, locale);
            return (
                <View key={questionNumber} style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {questionNumber + result.title}
                    </Text>
                    <Text style={styles.question_answer}>{result.answer}</Text>
                </View>
            );
        };

        switch (question.type) {
            case "inputdate":
                return renderDate();
            case "inputcustom":
                return renderCustom();
            case "inputinteger":
                return renderCustom();
            case "radiogroup":
                return renderRadiogroup();
            case "dropdown":
                return renderDropdown();
            case "checkbox":
                return renderCheckbox();
            case "leadmatrix8combined":
                return renderLeadMatrix();
            case "leadmatrix8v2":
                return renderLeadMatrix();
            case "leadprobes8v2":
                return renderLeadProbes();
            default:
                return renderOtherTypes();
        }
    };

    const renderQuestions = ({ questions, page_number, calcColumn }) => {
        const questions_render = [];
        let qnum = 1;
        if (!questions) return questions_render;
        let calc= calcColumn.selected
        if (calc.column_place_after.name==="-1" && !calc.hide) {
            questions_render.push(
                <View key={calc.name} style={styles.question} wrap={false}>
                    <Text style={styles.question_title}>
                        {calc.label}
                    </Text>
                    <Text style={styles.question_answer}>{survey[calc.name]}</Text>
                </View>
            );
        }
        Object.keys(questions).forEach((question_index) => {
            const question = questions[question_index];
            questions_render.push(
                renderQuestion({ question, page_number, qnum })
            );
            qnum++;
            if (question.name===calc.column_place_after.name && !calc.hide) {
                questions_render.push(
                    <View key={calc.name} style={styles.question} wrap={false}>
                        <Text style={styles.question_title}>
                            {calc.label}
                        </Text>
                        <Text style={styles.question_answer}>{survey[calc.name]}</Text>
                    </View>
                );
            }
        });
        return questions_render;
    };

    const surveyPages = () => {
        const pages = [];
        Object.keys(template.pages).forEach((page_index) => {
            const page = template.pages[page_index];
            const page_number = parseInt(page_index) + 1;
            const title = qHelper.gettitle(page, locale);
            const description = qHelper.getdescription(page, locale);
            pages.push(
                <View key={page_index} className="survey-page">
                    <View wrap={false} style={styles.chapter_header}>
                        <Text className="page-number">{`${page_number}. ${title}`}</Text>
                    </View>
                    <View style={styles.page_info}>
                        <Text style={styles.survey_description}>
                            {description}
                        </Text>
                    </View>
                    <View className="page-body">
                        {renderQuestions({
                            questions: page.elements,
                            page_number: page_number,
                            calcColumn: calcColumn
                        })}
                    </View>
                </View>
            );
        });
        return pages;
    };

    return (
        <Document>
            <Page size="A4" style={styles.body} wrap>
                <View style={styles.header} fixed>
                    <Image style={styles.header_logo} src={logo} />
                    <View style={styles.header_info}>
                        <Text>
                            {t("Survey report") +
                                ": " +
                                qHelper.gettitle(template, locale)}
                        </Text>
                        <Text>{`FID/ID: ${template.id}/${survey.instance_id}/${survey.relevant_date}`}</Text>
                        <Text>{}</Text>
                    </View>
                </View>
                {surveyHeader()}
                {surveyPages()}
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />
            </Page>
        </Document>
    );
}

const DownloadPDF = ({ template, survey , calcColumn}) => {
    const date = dayjs(new Date()).format("YYYY-MM-DD_HHmmss");
    const filename = `Survey_${survey.instance_id}_${qHelper.gettitle(
        template,
        template.locale || "de"
    )}_${template.id}_${date}.pdf`;
    return (
        <PDFDownloadLink
            className="button"
            document={SurveyReportPDF({ template, survey, calcColumn })}
            fileName={filename}
        >
            {({ blob, url, loading, error }) =>
                loading ? "Wait, preparing PDF..." : "Download PDF"
            }
        </PDFDownloadLink>
    );
};

export default DownloadPDF;
