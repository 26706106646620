export default function LogActionsReducer(state = {
    log_actions: {
        list: [],
        list_pagination: { rpp: 20, max_pages: 0, total_rows: 0, page: 1 },
        filter: {},
    },
    users: {}
}, action) {
    switch (action.type) {
        case 'res_log_actions_list':
            state.log_actions.list = action.data.list;
            state.log_actions.list_pagination = action.data.list_pagination;
            return { ...state };
        case 'res_log_actions_get_users':
            state.users = action.data.field_options;
            return { ...state };
        case 'res_log_actions_get_events':
            state.events = action.data.field_options;
            return { ...state };
        case 'res_log_actions_list_csv':
            state.csv = action.data;
            return { ...state };
        case 'LOG_ACTIONS_NAVIGATION':
            state.log_actions.list_pagination[action.params.name] = action.params.value;
            state.log_actions.list_pagination = { ...state.log_actions.list_pagination }
            return { ...state };
        case 'LOG_ACTIONS_FILTER':
            state.log_actions.filter[action.params.name] = action.params.value;
            state.log_actions.filter = { ...state.log_actions.filter }
            return { ...state };
        default:
            break;
    }
    return state;
}