import {
    Card,
    CardContent,
    CardActions,
    IconButton,
    Box,
    TextField,
    Button,
} from "@mui/material";
import { Layers, Delete } from "@mui/icons-material";
import PdfEditorContext from "../pdf_editor_context";

const Layer = (props) => {
    return (
        <PdfEditorContext.Consumer>
            {({ data, setData, isInstance }) => (
                <Card sx={{ minWidth: 420 }}>
                    <CardContent>
                        <Layers sx={{ color: props.color }} />
                        <TextField
                            id="layer_name"
                            label="Name"
                            variant="standard"
                            value={data.layers[props.index]?.placeholder || ""}
                            fullWidth
                            onChange={(e) => {
                                data.layers[props.index].placeholder =
                                    e.target.value;
                                setData({ ...data });
                            }}
                        />
                        <Box>
                            <TextField
                                id="layer_source_select_native"
                                select
                                SelectProps={{
                                    native: true,
                                }}
                                label="Source"
                                fullWidth
                                variant="standard"
                                value={data.layers[props.index]?.source || "1"}
                                onChange={(e) => {
                                    data.layers[props.index].source =
                                        e.target.value;
                                    data.layers[props.index].receiver = null;

                                    setData({ ...data });
                                }}
                            >
                                {[
                                    { label: "PCOM", value: "1" },
                                    { label: "3rd party", value: "2" },
                                    { label: "Free form", value: "3" },
                                ].map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </Box>
                        {isInstance && (
                            <Box>
                                <TextField
                                    id="layer_receiver_name"
                                    label="Receiver name"
                                    variant="standard"
                                    fullWidth
                                    disabled={
                                        data.layers[props.index]?.source !== "3"
                                    }
                                    value={
                                        data.layers[props.index]
                                            ?.receiver_name || ""
                                    }
                                    onChange={(e) => {
                                        data.layers[props.index].receiver_name =
                                            e.target.value;

                                        setData({ ...data });
                                    }}
                                />
                                <TextField
                                    id="layer_receiver"
                                    label="Receiver"
                                    variant="standard"
                                    fullWidth
                                    disabled={
                                        data.layers[props.index]?.source !== "3"
                                    }
                                    value={
                                        data.layers[props.index]?.receiver || ""
                                    }
                                    onChange={(e) => {
                                        data.layers[props.index].receiver =
                                            e.target.value;

                                        setData({ ...data });
                                    }}
                                />
                                {data.layers[props.index]?.source !== "3" && (
                                    <Box>
                                        <Button disableRipple>Search</Button>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </CardContent>
                    <CardActions>
                        <IconButton
                            disableRipple
                            disabled={!props.canDelete}
                            sx={{
                                border: "0 !important",
                                boxShadow: "none !important",
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                props.onDeleteLayer(props.index);
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </CardActions>
                </Card>
            )}
        </PdfEditorContext.Consumer>
    );
};

export default Layer;
