export default function get_choices_all(question, lang) {
    const choices = [];
    question.choices.forEach((found)=>{
        if (typeof found === 'object') {
            choices.push((typeof found.text === 'object') ? found.text?.[lang] || found.text?.default || found.value : found.text || found.value);
        } else {
            choices.push(found);
        }
    })
    if (question.hasOther) {
        if (question.otherText && question.otherText[lang]) {
            choices.push('Other: ' + question.otherText[lang]);
        } else {
            choices.push('Other');
        }
        
    }
    return ('[' + choices.join(', ') + ']');
}