import person_data from "./../person_data";
import { extract_template, extract_answers } from "./extract_data";
import getAllQuestionsFromTemplate from "../../../helpers/survey/questions";
import { addNdaAnswers } from "../../../helpers/survey";
import store from "../../../store";

const normal_template = (data_set, callback) => {
    const protected_field = [
        "instance_answering_person_bd",
        "relevant_date",
        "lc",
        "instance_answering_person_name",
        "system_field",
        "instance_id",
        "doctor_id",
    ];
    const template = extract_template(
        data_set?.template,
        false,
        null,
        data_set?.instances
    );
    if (template.pages) template.pages.unshift(person_data);
    //original tempalte for pdf
    const template_origin = extract_template(
        data_set?.template,
        false,
        null,
        data_set?.instances
    );
    const allquests = getAllQuestionsFromTemplate(template_origin);
    const answers_array = extract_answers(
        Object.values(data_set?.instances || {}),
        template_origin.utf8
    );
    let encrypted = false;
    answers_array.forEach((answer) => {
        Object.keys(answer).forEach((key) => {
            let quest = allquests.find((f) => f.name === key);
            if (!protected_field.includes(key)) {
                if (quest) {
                    if (answer[key].value) {
                        answer[key].question_master = quest;
                    } else {
                        answer[key] = {
                            value: answer[key],
                            question_master: quest,
                        };
                    }
                } else if (!answer[key].value) {
                    answer[key] = { value: answer[key] };
                }
                if (key.endsWith("_nda")) {
                    let nda_val = answer[key]?.value
                        ? answer[key].value
                        : answer[key];
                    if (answer[key.replace("_nda", "")]?.value) {
                        answer[key.replace("_nda", "")].nda = nda_val;
                    } else {
                        answer[key.replace("_nda", "")] = {
                            value: answer[key],
                            question_master: quest,
                            nda: nda_val,
                        };
                    }
                    delete answer[key];
                }
            }
        });
        answer.instance_id = template_origin.id + "-" + answer.instance_id;
        if (answer.system_field.encrypted && !encrypted) {
            encrypted = true;
        }
    });
    store.dispatch({
        type: "set_template_encrypted",
        value: encrypted,
    });

    template.total_instances = answers_array.filter(
        (f) => !Array.isArray(f)
    ).length;
    addNdaAnswers(answers_array);
    callback(false, { template, template_origin, answers_array });
};

export default normal_template;
