import React, { useEffect, useState } from 'react';
import { Table, Form, TableControl, Button } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import data_source from './template_connect'

import { searchInArray } from '../../helpers/search';

export default function TemplateSelector(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const template_list = useSelector(state => state.SurveyReducer.template_list);
    const template_selector = useSelector(state => state.SurveyReducer.template_selector);
    const [filter, setFilter] = useState("");
    useEffect(() => {
        data_source.get_template_list();
    }, []);
    const selectTemplate = (id, params) => {
        dispatch({
            type: 'set_template_selector',
            value: params
        });
    }
    const filteredList = searchInArray({ list: template_list, filter: filter, fields: ['title', 'editor', 'clinic'] });
    const next_status = (template_selector) ? 'enabled' : 'hidden';
    return (
        <Form className="event-wrap" title="Select survey template">
            <div className="monitor-table-info">
                <TableControl search={filter} className="survey-template" handleSearch={(params)=>{setFilter(params.value)}} />
                <Table name="templates_list" className="log-actions" table_key={'id'}
                    handleSelect={selectTemplate.bind(this)} selected={template_selector?.id}
                    headers={{
                        id: { label: 'ID', width: '4%' },
                        title: { label: 'Title', width: '15%' },
                        description: { label: 'Description', width: '35%' },
                        editor: { label: 'Editor', width: '15%' },
                        created: { label: 'Created', width: '11%' },
                        clinic: { label: 'Hospital', width: '10%' },
                        status: { label: 'Status', width: '10%'}
                    }} data={filteredList} />
                <Button status={next_status} label="Next" onClick={() => {
                    history.push('/template_report');
                }} />
            </div>
        </Form>
    )
}



