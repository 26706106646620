import React from 'react';
import { Table, Form, TableControl, Button } from '../../components';
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next';
import templates from './../../data/z_templates';
import { searchInArray } from '../../helpers/search';

class SurveySelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = { filter: '' };
    }
    handleFilter(params) {
        this.setState({ filter: params.value });
    }
    render() {
        if (!templates.length) return null;
        const filteredList = searchInArray({ list: templates, filter: this.state.filter, fields: ['title', 'editor'] });
        const next_status = (this.props.template_selector) ? 'enabled' : 'hidden';
        return (
            <Form className="event-wrap" title="Select survey template">
                <div className="monitor-table-info">
                    <TableControl search={this.state.filter} className="survey-template" handleSearch={this.handleFilter.bind(this)} />
                    <Table name="templates_list" className="log-actions" table_key={'fid'}
                        handleSelect={this.props.selectTemplate.bind(this)} selected={this.props.template_selector?.fid}
                        headers={{
                            fid: { label: 'ID', width: '4%' },
                            title: { label: 'Title', width: '20%' },
                            description: { label: 'Description', width: '40%' },
                            editor: { label: 'Creator', width: '20%' },
                            created: { label: 'Created', width: '6%' },
                            mid: { label: 'Hospital', width: '10%' },
                        }} data={filteredList} />
                    <Button status={next_status} label="Next" onClick={()=>{
                        this.props.history.replace('/survey_report');
                    }} />
                </div>
            </Form>

        )
    }
}

function mapStateToProps(state) {
    return {
        template_selector: state.SurveyReducer.template_selector
    };
}

function mapDispatchToProps(dispatch) {
    return ({
        selectTemplate(id,params) {
            dispatch({
                type: 'set_template_selector',
                value: params
            });
        },
    })
}

export default withTranslation()(SurveySelector = connect(mapStateToProps, mapDispatchToProps)(SurveySelector));





