import get_choices from "./getchoices";
import get_title from "./gettitle";
import question_leadmatrix from './../../data/z_leadmatrix';

function matrix(question, survey_answer, lang = 'default') {
    const result = [];
    result['header'] = get_title(question, lang);
    result[get_title(question_leadmatrix.elementsJSON[0], lang)] = side(0, question, survey_answer, lang);
    result[get_title(question_leadmatrix.elementsJSON[1], lang)] = side(1, question, survey_answer, lang);

    return result;
}

function side(side_index, question, survey_answer, lang = 'default') {
    const result = [];


    const question_side = question_leadmatrix.elementsJSON[side_index];
    const side_name = question_side.name;

    result['header'] = [];
    question_side.columns.forEach(column => {
        result['header'].push(get_title(column, lang));
    })

    question_side.rows.forEach(row => {
        result[row] = [];
        question_side.columns.forEach(column => {
            if (!survey_answer[question.name] || typeof survey_answer[question.name] !== 'object') return;
            let side=survey_answer[question.name]?.[side_name]
            if (side) {
                if (column.name === 'comment') result[row].push (side[row]?.[column.name]);
                else result[row].push (get_choices (column, side[row]?.[column.name], lang));
            }
        })
    })
    return result;
}

function plain(question, survey_answer, lang = 'default') {
    const result = [];
    const q_name = question.name;
    let s_name, r_name, c_name, a_name, question_side;
    //left side
    question_side = question_leadmatrix.elementsJSON[0];
    s_name = question_side.name;
    question_side.rows.forEach(row => {
        r_name = row;
        question_side.columns.forEach(column => {
            c_name = column.name;
            a_name = `${q_name} ${s_name} ${r_name} ${c_name}`;
            if (!survey_answer[question.name] || typeof survey_answer[question.name] !== 'object') return;
            if (column.name === 'comment') result[a_name] = survey_answer[question.name]?.[s_name][row]?.[column.name] || null;
            else result[a_name] = get_choices(column, survey_answer[question.name]?.[s_name][row]?.[column.name], lang);
        })
    })
    //right side
    question_side = question_leadmatrix.elementsJSON[1];
    s_name = question_side.name;
    question_side.rows.forEach(row => {
        r_name = row;
        question_side.columns.forEach(column => {
            c_name = column.name;
            a_name = `${q_name} ${s_name} ${r_name} ${c_name}`;
            if (!survey_answer[question.name] || typeof survey_answer[question.name] !== 'object') return;
            if (column.name === 'comment') result[a_name] = survey_answer[question.name]?.[s_name][row]?.[column.name] || null;
            else result[a_name] = get_choices(column, survey_answer[question.name]?.[s_name][row]?.[column.name], lang);
        })
    })
    return result;
}

export {
    plain,
    matrix,
}


