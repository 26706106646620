import React from "react";


const OptionsMapedValueSelector = (props)  => {
    const onChange = (params) => {
        props.onChange ({ row_key: props.row_key,map_val: props.map_value, val: params.target.value })
    }
    return (
        <select onChange={onChange} value={props.value}>
            <option key={props.row_key+"val-map-header"} value={""} defaultValue="" >
                Not found
            </option>
            <option key={props.row_key+"val-map-header-na"} value={props.na_value.value} >
                {props.na_value.label}
            </option>
            {props.List.map ((option,index) => {
                return (
                    <option key={props.row_key+option.key+index} value={option.value}>
                        {option.label}
                    </option>
                );
            })}
        </select>
    );
}

export default OptionsMapedValueSelector;