import { Pagination, PaginationItem } from "@mui/material";
import PdfEditorContext from "../pdf_editor_context";

const PdfToolbarPaginator = () => {
    const renderPaginationItem = (item) => {
        if (
            item.selected ||
            item.type === "previous" ||
            item.type === "next" ||
            item.type === "last" ||
            item.type === "first"
        ) {
            return <PaginationItem disableRipple component="div" {...item} />;
        }

        return null;
    };

    return (
        <PdfEditorContext.Consumer>
            {({ data, activePage, onPageChange }) => (
                <Pagination
                    size="small"
                    boundaryCount={0}
                    siblingCount={0}
                    showFirstButton
                    showLastButton
                    renderItem={renderPaginationItem}
                    count={data.pages.length}
                    page={activePage + 1}
                    onChange={(e, v) => onPageChange(v - 1)}
                />
            )}
        </PdfEditorContext.Consumer>
    );
};

export default PdfToolbarPaginator;
