import { useContext, useEffect } from "react";
import { TextField, Typography, Box, Button } from "@mui/material";
import PdfEditorContext from "../pdf_editor_context";

const PdfToolbarLayerEdit = () => {
    const pdfEditorContext = useContext(PdfEditorContext);

    useEffect(() => {
        pdfEditorContext.setEditObject({
            ...pdfEditorContext.data.layers[pdfEditorContext.activeLayer],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PdfEditorContext.Consumer>
            {({ editObject, setEditObject, isInstance }) => (
                <Box sx={{ paddingTop: 1 }}>
                    <Typography variant="h6" component="h6">
                        Layer edit
                    </Typography>
                    <TextField
                        id="layer_name"
                        label="Name"
                        variant="standard"
                        value={editObject?.placeholder || ""}
                        fullWidth
                        onChange={(e) => {
                            setEditObject({
                                ...editObject,
                                placeholder: e.target.value,
                            });
                        }}
                    />
                    <Box>
                        <TextField
                            id="layer_source_select_native"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            label="Source"
                            fullWidth
                            variant="standard"
                            value={editObject?.source || "1"}
                            onChange={(e) => {
                                setEditObject({
                                    ...editObject,
                                    source: e.target.value,
                                    receiver: null,
                                });
                            }}
                        >
                            {[
                                { label: "PCOM", value: "1" },
                                { label: "3rd party", value: "2" },
                                { label: "Free form", value: "3" },
                            ].map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                    {isInstance && (
                        <Box>
                            <TextField
                                id="layer_receiver"
                                label="Receiver"
                                variant="standard"
                                fullWidth
                                disabled={editObject?.source !== "3"}
                                value={editObject?.receiver || ""}
                                onChange={(e) => {
                                    setEditObject({
                                        ...editObject,
                                        receiver: e.target.value,
                                    });
                                }}
                            />
                            {editObject?.source !== "3" && (
                                <Box>
                                    <Button disableRipple>Search</Button>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            )}
        </PdfEditorContext.Consumer>
    );
};

export default PdfToolbarLayerEdit;
