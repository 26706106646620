import store from "../store";

export function LoaderShowInfitite(msg = "prepare surveys...") {
    store.dispatch({
        type: "set_loader_content",
        content: msg,
    });
    store.dispatch({
        type: "loader_show_infinite",
    });
}

export function LoaderHideInfitite() {
    store.dispatch({
        type: "loader_hide",
    });
}