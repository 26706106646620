import { Box, Button } from "@mui/material";
import PdfEditorContext from "../pdf_editor_context";

const PdfEditorFooter = () => {
    return (
        <PdfEditorContext.Consumer>
            {({
                editMode,
                onEditSave,
                onEditCancel,
                onDocumentSave,
                onPageClear,
                isLocked,
            }) => (
                <Box
                    sx={{
                        display: "flex",
                        columnGap: "15px",
                        width: "100%",
                        padding: "10px 20px",
                        justifyContent: "end",
                    }}
                >
                    {editMode ? (
                        <>
                            <Button
                                disableRipple
                                variant="outlined"
                                onClick={onEditCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                disableRipple
                                variant="contained"
                                onClick={onEditSave}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <>
                            {!isLocked && (
                                <Button
                                    disableRipple
                                    variant="outlined"
                                    onClick={onPageClear}
                                >
                                    Clear
                                </Button>
                            )}
                            <Button
                                disableRipple
                                variant="contained"
                                onClick={onDocumentSave}
                            >
                                Save
                            </Button>
                        </>
                    )}
                </Box>
            )}
        </PdfEditorContext.Consumer>
    );
};

export default PdfEditorFooter;
