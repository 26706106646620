//import nosleep from "nosleep.js";
import ApiModel from "./api_model";
import Connector from "./http_connector";

const connector = new Connector({
    link: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_LINK,
    endpoint: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_ENDPOINT,
});
class apiModelAuth extends ApiModel {
    auth(callback) {
        if (!window.uri_params.otp_param && !window.uri_params.fid) {
            callback({ api_error: { message: "Data error: Keys dont exist" } });
            return;
        }
        const payload = {};
        payload.mount_name = "auth";
        payload.mount_type = "validate_OTP_keys";
        payload.params = {
            otp_param: window.uri_params.otp_param,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                window.token = res.api_data.token;
                window.auth = res.api_data.auth;

                callback(false, true);
            } else {
                if (err?.api_error?.message) callback(err);
                else callback({ api_error: { message: "Auth: Wrong keys" } });
            }
        });
    }
    get_appuser_info(callback) {
        if (!window.auth) {
            callback({ api_error: { message: "Error getting user info" } });
            return;
        }
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "system";
        payload.mount_type = "get_appuser_info";
        payload.params = {
            search_auid: window.auth.auid,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.username = res.api_data.username;
                callback(false, true);
            } else {
                callback({ api_error: { message: "Error getting user info" } });
            }
        });
    }
    get_doctor_info(callback) {
        if (!window.auth) {
            callback({ api_error: { message: "Error getting user info" } });
            return;
        }
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "system";
        payload.mount_type = "get_doctor_info";
        payload.params = {
            entid: window.auth.entid,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.doctorname = res.api_data.zaname;
                callback(false, true);
            } else {
                callback({ api_error: { message: "Error getting user info" } });
            }
        });
    }
}

const apiModel = new apiModelAuth(connector);

export default apiModel;
