export default function matrix(question, survey_answers, lang = "default") {
    const result = [];

    let survey_answer = survey_answers[question.name];

    if (!survey_answer) return result;

    if (survey_answer === "N/A" || survey_answer.value === "N/A" || survey_answer.nda) {
        result["answer"] = "N/A";
        return result;
    }

    result["answer"] = "L1: " + Side(survey_answer.L1);
    result["answer"] += ", L2: " + Side(survey_answer.L2);

    return result;
}

function Side(sideAnswers) {
    let answerString = " [ ";

    if (!sideAnswers?.side_enabled || sideAnswers.side_enabled === "No") {
        return "No";
    }

    const probes = sideAnswers?.probes ?? [];

    answerString +=
        "Probes" +
        ": [ " +
        "C: " +
        (probes["C"]?.electrode_configuration ?? "") +
        ", " +
        "3: " +
        (probes["3"]?.electrode_configuration ?? "") +
        ", " +
        "2: " +
        (probes["2"]?.electrode_configuration ?? "") +
        ", " +
        "1: " +
        (probes["1"]?.electrode_configuration ?? "") +
        ", " +
        "0: " +
        (probes["0"]?.electrode_configuration ?? "") +
        " ], ";

    answerString +=
        "Amplitude" +
        ": " +
        (sideAnswers?.amplitude_type === "V"
            ? (sideAnswers?.amplitude_v ?? "") + " V"
            : (sideAnswers?.amplitude_ma ?? "") + " mA") +
        ", ";

    answerString +=
        "Pulse Width: " + (sideAnswers?.pulse_width ?? "") + " microSec, ";
    answerString += "Frequency: " + (sideAnswers?.frequency ?? "") + " Hz";
    answerString += " ]";

    return answerString;
}
