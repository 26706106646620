import React from 'react';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';

class Notification extends React.Component{
    render() {
        return (
                <Notifications notifications={this.props.notifications} />
        )
    }
}

function mapStateToProps(state) {
    return {
        notifications: state.notifications
    };
}

export default Notification = connect(mapStateToProps, null)(Notification);
