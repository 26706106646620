const data = {
    "title": "Test survey for data analyzis",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "inputdate",
                    "name": "patient_birthdate",
                    "title": "Date of birthday"
                },
                {
                    "type": "radiogroup",
                    "name": "patient_sex",
                    "title": "Sex of the patient",
                    "choices": [
                        {
                            "value": "male",
                            "text": { "default": "Male", "de": "Männlich" }
                        },
                        {
                            "value": "female",
                            "text": { "default": "Female", "de": "Weiblich" }
                        },
                        {
                            "value": "trans",
                            "text": "Trans"
                        }
                    ]
                }
            ],
            "title": "Common data about patient"
        },
        {
            "name": "page2",
            "elements": [
                {
                    "type": "leadmatrix8combined",
                    "name": "lead_position",
                    "title": "Positions of leads"
                },
                {
                    "type": "leadprobes8v2",
                    "name": "lead_programming",
                    "title": "Programming of leads"
                }
            ],
            "title": "Data about lead position and programming"
        }
    ]
}

export default data;