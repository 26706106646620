const data = [
    {
        auid: 73,
        name: 'Kimio Tremmel',
        birthdate: '2021-01-08',
        address: 'Greentree street - 45, Berlin, 10234',
        phone: '00334 345345 34535',
        email: 'demo@gmail.com'
    },
    {
        auid: 77,
        name: 'Alex Jasinsky',
        birthdate: '2021-11-12',
        address: 'Lookmore street - 45, LA, 10234',
        phone: '00334 345345 34535',
        email: 'alex@gmail.com'
    },
    {
        auid: 123,
        name: 'Arnold Swarchneger',
        birthdate: '2020-10-12',
        address: 'Ferrari street - 45, London, 10234',
        phone: '00334 345345 34535',
        email: 'arnold@gmail.com'
    },
    {
        auid: 666,
        name: 'Silvester Stallone',
        birthdate: '2019-03-23',
        address: 'Bla bla bla street - 45, Moscow, 10234',
        phone: '00334 345345 34535',
        email: 'silvestor@gmail.com'
    }
]

export default data;