import ApiModel from "./api_model";
import Connector from "./http_connector";

const connector = new Connector({
    link: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_LINK,
    endpoint: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_ENDPOINT,
});
class apiModelAuth extends ApiModel {
    load_template(templateId, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "pdf";
        payload.mount_type = "load_template";
        payload.params = {
            au_aid: window.uri_params.auid,
            template_id: templateId,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
    load_instance(instanceId, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "pdf";
        payload.mount_type = "load_instance";
        payload.params = {
            au_aid: window.uri_params.auid,
            instance_id: instanceId,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
    save_template(templateId, template, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "pdf";
        payload.mount_type = "save_template";

        payload.params = {
            au_aid: window.uri_params.auid,
            template_id: templateId,
            payload: template,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
    save_instance(templateId, instanceId, template, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "pdf";
        payload.mount_type = "save_instance";

        payload.params = {
            au_aid: window.uri_params.auid,
            template_id: templateId,
            instance_id: instanceId,
            payload: template,
        };

        this._connectApi(payload, (err, res) => {
            if (!err) {
                window.uri_params.otp_param = res.api_data.otp_param_s64;
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
}

const apiModel = new apiModelAuth(connector);

export default apiModel;
