import React from 'react'
import Cleave from 'cleave.js/react';
import { withTranslation } from 'react-i18next';

class InputField extends React.Component{
    componentDidMount() {
        if (this.props.autoFocus) {
            this.nameInput.focus();
        }
    }
    onKeyDown(event) {
        if (this.props['onEnter'] && event.key==='Enter') {
            this.props.onEnter({name: this.props.name, value: event.target.value}) 
        }
    }
    onChange(event) {
        let maxlength = (this.props.maxlength) ? Number(this.props.maxlength)+ 1 : 1000;
        if (this.props['onChange'] && event.target.value.length < maxlength)
        this.props.onChange({name: this.props.name, value: event.target.value})
    }
    getNamePostfix() {
        return (this.props.postfix) ? this.props.name + '_' + this.props.postfix : this.props.name + '_edit';
    }
    getWrapClassName() {
        let wrap_class_name = "input field_wrap " + this.getNamePostfix();
        if (this.props.className) wrap_class_name += ' ' + this.props.className;
        return wrap_class_name;
    }
    isDisabled() {
        let disabled = false;
        if (this.props.status && this.props.status === 'disabled') {
            disabled = true;
        }
        return disabled;
    }
    isHidden() {
        let hidden = false;
        if (this.props.status && this.props.status === 'hidden') {
            hidden = true;
        }
        return hidden;
    }
    showError() {
        if(this.props.error && this.props.error['message']) {
            return (<span className="error_tag">{this.props.error.message}</span>)
        } else return null;
    }
    showLabel() {
        const { t } = this.props;
        if (this.props.label) {
            return (
                <label htmlFor={this.getNamePostfix()}>{t(this.props.label)}</label>
            )
        } else {
            return null;
        }
    }
    showInput() {
        let value = (this.props.value) ? this.props.value : '';
        let autofocus = (this.props.autofocus) ? true : false;
        let maxlength = (this.props.maxlength) ? this.props.maxlength : 1000;
        if (this.props.input_control) {
            return (
                <Cleave id={this.getNamePostfix()} name={this.getNamePostfix()} type={this.props.type} data-tip={this.props.tip} value={value}
                        placeholder={this.props.placeholder}
                        options={this.props.input_control}
                        maxLength={maxlength}
                        autoCorrect="off" 
                        autoCapitalize="none" 
                        autoComplete={this.props.autoComplete || "off"}
                        ref={(input) => { this.nameInput = input; }}
                        onKeyDown={this.onKeyDown.bind(this)}
                        onChange={this.onChange.bind(this)}
                        disabled={this.isDisabled()}/>
            )
        } else {
            return (
                <input autoFocus={autofocus} id={this.getNamePostfix()} name={this.getNamePostfix()} data-tip={this.props.tip} value={value}
                        placeholder={this.props.placeholder}
                        autoCorrect="off" 
                        autoCapitalize="none" 
                        autoComplete={this.props.autoComplete || "off"}
                        ref={(input) => { this.nameInput = input; }}
                        type={this.props.type}
                        maxLength={maxlength}
                        onKeyDown={this.onKeyDown.bind(this)}
                        onChange={this.onChange.bind(this)}
                        disabled={this.isDisabled()}/>
            )
        }
    }
    render() {
        if (this.isHidden()) {
            return null
        } else {
            return (
                <div className={this.getWrapClassName()}>
                    {this.showLabel()}
                    {this.showInput()}
                    {this.showError()}
                </div>
            )
        }
    }
}

export default withTranslation()(InputField);