import { StyleSheet, Font } from "@react-pdf/renderer";
import font_light from "./../../static/fonts/RobotoCondensed-Light.ttf";
import font_regular from "./../../static/fonts/RobotoCondensed-Regular.ttf";

Font.register({
    family: "RobotoCondensedLight",
    src: font_light,
});
Font.register({
    family: "RobotoCondensed",
    src: font_regular,
});
Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const headers = {
    area_name: { label: "Area", width: "10%" },
    event_time: { label: "Time", width: "13%" },
    accept_time: { label: "Accepted", width: "6%" },
    complete_time: { label: "Cleared", width: "6%" },
    reaction_time: { label: "Reaction", width: "6%" },
    event_name: { label: "Type", width: "10%" },
    status: { label: "Status", width: "8%" },
    user_name: { label: "User", width: "13%" },
    note: { label: "Note", width: "15%" },
    note_user_name: { label: "Author", width: "13%" },
};
const BORDER_COLOR = "#dfdfdf";
const BORDER_STYLE = "solid";

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 45,
        paddingHorizontal: 35,
    },
    page_info: {
        marginBottom: 20,
    },
    page: {
        flexDirection: "row",
        // backgroundColor: '#E4E4E4'
        display: "flex",
    },
    header: {
        fontSize: 8,
        marginBottom: 20,
        color: "grey",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    header_logo: {
        fontSize: 8,
        marginBottom: 20,
        width: 100,
        color: "grey",
    },
    header_info: {
        fontSize: 8,
        marginBottom: 20,
        width: 150,
        color: "grey",
        textAlign: "right",
    },
    header_survey: {
        fontSize: 16,
        marginBottom: 10,
        color: "grey",
        display: "flex",
        flexDirection: "column",
    },
    survey_info: {
        color: "black",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "center",
    },
    survey_description: {
        color: "grey",
        fontSize: 12,
        fontFamily: "RobotoCondensed",
    },
    survey_person: {
        backgroundColor: BORDER_COLOR,
        borderRadius: 3,
        color: "black",
        padding: 5,
        fontSize: 14,
        fontFamily: "RobotoCondensedLight",
    },
    chapter_header: {
        marginTop: 20,
        marginBottom: 8,
        fontSize: 16,
        padding: 5,
        fontFamily: "RobotoCondensed",
        backgroundColor: BORDER_COLOR,
        borderTopRightRadius: 20,
    },
    h2: {
        fontSize: 20,
        marginTop: 0,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        color: "#AAA",
        borderBottom: 0.5,
        borderBottomColor: BORDER_COLOR,
        width: "auto",
    },
    question: {
        fontSize: 14,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontFamily: "RobotoCondensedLight",
        marginBottom: 8,
        borderBottom: 0.5,
        borderBottomColor: BORDER_COLOR,
    },
    question_title: {
        flex: 1,
        textAlign: "left",
        paddingLeft: 5,
    },
    question_answer: {
        flex: 1,
        textAlign: "right",
        paddingLeft: 5,
    },
    row: {
        margin: 0,
        padding: 0,
        marginBottom: 10,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    row_wrap: {
        margin: 0,
        padding: 0,
        marginBottom: 0,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    row_title_description: {
        margin: 0,
        padding: 0,
        marginBottom: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        fontFamily: "RobotoCondensed",
    },
    title: {
        fontSize: 10,
        width: 70,
        fontWeight: 100,
        color: "#888",
    },
    description: {
        fontSize: 10,
    },
    column_wrap: {
        margin: 0,
        padding: 0,
        marginBottom: 0,
        flexDirection: "column",
        justifyContent: "space-between",
        fontFamily: "RobotoCondensed",
        width: "49%",
    },
    column_wrap2: {
        margin: 0,
        marginLeft: 10,
        padding: 0,
        marginBottom: 0,
        flexDirection: "column",
        justifyContent: "space-between",
        fontFamily: "RobotoCondensed",
        width: "49%",
    },
    left: {
        flexGrow: 0,
        flexShrink: 1,
        width: "66%",
    },
    right: {
        flexDirection: "row",
        flexGrow: 2,
        flexShrink: 1,
        textAlign: "right",
        width: "33%",
        justifyContent: "flex-end",
    },
    row_table_wrap: {
        margin: 0,
        padding: 0,
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "space-between",
    },

    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 0.5,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        fontFamily: "RobotoCondensed",
        margin: 0,
        padding: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableColHeader: {
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 0.5,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        backgroundColor: "#BBB",
    },
    tableCol: {
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 0.5,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColArea: {
        width: headers.area_name.width,
    },
    tableColTime: {
        width: headers.event_time.width,
    },
    tableColAccepted: {
        width: headers.accept_time.width,
    },
    tableColCleared: {
        width: headers.complete_time.width,
    },
    tableColReaction: {
        width: headers.reaction_time.width,
    },
    tableColType: {
        width: headers.event_name.width,
    },
    tableColStatus: {
        width: headers.status.width,
    },
    tableColUser: {
        width: headers.user_name.width,
    },
    tableColNote: {
        width: headers.note.width,
    },
    tableColAuthor: {
        width: headers.note_user_name.width,
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 8,
        fontWeight: 700,
    },
    tableCell: {
        margin: 5,
        fontSize: 6,
    },
    pageNumber: {
        position: "absolute",
        fontSize: 8,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

export default styles;
