const data = {
    "name": "system_page",
    "elements": [
        {
            "type": "instanceid",
            "name": "instance_id",
            "title": "ID"
        },
        {
            "type": "doctorid",
            "name": "doctor_id",
            "title": "Doctor"
        },
        {
            "type": "patientname",
            "name": "instance_answering_person_name",
            "title": "Patient name",
        },
        {
            "type": "patientbd",
            "name": "instance_answering_person_bd",
            "title": "Birthdate"
        },
        {
            "type": "relevantdate",
            "name": "relevant_date",
            "title": "Relevantes Datum"
        },
        {
            "type": "lc",
            "name": "lc",
            "title": "Zuletzt bearbeitet"
        },

    ]
}

export default data;

// const data = {
//     "name": "system_page",
//     "elements": [
//         {
//             "type": "instanceid",
//             "name": "instance_id",
//             "title": "ID"
//         },
//         {
//             "type": "patientname",
//             "name": "instance_answering_person_name",
//             "title": "Patient name",
//         },
//         {
//             "type": "patientbd",
//             "name": "instance_answering_person_bd",
//             "title": "Birthdate"
//         },
//         {
//             "type": "patienencrypted",
//             "name": "instance_answering_person_is_pseudonym",
//             "title": "Pseudonym"
//         },
//         {
//             "type": "relevantdate",
//             "name": "relevant_date",
//             "title": "Survey date"
//         },
//     ]
// }