const leadMatrix8v2 = {
    name: "leadmatrix8v2",
    iconName: "icon-default",
    title: "Lead Position",
    elementsJSON:
        [

            {
                name: "left", title: { "default": "Left", "de": "Links" },
                "type": "matrixdropdown",
                "columns": [
                    {
                        "name": "manufacturer",
                        "title": { "default": "Manufacturer", "de": "Manufacturer" },
                        "cellType": "dropdown",
                        "showOptionsCaption": "false",
                        "choices": [
                            {
                                "value": "medtronic",
                                "text": "Medtronic"
                            },
                            {
                                "value": "axion",
                                "text": "Axion"
                            },
                            {
                                "value": "others",
                                "text": {
                                    "default": "Others",
                                    "de": "Andere"
                                }
                            }
                        ]
                    },
                    {
                        "name": "options",
                        "title": { "default": "Type of lead", "de": "Type of lead" },
                        "cellType": "dropdown",
                        "showOptionsCaption": "false",
                        "choices": [
                            {
                                "value": "test",
                                "text": "Test"
                            },
                            {
                                "value": "tined_lead",
                                "text": "Tined Lead"
                            }
                        ]
                    },
                    {
                        "name": "comment",
                        "title": { "default": "Comment", "de": "Comment" },
                        "cellType": "text"
                    }
                ],
                "rows": [
                    "S1",
                    "S2",
                    "S3",
                    "S4"
                ]
            },
            {
                name: "right", title: { "default": "Right", "de": "Rechts" }, startWithNewLine: false,
                "type": "matrixdropdown",
                "columns": [
                    {
                        "name": "manufacturer",
                        "title": { "default": "Manufacturer", "de": "Manufacturer" },
                        "cellType": "dropdown",
                        "showOptionsCaption": "false",
                        "choices": [
                            {
                                "value": "medtronic",
                                "text": "Medtronic"
                            },
                            {
                                "value": "axion",
                                "text": "Axion"
                            },
                            {
                                "value": "others",
                                "text": {
                                    "default": "Others",
                                    "de": "Andere"
                                }
                            }
                        ]
                    },
                    {
                        "name": "options",
                        "title": { "default": "Type of lead", "de": "Type of lead" },
                        "cellType": "dropdown",
                        "showOptionsCaption": "false",
                        "choices": [
                            {
                                "value": "test",
                                "text": "Test"
                            },
                            {
                                "value": "tined_lead",
                                "text": "Tined Lead"
                            }
                        ]
                    },
                    {
                        "name": "comment",
                        "title": { "default": "Comment", "de": "Comment" },
                        "cellType": "text"
                    }
                ],
                "rows": [
                    "S1",
                    "S2",
                    "S3",
                    "S4"
                ]
            }
        ],
    onAfterRender(question, htmlElement) {
        htmlElement.className += ' leads8';
    }
}

export default leadMatrix8v2;