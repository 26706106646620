import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

const ToolBoxItem = (props) => {
    function onDragStart(e) {
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData(
            "text",
            JSON.stringify({
                type: props.type,
                label: props.label,
            })
        );
    }

    return (
        <ListItem
            disablePadding
            sx={{ padding: "5px", cursor: "grab" }}
            draggable
            onDragStart={onDragStart}
        >
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText primary={props.label} />
        </ListItem>
    );
};

export default ToolBoxItem;
