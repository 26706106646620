export default function get_choices(question, answer, lang, showValues = false) {
    if (!answer) return null;

    let found = question.choices.find(element => {
        if (typeof element === 'object') return element.value === answer;
        else return element === answer;
    });
    if (!found) {
        found=answer
    }

    if (typeof found === 'object') {
        if (showValues && found.code) return found.code
        return (typeof found.text === 'object') ? found.text?.[lang] || found.text?.default || found.value : found.text || found.value;
    }
    return found;
}