import { Parser } from "json2csv";
import fileDownload from "js-file-download";
import dayjs from "dayjs";

export default function tableCSV({
    title,
    showQuestions,
    locale,
    data,
    showValues,
}) {
    const createHeader = () => {
        const table_header = {};
        showQuestions.forEach((question) => {
            table_header[question.name] = question;
        });
        return table_header;
    };

    const table_header = createHeader();

    const renderHead = () => {
        const csv_label = [];
        //selection column
        Object.keys(table_header).forEach((key) => {
            const header_label = table_header[key].label;
            const header_numeration = table_header[key].number
                ? table_header[key].number
                : "";
            const header = header_numeration
                ? header_numeration + " " + header_label
                : header_label;
            csv_label.push({ label: header, value: key });
        });
        if (!csv_label.length) return null;
        return csv_label;
    };
    const renderRow = () => {
        const renderCell = (row) => {
            const result = {};
            Object.keys(table_header).forEach((key) => {
                result[key] = row[key] ? row[key] : null;
            });
            return result;
        };

        const result = [];

        data.forEach((row) => {
            result.push(renderCell(row));
        });

        return result;
    };

    const fields = renderHead();
    const delimiter = ";";
    const parser = new Parser({ fields, delimiter });
    const csv = parser.parse(renderRow());
    const date = dayjs(new Date()).format("YYYY-MM-DD_HHmmss");
    fileDownload(csv, `${title}_${date}.csv`);
    return csv;
}
