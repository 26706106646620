import "./check_list.css"


const CheckList = (props) => {
    if (!Array.isArray(props?.list)) return null;

    var isCheckedClass = (item) =>{
        return item.checked ? "checklist-checked-item" : "checklist-not-checked-item";
    }

    const onChange=(params)=>{
        if (props.onChangeChecked)
            props.onChangeChecked({key:params.target.value,checked:params.target.checked})
    }

    return (
        <div className="checkList">
            <div className="title">{props.title}</div>
            <div className="checklist-container">
                {props.list.map((item) => (
                    <div className={"checklist-item "+isCheckedClass(item)} key={item.key}>
                        <input value={item.key} defaultChecked={item.checked} type="checkbox" onChange={onChange} />
                        <div>{item.label}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CheckList;