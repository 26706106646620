export default function JournalReducer(state = {
    journal: {
        list: [],
        selected: false,
        details: {},
        list_pagination: { rpp: 20, max_pages: 0, total_rows: 0, page: 1 },
        filter: {},
        csv: null
    },
    event_row: {},
    users: {}
}, action) {
    switch (action.type) {
        case 'res_journal_list':
            state.journal.list = action.data.list;
            state.journal.list_pagination = action.data.list_pagination;
            return { ...state };
        case 'res_journal_get_users':
            state.users = action.data.field_options;
            return { ...state };
        case 'res_journal_get_events':
            state.events = action.data.field_options;
            return { ...state };
        case 'res_journal_list_csv':
            state.csv = action.data;
            return { ...state };
        case 'JOURNAL_SELECTED':
            state.journal.selected = action.value;
            state.event_row = action.event_row
            return { ...state };
        case 'JOURNAL_DESELECTED':
            state.journal.selected = null;
            state.event_row = {}
            return { ...state };
        case 'JOURNAL_NAVIGATION':
            state.journal.list_pagination[action.params.name] = action.params.value;
            state.journal.list_pagination = { ...state.journal.list_pagination }
            state.journal.selected = null;
            state.event_row = {}
            return { ...state };
        case 'JOURNAL_FILTER':
            state.journal.filter[action.params.name] = action.params.value;
            state.journal.filter = { ...state.journal.filter }
            return { ...state };
        default:
            break;
    }
    return state;
}