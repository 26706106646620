import ApiModel from "../../api/api_model";
import Connector from "../../api/http_connector";
import store from "../../store";
import normal_template from "./data_parser/normal_template";
import virtual_template from "./data_parser/virtual_template";
import { decode } from "../../helpers/base64decode";
import { uncompress } from "../../helpers/pako";
import { encode } from "js-base64";

const connector = new Connector({
    link: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_LINK,
    endpoint: process.env.REACT_APP_CONNECT_QUESTIONNAIRE_ENDPOINT,
});

class apiModelSurvey extends ApiModel {
    load_answers(anonymous_mode, only_scf, callback) {
        store.dispatch({
            type: "set_loader_content",
            content: "loading surveys...",
        });
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "surveydata";
        payload.mount_type = "load_templates_with_surveys";
        payload.params = {
            do_compress: true,
            fid: window.uri_params.fids,
            do_anonymous: anonymous_mode,
            only_SCF: only_scf,
        };
        this._connectApi(payload, async (err, res) => {
            if (!err) {
                const uD = await uncompress(res.api_data);
                // const uD = res;
                let temp_template = {};
                const template_record = Object.values(uD)[0]?.template;
                if (template_record?.arr_fragen) {
                    const json_template = decode(template_record?.arr_fragen);
                    temp_template = JSON.parse(json_template);
                }
                let virtual_templates = [];

                if (template_record?.arr_combined) {
                    const json_template_combined = decode(
                        template_record?.arr_combined
                    );
                    virtual_templates.push(JSON.parse(json_template_combined));
                }

                temp_template.type !== "virtual"
                    ? normal_template(
                          Object.values(uD)[0],
                          (err, res) => {
                              store.dispatch({
                                  type: "res_get_surveys_for_template",
                                  success: res.success,
                                  data: {
                                      template: res.template,
                                      surveys: res.answers_array,
                                      template_origin: res.template_origin,
                                  },
                              });
                              //save virtual templates
                              store.dispatch({
                                  type: "res_get_virtual_templates",
                                  success: res.success,
                                  data: {
                                      virtual_templates: virtual_templates,
                                  },
                              });
                              callback(false, true);
                          }
                      )
                    : virtual_template(temp_template, (err, res) => {
                          //leave this for initial idea to keep virtual template as ordinary one
                        console.log ('virtual_template');
                          store.dispatch({
                              type: "res_get_surveys_for_template",
                              success: res.success,
                              data: {
                                  template: res.template,
                                  surveys: res.answers_array,
                                  template_origin: res.template_origin,
                              },
                          });
                          callback(false, true);
                      });
            } else {
                callback(err, false);
            }
        });
    }
    load_virtual(virtual, anonymous_mode, only_scf, callback) {
        virtual_template(virtual, anonymous_mode, only_scf, (err, res) => {
            if (err) {
                console.log("Failed to load combination");
                console.log(err);
                return;
            }
            store.dispatch({
                type: "res_get_surveys_for_template",
                success: res.success,
                data: {
                    template: res.template,
                    surveys: res.answers_array,
                    template_origin: res.template_origin,
                },
            });
            callback(false, true);
        });
    }
    get_doctor_info(doctor_id, callback) {
        if (!window.auth) {
            callback({ api_error: { message: "Error getting user info" } });
            return;
        }
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "system";
        payload.mount_type = "get_doctor_info";
        payload.params = {
            entid: doctor_id,
        };
        this._connectApi(payload, (err, res) => {
            if (!err) {
                callback(false, res);
            } else {
                console.log(err);
                callback({ api_error: { message: "Error getting user info" } });
            }
        });
    }

    save_template(template, callback) {
        const payload = {};
        payload.auth = window.auth;
        payload.mount_name = "webtemplate";
        payload.mount_type = "save_template";
        payload.params = {
            fid: template.id,
        };

        let arr_fragen = null;
        let convert_err = null;
        try {
            arr_fragen = encode(JSON.stringify(template), true);
        } catch (err) {
            convert_err = err;
            console.error(err);
        }
        if (convert_err) {
            callback("Convert error", false);
            return;
        }
        const data = {
            arr_fragen_json64: arr_fragen,
        };
        payload.params.data = data;

        this._connectApi(payload, (err, res) => {
            if (!err) {
                callback(false, res.api_data);
            } else {
                callback(err, res);
            }
        });
    }
}

const apiModel = new apiModelSurvey(connector);

export default apiModel;
